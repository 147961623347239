import { Select } from "antd";
import type { SelectProps } from "antd";

type TagInputProps = {
  value?: SelectProps["value"];
  onChange?: (value: string[]) => void;
  placeholder?: string;
  options?: SelectProps["options"];
};

const InputTags = ({
  value,
  onChange,
  placeholder,
  options,
}: TagInputProps) => {
  return (
    <Select
      mode="tags"
      className="w-full h-11 sm:h-9"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      getPopupContainer={(triggerNode) => triggerNode}
      labelRender={(label) => {
        return <div className="">{label.value}</div>;
      }}
      optionRender={(label) => {
        return (
          <div className="flex flex-col">
            <span className="mr-2 text-sm font-medium">{label.label}</span>
            <span className="text-xs font-normal text-gray-500">
              {label.value}
            </span>
          </div>
        );
      }}
    />
  );
};

export default InputTags;
