import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import { Banknote, Landmark } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CashflowAccountsBalances = () => {
  const navigate = useNavigate();
  const { data: chartOfAccounts } = trpc.books.cashflowAccounts.list.useQuery();

  return (
    <div className="grid grid-cols-1 gap-1">
      {chartOfAccounts?.map((account) => (
        <Card
          key={account.id}
          className="h-28"
          parentClassName="cursor-pointer hover:border hover:border-primary-700 mt-2"
          onClick={() =>
            navigate(`/books/cashflow-accounts/transactions/${account.id}`)
          }
        >
          <div className="font-medium">
            <div className="flex justify-between">
              <p className="text-sm">{account.name}</p>
              {account.type === "BANK" && (
                <Landmark className="w-5 h-5 text-gray-500" />
              )}
              {account.type === "CASH" && (
                <Banknote className="w-5 h-5 text-gray-500" />
              )}
            </div>
            <p className="text-[10px] text-gray-500">{account.type}</p>
          </div>
          <div className="pt-2 mt-8 border-t border-gray-200">
            <p className="text-base font-medium text-gray-700">
              {heffl.format.currency(account.balance, "AED")}
            </p>
            <p className="-mt-1 text-[10px] text-gray-500">BALANCE</p>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default CashflowAccountsBalances;
