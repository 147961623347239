import DataGrid from "@/components/dataGrid/DataGrid";
import DetailsPage from "@/components/details-page";
import heffl from "@/helpers/heffl";
import { getS3URL } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  formatCustomFields,
  renderCustomFieldValue,
} from "@heffl/server/src/helpers/customFields";
import FilePicker from "@heffl/ui/components/file-picker";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { PhoneInput } from "@heffl/ui/components/primitives/phone-input";
import { RadioGroup } from "@heffl/ui/components/primitives/radio-group";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import RenderHtml from "@heffl/ui/components/render-html";
import {
  Sortable,
  SortableDragHandle,
  SortableItem,
} from "@heffl/ui/components/sortable";
import InputTags from "@heffl/ui/components/tag-input";
import { downloadFile, makeEllipsis } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  ArrowUpRightFromSquare,
  Copy,
  Download,
  GripVertical,
  Mail,
  NotebookPen,
  Pencil,
  Plus,
  Rows4,
  Trash,
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { RadioItem } from "../documentTemplates/components/templateForm";
import {
  AddFormQuestionModal,
  EditFormQuestionModal,
} from "./components/question-field-modals";
import { EditCustomObjectRecordModal } from "./components/edit-custom-object-record";
import { EditFormModal } from "./list";
import useTeam from "@/lib/hooks/useTeam";

const FormDetails = () => {
  const params = useParams();
  const formId = Number(params.id);
  const [animationParent] = useAutoAnimate();

  const [editForm, setEditForm] = useState(false);
  const [showAddField, setShowAddField] = useState(false);
  const [editCustomField, setEditCustomField] = useState<number | null>(null);
  const [editCustomObjectRecord, setEditCustomObjectRecord] = useState<
    number | null
  >(null);

  const team = useTeam();

  const { data: formDetails } = trpc.forms.details.useQuery(formId);

  const { mutate: updatePosition } =
    trpc.customizations.customFields.positionUpdate.useMutation();

  const { mutate: deleteCustomObjectRecord } =
    trpc.customizations.objects.custom.records.delete.useMutation({
      onSuccess: () => {
        heffl.toast.success("Response deleted");
      },
      onError: (error) => {
        heffl.toast.error(error.message);
      },
    });

  if (!formDetails || !team) return <FullScreenSpinner />;

  return (
    <>
      <EditFormModal
        open={editForm}
        onClose={() => setEditForm(false)}
        formId={formDetails.id}
      />
      <AddFormQuestionModal
        open={showAddField}
        onClose={() => setShowAddField(false)}
        defaultValues={{
          customObjectId: formDetails.customObjectId,
          section: "CUSTOM_OBJECT",
        }}
      />
      {editCustomField && (
        <EditFormQuestionModal
          open={true}
          onClose={() => setEditCustomField(null)}
          customFieldId={editCustomField}
        />
      )}

      {editCustomObjectRecord && (
        <EditCustomObjectRecordModal
          recordId={editCustomObjectRecord}
          onClose={() => setEditCustomObjectRecord(null)}
        />
      )}

      <DetailsPage
        title={formDetails.title}
        description={`Created on ${dayjs(formDetails.createdAt).format(
          "DD/MM/YYYY"
        )}`}
        tabs={{
          items: [
            {
              label: "Builder",
              key: "builder",
              icon: Pencil,
              children: (
                <>
                  <div className="flex gap-2 justify-end w-full">
                    <Button
                      onClick={() => setShowAddField(true)}
                      variant="primary"
                      icon={Plus}
                      size="sm"
                    >
                      Question
                    </Button>

                    <Button
                      onClick={() => {
                        const host = team.subdomain
                          ? `https://${team.subdomain}.heffl.site`
                          : "https://clienthub.heffl.com";

                        const formLink = `${host}/forms/${formDetails.shortId}`;
                        copyToClipboard(formLink);
                      }}
                      variant="outline"
                      icon={Copy}
                      size="sm"
                    >
                      Copy form link
                    </Button>
                  </div>

                  <div className="flex flex-col gap-6 mx-auto w-full max-w-2xl">
                    <div>
                      <p className="text-3xl font-bold text-gray-900">
                        {formDetails.title}
                      </p>
                      {formDetails.description && (
                        <RenderHtml className="text-sm text-gray-500">
                          {formDetails.description}
                        </RenderHtml>
                      )}
                    </div>
                    <div className="flex flex-col gap-4" ref={animationParent}>
                      <Sortable
                        value={formDetails.customObjects?.customFields || []}
                        onValueChange={(fields) => {
                          const updatedCustomFields = fields.map(
                            (field, index) => ({
                              ...field,
                              position: index + 1,
                            })
                          );
                          updatePosition(updatedCustomFields);
                        }}
                      >
                        {formDetails.customObjects?.customFields.map(
                          (field) => (
                            <SortableItem
                              key={field.id}
                              value={field.id}
                              onClick={() => setEditCustomField(field.id)}
                            >
                              <SortableDragHandle
                                variant="ghost"
                                size="xs"
                                className="w-full h-full hover:bg-white text-wrap text-start"
                              >
                                <div className="flex flex-col gap-2 p-4 w-full rounded-lg border transition-all cursor-pointer hover:border-primary hover:border-1">
                                  <div className="flex justify-between">
                                    <label className="text-lg font-medium text-gray-900">
                                      {field.label}{" "}
                                      {field.required && (
                                        <span className="font-bold text-red-500">
                                          *
                                        </span>
                                      )}
                                    </label>
                                    <div className="flex gap-2 items-center">
                                      <Button
                                        size="xs"
                                        icon={Pencil}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setEditCustomField(field.id);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                      <GripVertical className="w-4 h-4 cursor-pointer" />
                                    </div>
                                  </div>
                                  {field.description && (
                                    <RenderHtml className="text-sm text-gray-500">
                                      {field.description}
                                    </RenderHtml>
                                  )}
                                  {(() => {
                                    switch (field.dataType) {
                                      case "TEXT":
                                        return (
                                          <Input
                                            placeholder={
                                              field.placeholder ||
                                              "Enter " +
                                                field.label.toLowerCase()
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                        );
                                      case "NUMBER":
                                        return (
                                          <Input
                                            type="number"
                                            placeholder={
                                              field.placeholder ||
                                              "Enter " +
                                                field.label.toLowerCase()
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                        );
                                      case "DATE":
                                        return (
                                          <Input
                                            type="date"
                                            placeholder={
                                              field.placeholder ||
                                              "Select " +
                                                field.label.toLowerCase()
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                        );
                                      case "SINGLE_OPTION":
                                      case "MULTIPLE_OPTION":
                                        return (
                                          <RadioGroup
                                            className="flex flex-col gap-3 pt-1"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            {field.values.map((option) => (
                                              <RadioItem
                                                key={option}
                                                value={option}
                                                label={option}
                                              />
                                            ))}
                                          </RadioGroup>
                                        );
                                      case "MULTIPLE_USER_SELECT":
                                        return (
                                          <InputTags
                                            placeholder={
                                              field.placeholder ||
                                              "Select " +
                                                field.label.toLowerCase()
                                            }
                                          />
                                        );
                                      case "FILE_PICKER":
                                        return (
                                          <div className="flex justify-start w-fit">
                                            <FilePicker label="Choose file" />
                                          </div>
                                        );
                                      case "LONG_TEXT":
                                        return (
                                          <Textarea
                                            placeholder={field.placeholder}
                                          />
                                        );
                                      case "MOBILE":
                                        return (
                                          <PhoneInput
                                            placeholder={
                                              field.placeholder ||
                                              "Enter mobile number"
                                            }
                                            defaultCountry="AE"
                                          />
                                        );
                                      case "EMAIL":
                                        return (
                                          <Input
                                            type="email"
                                            prefix={
                                              <Mail className="w-4 h-4" />
                                            }
                                            placeholder={
                                              field.placeholder || "Enter email"
                                            }
                                          />
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                </div>
                              </SortableDragHandle>
                            </SortableItem>
                          )
                        )}
                      </Sortable>
                      <Button
                        onClick={() => setShowAddField(true)}
                        variant="primary"
                        icon={Plus}
                        className="w-full"
                      >
                        Question
                      </Button>
                    </div>
                  </div>
                </>
              ),
            },
            {
              icon: Rows4,
              label: "Responses",
              key: "responses",
              count: formDetails.customObjects?.customObjectsRecords.length,
              children: (
                <div className="w-full">
                  <DataGrid
                    name={`formResponsesList-${formDetails.id}`}
                    label={`Responses for ${formDetails.title}`}
                    className="h-[calc(100vh-184px)] mt-10"
                    rowKey="id"
                    empty={{
                      title: "No responses yet",
                      description: "When someone responds, they'll appear here",
                      icon: NotebookPen,
                    }}
                    rightClickMenuItems={(record) => [
                      {
                        type: "item",
                        label: "Edit",
                        icon: Pencil,
                        onClick: () => setEditCustomObjectRecord(record.id),
                      },
                      {
                        type: "item",
                        label: "Delete",
                        className: "text-red-500",
                        icon: Trash,
                        onClick: () => {
                          heffl.modal.confirm({
                            title: "Delete response",
                            description: `Are you sure you want to delete #${record.number} response? This action cannot be undone.`,
                            onConfirm: () =>
                              deleteCustomObjectRecord(record.id),
                          });
                        },
                      },
                    ]}
                    rows={formDetails.customObjects?.customObjectsRecords.map(
                      (record) => ({
                        number: record.number,
                        createdAt: record.createdAt,
                        data: record.data,
                        files: record.files,
                        id: record.id,
                      })
                    )}
                    columns={[
                      {
                        key: "createdAt",
                        name: "Created At",
                        width: 150,
                        renderCell: ({ row }) =>
                          dayjs(row.createdAt).format("DD MMM YYYY HH:mm A"),
                      },
                      {
                        key: "number",
                        name: "Number",
                        width: 100,
                        renderCell: ({ row }) => `#${row.number}`,
                      },
                      ...(
                        formDetails.customObjects?.customFields.filter(
                          (field) => field.dataType !== "FILE_PICKER"
                        ) ?? []
                      ).map((field) => ({
                        key: field.name,
                        name: field.label,
                        width: 300,
                        // @ts-ignore
                        renderCell: ({ row }) =>
                          renderCustomFieldValue(
                            field,
                            formatCustomFields({
                              customFields: row.data,
                              customFieldFields:
                                formDetails.customObjects?.customFields || [],
                            })[field.name]
                          ),
                      })),
                      ...(
                        formDetails.customObjects?.customFields.filter(
                          (field) => field.dataType === "FILE_PICKER"
                        ) ?? []
                      ).map((field) => ({
                        key: field.name,
                        name: field.label,
                        width: 300,
                        // @ts-ignore
                        renderCell: ({ row }) => (
                          <div className="flex flex-wrap gap-2">
                            {/* @ts-ignore */}
                            {row.files?.fields[field.name]?.map((file) => (
                              <div
                                key={file.id}
                                className="flex gap-2 items-center px-2 py-0 h-5 rounded-xl border"
                              >
                                {makeEllipsis(file.name, 7)}
                                <ArrowUpRightFromSquare
                                  className="w-3 h-3 cursor-pointer hover:text-primary-600"
                                  onClick={() =>
                                    window.open(getS3URL(file), "_blank")
                                  }
                                />
                                <Download
                                  className="w-3 h-3 cursor-pointer hover:text-primary-600"
                                  onClick={() =>
                                    downloadFile(file.name, getS3URL(file))
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        ),
                      })),
                    ]}
                  />
                </div>
              ),
            },
          ],
        }}
        widgets={[
          <Badge key="days-since" variant="neutral" small icon={Pencil}>
            Created {dayjs(formDetails.createdAt).fromNow()}
          </Badge>,
          <Badge key="responses" icon={Rows4} small variant="outline">
            {formDetails.customObjects?.customObjectsRecords.length} responses
          </Badge>,
        ]}
        actions={[
          {
            label: "Edit",
            icon: Pencil,
            onClick: () => setEditForm(true),
          },
        ]}
        attributes={[
          {
            section: "Details",
            items: [
              {
                label: "Title",
                value: formDetails.title,
              },
              {
                label: "Created on",
                value: dayjs(formDetails.createdAt).format(
                  "DD/MM/YYYY HH:mm A"
                ),
              },
              {
                label: "Created by",
                value: heffl.format.name(formDetails.createdBy),
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default FormDetails;
