import { RouterOutputs, trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import Calendar from "@event-calendar/core";
import "@event-calendar/core/index.css";
import Daygrid from "@event-calendar/day-grid";
import Interaction from "@event-calendar/interaction";
import Resource from "@event-calendar/resource-time-grid";
import ResourceTimeline from "@event-calendar/resource-timeline";
import TimeGrid from "@event-calendar/time-grid";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { calculateScheduleRevenue } from "@heffl/server/src/helpers/lineItems/calculateScheduleRevenue";
import enums from "@heffl/server/src/schemas/enums";
import DropMenu from "@heffl/ui/components/DropMenu";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import Select from "@heffl/ui/components/primitives/select";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  capitalize,
  cn,
  formatCurrency,
  generateUniqueColor,
  isMobile,
  makeEllipsis,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { styled } from "goober";
import { convert } from "html-to-text";
import {
  AlertTriangle,
  CalendarClock,
  CalendarDays,
  CalendarX,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  CircleDollarSign,
  CircleUserRound,
  Clock,
  Copy,
  Hammer,
  Printer,
  UserCircle,
  UsersRound,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useImmer } from "use-immer";
import { z } from "zod";
import DayEventCalendar from "../components/DayEventCalendar";
import { generateGoogleMapsLink } from "../components/property-selector";
import { jobSchema } from "../jobs/components/job-form";
import { ScheduleStatusBadge } from "../jobs/details";
import { getJobId } from "../jobs/list";
import ScheduleDetailsModal from "./components/schduleDetailsModal";
import SchedulesExport, { getSegragtedSchedules } from "./schedules-export";
import PendingSchedulesModal from "./components/pending-schedules-modal";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";

export type ScheduleEvent = {
  allDay: boolean;
  backgroundColor?: string;
  display: "auto";
  durationEditable?: boolean;
  editable?: boolean;
  extendedProps: Record<string, unknown>;
  id: string;
  resourceIds: string[];
  start: Date;
  end: Date;
  startEditable?: boolean;
  textColor?: string;
  title: string;
  titleHTML: string;
};

type ScheduleMessage = {
  invoiceRequired: boolean;
  fsScheduleTags: {
    tags: {
      name: string;
    };
  }[];
  timeDisabled: boolean;
  startDate: Date;
  fsJobs: {
    id: number;
    type: "ONEOFF" | "CONTRACT";
    paymentsCount: number;
    fsJobTags: {
      tags: {
        name: string;
      };
    }[];
    paymentMethods: {
      name: string;
    } | null;
    fsJobServices: {
      name: string;
      quantity: number;
      price: number;
      viewType: z.infer<typeof enums.lineItemViewType>;
    }[];
    discount: number;
    fsProperties: {
      city: string;
      name: string | null;
      googleMapsLink?: string | null;
      googleMapsPlaceId?: string | null;
      address?: string | null;
      clients: {
        name: string;
      };
    };
    siteContactPerson: {
      mobile: string | null;
    } | null;
    instructions: string | null;
  };
};

export const getScheduleMessage = (
  schedule: ScheduleMessage,
  team: { title: string; showJobTagsInSchedules: boolean }
) => {
  const scheduleTags = [
    ...schedule.fsScheduleTags,
    ...(team.showJobTagsInSchedules ? schedule.fsJobs.fsJobTags : []),
  ]
    .map((tag) => `- #*_${tag.tags.name.trim()}_* `)
    .join("");

  const time = schedule.timeDisabled
    ? "🕐 No time"
    : `🕘 ${dayjs(schedule.startDate).format("h:mm a")}`;

  const jobNo = `*${getJobId(schedule.fsJobs)}*`;

  const dateTitle = `🗓️ _${dayjs(schedule.startDate).format(
    "DD-MMM-YYYY"
  )}_ ${time}`;
  const teamTitle = `🚐  *${team.title.trim()}*`;

  const oneOffJobAmount = formatCurrency(
    calculateLineItems({
      lineItems: schedule.fsJobs.fsJobServices,
      discount: schedule.fsJobs.discount,
    }).total,
    "AED"
  );

  const contractJobAmount = schedule.invoiceRequired
    ? formatCurrency(
        calculateLineItems({
          lineItems: schedule.fsJobs.fsJobServices,
          discount: schedule.fsJobs.discount,
        }).total / schedule.fsJobs.paymentsCount,
        "AED"
      )
    : 0;

  const jobAmount = `💵 ${
    schedule.fsJobs.type === "ONEOFF" ? oneOffJobAmount : contractJobAmount
  } - (${
    schedule.fsJobs.paymentMethods?.name
      ? `*${schedule.fsJobs.paymentMethods.name}*`
      : "No Payment Method"
  })`;
  const propertyName = schedule.fsJobs.fsProperties.name
    ? `${schedule.fsJobs.fsProperties.name.trim()},${schedule.fsJobs.fsProperties.city.trim()}`
    : schedule.fsJobs.fsProperties.city.trim();

  const clientDetails = `🏬 *${schedule.fsJobs.fsProperties.clients.name.trim()}* - ${propertyName.trim()}`;

  const contactPerson = `📞 ${
    schedule.fsJobs.siteContactPerson?.mobile
      ? `- *${schedule.fsJobs.siteContactPerson.mobile}*`
      : "No mobile"
  }`;

  const services = schedule.fsJobs.fsJobServices
    .map((ser) => `🔧 ${ser.name}`)
    .join("\n");

  const instructions = schedule.fsJobs.instructions
    ? `👉  ${convert(schedule.fsJobs.instructions)}\n`
    : "";

  const message = `${dateTitle} - ${jobNo}\n${teamTitle}\n${clientDetails}\n${contactPerson}\n${jobAmount} \n${services} \n${scheduleTags} \n${instructions}${generateGoogleMapsLink(
    schedule.fsJobs.fsProperties
  )}\n------------------------------------------------------`;
  return message;
};

type ScheduleStatus = z.infer<typeof enums.scheduleStatus>;

const joinedUserId = (
  assignees: { users: { firstName: string; id: number } }[]
) => {
  return assignees
    .sort((a, b) => a.users.id - b.users.id)
    .map((user) => `${user.users.firstName}-${user.users.id}`)
    .join(",");
};

type Schedule =
  RouterOutputs["fieldService"]["schedules"]["list"]["schedules"][number];

export const groupSchedules = (schedules: Schedule[]) => {
  const grouped = schedules.reduce((acc, schedule) => {
    const key = schedule.fsScheduleAssignees.length
      ? joinedUserId(schedule.fsScheduleAssignees)
      : "unassigned";
    acc[key] = acc[key] || [];
    acc[key].push(schedule);
    return acc;
  }, {} as Record<"unassigned" | string, Schedule[]>);

  const resourcesList = Object.keys(grouped)
    .map((key) => ({
      id:
        key === "unassigned"
          ? "unassigned"
          : key
              .split(",")
              .map((user) => user.split("-")[1])
              .sort(),
      title: key
        .split(",")
        .map((user) => capitalize(user.split("-")[0]))
        .sort()
        .join(", "),
    }))
    .sort((a, b) => {
      if (a.title === "Unassigned") return -1;
      if (b.title === "Unassigned") return 1;
      return a.title.localeCompare(b.title);
    });

  return {
    resourcesList,
    schedulesList: schedules.map((schedule) => ({
      ...schedule,
      resourceIds: schedule.fsScheduleAssignees.length
        ? [
            schedule.fsScheduleAssignees
              .map((user) => user.userId)
              .sort()
              .join(","),
          ]
        : ["unassigned"],
    })),
  };
};

const groupSchedulesByUsers = (
  schedules: Schedule[],
  users: RouterOutputs["users"]["list"]
) => {
  const resourcesList = [
    {
      id: "unassigned",
      title: "Unassigned",
    },
    ...users.map((user) => ({
      id: user.id.toString(),
      title: user.firstName,
    })),
  ];

  const schedulesList = [];

  for (const resource of resourcesList) {
    const resourceSchedules = schedules
      .filter((schedule) =>
        schedule.fsScheduleAssignees.some(
          (assignee) => String(assignee.userId) === resource.id
        )
      )
      .map((schedule) => ({
        ...schedule,
        startDate: schedule.fsScheduleAssignees.find(
          (assignee) => String(assignee.userId) === resource.id
        )?.startDate,
        endDate: schedule.fsScheduleAssignees.find(
          (assignee) => String(assignee.userId) === resource.id
        )?.endDate,
        resourceIds: [resource.id],
      }));
    schedulesList.push(...resourceSchedules);
  }

  return {
    resourcesList,
    schedulesList,
  };
};

export const getJobName = (
  fsJobServices: Schedule["fsJobs"]["fsJobServices"]
) => {
  if (!fsJobServices.length) return "No Services";
  const lineItemService = fsJobServices.find(
    (item) => item.products?.type === "SERVICE" && item.viewType === "LINE_ITEM"
  );
  const lineItemProduct = fsJobServices.find(
    (item) => item.products?.type === "PRODUCT" && item.viewType === "LINE_ITEM"
  );

  if (lineItemService) {
    return lineItemService.products?.name || "";
  } else if (lineItemProduct) {
    return lineItemProduct.products?.name || "";
  } else {
    return fsJobServices[0].name || "";
  }
};

const SLOT_WIDTH = 70;

const CalendarWrapper = styled("div")`
  background-color: white;
  .striped-background {
    background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1) 10px,
      transparent 10px,
      transparent 20px
    );
  }
  .ec-event {
    z-index: 1 !important;
    padding: 0px;
  }
  .ec-toolbar {
    display: none;
  }
  .ec-timeline .ec-time,
  .ec-timeline .ec-line {
    width: ${SLOT_WIDTH}px;
  }
`;

const twoWeeksAfterToday = dayjs().add(2, "weeks").toDate();

const SchedulesCalendar = ({
  syncedDates,
  setSyncedDates,
  onJobAdd,
}: {
  onJobAdd?: (defaultValues?: Partial<z.infer<typeof jobSchema>>) => void;
  syncedDates: [Date, Date] | undefined;
  setSyncedDates: (dates: [Date, Date]) => void;
}) => {
  const calendarRef = useRef<typeof Calendar | null>(null);
  const navigate = useNavigate();
  const schedulePrintRef = useRef<HTMLDivElement | null>(null);
  const confirm = useConfirm();

  const [filters, setFilters] = useImmer({
    status: "all" as "all" | "pending" | "completed",
    showUnconfirmed: false,
    startDate: syncedDates?.[0] || dayjs().startOf("day").toDate(),
    endDate: syncedDates?.[0] || dayjs().endOf("day").toDate(),
  });
  const [showPending, setShowPending] = useState(false);
  const [schedulePrintTeam, setSchedulePrintTeam] = useState<
    "all-teams" | "all-schedules" | "all-assigned-schedules" | string | null
  >(null);
  const [scheduleDetailsId, setScheduleDetailsId] = useState<number | null>(
    null
  );
  const [mobileSelectedTeam, setMobileSelectedTeam] = useState<string | "all">(
    "all"
  );

  const team = useTeam();

  const { data: users } = trpc.users.list.useQuery({
    type: ["FIELD_STAFF"],
  });

  const { data: pendingSchedulesCount } =
    trpc.fieldService.schedules.listPending.useQuery({
      endDate: twoWeeksAfterToday,
      excludeSchedules: true,
    });

  const [viewType, setViewType] = useState<{
    type: "Day" | "Person" | "Month" | "TimelineDay";
    value: "resourceTimeGridDay" | "dayGridMonth" | "resourceTimelineDay";
  }>({
    type: "Day",
    value: "resourceTimeGridDay",
  });

  const generatePrintName = () => {
    if (!schedulePrintTeam) return "Schedule";

    let team = schedulePrintTeam || "";
    if (
      schedulePrintTeam === "all-teams" ||
      schedulePrintTeam === "all-schedules" ||
      schedulePrintTeam === "all-assigned-schedules"
    ) {
      team = schedulePrintTeam.split("-").join(" ");
    } else {
      const userIds = schedulePrintTeam.split(",").map((id) => Number(id));
      team = userIds
        .map((id) => users?.find((user) => user.id === id)?.firstName)
        .join(", ");
    }

    return `${dayjs(filters.startDate).format("DD MMM YYYY")} - ${capitalize(
      team || " "
    )} - Schedule`;
  };

  const printSchedules = useReactToPrint({
    content: () => schedulePrintRef.current,
  });

  useEffect(() => {
    if (schedulePrintTeam) {
      document.title = generatePrintName();
    }
  }, [schedulePrintTeam]);

  const serverViewType: ScheduleStatus[] = useMemo(() => {
    if (filters.status === "completed") {
      return ["COMPLETED"];
    }
    if (filters.status === "pending") {
      return ["CONFIRMED", "IN_PROGRESS", "ON_MY_WAY", "ARRIVED"];
    }
    if (filters.status === "all") {
      const statuses = [
        "CONFIRMED",
        "IN_PROGRESS",
        "ON_MY_WAY",
        "ARRIVED",
        "COMPLETED",
      ];
      if (filters.showUnconfirmed) {
        statuses.push("SCHEDULED");
      }
      return statuses as ScheduleStatus[];
    }
    return [];
  }, [filters.status, viewType, filters.showUnconfirmed]);

  const serverStartDate = useMemo(() => {
    return viewType.value === "dayGridMonth"
      ? dayjs(filters.startDate).startOf("month").toDate()
      : filters.startDate;
  }, [viewType, filters.startDate]);

  const serverEndDate = useMemo(() => {
    return viewType.value === "dayGridMonth"
      ? dayjs(filters.endDate).endOf("month").toDate()
      : filters.endDate;
  }, [viewType, filters.endDate]);

  const { data: schedulesData } = trpc.fieldService.schedules.list.useQuery({
    status: serverViewType,
    startDate: serverStartDate,
    endDate: serverEndDate,
    orderBy: {
      startDate: "asc",
    },
  });

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const scheduleUpdateMutation = trpc.fieldService.schedules.update.useMutation(
    {
      onSuccess: () => {
        toast.success("Schedule updated successfully");
        return { invalidate: false };
      },
      onError: (error) => {
        toast.error(error.message);
        return { invalidate: false };
      },
    }
  );

  const statusColors: Record<ScheduleStatus, string> = {
    SCHEDULED: "#EAB308",
    COMPLETED: "#22C55E",
    IN_PROGRESS: "#14B8A6",
    ON_MY_WAY: "#14B8A6",
    ARRIVED: "#14B8A6",
    CONFIRMED: "#14B8A6",
    CANCELLED: "#EF4444",
  };

  const scheduelsFormatted = useMemo(() => {
    const schedulesGrouped =
      viewType.type === "Person"
        ? groupSchedulesByUsers(schedulesData?.schedules || [], users || [])
        : groupSchedules(schedulesData?.schedules || []);

    return schedulesGrouped.schedulesList.map((schedule) => ({
      id: schedule.id,
      resourceIds: schedule.resourceIds,
      start: dayjs(schedule.startDate).isAfter(schedule.endDate)
        ? undefined
        : schedule.startDate,
      end: dayjs(schedule.endDate).isBefore(schedule.startDate)
        ? undefined
        : schedule.endDate,
      title: `${schedule.fsJobs.fsProperties.clients.name} - ${getJobName(
        schedule.fsJobs.fsJobServices
      )}`,
      backgroundColor: statusColors[schedule.status],
      allDay:
        schedule.timeDisabled ||
        dayjs(schedule.startDate).isSame(schedule.endDate) ||
        dayjs(schedule.startDate).isAfter(schedule.endDate) ||
        dayjs(schedule.endDate).isBefore(schedule.startDate),
      slotWidth: SLOT_WIDTH,
    }));
  }, [schedulesData, viewType]);

  const resourcesList = useMemo(() => {
    const schedulesGrouped =
      viewType.type === "Person"
        ? groupSchedulesByUsers(schedulesData?.schedules || [], users || [])
        : groupSchedules(schedulesData?.schedules || []);
    return schedulesGrouped.resourcesList;
  }, [schedulesData, viewType]);

  const UnScheduledContent = useCallback(() => {
    return (
      <div className="flex gap-1 items-center text-xs">
        <CalendarClock className="w-4 h-4" />
        Anytime
      </div>
    );
  }, []);

  const SchduleDayViewCard = ({
    id,
    timeText,
  }: {
    id: number;
    timeText: string;
  }) => {
    const schedule = schedulesData?.schedules?.find(
      (schedule) => schedule.id === id
    );
    if (!schedule) return <div></div>;
    return (
      <div
        className={cn(
          "flex flex-col justify-start h-full w-full !rounded-sm p-1 text-black border-1.5",
          schedule.status === "COMPLETED" && "bg-gray-400 striped-background ",
          schedule.status === "SCHEDULED" && "bg-yellow-400 striped-background "
        )}
        style={{
          backgroundColor:
            schedule.status !== "COMPLETED" && schedule.status !== "SCHEDULED"
              ? generateUniqueColor(
                  schedule.fsScheduleAssignees.map((a) => a.userId).join(","),
                  400
                )
              : undefined,
        }}
      >
        <div className="flex items-center gap-0.5 mb-1 flex-wrap">
          <ScheduleStatusBadge
            schedule={{ status: schedule.status }}
            iconOnly
          />
          {schedule.invoiceRequired && (
            <CircleDollarSign
              className={cn(
                "w-4 h-4 text-white bg-red-500 rounded-full flex-shrink-0",
                schedule.fsJobInvoices.length && "bg-green-600"
              )}
            />
          )}
          {schedule.status === "SCHEDULED" && (
            <Badge icon={CalendarX} variant="error" small>
              Unconfirmed
            </Badge>
          )}
          <strong className="ec-event-time">{timeText}</strong>
        </div>

        <div className="text-xs font-medium">
          {makeEllipsis(schedule.fsJobs.fsProperties.clients.name, 28)}
          {" - "}
          {schedule.fsJobs.fsProperties.name ||
            schedule.fsJobs.fsProperties.city}{" "}
          {team &&
            team.settings.fsJobs.showScheduleRevenue &&
            `- (${formatCurrency(
              calculateScheduleRevenue(schedule, {
                startDate: filters.startDate,
                endDate: filters.endDate,
              }),
              "AED"
            )})`}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isMobile()) return;
    const ecElement = document.getElementById("ec");
    if (ecElement && !calendarRef.current) {
      calendarRef.current = new Calendar({
        target: ecElement,
        props: {
          plugins: [TimeGrid, Resource, Interaction, Daygrid, ResourceTimeline],
          options: {
            slotEventOverlap: false,
            headerToolbar: {
              start: "",
              center: "",
              end: "",
            },
            allDaySlot: true,
            allDayContent: function () {
              return {
                html: renderToString(<UnScheduledContent />),
              };
            },
            height: "calc(100vh - 156px)",
            date: filters.startDate,
            selectable: true,
            nowIndicator: true,
            view: viewType.value,
            events: scheduelsFormatted,
            resources: resourcesList,
            views: {
              resourceTimelineDay: {
                selectMinDistance: 1,
                selectLongPressDelay: 0,
                slotMinTime: "00:00",
                slotMaxTime: "23:59",
                scrollTime: "00:00:00",
                slotWidth: SLOT_WIDTH,
                selectable: true,
                nowIndicator: true,
                allDaySlot: true,
                slotDuration: "00:30:00",
                selectBackgroundColor: "#ff0000",
              },
            },
            select: function (event: ScheduleEvent) {
              if (onJobAdd)
                onJobAdd({
                  startDate: event.start,
                  startTime: event.start,
                  endTime: event.end,
                  // @ts-ignore
                  fsScheduleAssignees: event?.resource?.id
                    ?.split(",")
                    .filter((id: string) => !isNaN(Number(id)))
                    .map((id: string) => Number(id)),
                });
            },
            eventResize: async function ({
              event,
              revert,
            }: {
              event: ScheduleEvent;
              revert: () => void;
            }) {
              const confirmed = await confirm({
                title: "Are you sure you want to update this schedule?",
                body: "This will update the start and end times for the schedule.",
              });
              if (!confirmed) {
                revert();
                return;
              }
              scheduleUpdateMutation.mutate({
                id: Number(event.id),
                schedule: {
                  startDate: event.start,
                  endDate: event.end,
                  timeDisabled: false,
                },
              });
            },
            eventDrop: async function ({
              event,
              revert,
            }: {
              event: ScheduleEvent;
              revert: () => void;
            }) {
              const confirmed = await confirm({
                title: "Are you sure you want to update this schedule?",
                body: "This will update the start and end times and assignees for the schedule.",
              });
              if (!confirmed) {
                revert();
                return;
              }
              const team =
                event.resourceIds[0] === "unassigned"
                  ? []
                  : event.resourceIds[0].split(",").map((id) => Number(id));
              scheduleUpdateMutation.mutate({
                id: Number(event.id),
                schedule: {
                  startDate: event.start,
                  endDate: event.end,
                  timeDisabled: false,
                  fsScheduleAssignees: team,
                },
              });
            },
            eventClick: function ({ event }: { event: ScheduleEvent }) {
              setScheduleDetailsId(Number(event.id));
            },
            eventContent: function ({
              event,
              timeText,
            }: {
              event: ScheduleEvent;
              timeText: string;
            }) {
              return {
                html: renderToString(
                  <SchduleDayViewCard
                    id={Number(event.id)}
                    timeText={timeText}
                  />
                ),
              };
            },
            eventTimeFormat: function (start: Date) {
              return dayjs(start).format("h:mm a ");
            },
            // eventMouseEnter: function ({ event }: { event: ScheduleEvent }) {},
          },
        },
      });
    } else if (calendarRef.current) {
      calendarRef.current.setOption("view", viewType.value);
      calendarRef.current.setOption("date", filters.startDate);
      calendarRef.current.setOption("resources", resourcesList);
      calendarRef.current.setOption("events", scheduelsFormatted);

      if (viewType.type === "Person") {
        calendarRef.current.setOption("eventStartEditable", false);
        calendarRef.current.setOption("eventDurationEditable", false);
        calendarRef.current.setOption("editable", false);
      }

      if (
        viewType.value === "resourceTimeGridDay" ||
        viewType.value === "resourceTimelineDay"
      ) {
        calendarRef.current.setOption(
          "eventContent",
          function ({
            event,
            timeText,
          }: {
            event: ScheduleEvent;
            timeText: string;
          }) {
            return {
              html: renderToString(
                <SchduleDayViewCard id={Number(event.id)} timeText={timeText} />
              ),
            };
          }
        );
      } else {
        calendarRef.current.setOption("eventContent", undefined);
      }
    }
  }, [viewType, filters.startDate, schedulesData]);

  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.type === "FIELD_STAFF") {
      navigate("/field-service/staff-app/dashboard");
    }
  }, [currentUser]);

  const mobileDates = [
    dayjs().subtract(3, "day").toDate(),
    dayjs().subtract(2, "day").toDate(),
    dayjs().subtract(1, "day").toDate(),
    dayjs().toDate(),
    dayjs().add(1, "day").toDate(),
    dayjs().add(2, "day").toDate(),
    dayjs().add(3, "day").toDate(),
  ];

  const copySchedules = async (teamTitle: string) => {
    const grouped = groupSchedules(schedulesData?.schedules || []);

    const message = getSegragtedSchedules(grouped, teamTitle)
      .map(({ team: scheduleTeam, teamSchedules }) => {
        const schedules = teamSchedules
          .map((schedule) => {
            return getScheduleMessage(schedule, {
              title: scheduleTeam.title,
              showJobTagsInSchedules: team
                ? team.settings.fsJobs.showJobTagsInSchedules
                : false,
            });
          })
          .join("\n");

        return schedules;
      })
      .join("\n");
    copyToClipboard(message);
  };

  if (!currentUser) return <FullScreenSpinner />;

  if (isMobile()) {
    const scheduleTeamFiltered = scheduelsFormatted
      .filter((schedule) => {
        if (mobileSelectedTeam === "all") return true;
        return schedule.resourceIds[0] === mobileSelectedTeam;
      })
      .map((schedule) => {
        return {
          ...schedule,
          title: `${schedule.title}`,
        };
      });
    return (
      <div className="px-3 pt-3">
        {!!scheduleDetailsId && (
          <ScheduleDetailsModal
            onClose={() => setScheduleDetailsId(null)}
            open={!!scheduleDetailsId}
            id={scheduleDetailsId}
          />
        )}

        <div className="grid grid-cols-7 gap-2 items-center">
          {mobileDates.map((date) => (
            <div
              className={cn(
                "flex flex-col gap-0.5 items-center p-1 rounded-lg",
                dayjs(date).isSame(dayjs(filters.startDate), "day") &&
                  "bg-primary-600  text-white"
              )}
              key={date.toString()}
              onClick={() =>
                setFilters((draft) => {
                  draft.startDate = date;
                  draft.endDate = date;
                })
              }
            >
              <p>{dayjs(date).format("ddd")}</p>
              <p>{dayjs(date).format("DD")}</p>
              {dayjs(date).isSame(dayjs(), "day") && (
                <span
                  className={cn(
                    "w-1 h-1 rounded-full bg-primary-600",
                    dayjs(date).isSame(dayjs(filters.startDate), "day") &&
                      "bg-white"
                  )}
                />
              )}
            </div>
          ))}
        </div>
        <div className="mt-1">
          <p className="pb-1 text-sm">Team</p>
          <Select
            icon={UserCircle}
            value={mobileSelectedTeam}
            onChange={(value) => setMobileSelectedTeam(value)}
            options={[
              { label: "All", value: "all" },
              ...resourcesList.map((team) => ({
                label: team.title,
                value:
                  // @ts-ignore
                  team.id !== "unassigned" ? team.id.join(",") : "unassigned",
              })),
            ]}
          />
        </div>
        <TabsInput
          className="mt-4"
          options={[
            { label: "All", value: "all" },
            { label: "Pending", value: "pending" },
            {
              label: (
                <span className="flex gap-1 items-center">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Completed
                </span>
              ),
              value: "completed",
            },
          ]}
          value={filters.status}
          onChange={(value) =>
            setFilters((draft) => {
              draft.status = value as "all" | "completed" | "pending";
            })
          }
        />
        <DayEventCalendar
          className="mt-4"
          events={scheduleTeamFiltered}
          date={filters.startDate}
          allDayContent={<UnScheduledContent />}
          onEventClick={(event) => setScheduleDetailsId(Number(event.id))}
        />
      </div>
    );
  }

  const Header = (
    <div
      className={cn(
        "flex  p-2 w-full bg-white",
        filters.status === "completed" && "bg-green-200"
      )}
    >
      <div className="flex gap-2 justify-center items-center">
        <DatePicker
          value={filters.startDate}
          onChange={(date) => {
            if (!date) return;
            const startDate = dayjs(date).startOf("day").toDate();
            const endDate = dayjs(date).endOf("day").toDate();
            setFilters((draft) => {
              draft.startDate = startDate;
              draft.endDate = endDate;
            });
            // sync with list view
            setSyncedDates([startDate, endDate]);
          }}
        />
        <Button
          size="icon"
          className="h-9"
          onClick={() => {
            const startDate = dayjs(filters.startDate)
              .subtract(1, "day")
              .toDate();
            const endDate = dayjs(filters.endDate).subtract(1, "day").toDate();
            setFilters((draft) => {
              draft.startDate = startDate;
              draft.endDate = endDate;
            });
            setSyncedDates([startDate, endDate]);
          }}
        >
          <ChevronLeft className="!w-8" />
        </Button>
        <Button
          size="icon"
          className="h-9"
          onClick={() => {
            const startDate = dayjs(filters.startDate).add(1, "day").toDate();
            const endDate = dayjs(filters.endDate).add(1, "day").toDate();
            setFilters((draft) => {
              draft.startDate = startDate;
              draft.endDate = endDate;
            });
            setSyncedDates([startDate, endDate]);
          }}
        >
          <ChevronRight className="!w-8" />
        </Button>
        <Button
          disabled={dayjs(filters.startDate).isSame(dayjs(), "day")}
          onClick={() => {
            setFilters((draft) => {
              draft.startDate = dayjs().startOf("day").toDate();
              draft.endDate = dayjs().endOf("day").toDate();
            });
            setSyncedDates([
              dayjs().startOf("day").toDate(),
              dayjs().endOf("day").toDate(),
            ]);
          }}
        >
          Today
        </Button>
        <TabsInput
          options={[
            { label: "All", value: "all" },
            { label: "Pending", value: "pending" },
            {
              label: (
                <span className="flex gap-1 items-center">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  Completed
                </span>
              ),
              value: "completed",
            },
          ]}
          value={filters.status}
          onChange={(value) =>
            setFilters((draft) => {
              draft.status = value as "all" | "completed" | "pending";
            })
          }
        />
        <div className="flex flex-col gap-1">
          <p className="text-xs">Unconfirmed</p>
          <Switch
            className="w-fit"
            value={filters.showUnconfirmed}
            onChange={(value) =>
              setFilters((draft) => {
                draft.showUnconfirmed = value;
              })
            }
          />
        </div>
      </div>
      <div className="flex-grow" />
      <div className="flex gap-2">
        {(viewType.type === "Day" || viewType.type === "TimelineDay") && (
          <div className="flex gap-2 items-center">
            {!!pendingSchedulesCount?.meta.count && (
              <Button
                onClick={() => setShowPending(true)}
                size="sm"
                variant="warningOutline"
                icon={AlertTriangle}
                className="bg-white"
              >
                Pending ({pendingSchedulesCount.meta.count})
              </Button>
            )}
            <DropMenu
              contentClassName="w-60"
              items={[
                {
                  icon: UsersRound,
                  label: "All teams",
                  iconClassName: "text-green-500",
                  onClick: () => {
                    copySchedules("all-teams");
                  },
                },
                {
                  icon: Hammer,
                  iconClassName: "text-orange-500",
                  label: "All assigned schedules",
                  onClick: () => {
                    copySchedules("all-assigned-schedules");
                  },
                },
                {
                  icon: Hammer,
                  iconClassName: "text-orange-500",
                  label: "All schedules",
                  onClick: () => {
                    copySchedules("all-schedules");
                  },
                },
                ...resourcesList
                  .filter((team) => team.id !== "unassigned")
                  .map((team) => ({
                    icon: CircleUserRound,
                    label: team.title,
                    onClick: () => {
                      copySchedules(
                        Array.isArray(team.id) ? team.id.join(",") : team.id
                      );
                    },
                  })),
              ]}
            >
              <Tooltip content="Copy">
                <Button size="sm" icon={Copy} />
              </Tooltip>
            </DropMenu>
            <DropMenu
              contentClassName="w-60"
              items={[
                {
                  icon: UsersRound,
                  label: "All teams",
                  iconClassName: "text-green-500",
                  onClick: () => {
                    setSchedulePrintTeam("all-teams");
                    setTimeout(() => {
                      printSchedules();
                    }, 100);
                  },
                },
                {
                  icon: Hammer,
                  iconClassName: "text-orange-500",
                  label: "All assigned schedules",
                  onClick: () => {
                    setSchedulePrintTeam("all-assigned-schedules");
                    setTimeout(() => {
                      printSchedules();
                    }, 100);
                  },
                },
                {
                  icon: Hammer,
                  iconClassName: "text-orange-500",
                  label: "All schedules",
                  onClick: () => {
                    setSchedulePrintTeam("all-schedules");
                    setTimeout(() => {
                      printSchedules();
                    }, 100);
                  },
                },
                ...resourcesList
                  .filter((team) => team.id !== "unassigned")
                  .map((team) => ({
                    icon: CircleUserRound,
                    label: team.title,
                    onClick: () => {
                      setSchedulePrintTeam(
                        Array.isArray(team.id) ? team.id.join(",") : team.id
                      );
                      setTimeout(() => {
                        printSchedules();
                      }, 100);
                    },
                  })),
              ]}
            >
              <Tooltip content="Print">
                <Button size="sm" icon={Printer} onClick={printSchedules} />
              </Tooltip>
            </DropMenu>
          </div>
        )}
        <TabsInput
          options={[
            {
              label: (
                <span className="flex gap-1 items-center">
                  <Clock className="w-4 h-4" /> Timeline
                </span>
              ),
              value: "TimelineDay",
            },
            {
              label: (
                <span className="flex gap-1 items-center">
                  <Clock className="w-4 h-4" /> Day
                </span>
              ),
              value: "Day",
            },
            {
              label: (
                <span className="flex gap-1 items-center">
                  <CalendarDays className="w-4 h-4" />
                  Month
                </span>
              ),
              value: "Month",
            },
            {
              label: (
                <span className="flex gap-1 items-center">
                  <CircleUserRound className="w-4 h-4" />
                  Person
                </span>
              ),
              value: "Person",
            },
          ]}
          value={viewType.type}
          onChange={(type) => {
            setViewType({
              type: type as "Day" | "Person" | "Month" | "TimelineDay",
              value:
                type === "Day" || type === "Person"
                  ? "resourceTimeGridDay"
                  : type === "TimelineDay"
                  ? "resourceTimelineDay"
                  : "dayGridMonth",
            });
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div ref={schedulePrintRef}>
        <SchedulesExport
          schedulesData={schedulesData}
          schedulePrintTeam={schedulePrintTeam || ""}
          filters={filters}
        />
      </div>
      {!!scheduleDetailsId && (
        <ScheduleDetailsModal
          onClose={() => setScheduleDetailsId(null)}
          open={!!scheduleDetailsId}
          id={scheduleDetailsId}
        />
      )}
      {showPending && (
        <PendingSchedulesModal
          open={showPending}
          onClose={() => setShowPending(false)}
        />
      )}
      {Header}
      <CalendarWrapper id="ec" />
    </div>
  );
};

export default SchedulesCalendar;
