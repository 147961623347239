import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { Plus } from "lucide-react";
import { Switch } from "@heffl/ui/components/primitives/switch";

export const ProductForm = () => (
  <>
    <FormField name="name" label="Name" className="mb-4">
      <Input placeholder="Product name" />
    </FormField>
    <div className="flex gap-2 w-full">
      <div className="flex gap-2 w-4/5">
        <FormField name="price" label="Price">
          <Input
            placeholder="Product price"
            type="number"
            prefix="AED"
            prefixFilled
          />
        </FormField>
        <FormField name="buyPrice" label="Cost price">
          <Input
            placeholder="Cost price"
            type="number"
            prefix="AED"
            prefixFilled
          />
        </FormField>
      </div>
      <FormField className="w-1/5" name="isActive" label="Active">
        <Switch />
      </FormField>
    </div>
    <div className="grid grid-cols-2 gap-2">
      <FormField name="type" label="Type">
        <Select
          allowClear={false}
          options={[
            {
              label: "Service",
              value: "SERVICE",
            },
            {
              label: "Product",
              value: "PRODUCT",
            },
          ]}
          placeholder="Select product type"
        />
      </FormField>
      <FormField name="sku" label="SKU">
        <Input placeholder="Product SKU" type="text" />
      </FormField>
    </div>

    <FormField name="description" label="Description" className="mb-4">
      <MiniRichTextEditor placeholder="Product description" />
    </FormField>
  </>
);

type AddProductResponse = RouterOutputs["products"]["add"];

export const AddProductDrawer = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: (product: AddProductResponse | undefined) => void;
  defaultValues?: Partial<z.infer<typeof Schemas.crm.product>>;
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.product>, unknown>({
    resolver: zodResolver(Schemas.crm.product),
    defaultValues: {
      type: "PRODUCT",
      isActive: true,
      ...defaultValues,
    },
  });

  const productCreateMutation = trpc.products.add.useMutation({
    onSuccess: (newProduct) => {
      toast.success("Product added successfully");
      onClose(newProduct);
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={() => onClose(undefined)}
      className={`w-full sm:w-1/4`}
      title="Add Product"
      closeOnOverlayClick={false}
    >
      <Form
        {...form}
        onSubmit={(values) => productCreateMutation.mutate(values)}
      >
        <ProductForm />
        <Button
          variant="primary"
          size="lg"
          type="submit"
          className="w-full"
          loading={productCreateMutation.isLoading}
          icon={Plus}
        >
          Add Product
        </Button>
      </Form>
    </ModalDrawer>
  );
};
