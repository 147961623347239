import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import dayjs from "dayjs";
import { useState } from "react";
import ExpenseDetailsModal from "../../purchases/expenses/details-modal";
import PaymentMadeDetailsModal from "../../purchases/payments-made/details-modal";

type Filters = {
  dates: [Date, Date] | undefined;
  vendors: number[];
};

const VendorTransactions = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    dates: undefined,
    vendors: [],
  });

  const [showDetails, setShowDetails] = useState<
    | {
        type: "Expense" | "Payment Made";
        id: number;
      }
    | undefined
  >(undefined);

  const { data: transactions } =
    trpc.purchases.vendors.transactionsList.useQuery({
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
      vendorIds: filters.vendors,
    });

  const { data: vendors } = trpc.purchases.vendors.list.useQuery();

  return (
    <Page
      title="Vendor Transactions"
      className="!p-0"
      fullWidth
      breadcrumbs={[
        {
          label: "Reports",
          path: "/books/reports",
        },
        {
          label: "Vendor Transactions",
          path: "/books/reports/vendor-transactions",
        },
      ]}
    >
      {showDetails?.type === "Expense" && (
        <ExpenseDetailsModal
          id={showDetails.id}
          open={true}
          onClose={() => setShowDetails(undefined)}
        />
      )}
      {showDetails?.type === "Payment Made" && (
        <PaymentMadeDetailsModal
          id={showDetails.id}
          open={true}
          onClose={() => setShowDetails(undefined)}
        />
      )}

      <div className="p-4">
        <FilterBar
          onChange={() => {}}
          filters={[
            {
              type: "date-range",
              key: "dates",
              label: "Date",
              value: filters.dates,
              onChange: (dates) => setFilters({ dates }),
            },
            {
              type: "checkbox",
              key: "vendors",
              label: "Vendors",
              value: filters.vendors,
              onChange: (vendors) =>
                setFilters({ vendors: vendors as number[] }),
              options: vendors?.vendors?.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
            },
          ]}
        />
      </div>

      <DataGrid
        name="vendorTransactionsListMain"
        className="h-[calc(100vh-130px)]"
        label="Vendor Transactions"
        rowKey="transactionNo"
        show={{
          viewSettings: false,
          export: false,
        }}
        rows={transactions}
        onCellClick={(row) => {
          setShowDetails({
            type: row.row.type,
            id: Number(row.row.id.split("-")[1]),
          });
        }}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 120,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "type",
            name: "Type",
            width: 120,
          },
          {
            key: "transactionNo",
            name: "Transaction #",
            width: 140,
          },
          {
            key: "vendor",
            name: "Vendor",
            width: 200,
            renderCell: ({ row }) => row.vendors.name,
          },
          {
            key: "refNo",
            name: "Ref.#",
            width: 120,
          },
          {
            key: "amount",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) => heffl.format.currency(row.amount, "AED"),
          },
        ]}
      />
    </Page>
  );
};

export default VendorTransactions;
