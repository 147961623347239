import { z } from "zod";
import parsePhone from "../helpers/utils/parsePhone";
import enums from "./enums";

const user = z.object({
  active: z.boolean().default(true),
  firstName: z.string(),
  teamId: z.number(),
  email: z.string().transform((val) => val.toLowerCase()),
  lastName: z.string().default(""),
  outlookAccessToken: z.string().nullish(),
  password: z.string(),
  phone: z.string().max(7).nullish(),
  mobile: z.string().transform(parsePhone).nullish(),
  countryCode: z.number().nullish(),
  type: enums.userTypes,
  permissionSetId: z.number().nullish(),
  pinnedApps: z.array(z.string()).optional().default([]),
  hourlyRate: z.coerce.number().optional().default(0),
  personalNotes: z.string().nullish().default(""),
});
export default {
  user,
};
