import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import SimpleTable from "@heffl/ui/components/simple-table";
import {
  Copy,
  LayoutPanelTop,
  Pencil,
  Plus,
  Save,
  Settings,
  Shapes,
} from "lucide-react";
import { capitalize } from "radash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddCustomFieldModal,
  EditCustomFieldModal,
} from "../settings/custom-fields";
import { useForm } from "react-hook-form";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Button } from "@heffl/ui/components/primitives/button";
import heffl from "@/helpers/heffl";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import {
  AddObjectTemplateModal,
  EditObjectTemplateModal,
} from "./templates/object-template-modals";
import { Badge } from "@heffl/ui/components/primitives/badge";

const ObjectDetails = () => {
  const { slug } = useParams();

  const form = useForm<z.infer<typeof Schemas.customizations.customObject>>({
    resolver: zodResolver(Schemas.customizations.customObject),
  });

  const [showAddField, setShowAddField] = useState(false);
  const [editCustomField, setEditCustomField] = useState<number | null>(null);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState<number | null>(null);

  const updateCustomObjectMutation =
    trpc.customizations.objects.custom.update.useMutation({
      onSuccess: () => {
        heffl.toast.success("Object updated successfully");
      },
      onError: () => {
        heffl.toast.error("Failed to update object");
      },
    });

  const { data: objectDetails } = trpc.customizations.objects.details.useQuery(
    slug ?? ""
  );

  useEffect(() => {
    if (objectDetails) {
      form.reset({
        name: objectDetails.name,
        singularLabel: objectDetails.singularLabel,
        pluralLabel: objectDetails.pluralLabel,
      });
    }
  }, [objectDetails]);

  if (!objectDetails || !slug) return <FullScreenSpinner />;

  const onSubmit = (
    values: z.infer<typeof Schemas.customizations.customObject>
  ) => {
    if (objectDetails.customObjectId) {
      updateCustomObjectMutation.mutate({
        id: objectDetails.customObjectId,
        customObject: values,
      });
    }
  };

  return (
    <Page
      title="Object Details"
      breadcrumbs={[
        { label: "Settings", path: "/settings" },
        { label: "Objects", path: "/objects" },
        { label: "Details" },
      ]}
      loading={!objectDetails}
      showBack
      className="max-w-4xl"
    >
      <AddCustomFieldModal
        open={showAddField}
        onClose={() => setShowAddField(false)}
        defaultValues={
          objectDetails.type === "custom"
            ? {
                customObjectId: objectDetails.customObjectId,
                section: "CUSTOM_OBJECT",
              }
            : {
                section: "LEAD",
              }
        }
      />
      {editCustomField && (
        <EditCustomFieldModal
          customFieldId={editCustomField}
          open={!!editCustomField}
          onClose={() => setEditCustomField(null)}
        />
      )}
      <AddObjectTemplateModal
        open={showAddTemplate}
        onClose={() => setShowAddTemplate(false)}
        entitySlug={slug}
        defaultValues={{
          entity: objectDetails.entity,
          customObjectId: objectDetails.customObjectId,
        }}
      />
      {editTemplate && (
        <EditObjectTemplateModal
          objectTemplateId={editTemplate}
          open={!!editTemplate}
          onClose={() => setEditTemplate(null)}
          entitySlug={slug}
        />
      )}

      <div>
        <p className="text-2xl font-semibold">{objectDetails.singularLabel}</p>
        <p className="text-sm text-gray-500">
          Manage custom object and its fields.
        </p>
        <StripeTabs
          className="mt-4"
          items={[
            {
              key: "configuration",
              label: "Configuration",
              icon: Settings,
              children: (
                <div className="mt-4">
                  <Form {...form} onSubmit={onSubmit}>
                    <div className="grid grid-cols-2 gap-2">
                      <FormField name="singularLabel" label="Singular Label">
                        <Input disabled={objectDetails.type !== "custom"} />
                      </FormField>
                      <FormField name="pluralLabel" label="Plural Label">
                        <Input disabled={objectDetails.type !== "custom"} />
                      </FormField>
                      <FormField
                        name="name"
                        label="Name"
                        className="col-span-2"
                      >
                        <Input disabled />
                      </FormField>
                    </div>
                    <div className="flex justify-end">
                      <Button
                        icon={Save}
                        type="submit"
                        className="mt-4 float-end w-fit"
                        variant="primary"
                        disabled={objectDetails.type !== "custom"}
                        size="sm"
                        loading={updateCustomObjectMutation.isLoading}
                      >
                        Update object
                      </Button>
                    </div>
                  </Form>
                </div>
              ),
            },
            {
              key: "attributes",
              label: "Attributes",
              icon: Shapes,
              children: (
                <div className="mt-4">
                  <SimpleTable
                    borderless
                    idKey="name"
                    actions={(row) => [
                      {
                        type: "item",
                        label: "Copy Name",
                        icon: Copy,
                        onClick: (row) => {
                          copyToClipboard(row.name);
                        },
                      },
                      {
                        hidden: row.type !== "custom",
                        type: "item",
                        label: "Edit",
                        icon: Pencil,
                        onClick: () =>
                          row.customFieldId &&
                          setEditCustomField(row.customFieldId),
                      },
                    ]}
                    columns={[
                      {
                        label: "Label",
                        key: "label",
                      },
                      {
                        label: "Type",
                        key: "dataType",
                        render: (row) =>
                          capitalize(row.dataType.replace("_", " ")),
                      },
                      {
                        label: "Attribute Properties",
                        render: (row) =>
                          row.type === "custom" ? "Custom" : "System",
                      },
                      {
                        label: "Constraints",
                        render: (row) => {
                          const constraints = [];
                          if (row.required) constraints.push("Required");
                          if (!row.isActive) constraints.push("Inactive");
                          return constraints.join(", ");
                        },
                      },
                    ]}
                    rows={objectDetails.attributes}
                  />
                </div>
              ),
              title: "Attributes",
              actions: [
                {
                  label: "Field",
                  icon: Plus,
                  onClick: () => setShowAddField(true),
                  buttonVariant: "primary",
                },
              ],
            },
            {
              key: "templates",
              label: "Templates",
              icon: LayoutPanelTop,
              children: (
                <div className="mt-4">
                  <SimpleTable
                    borderless
                    idKey="name"
                    actions={(row) => [
                      {
                        type: "item",
                        label: "Edit",
                        icon: Pencil,
                        onClick: () => setEditTemplate(row.id),
                      },
                    ]}
                    columns={[
                      {
                        label: "Name",
                        key: "name",
                      },
                      {
                        label: "Fields",
                        render: (row) => (
                          <Badge variant="neutral">{row.fieldsCount}</Badge>
                        ),
                      },
                    ]}
                    rows={objectDetails.templates || []}
                  />
                </div>
              ),
              title: "Templates",
              actions: [
                {
                  label: "Template",
                  icon: Plus,
                  buttonVariant: "primary",
                  onClick: () => setShowAddTemplate(true),
                },
              ],
            },
          ]}
        />
      </div>
    </Page>
  );
};

export default ObjectDetails;
