import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Input } from "@heffl/ui/components/primitives/input";
import { KeyIcon, Save, Upload } from "lucide-react";

import FilePicker from "@/components/FilePicker";
import { UserAvatar } from "@/components/UserAvatar";
import Page from "@/components/page";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

export const ChangePasswordModal = ({
  open,
  onClose,
  userId,
}: {
  open: boolean;
  onClose: () => void;
  userId?: number;
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const changePasswordMutation = trpc.auth.changePassword.useMutation({
    onSuccess: () => {
      toast.success("Password changed successfully");
      onClose();
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const handleSubmit = () => {
    setError("");
    if (!newPassword || !confirmPassword) {
      setError("Please fill in all fields");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    changePasswordMutation.mutate({ newPassword, userId });
  };

  return (
    <ModalDrawer open={open} onClose={onClose} title="Change Password">
      <div className="flex flex-col gap-4">
        <Input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {!!error && <p className="text-red-500">{error}</p>}
        <Button
          onClick={handleSubmit}
          variant="primary"
          className="w-full"
          loading={changePasswordMutation.isLoading}
        >
          Change Password
        </Button>
      </div>
    </ModalDrawer>
  );
};

const Profile = () => {
  const form = useForm<z.infer<typeof Schemas.profile.profile>>({
    resolver: zodResolver(Schemas.profile.profile),
  });

  const trpcUtils = trpc.useUtils();

  const [showChangePassword, setShowChangePassword] = useState(false);

  const uploadProfileImage = trpc.users.uploadProfileImage.useMutation();
  const { data } = trpc.profile.details.useQuery();
  const updateUserMutation = trpc.profile.update.useMutation({
    onSuccess: () => {
      toast.success("Succesfully updated profile.");
    },
  });

  useEffect(() => {
    if (data) {
      form.reset(data);
    }
  }, [data]);

  if (!data) return <FullScreenSpinner />;
  return (
    <Page className="flex justify-center" title="Profile">
      <ChangePasswordModal
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      />

      <div className="flex flex-col gap-3 p-5 w-full max-w-3xl">
        <div className="flex items-start space-x-6">
          <UserAvatar user={data} size="lg" hideToast />
          <div className="flex-1">
            <h1 className="text-lg font-medium">Profile Picture</h1>
            <FilePicker
              onPick={async (file) => {
                if (file[0]) {
                  const { presignedUrl } = await uploadProfileImage.mutateAsync(
                    { format: file[0].type }
                  );
                  if (presignedUrl) {
                    await axios.put(presignedUrl, file[0], {
                      headers: { "Content-Type": file[0].type },
                    });
                    trpcUtils.invalidate();
                  }
                }
              }}
              accept="image/*"
            >
              <Button>
                <Upload className="h-4" />
                Upload image
              </Button>
            </FilePicker>
            <p className="mt-2 text-sm text-gray-500">
              We support PNGs, JPEGs and GIFs under 10MB
            </p>
          </div>
        </div>
        <Form
          {...form}
          onSubmit={(values) => {
            updateUserMutation.mutate({
              user: values,
            });
          }}
        >
          <div className="grid grid-cols-2 gap-6">
            <FormField name="firstName" label="First Name">
              <Input />
            </FormField>
            <FormField name="lastName" label="Last Name">
              <Input />
            </FormField>
            <FormField name="email" label="Email">
              <Input />
            </FormField>
          </div>
          <div className="flex gap-2">
            <Button
              type="button"
              variant="primaryOutline"
              icon={KeyIcon}
              className="w-fit"
              onClick={() => setShowChangePassword(true)}
            >
              Change Password
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="w-full"
              loading={updateUserMutation.isLoading}
              icon={Save}
            >
              Update Profile
            </Button>
          </div>
        </Form>
      </div>
    </Page>
  );
};

export default Profile;
