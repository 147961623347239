import { z } from "zod";
import { address, contact } from "./crm";
import enums from "./enums";
import * as files from "./files";
import { lineItemProduct } from "./sales";

export const vendor = z.object({
  name: z.string(),
  taxNumber: z
    .string()
    .min(15, "Invalid tax number")
    .max(15, "Invalid tax number")
    .nullish()
    .or(z.literal("")),
  openingBalance: z.coerce.number().default(0),
  description: z.string().nullish(),
  zohoVendorId: z.string().nullish(),
  billingAddress: address.nullish(),
  externalId: z.string().nullish(),
  importOperationId: z.number().nullish(),
  primaryContact: contact
    .partial({
      firstName: true,
    })
    .omit({ clientId: true, vendorId: true })
    .nullish(),
});

export const purchaseOrder = z.object({
  date: z.date(),
  status: enums.purchaseOrderStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish(),
  discount: z.coerce.number().default(0),
  templateId: z.number(),
  contactId: z.number().nullish(),
  expectedDeliveryDate: z.date().nullish(),
  markedSentOn: z.date().nullish(),
  vendorId: z.number(),
  purchaseOrderItems: z.array(lineItemProduct),
  purchaseOrderTags: z.coerce.number().array().default([]),
  purchaseOrderJobs: z.coerce.number().array().default([]),
  cancelReason: z.string().nullish(),
});

export const bill = z.object({
  billNumber: z.string().min(1, "Bill number is required"),
  date: z.date(),
  status: enums.billStatuses.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish(),
  discount: z.coerce.number().default(0),
  templateId: z.number(),
  dueDate: z.date(),
  markedSentOn: z.date().nullish(),
  vendorId: z.number(),
  billItems: z.array(lineItemProduct),
  billTags: z.coerce.number().array().default([]),
  cancelReason: z.string().nullish(),
});

export const expenses = z.object({
  expenseAccountId: z.number(),
  paidThroughAccountId: z.number(),
  userId: z.number().nullish(),
  type: enums.expenseTypes,
  refNo: z.string().nullish(),
  projectId: z.number().nullish(),
  fsJobId: z.number().nullish(),
  date: z.date(),
  amount: z.coerce.number(),
  notes: z.string().nullish(),
  vendorId: z.number().nullish(),
  paidBy: enums.expenses.paidBy.schema.default("COMPANY"),
  salesOrderId: z.number().nullish(),
  files: z
    .object({
      expense_reciept: files.filesInput,
    })
    .optional(),
});

export const paymentsMade = z.object({
  vendorId: z.number(),
  date: z.date(),
  amount: z.coerce.number(),
  notes: z.string().nullish(),
  refNo: z.string().nullish(),
  paidThroughAccountId: z.number(),
  paymentMadeAllocations: z
    .object({
      billId: z.number().nullish(),
      amount: z.coerce.number().default(0),
      type: enums.paymentMadeAllocationType,
    })
    .array()
    .default([]),
});
