import DetailsPage from "@/components/details-page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import RenderTimeline from "@/helpers/userActivity/render-timeline";
import ExpenseDetailsModal from "@/pages/purchases/expenses/details-modal";
import { AddExpenseModal } from "@/pages/purchases/expenses/list";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import enums from "@heffl/server/src/schemas/enums";
import { Badge, BadgeProps } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  Activity,
  ArrowDownToLine,
  Ban,
  Check,
  Copy,
  FileSearch,
  MoreHorizontal,
  Pencil,
  PencilIcon,
  Plus,
  Printer,
  Trash,
} from "lucide-react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { z } from "zod";
import { downloadPdf } from "../../sales/invoices/details";
import {
  AddCommissionModal,
  EditCommissionModal,
} from "../commissions/commission-modals";
import { InvoiceStatusBadge } from "../invoices/list";
import { SalesOrderStatusBadge } from "./list";

export const generateSalesOrderName = (salesOrder: {
  date: Date;
  number: string;
  clients: {
    name: string;
  };
}) => {
  if (!salesOrder) return "";
  return `Sales Order ${salesOrder.number}-${salesOrder.clients.name}-${dayjs(
    salesOrder.date
  ).format("DD-MM-YYYY")}`;
};

export const commissionStatus: Record<
  z.infer<typeof enums.commissionStatus>,
  {
    label: string;
    variant: BadgeProps["variant"];
  }
> = {
  DRAFT: {
    label: "Draft",
    variant: "neutral",
  },
  CONFIRMED: {
    label: "Confirmed",
    variant: "success",
  },
  CANCELLED: {
    label: "Cancelled",
    variant: "error",
  },
};

const SalesOrderDetails: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const salesOrderPrintRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();

  const salesOrderId = Number(params.id);

  const [addCommission, setAddCommission] = useState(false);
  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [editCommission, setEditCommission] = useState<number | undefined>(
    undefined
  );
  const [addExpense, setAddExpense] = useState(false);
  const [showExpenseDetails, setShowExpenseDetails] = useState<
    number | undefined
  >(undefined);

  const { data: salesOrder, isLoading } =
    trpc.sales.salesOrders.details.useQuery(salesOrderId);

  const { data: commissions } = trpc.sales.commissions.list.useQuery({
    salesOrderId,
  });

  const { data: expenses } = trpc.purchases.expenses.list.useQuery({
    salesOrderId,
  });

  const { data: invoices } = trpc.invoices.list.useQuery({
    salesOrderIds: [salesOrderId],
  });

  const deleteCommissionMutation = trpc.sales.commissions.delete.useMutation({
    onSuccess() {
      heffl.toast.success("Commission deleted successfully");
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const updateCommissionMutation = trpc.sales.commissions.update.useMutation({
    onSuccess() {
      heffl.toast.success("Commission updated successfully");
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const printSalesOrder = useReactToPrint({
    content: () => salesOrderPrintRef.current,
    documentTitle: salesOrder ? generateSalesOrderName(salesOrder) : "",
  });

  const updateSalesOrderMutation = trpc.sales.salesOrders.update.useMutation({
    onSuccess() {
      toast.success("Sales order updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const deleteSalesOrderMutation = trpc.sales.salesOrders.delete.useMutation({
    onSuccess() {
      toast.success("Sales order deleted successfully");
      navigate("/sales/sales-orders");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (isLoading || !salesOrder) return <FullScreenSpinner />;

  return (
    <>
      <AddCommissionModal
        open={addCommission}
        onClose={() => setAddCommission(false)}
        defaultValues={{ salesOrderId: salesOrder.id }}
      />
      <AddExpenseModal
        open={addExpense}
        onClose={() => setAddExpense(false)}
        defaultValues={{ salesOrderId: salesOrderId }}
      />
      {!!showExpenseDetails && (
        <ExpenseDetailsModal
          open={!!showExpenseDetails}
          id={showExpenseDetails}
          onClose={() => setShowExpenseDetails(undefined)}
        />
      )}
      {!!editCommission && (
        <EditCommissionModal
          open={!!editCommission}
          id={editCommission}
          onClose={() => setEditCommission(undefined)}
        />
      )}
      <DetailsPage
        activities={[]}
        title={`Sales Order #${salesOrder.number}`}
        widgets={[
          <Badge key="days-since" variant="neutral" small icon={Pencil}>
            Created {dayjs(salesOrder.createdAt).fromNow()}
          </Badge>,
          <SalesOrderStatusBadge
            key="status"
            status={salesOrder.status}
            small
          />,
        ]}
        description={`Created on ${format(
          salesOrder.createdAt,
          "dd MMM yyyy hh:mm a"
        )}`}
        attributes={[
          {
            section: "Details",
            icon: Pencil,
            onClick: () => {
              navigate(`/sales/sales-orders/edit/${salesOrderId}`);
            },
            items: [
              {
                label: "Date",
                value: dayjs(salesOrder.date).format("DD/MM/YYYY"),
              },
              {
                label: "Status",
                value: <SalesOrderStatusBadge status={salesOrder.status} />,
              },
              {
                label: "Client",
                value: salesOrder.clients.name,
                onClick() {
                  navigate(`/sales/clients/details/${salesOrder.clientId}`);
                },
              },
              {
                label: "Sales Person",
                value: salesOrder.salesPerson
                  ? heffl.format.name(salesOrder.salesPerson)
                  : "-",
              },
              {
                label: "Created by",
                value: heffl.format.name(salesOrder.createdBy),
              },
              ...salesOrder.customFieldDetails.fields.map((field) => ({
                label: field.label,
                value: renderCustomFieldValue(
                  field,
                  salesOrder.customFieldDetails.values[field.name]
                ),
              })),
            ],
          },
          {
            section: "Contact",
            items: salesOrder.contacts
              ? [
                  {
                    label: "Name",
                    value: heffl.format.name(salesOrder.contacts),
                  },
                  {
                    label: "Email",
                    value: salesOrder.contacts.email,
                  },
                  {
                    label: "Mobile",
                    value: salesOrder.contacts.mobile,
                  },
                ]
              : [],
          },
        ]}
        tabs={{
          items: [
            {
              key: "preview",
              label: "Preview",
              icon: FileSearch,
              children: (
                <div className="w-full rounded-lg border border-gray-200 sm:w-fit">
                  <div ref={salesOrderPrintRef}>
                    <TemplateRender
                      contentHtml={salesOrder.contentInjected}
                      template={salesOrder.documentTemplates}
                      previewMode={previewMode}
                    />
                  </div>
                </div>
              ),
            },
            {
              key: "invoices",
              label: "Invoices",
              icon: heffl.icons.sales.invoices.icon,
              title: "Invoices",
              count: salesOrder._count.salesOrderInvoices,
              actions: [
                {
                  label: "Invoice",
                  icon: Plus,
                  buttonVariant: "primary",
                  onClick: () =>
                    navigate(
                      `/sales/invoices/add?salesOrderId=${salesOrderId}`
                    ),
                },
              ],
              children: (
                <SimpleTable
                  borderless
                  className="mt-4"
                  rows={invoices?.invoices || []}
                  onRowClick={(row) =>
                    navigate(`/sales/invoices/details/${row.id}`)
                  }
                  columns={[
                    {
                      label: "Number",
                      key: "number",
                    },

                    {
                      label: "Client",
                      key: "clients",
                      render: (row) => row.clients.name,
                    },
                    {
                      label: "Status",
                      key: "status",
                      render: (row) => <InvoiceStatusBadge invoice={row} />,
                    },
                    {
                      label: "Date",
                      render: (row) => dayjs(row.date).format("DD/MM/YYYY"),
                    },
                    {
                      label: "Amount",
                      render: (row) =>
                        heffl.format.currency(
                          calculateInvoice({
                            invoiceProducts: row.invoiceProducts,
                            discount: row.discount,
                            paymentReceivedAllocations:
                              row.paymentReceivedAllocations,
                          }).invoiceTotal,
                          "AED",
                          true
                        ),
                    },
                  ]}
                  idKey="id"
                />
              ),
            },
            {
              key: "commissions",
              label: "Commissions",
              icon: heffl.icons.sales.commissions.icon,
              count: salesOrder._count.commissions,
              children: (
                <SimpleTable
                  borderless
                  className="mt-4"
                  rows={commissions?.commissions || []}
                  actions={(row) => [
                    {
                      type: "item",
                      label: "Mark as confirmed",
                      onClick: () => {
                        updateCommissionMutation.mutate({
                          id: row.id,
                          commission: {
                            status: "CONFIRMED",
                          },
                        });
                      },
                      icon: Check,
                      hidden: row.status === "CONFIRMED",
                      classes: {
                        icon: "text-green-500",
                      },
                    },
                    {
                      type: "item",
                      label: "Edit",
                      onClick: () => setEditCommission(row.id),
                      icon: Pencil,
                    },
                    {
                      type: "item",
                      icon: Ban,
                      classes: {
                        label: "text-red-500",
                      },
                      hidden: row.status === "CANCELLED",
                      label: "Cancel commission",
                      onClick: () => {
                        heffl.modal.reason({
                          title: "Cancel commission",
                          onSubmit: (reason) => {
                            if (row.billId) {
                              return heffl.toast.error(
                                "Commission cannot be cancelled because it has a bill"
                              );
                            }
                            updateCommissionMutation.mutate({
                              id: row.id,
                              commission: {
                                status: "CANCELLED",
                                cancelReason: reason.description,
                              },
                            });
                          },
                        });
                      },
                    },
                    {
                      type: "item",
                      icon: Trash,
                      classes: {
                        label: "text-red-500",
                      },
                      label: "Delete",
                      onClick: () => {
                        heffl.modal.confirm({
                          title: "Delete commission",
                          description:
                            "Are you sure you want to delete this commission?",
                          onConfirm: () =>
                            deleteCommissionMutation.mutate(row.id),
                        });
                      },
                    },
                  ]}
                  columns={[
                    {
                      label: "Created At",
                      render: (row) => heffl.format.dateTime(row.createdAt),
                    },
                    {
                      label: "Amount",
                      render: (row) => heffl.format.currency(row.amount, "AED"),
                    },
                    {
                      label: "Paid To",
                      key: "paidTo",
                    },
                    {
                      label: "Payable To",
                      render: (row) => {
                        if (row.paidTo === "USER" && row.payableToUser) {
                          return heffl.format.name(row.payableToUser);
                        }
                        if (row.paidTo === "VENDOR" && row.payableToVendor) {
                          return row.payableToVendor.name;
                        }
                        return "-";
                      },
                    },
                    {
                      label: "Status",
                      render: (row) => (
                        <Badge variant={commissionStatus[row.status].variant}>
                          {commissionStatus[row.status].label}
                        </Badge>
                      ),
                    },
                    {
                      label: "Bill",
                      render: (row) =>
                        row.bills ? (
                          <div className="flex gap-2 items-center hover:underline hover:text-primary">
                            <Link to={`/purchases/bills/details/${row.billId}`}>
                              #{row.bills.number}
                            </Link>
                          </div>
                        ) : (
                          ""
                        ),
                    },
                  ]}
                  idKey="id"
                />
              ),
              title: "Commissions",
              actions: [
                {
                  label: "Add Commission",
                  icon: Plus,
                  buttonVariant: "primary",
                  onClick: () => setAddCommission(true),
                },
              ],
            },
            {
              key: "expenses",
              label: "Expenses",
              icon: heffl.icons.purchases.expenses.icon,
              title: "Expenses",
              count: salesOrder._count.expenses,
              actions: [
                {
                  label: "Expense",
                  icon: Plus,
                  buttonVariant: "primary",
                  onClick: () => setAddExpense(true),
                },
              ],
              children: (
                <SimpleTable
                  rows={expenses?.expenses || []}
                  idKey="id"
                  onRowClick={(row) => setShowExpenseDetails(row.id)}
                  columns={[
                    {
                      label: "Date",
                      render: (row) => dayjs(row.date).format("DD/MM/YYYY"),
                    },
                    {
                      label: "Number",
                      key: "number",
                    },
                    {
                      label: "Expense Account",
                      render: (row) => row.expenseAccount?.name || "-",
                    },
                    {
                      label: "Amount",
                      render: (row) =>
                        heffl.format.currency(row.amount, "AED", true),
                    },
                    {
                      label: "Paid Through",
                      render: (row) => row.paidThroughAccount?.name || "-",
                    },
                  ]}
                  borderless
                  className="mt-4"
                />
              ),
            },
            {
              key: "activity",
              label: "Activity",
              icon: Activity,
              children: (
                <RenderTimeline userActivities={salesOrder.userActivities} />
              ),
            },
          ],
        }}
        actions={[
          {
            icon: Printer,
            buttonVariant: "outline",
            iconClassName: "text-purple-500",
            buttonClassName: "sm:rounded-r-none",
            label: "Print",
            onClick: () => {
              setPreviewMode(false);
              setTimeout(() => {
                printSalesOrder();
                setPreviewMode(true);
              }, 100);
            },
          },
          {
            buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
            buttonVariant: "outline",
            icon: ArrowDownToLine,
            label: "Download",
            onClick: async () => {
              setDownloadLoading(true);
              await downloadPdf({
                name: generateSalesOrderName(salesOrder),
                url: `print?sales-order=${salesOrder.uuid}`,
              });
              setTimeout(() => {
                setDownloadLoading(false);
              }, 3000);
            },
            loading: downloadLoading,
            hideLabelOnDesktop: true,
          },
          {
            buttonClassName: "sm:rounded-l-none sm:-ml-2",
            buttonVariant: "outline",
            label: "Duplicate",
            icon: Copy,
            hideLabelOnDesktop: true,
            onClick: () =>
              navigate(`/sales/sales-orders/add?salesOrderId=${salesOrder.id}`),
          },
          {
            hide: salesOrder.status !== "DRAFT",
            buttonVariant: "outline",
            label: "Mark Confirmed",
            icon: Check,
            iconClassName: "text-green-500",
            loading: updateSalesOrderMutation.isLoading,
            onClick: () => {
              updateSalesOrderMutation.mutate({
                id: salesOrder.id,
                salesOrder: {
                  status: "CONFIRMED",
                },
              });
            },
          },
          {
            icon: MoreHorizontal,
            label: "More",
            onClick: () => {},
            dropdown: (
              <>
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={() =>
                    navigate(`/sales/sales-orders/edit/${salesOrder.id}`)
                  }
                >
                  <PencilIcon className="mr-2 w-5" />
                  Edit
                </Button>
                {salesOrder.status !== "CANCELLED" && (
                  <Button
                    icon={Ban}
                    variant="ghost"
                    className="text-red-500"
                    onClick={() =>
                      heffl.modal.reason({
                        submitText: "Cancel sales order",
                        onSubmit: (reason) => {
                          updateSalesOrderMutation.mutate({
                            id: salesOrder.id,
                            salesOrder: {
                              status: "CANCELLED",
                              cancelReason: reason.description,
                            },
                          });
                        },
                      })
                    }
                  >
                    Cancel sales order
                  </Button>
                )}
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={async () => {
                    const confirmed = await confirm(
                      "Are you sure to delete this sales order?"
                    );
                    if (confirmed) {
                      deleteSalesOrderMutation.mutate({ id: salesOrder.id });
                    }
                  }}
                >
                  <Trash className="mr-2 w-5 text-red-500" />
                  Delete
                </Button>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default SalesOrderDetails;
