import Page from "@/components/page";
import { CustomFieldInput } from "@/helpers/customFields/custom-fields-input-form";
import { trpc } from "@/helpers/trpc";
import { workflowApps } from "@heffl/server/src/helpers/workflows/apps";
import { WorkFlowApp } from "@heffl/server/src/helpers/workflows/types";
import Schemas from "@heffl/server/src/schemas";
import DropMenu, { DropMenuItem } from "@heffl/ui/components/DropMenu";
import { CustomIcon } from "@heffl/ui/components/icons";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import { Sheet } from "@heffl/ui/components/primitives/sheet";
import { Switch } from "@heffl/ui/components/primitives/switch";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { zodResolver } from "@hookform/resolvers/zod";
import Fuse from "fuse.js";
import { styled } from "goober";
import { MoreHorizontal, Plus, Save, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { z } from "zod";
import validateActionFields from "./components/validate-action-fields";

const AppCard = ({
  app,
  onClick,
}: {
  app: WorkFlowApp | undefined;
  onClick?: (appName: string) => void;
}) => {
  if (!app) return null;

  return (
    <div
      onClick={() => onClick && onClick(app.name)}
      className="flex gap-3 items-center p-3 bg-white rounded-md border-2 border-gray-200 cursor-pointer hover:border-primary-500"
    >
      <CustomIcon icon={app.logo} className="w-8 h-8" />
      <div>
        <p className="text-sm font-medium">{app.label}</p>
        <p className="text-gray-500">{app.description}</p>
      </div>
    </div>
  );
};

const getActionInput = (appName: string, actionName: string) => {
  const app = workflowApps.find((app) => app.name === appName);
  if (!app) return [];
  const action = app.actions.find((action) => action.name === actionName);
  if (!action) return [];
  return action.inputs;
};

const getTriggerInput = (appName: string, triggerName: string) => {
  const app = workflowApps.find((app) => app.name === appName);
  if (!app) return [];
  const trigger = app.triggers.find((trigger) => trigger.name === triggerName);
  if (!trigger) return [];
  return trigger.inputs;
};

const AutomationForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.workflows.workFlowItem>>;
}) => {
  const selectedApp = form.watch("app");
  const selectedAction = form.watch("action");

  return (
    <>
      {selectedApp && (
        <>
          <AppCard app={workflowApps.find((app) => app.name === selectedApp)} />
          <p className="">Select action</p>
          <div className="flex flex-col gap-2">
            <FormField name="action">
              <Select
                options={workflowApps
                  .find((app) => app.name === selectedApp)
                  ?.actions.map((action) => ({
                    label: action.label,
                    value: action.name,
                  }))}
              />
            </FormField>
            {getActionInput(selectedApp, selectedAction).map((field) => (
              <FormField
                name={`data.${field.name}`}
                key={field.name}
                label={field.label}
              >
                <CustomFieldInput
                  field={{ ...field, required: field.required || false }}
                />
              </FormField>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const AutomationTriggerForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof triggerSchema>>;
}) => {
  const selectedApp = form.watch("triggerApp");
  const selectedTrigger = form.watch("triggerName");

  return (
    <>
      {selectedApp && (
        <>
          <AppCard app={workflowApps.find((app) => app.name === selectedApp)} />
          <p className="">Trigger</p>
          <div className="flex flex-col gap-2">
            <FormField name="triggerName">
              <Select
                options={workflowApps
                  .find((app) => app.name === selectedApp)
                  ?.triggers.map((action) => ({
                    label: action.label,
                    value: action.name,
                  }))}
              />
            </FormField>
            {selectedTrigger &&
              getTriggerInput(selectedApp, selectedTrigger).map((field) => (
                <FormField
                  name={`triggerData.${field.name}`}
                  key={field.name}
                  label={field.label}
                >
                  <CustomFieldInput
                    field={{ ...field, required: field.required || false }}
                  />
                </FormField>
              ))}
          </div>
          <Button type="submit" variant="primary" icon={Save}>
            Update trigger
          </Button>
        </>
      )}
    </>
  );
};

const triggerSchema = Schemas.workflows.workflow.pick({
  triggerApp: true,
  triggerName: true,
  triggerData: true,
});

const AddAutomationTriggerModal = ({
  open,
  onClose,
  automationId,
  defaultValues,
}: {
  defaultValues?: {
    triggerApp: string;
    triggerName: string;
  };
  open: boolean;
  onClose: () => void;
  automationId: number;
}) => {
  console.log(defaultValues);

  const { data: automation } = trpc.automations.details.useQuery(automationId);

  const form = useForm<z.infer<typeof triggerSchema>>({
    resolver: zodResolver(triggerSchema),
  });

  useEffect(() => {
    if (defaultValues) {
      form.setValue("triggerApp", defaultValues.triggerApp);
      form.setValue("triggerName", defaultValues.triggerName);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (automation?.triggerApp && automation?.triggerName) {
      form.reset({
        triggerApp: automation.triggerApp,
        triggerName: automation.triggerName,
        // @ts-ignore
        triggerData: automation.triggerData,
      });
    }
  }, [automation]);

  const updateAutomationMutation = trpc.automations.update.useMutation({
    onSuccess: () => {
      onClose();
      form.reset();
      toast.success("Added trigger successfully");
    },
  });

  const onSubmit = (values: z.infer<typeof triggerSchema>) => {
    if (!values.triggerApp || !values.triggerName) return;
    const isValid = validateActionFields({
      fields: getActionInput(values.triggerApp, values.triggerName),
      data: values.triggerData || {},
      form,
    });
    if (!isValid) return;
    updateAutomationMutation.mutate({
      id: automationId,
      automation: {
        ...values,
      },
    });
  };

  return (
    <Sheet
      open={open}
      className="w-[400px]"
      title="Add automation trigger"
      onClose={() => {
        onClose();
        form.reset();
      }}
    >
      <Form {...form} onSubmit={onSubmit}>
        <AutomationTriggerForm form={form} />
      </Form>
    </Sheet>
  );
};

const EditWorkflowModal = ({
  open,
  onClose,
  workflowItemId,
}: {
  open: boolean;
  onClose: () => void;
  workflowItemId: number;
}) => {
  const { data: automationStep } =
    trpc.automations.steps.details.useQuery(workflowItemId);

  const form = useForm<z.infer<typeof Schemas.workflows.workFlowItem>>({
    resolver: zodResolver(Schemas.workflows.workFlowItem),
  });

  const updateWorkflowItemMutation = trpc.automations.steps.update.useMutation({
    onSuccess: () => {
      onClose();
      form.reset();
      toast.success("Workflow item updated successfully");
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.workflows.workFlowItem>) => {
    const isValid = validateActionFields({
      fields: getActionInput(values.app, values.action),
      data: values.data,
      form,
    });
    if (!isValid) return;
    updateWorkflowItemMutation.mutate({
      id: workflowItemId,
      workflowItem: values,
    });
  };

  useEffect(() => {
    if (automationStep) {
      form.reset({
        ...automationStep,
        data: automationStep.data as Record<string, string>,
      });
    }
  }, [automationStep]);

  return (
    <Sheet
      open={open}
      className="w-[400px]"
      title="Edit workflow item"
      onClose={() => {
        onClose();
        form.reset();
      }}
    >
      <Form {...form} onSubmit={onSubmit}>
        <AutomationForm form={form} />
        <Button type="submit" variant="primary" icon={Save}>
          Update step
        </Button>
      </Form>
    </Sheet>
  );
};

const AddAutomationStepModal = ({
  open,
  onClose,
  workflowId,
  app,
  action,
}: {
  app: string;
  action: string;
  open: boolean;
  onClose: () => void;
  workflowId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.workflows.workFlowItem>>({
    resolver: zodResolver(Schemas.workflows.workFlowItem),
  });

  useEffect(() => {
    form.setValue("app", app);
    form.setValue("action", action);
  }, [app, action]);

  const addWorkflowItemMutation = trpc.automations.steps.add.useMutation({
    onSuccess: () => {
      onClose();
      form.reset();
      toast.success("Workflow item added successfully");
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.workflows.workFlowItem>) => {
    const isValid = validateActionFields({
      fields: getActionInput(values.app, values.action),
      data: values.data,
      form,
    });
    if (!isValid) return;
    addWorkflowItemMutation.mutate({
      ...values,
      automationId: workflowId,
    });
  };

  return (
    <Sheet
      open={open}
      className="w-[400px]"
      title="Add automation step"
      onClose={() => {
        onClose();
        form.reset();
      }}
    >
      <Form {...form} onSubmit={onSubmit}>
        <AutomationForm form={form} />
        <Button type="submit" variant="primary" icon={Save}>
          Add step
        </Button>
      </Form>
    </Sheet>
  );
};

const DottedBackground = styled("div")`
  --dot-bg: #f7f7f7;
  --dot-color: gray;
  --dot-size: 1px;
  --dot-space: 22px;
  background: linear-gradient(
        90deg,
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    linear-gradient(
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    var(--dot-color);
`;

const ActionCard = ({
  app,
  value,
  type,
  menu,
  onClick,
}: {
  app: WorkFlowApp;
  value: string;
  type: "triggers" | "actions";
  menu: DropMenuItem[];
  onClick: ({ app, value }: { app: string; value: string }) => void;
}) => {
  return (
    <div
      onClick={() => onClick({ app: app.name, value })}
      className="flex gap-4 justify-between items-center p-3 w-80 bg-white rounded-2xl border border-gray-200 cursor-pointer hover:shadow-sm hover:shadow-primary-500"
    >
      <div className="flex gap-4 items-center">
        <CustomIcon icon={app.logo} className="w-8 h-8" />
        <div>
          <p className="text-sm text-gray-500">{app.label}</p>
          <p className="text-base">
            {app[type].find((action) => action.name === value)?.label}
          </p>
        </div>
      </div>
      <DropMenu items={menu}>
        <Button variant="ghost" className="justify-self-end">
          <MoreHorizontal />
        </Button>
      </DropMenu>
    </div>
  );
};

type SelectAction = {
  automationStepId?: number;
  triggerLabel: string;
  value: { app: string; value: string } | null;
  onChange?: (value: { app: string; value: string } | null) => void;
  onClick: ({ app, value }: { app: string; value: string }) => void;
  automationId: number;
  type: "triggers" | "actions";
};

const SelectAction = ({
  triggerLabel,
  value,
  onChange,
  onClick,
  type,
  automationId,
  automationStepId,
}: SelectAction) => {
  const [search, setSearch] = useState("");

  const updateAutomationMutation = trpc.automations.update.useMutation();
  const deleteAutomationStepMutation =
    trpc.automations.steps.delete.useMutation();

  const filteredApps = () => {
    if (search.length) {
      const filteredProperties = new Fuse(workflowApps, {
        keys: ["name"],
      });
      return filteredProperties.search(search).map((result) => result.item);
    } else {
      return workflowApps || [];
    }
  };

  return value?.app && value?.value ? (
    <ActionCard
      onClick={({ app, value }) => onClick({ app, value })}
      app={workflowApps.find((app) => app.name === value.app)!}
      value={value.value}
      type={type}
      menu={[
        {
          icon: Trash2,
          className: "text-red-500",
          label: "Delete",
          onClick: () => {
            if (type === "triggers") {
              updateAutomationMutation.mutate({
                id: automationId,
                automation: {
                  triggerApp: null,
                  triggerName: null,
                },
              });
            } else if (type === "actions") {
              automationStepId &&
                deleteAutomationStepMutation.mutate(automationStepId);
            }
          },
        },
      ]}
    />
  ) : (
    <Popover>
      <PopoverTrigger>
        <Button
          variant="outline"
          className="px-24 !font-normal text-gray-600 border-dashed text-primary-700"
          size="lg"
          icon={Plus}
          iconClassName="w-4 h-4"
        >
          {triggerLabel}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <div className="flex flex-col">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Search ${type}...`}
            className="p-2 border-none ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
          />
          <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="flex flex-col gap-2 mt-2">
            {filteredApps()
              .filter((app) => app[type].length)
              .map((app) => (
                <div key={app.name}>
                  <div className="">
                    <p className="px-2 py-1 text-xs text-gray-500 uppercase">
                      {app.label}
                    </p>
                    <ScrollArea className="flex overflow-auto flex-col gap-2">
                      {app[type].map((trigger) => {
                        return (
                          <div
                            key={trigger.name}
                            className="flex gap-1 items-center p-2 text-gray-600 cursor-pointer hover:bg-primary-50"
                            onClick={() => {
                              onChange &&
                                onChange({
                                  app: app.name,
                                  value: trigger.name,
                                });
                            }}
                          >
                            <CustomIcon
                              icon={app.logo}
                              className="w-5 h-5 rounded-full"
                            />
                            {trigger.label}
                          </div>
                        );
                      })}
                    </ScrollArea>
                  </div>
                  <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
                </div>
              ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const EditAutomation: React.FC = () => {
  const params = useParams<{ id: string }>();

  const workflowId = Number(params.id);

  const [addAutomationStep, setAddAutomationStep] = useState<
    { app: string; action: string } | undefined
  >(undefined);
  const [editWorkflowItemId, setEditWorkflowItemId] = useState<number | null>(
    null
  );
  const [tab, setTab] = useState<"manage" | "runs">("manage");

  const [addAutomationTrigger, setAddAutomationTrigger] = useState<{
    open: boolean;
    defaultValues?: { triggerApp: string; triggerName: string };
  }>({
    open: false,
  });

  const { data: workflow } = trpc.automations.details.useQuery(workflowId);

  if (!workflow) return <FullScreenSpinner />;

  return (
    <Page
      title="Add Automation"
      fullWidth
      className="!p-0 min-h-screen bg-gray-100"
      showBack
      description="Automate your workflows"
      suffix={
        <div className="flex gap-2 items-center">
          <TabsInput
            value={tab}
            onChange={(value) => setTab(value as "manage" | "runs")}
            className="mr-6"
            options={[
              {
                label: "Manage",
                value: "manage",
              },
              {
                label: "Runs",
                value: "runs",
              },
            ]}
          />
          <Switch value={workflow.isActive} onChange={() => {}} />
          <p className="text-sm text-gray-500">Enable automation</p>
        </div>
      }
    >
      {addAutomationStep && (
        <AddAutomationStepModal
          open={!!addAutomationStep}
          onClose={() => setAddAutomationStep(undefined)}
          workflowId={workflowId}
          app={addAutomationStep.app}
          action={addAutomationStep.action}
        />
      )}
      {editWorkflowItemId && (
        <EditWorkflowModal
          open={true}
          onClose={() => setEditWorkflowItemId(null)}
          workflowItemId={editWorkflowItemId}
        />
      )}

      <AddAutomationTriggerModal
        open={addAutomationTrigger.open}
        onClose={() => setAddAutomationTrigger({ open: false })}
        automationId={workflowId}
        defaultValues={addAutomationTrigger.defaultValues}
      />

      <DottedBackground className="flex flex-col items-center pt-8 space-y-3 min-h-screen">
        <Badge variant="warning">When this happens..</Badge>
        <SelectAction
          value={
            workflow.triggerApp && workflow.triggerName
              ? {
                  app: workflow.triggerApp,
                  value: workflow.triggerName,
                }
              : null
          }
          type="triggers"
          triggerLabel="Add Trigger"
          automationId={workflowId}
          onChange={(value) => {
            value &&
              setAddAutomationTrigger({
                open: true,
                defaultValues: {
                  triggerApp: value.app,
                  triggerName: value.value,
                },
              });
          }}
          onClick={() => {
            setAddAutomationTrigger({ open: true });
          }}
        />

        <Badge variant="success" className="!mt-10">
          Do this...
        </Badge>
        {workflow.automationSteps.map((step) => (
          <div key={step.id}>
            <SelectAction
              automationStepId={step.id}
              automationId={workflowId}
              key={step.id}
              value={{
                app: step.app,
                value: step.action,
              }}
              onClick={() => setEditWorkflowItemId(step.id)}
              type="actions"
              triggerLabel="Add Action"
            />
            <div className="flex flex-col items-center w-full">
              <div className="h-12 border-gray-200 border-dashed border-[1px]"></div>
            </div>
          </div>
        ))}
        <SelectAction
          value={null}
          automationId={workflowId}
          onClick={(value) => {
            value.app &&
              value.value &&
              setAddAutomationStep({
                app: value.app,
                action: value.value,
              });
          }}
          onChange={(value) => {
            value &&
              setAddAutomationStep({
                action: value?.value,
                app: value?.app,
              });
          }}
          type="actions"
          triggerLabel="Add Action"
        />
      </DottedBackground>
    </Page>
  );
};

export default EditAutomation;
