import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import Select from "@heffl/ui/components/primitives/select";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const CommissionSettings: React.FC = () => {
  const form = useForm<z.infer<typeof teamSettingsSchema>>({
    resolver: zodResolver(teamSettingsSchema),
  });

  const [productSearch, setProductSearch] = useState("");

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();
  const { data: products } = trpc.products.list.useQuery({
    search: productSearch,
    pageSize: 20,
  });

  const updateTeamSettingsMutation = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Settings updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof teamSettingsSchema>) => {
    updateTeamSettingsMutation.mutate({
      commission: data.commission,
    });
  };

  useEffect(() => {
    if (teamDetails?.teamSettings) {
      form.reset({
        ...teamDetails.teamSettings,
      });
    }
  }, [teamDetails, form]);

  return (
    <Page title="Commission">
      <Form {...form} onSubmit={onSubmit}>
        <p className="text-sm text-gray-500">Commission settings</p>
        <FormField
          name="commission.commissionLineItemId"
          label="Commission line item for bill"
          className="w-80"
        >
          <Select
            placeholder="Select commission line item for bill"
            onSearch={setProductSearch}
            options={products?.products.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
          />
        </FormField>
        <FormField
          name="commission.createInstantBillDefault"
          label="Create instant bill by default"
        >
          <Switch />
        </FormField>

        <div className="flex justify-end">
          <Button
            loading={updateTeamSettingsMutation.isLoading}
            type="submit"
            icon={Save}
            variant="primary"
          >
            Update settings
          </Button>
        </div>
      </Form>
    </Page>
  );
};

export default CommissionSettings;
