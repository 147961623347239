/* eslint-disable react/prop-types */
import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  FormProviderProps,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";

import { cn } from "@heffl/ui/lib/utils";
import { Label } from "@heffl/ui/components/primitives/label";
import { Info } from "lucide-react";
import { Tooltip } from "./tooltip";

type TFormProvider = <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined
>(
  // @ts-ignore
  props: FormProviderProps<TFieldValues, TContext, TTransformedValues> & {
    id?: string;
    className?: string;
    onSubmit: TTransformedValues extends FieldValues
      ? SubmitHandler<TTransformedValues>
      : SubmitHandler<TFieldValues>;
  }
) => React.JSX.Element;

const Form: TFormProvider = ({ children, className, id = "", ...props }) => {
  const formRef = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    const scrollToError = () => {
      const errorElement = formRef.current?.querySelector(
        '[aria-invalid="true"]'
      );
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    // Delay the scroll to ensure the DOM has updated
    const timeoutId = setTimeout(scrollToError, 100);

    return () => clearTimeout(timeoutId);
  }, [props.formState?.errors]);

  return (
    // @ts-ignore
    <FormProvider {...props}>
      <form
        ref={formRef}
        id={id}
        className={cn("flex flex-col gap-2", className)}
        onSubmit={(e) => {
          // workaround for overlay drawer submit triggering shadow drawer submit https://github.com/orgs/react-hook-form/discussions/3704
          e.stopPropagation();
          // @ts-ignore
          props.handleSubmit(props.onSubmit)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);

const FormFieldOld = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
);

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("space-y-2 w-full", className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && "text-destructive", className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn("text-sm text-muted-foreground", className)}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  let body = error ? String(error?.message) : children;

  if (Array.isArray(error)) {
    const firstError = error
      .filter((err) => err)
      .find((err) => {
        const key = Object.keys(err)[0];
        return err[key] && err[key].message;
      });
    body = firstError
      ? firstError[Object.keys(firstError)[0]].message
      : children;
  } else if (typeof error === "object" && error !== null) {
    const firstKey = Object.keys(error)[0];
    // @ts-ignore
    if (error[firstKey] && error[firstKey].message) {
      // @ts-ignore
      body = error[firstKey].message;
    }
  }
  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("text-sm font-medium text-destructive", className)}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

interface FormItemProps {
  label?: string;
  description?: string;
  children: React.ReactNode;
  name?: string;
  miniLabel?: string;
}

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  children,
  description,
  label,
  miniLabel,
  info,
  className,
  labelClassName,
  labelParentClassName,
  suffix,
  ...formFieldProps
}: Omit<
  ControllerProps<TFieldValues, TName> &
    FormItemProps & {
      label?: any;
      suffix?: React.ReactNode;
      info?: string;
      className?: string;
      labelClassName?: string;
      labelParentClassName?: string;
    },
  "render"
>) => {
  const [animationParent] = useAutoAnimate();

  if (React.Children.count(children) !== 1) {
    throw new Error("FormComponent can only have one child.");
  }
  const child = React.Children.only(children) as React.ReactElement;

  return (
    <FormFieldOld
      {...formFieldProps}
      render={({ field: { name, onBlur, onChange, ref, value } }) => {
        const existingOnChange = child.props.onChange;
        const combinedOnChange = existingOnChange
          ? // @ts-ignore
            (e) => {
              existingOnChange(e);
              onChange(e);
            }
          : onChange;

        const childWithProps = React.cloneElement(child, {
          name,
          onBlur: onBlur,
          onChange: combinedOnChange,
          ref: ref,
          value: value,
        });
        return (
          <FormItem className={className}>
            {(label || miniLabel || suffix || info) && (
              <div className="flex relative justify-between">
                <div className={cn("flex items-center", labelParentClassName)}>
                  {!!label && (
                    <FormLabel className={cn(labelClassName)}>
                      {label}
                    </FormLabel>
                  )}
                  {miniLabel ? <p className="text-xs">{miniLabel}</p> : null}
                  {info && (
                    <Tooltip content={info}>
                      <Info className="h-3.5 text-gray-400" />
                    </Tooltip>
                  )}
                </div>
                {suffix && (
                  <div className="absolute right-0 bottom-0">{suffix}</div>
                )}
              </div>
            )}

            <FormControl className="">{childWithProps}</FormControl>
            {description ? (
              <FormDescription>{description}</FormDescription>
            ) : null}
            <div ref={animationParent}>
              <FormMessage />
            </div>
          </FormItem>
        );
      }}
    />
  );
};

export { useFormField, Form, FormField, FormLabel };
