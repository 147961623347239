import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button } from "@heffl/ui/components/primitives/button";
import ModalDrawer from "@heffl/ui/components/modal-drawer";

export type ConfirmModalProps = {
  title?: string;
  description?: string;
  onClose?: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  variant?: "primary" | "destructive";
};

export const ConfirmModal = NiceModal.create(
  ({
    title = "Confirm Action",
    description = "Are you sure you want to continue?",
    onClose,
    onConfirm,
    confirmText = "Confirm",
    cancelText = "Cancel",
    variant = "primary",
  }: ConfirmModalProps) => {
    const modal = useModal();

    const onModalClose = () => {
      onClose?.();
      modal.hide();
    };

    const handleConfirm = () => {
      onConfirm();
      onModalClose();
    };

    return (
      <ModalDrawer
        open={modal.visible}
        onClose={() => modal.hide()}
        title={title}
        footer={
          <div className="flex gap-2 w-full">
            <Button variant="outline" className="w-full" onClick={onModalClose}>
              {cancelText}
            </Button>
            <Button
              className="w-full"
              variant={variant}
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
          </div>
        }
      >
        <p className="text-sm text-gray-600">{description}</p>
      </ModalDrawer>
    );
  }
);
