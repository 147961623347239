import { LucideIcon } from "lucide-react";
import { cn } from "../lib/utils";

type StatsCardProps = {
  items: {
    title: string;
    value: string;
    icon?: LucideIcon;
  }[];
  className?: string;
};

const StatsCard = ({ items, className }: StatsCardProps) => {
  return (
    <div className={cn("flex gap-4 px-4 w-full rounded-lg border", className)}>
      {items.map((item, index) => (
        <>
          <div
            key={item.title}
            className="flex flex-col items-center p-2 bg-white rounded-lg"
          >
            <div className="flex gap-1 items-center">
              {item.icon && <item.icon className="w-3 h-3" />}
              <p className="text-sm underline decoration-dashed decoration-gray-300 underline-offset-4">
                {item.title}
              </p>
            </div>
            <p className="mt-1 text-lg font-medium">{item.value}</p>
          </div>
          {index < items.length - 1 && (
            <div className="my-4 w-px bg-gray-200" />
          )}
        </>
      ))}
    </div>
  );
};

export default StatsCard;
