// Dependencies: pnpm install lucide-react

import { cn } from "../lib/utils";
import { Label } from "./primitives/label";
import { RadioGroup, RadioGroupItem } from "./primitives/radio-group";

export default function RadioGrid<T extends string>({
  items,
  cols = 2,
  value,
  onChange,
}: {
  value?: T;
  onChange?: (value: T) => void;
  items: {
    id: T;
    value: T;
    label: string;
    description?: string;
    Icon: React.ElementType;
    iconClassName?: string;
  }[];
  cols?: number;
}) {
  return (
    <RadioGroup
      className={`grid gap-3 grid-cols-${cols}`}
      value={value}
      // @ts-ignore
      onChange={onChange}
    >
      {items.map((item) => (
        <div
          key={item.id}
          className="relative flex rounded-lg border border-gray-200 p-2.5 shadow-sm has-[[data-state=checked]]:border-primary has-[[data-state=checked]]:bg-primary/5 justify-between"
        >
          <div
            className={cn("flex gap-2", !item.description && "items-center")}
          >
            <div className="flex-shrink-0 w-5 h-5">
              <item.Icon
                className={cn(
                  "w-full h-full text-gray-500",
                  item.iconClassName
                )}
                aria-hidden="true"
              />
            </div>
            <div className="flex flex-col gap-1">
              <Label htmlFor={item.id} className="font-medium">
                {item.label}
              </Label>
              {item.description && (
                <span className="text-sm text-gray-500">
                  {item.description}
                </span>
              )}
            </div>
          </div>
          <RadioGroupItem
            id={item.id}
            value={item.value}
            className="after:absolute after:inset-0"
          />
        </div>
      ))}
    </RadioGroup>
  );
}
