import { useState } from "react";
import Page from "@/components/page";
import { cn } from "@heffl/ui/lib/utils";
import { apps, defaultApps } from "../home/apps-list";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Download, ExternalLink, LayoutGrid, X } from "lucide-react";
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import Empty from "@/components/Empty";
import { toast } from "react-hot-toast";

const AppsList: React.FC = () => {
  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();
  const { mutate: installApp, isLoading: isInstalling } =
    trpc.teams.installApp.useMutation({
      onSuccess: () => {
        toast.success(`${selectedApp?.name} app installed successfully`);
        setSelectedApp(null);
      },
    });

  const { mutate: uninstallApp, isLoading: isUninstalling } =
    trpc.teams.uninstallApp.useMutation({
      onSuccess: () => {
        toast.success(`${selectedApp?.name} app uninstalled successfully`);
        setSelectedApp(null);
      },
    });

  const [selectedApp, setSelectedApp] = useState<
    | ((typeof apps)[0] & {
        isInstalled: boolean;
      })
    | null
  >(null);

  if (!teamDetails) return <FullScreenSpinner />;

  const appsFormatted = apps
    .filter((app) => !defaultApps.some((da) => da.id === app.id))
    .map((app) => {
      const isInstalled = teamDetails.installedApps.includes(app.id);
      return {
        ...app,
        isInstalled,
      };
    });

  const appsInstalled = appsFormatted.filter((app) => app.isInstalled);
  const appsAvailable = appsFormatted.filter((app) => !app.isInstalled);

  return (
    <Page
      fullWidth
      title="Apps"
      showBack
      description="Add new apps to your workspace"
      className="h-screen bg-[#F8F9FA]"
    >
      <p className="text-lg font-medium">Installed</p>
      <div className="grid grid-cols-1 gap-4 pt-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        {appsInstalled.map((app) => (
          <div
            key={app.id}
            className="flex gap-2 items-center p-4 bg-white rounded-xl shadow-md transition-shadow cursor-pointer hover:shadow-lg"
            onClick={() => setSelectedApp(app)}
          >
            <app.icon
              className={cn("p-2 w-10 h-10 text-white rounded-xl")}
              style={{ backgroundColor: app.color }}
            />
            <p className="text-sm font-medium">{app.name}</p>
          </div>
        ))}
      </div>
      {appsInstalled.length === 0 && (
        <Empty
          iconSize={26}
          className="h-20"
          icon={LayoutGrid}
          title="No apps installed"
          description="You can install apps to enhance your workspace"
        />
      )}

      <p className="mt-8 text-lg font-medium">Available</p>
      <div className="grid grid-cols-1 gap-4 pt-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        {appsAvailable.map((app) => (
          <div
            key={app.id}
            className="flex gap-2 items-center p-4 bg-white rounded-xl shadow-md transition-shadow cursor-pointer hover:shadow-lg"
            onClick={() => setSelectedApp(app)}
          >
            <app.icon
              className={cn("p-2 w-10 h-10 text-white rounded-xl")}
              style={{ backgroundColor: app.color }}
            />
            <p className="text-sm font-medium">{app.name}</p>
          </div>
        ))}
      </div>
      {appsAvailable.length === 0 && (
        <Empty
          iconSize={26}
          className="h-20"
          icon={LayoutGrid}
          title="No apps available"
          description="You can install apps to enhance your workspace"
        />
      )}
      <ModalDrawer
        open={!!selectedApp}
        onClose={() => setSelectedApp(null)}
        title={selectedApp?.name}
      >
        {selectedApp && (
          <div className="flex flex-col p-2">
            <div className="flex gap-5">
              <selectedApp.icon
                className={cn("p-4 mb-4 w-20 h-20 text-white rounded-2xl")}
                style={{ backgroundColor: selectedApp.color }}
              />
              <div>
                <h2 className="mb-2 text-2xl font-medium">
                  {selectedApp.name}
                </h2>
                {!selectedApp.isInstalled ? (
                  <Button
                    onClick={() => {
                      installApp({ appId: selectedApp.id });
                    }}
                    icon={Download}
                    variant="primary"
                    loading={isInstalling}
                  >
                    Install
                  </Button>
                ) : (
                  <div className="flex gap-2">
                    <Button icon={ExternalLink} variant="outline">
                      Open
                    </Button>
                    <Button
                      icon={X}
                      variant="destructiveOutline"
                      onClick={() => {
                        uninstallApp({ appId: selectedApp.id });
                      }}
                      loading={isUninstalling}
                    >
                      Uninstall
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <p className="mb-6 text-gray-500">{selectedApp.description}</p>
          </div>
        )}
      </ModalDrawer>
    </Page>
  );
};

export default AppsList;
