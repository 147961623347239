import { trpc } from "@/helpers/trpc";
import { integrationSchemas } from "@heffl/server/src/helpers/integrations";
import { z } from "zod";

const useIntegrations = () => {
  const { data, isLoading } = trpc.integrations.list.useQuery();

  const get = <T extends keyof typeof integrationSchemas>(provider: T) => {
    const integration = data?.find((i) => i.provider === provider);

    if (
      isLoading ||
      !data ||
      !integration ||
      !integration.connected ||
      (integration.type === "USER" && !integration.userId)
    )
      return {
        integrated: false as const,
        data: undefined,
      };

    return {
      integrated: true as const,
      data: integration.data as z.infer<(typeof integrationSchemas)[T]>,
    };
  };

  return { get };
};

export default useIntegrations;
