import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

import { useRef } from "react";
import { tinyMCEkeys } from "./primitives/RichTextEditor";

interface Props {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  height?: number;
  dynamicTags?: {
    title: string;
    menu: {
      value: string;
      title: string;
    }[];
  }[];
}

const defaultTinyConfig = {
  menubar: false,
  branding: false,
  toolbar_sticky: true,
  content_style: `
  body {
      background: #fff;
  }

  @media (min-width: 840px) {
      html {
          background: #eceef4;
          min-height: 100%;
          padding: 0 .5rem
       }
      body {
          background-color: #fff;
          box-shadow: 0 0 4px rgba(0, 0, 0, .15);
          box-sizing: border-box;
          margin: 1rem auto 0;
          max-width: 820px;
          padding:1rem;
       }
   }
 `,
  formats: {
    h1: { block: "h1", styles: { fontSize: "32px" } },
    h2: { block: "h2", styles: { fontSize: "24px" } },
    h3: { block: "h3", styles: { fontSize: "18.72px" } },
    p: { block: "p" },
  },
  line_height_formats: "0.5 0.75 1 1.2 1.4 1.6 2",
  style_formats: [
    { title: "Heading 1", format: "h1" },
    { title: "Heading 2", format: "h2" },
    { title: "Paragraph", format: "p" },
  ],
  plugins:
    "anchor autolink image lists media searchreplace table visualblocks wordcount code pagebreak hr",
  toolbar:
    "undo redo | fontfamily | styles fontsizeinput forecolor lineheight | bold italic underline strikethrough | image table | align | numlist bullist code pagebreak hr",
  pagebreak_separator:
    "<div style='page-break-before: always; clear:both'></div>",
};

const DocumentEditor = ({
  onChange,
  value,
  disabled = false,
  height = 800,
}: Props) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  return (
    <Editor
      // key is dynamically generated so component will rerender when props are changed
      // in react component renreders when key is changed
      disabled={disabled}
      onInit={(_, editor) => {
        // @ts-ignore
        editorRef.current = editor;
      }}
      onEditorChange={(content) => {
        if (onChange) onChange(content);
      }}
      value={value}
      apiKey={tinyMCEkeys[Math.floor(Math.random() * tinyMCEkeys.length)]}
      init={{
        ...defaultTinyConfig,
        height: `${height}px`,
      }}
    />
  );
};

export default DocumentEditor;
