import { ClipboardX } from "lucide-react";

const NoPermissionScreen = () => {
  return (
    <div className="min-h-[400px] flex flex-col items-center justify-center p-4 text-center">
      <div className="relative mb-6">
        {/* Background clipboard icons for depth */}
        <div className="absolute top-0 -right-4 text-primary-50">
          <ClipboardX size={64} />
        </div>
        <div className="absolute top-2 -left-4 text-primary-100">
          <ClipboardX size={64} />
        </div>
        {/* Main clipboard icon */}
        <div className="relative text-primary-800">
          <ClipboardX size={64} />
        </div>
      </div>
      <h1 className="mb-4 text-2xl font-semibold text-primary-500">
        Sorry, you cannot view the page.
      </h1>
      <p className="mb-2 text-gray-600">No permission to access this page.</p>
      <p className="text-gray-600">
        To access, please contact the administrator.
      </p>
    </div>
  );
};

export default NoPermissionScreen;
