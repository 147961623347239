/* eslint-disable @typescript-eslint/ban-ts-comment */
import { WorkFlowAction } from "../../../types";

export const sendHefflNotification: WorkFlowAction = {
  name: "notification",
  label: "Send notification",
  description: "Send a notification to the user",
  inputs: [
    {
      label: "Users",
      name: "users",
      dataType: "MULTIPLE_USER_SELECT",
      placeholder: "Select users to send",
      required: true,
    },
    {
      label: "Title",
      name: "title",
      dataType: "TEXT",
      placeholder: "Enter the title",
      required: true,
    },
    {
      label: "Message",
      name: "message",
      dataType: "TEXT",
      placeholder: "Enter the message",
      required: true,
    },
  ],
  run: async ({ payload }) => {
    if (!payload) {
      return;
    }

    // await sendNotification(
    //   {
    //     // @ts-ignore
    //     title: payload.title,
    //     // @ts-ignore
    //     body: payload.message,
    //     type: "WORKFLOW_NOTIFICATION",
    //   },
    //   // @ts-ignore
    //   payload.users.map((userId: string) => Number(userId)) || [],
    //   ctx.teamId
    // );
  },
};
