import Empty from "@/components/Empty";
import { UserAvatar } from "@/components/UserAvatar";
import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import RenderHtml from "@heffl/ui/components/render-html";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { dynamicDateFormatting, formatName } from "@heffl/ui/lib/utils";
import { StickyNote } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

export const NoteInput = ({
  value,
  onSubmit,
  loading = false,
  actionText = "Add note",
  cancelText = "Clear",
  onCancel,
  cancelButtonVariant = "outline",
}: {
  value: string;
  onSubmit: (v: string) => void;
  onCancel?: () => void;
  loading: boolean;
  actionText?: string;
  cancelText?: string;
  cancelButtonVariant?: "destructiveOutline" | "outline";
}) => {
  const [addNote, setAddNote] = useState(value);

  return (
    <div className="flex flex-col w-full">
      <MiniRichTextEditor
        autoFocus
        value={addNote}
        onChange={(value) => setAddNote(value)}
        height={170}
        placeholder="Add new notes, remarks etc.. about projects"
      />

      <div className="flex flex-row gap-2 mt-2 w-full">
        <Button onClick={onCancel} variant={cancelButtonVariant}>
          {cancelText}
        </Button>
        <Button
          className="w-full"
          loading={loading}
          onClick={() => {
            if (addNote.length) onSubmit(addNote);
            setAddNote("");
          }}
          variant="primary"
        >
          {actionText}
        </Button>
      </div>
    </div>
  );
};

type NoteCardProps = {
  note: {
    id: number;
    note: string;
    createdAt: Date;
    createdBy: {
      id: number;
      firstName: string;
      lastName: string;
    };
  };
  onClick: () => void;
};

export const NoteCard = ({ note, onClick }: NoteCardProps) => {
  return (
    <Card
      className="flex flex-row gap-3"
      parentClassName="w-full cursor-pointer  hover:border-primary-500"
      onClick={onClick}
    >
      <div className="flex-col gap-2 w-full">
        <RenderHtml>{note.note}</RenderHtml>
        <div className="flex justify-between pt-1.5 mt-3 border-t border-gray-200">
          <div className="flex gap-2 items-center">
            <UserAvatar user={note.createdBy} size="xxs" />
            <p className="text-xs text-gray-500">
              {formatName(note.createdBy)}
            </p>
          </div>
          <p className="text-xs text-gray-500">
            {dynamicDateFormatting(note.createdAt)}
          </p>
        </div>
      </div>
    </Card>
  );
};

const Notes = () => {
  const parms = useParams();
  const projectId = Number(parms.projectId);
  const confirm = useConfirm();

  const [addNote, setAddNote] = useState("");

  const [showEditNote, setShowEditNote] = useState<number | undefined>(
    undefined
  );

  const { data: notes } = trpc.projects.notes.list.useQuery({
    projectId,
  });
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const noteUpdateMutation = trpc.projects.notes.update.useMutation({
    onSuccess() {
      setAddNote("");
      toast.success("Updated note successfully");
    },
  });

  const noteDeleteMutation = trpc.projects.notes.delete.useMutation({
    onSuccess() {
      toast.success("Deleted note successfully");
    },
  });

  if (!notes) return <FullScreenSpinner />;

  return (
    <div className="flex flex-col mt-2">
      <ModalDrawer
        open={showEditNote !== undefined}
        onClose={() => {
          setShowEditNote(undefined);
          setAddNote("");
        }}
        title="Edit note"
      >
        <NoteInput
          value={addNote}
          actionText="Update note"
          onSubmit={(v) => {
            if (!showEditNote) return;
            noteUpdateMutation.mutate({
              id: showEditNote,
              note: {
                note: v,
              },
            });
            setShowEditNote(undefined);
            setAddNote("");
          }}
          loading={noteUpdateMutation.isLoading}
          cancelButtonVariant="destructiveOutline"
          cancelText="Delete"
          onCancel={async () => {
            if (!showEditNote) return;
            const confirmed = await confirm({
              title: "Are you sure you want to delete this note?",
              actionButton: "Delete",
              actionVariant: "destructive",
            });
            if (!confirmed) return;
            noteDeleteMutation.mutate(showEditNote);
            setShowEditNote(undefined);
            setAddNote("");
          }}
        />
      </ModalDrawer>
      {!notes.length && (
        <Empty
          title="No notes added yet"
          icon={StickyNote}
          description="Note down anything about the project, remarks, observations etc.."
          buttonSize="sm"
        />
      )}
      <div className="grid gap-2 sm:gap-4 sm:grid-cols-3">
        {notes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            onClick={() => {
              if (note.createdBy.id !== currentUser?.id)
                return toast.error(
                  "You cannot edit this note, only the creator can edit it"
                );
              setAddNote(note.note);
              setShowEditNote(note.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Notes;
