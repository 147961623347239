import { Button } from "./primitives/button";
import { X, LucideIcon } from "lucide-react";
import { cn } from "../lib/utils";
import { VariantProps } from "class-variance-authority";
import { buttonVariants } from "./primitives/button";

type ActionToolbarProps<T> = {
  selectedCount: number;
  actions?: {
    icon?: LucideIcon;
    key: T;
    label: string;
    onClick: () => void;
    variant?: VariantProps<typeof buttonVariants>["variant"];
    loading?: boolean;
    disabled?: boolean;
  }[];
  onClear: () => void;
  className?: string;
};

export const ActionsToolbar = <T extends string>({
  selectedCount,
  actions,
  onClear,
  className,
}: ActionToolbarProps<T>) => {
  if (!selectedCount) return null;

  return (
    <div
      className={cn(
        "fixed bottom-9 left-1/2 !z-50 p-3 px-5 border bg-white rounded-lg shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] transform flex items-center gap-2 animate-flip-up",
        className
      )}
    >
      <div className="flex gap-2 items-center">
        <div className="flex justify-center items-center w-5 h-5 text-sm text-white rounded-md bg-primary-600">
          {selectedCount}
        </div>
        <p className="text-sm">selected</p>
      </div>

      {actions?.map((action) => (
        <Button
          className="shadow-md"
          loading={action.loading}
          disabled={action.disabled}
          key={action.key.toString()}
          variant={action.variant}
          size="sm"
          icon={action.icon}
          onClick={action.onClick}
        >
          {action.label}
        </Button>
      ))}

      <Button
        onClick={onClear}
        size="sm"
        variant="ghost"
        className="ml-4"
        icon={X}
      />
    </div>
  );
};
