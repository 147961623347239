import * as crm from "./crm";
import aiChat from "./aiChat";
import user from "./user";
import profile from "./profile";
import project from "./project";
import * as files from "./files";
import * as fieldService from "./fieldService";
import * as tags from "./tags";
import * as admin from "./admin";
import * as accounting from "./accounting";
import * as permissions from "./permissions";
import * as zones from "./zones";
import * as customFields from "./customFields.schema";
import * as sources from "./sources";
import * as sales from "./sales";
import * as documents from "./documents";
import * as workflows from "./workflows";
import * as integrations from "./integrations";
import * as tables from "./tables";
import * as purchases from "./purchases";
import * as teams from "./teamSettings";
import * as common from "./common";
import customizationsSchema from "../routes/customizations/customizations.schema";
import * as form from "./forms";

const Schemas = {
  tables,
  integrations,
  crm,
  user,
  aiChat,
  profile,
  project,
  files,
  fieldService,
  tags,
  admin,
  accounting,
  permissions,
  zones,
  customFields,
  sources,
  sales,
  documents,
  workflows,
  purchases,
  teams,
  common,
  customizations: customizationsSchema,
  forms: form,
};

export default Schemas;
