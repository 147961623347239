import { CustomFieldInput } from "@/helpers/customFields/custom-fields-input-form";
import { trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import { AddProductDrawer } from "@/pages/sales/products/components/addProductDrawer";
import { EditProductDrawer } from "@/pages/sales/products/components/editProductDrawer";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { calculateMultiplierTotal } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import InputWithDropDown from "@heffl/ui/components/InputWithDropDown";
import { Button } from "@heffl/ui/components/primitives/button";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import { UAE_TAX } from "@heffl/ui/lib/constants";
import { cn, formatCurrency, formatString } from "@heffl/ui/lib/utils";
import { Drawer } from "antd";
import { AlignLeft, GripVertical, Pencil, Plus, Trash2, X } from "lucide-react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import {
  FieldErrors,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";
import { useDebounce } from "use-debounce";
import { z } from "zod";

type LineItemType =
  | "QUOTATION"
  | "INVOICE"
  | "BILL"
  | "PURCHASE_ORDER"
  | "JOB"
  | "DEAL"
  | "PROFORMA_INVOICE"
  | "SALES_ORDER"
  | "PROJECT";

export type LineItem = {
  id?: number;
  name?: string;
  productId?: number;
  quantity: number;
  price: number;
  description?: string | null;
  tax?: number;
  viewType: z.infer<typeof enums.lineItemViewType>;
};

export const defaultHeading: LineItem = {
  productId: undefined,
  quantity: 0,
  price: 0,
  tax: 0,
  description: "",
  viewType: "HEADING",
};

type HeadingLineItemProps = {
  id: string;
  remove: UseFieldArrayRemove;
  index: number;
  name: string;
  errors: FieldErrors;
  disabled?: boolean;
};

const HeadingLineItem = ({
  id,
  remove,
  index,
  name,
  errors,
  disabled = false,
}: HeadingLineItemProps) => {
  const form = useFormContext();

  const setValue = (value: unknown) => {
    form.setValue(`${name}.${index}.name`, value, {
      shouldDirty: true,
    });
  };
  const value = form.watch(`${name}.${index}.name`);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={cn(
        "flex flex-col gap-3 items-center p-3 w-full bg-gray-50 rounded-lg border border-gray-200 sm:p-2 sm:flex-row",
        errors && "border-red-500"
      )}
      style={style}
    >
      <Button
        disabled={disabled}
        variant="ghost"
        size="icon"
        className="self-start touch-none"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <GripVertical className="w-6 h-6" />
      </Button>
      <div className="w-full">
        <Label>Heading</Label>
        <Input
          disabled={disabled}
          className="mt-1 w-full"
          placeholder="Heading"
          value={value}
          onChange={(v) => setValue(v.target.value)}
        />
      </div>
      <Button
        disabled={disabled}
        variant="ghost"
        onClick={() => remove(index)}
        size="icon"
      >
        <Trash2 className="!w-5 !h-5 text-red-400" />
      </Button>
    </div>
  );
};

type SingleLineItemProps = {
  index: number;
  remove: UseFieldArrayRemove;
  fieldsLength: number;
  required: boolean;
  name: string;
  id: string;
  isEdit?: boolean;
  documentTemplateId?: number;
  errors: FieldErrors;
  buyingPriceDefault?: boolean;
  type: LineItemType;
  defaultTax: number;
  showBuyPrice?: boolean;
  disabled?: boolean;
  allowedProductIds?: number[];
};

const SingleLineItem = ({
  index,
  remove,
  fieldsLength,
  required,
  id,
  name,
  documentTemplateId,
  errors,
  buyingPriceDefault,
  type,
  defaultTax,
  showBuyPrice = false,
  disabled = false,
  allowedProductIds = [],
}: SingleLineItemProps) => {
  const [editProduct, setEditProduct] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [debouncedSearch] = useDebounce(productSearch, 500);
  const [showAddProductIndex, setShowAddProductIndex] = useState<
    number | undefined
  >(undefined);
  const [showScopeEditor, setShowScopeEditor] = useState(false);

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "QUOTATION_LINE_ITEM",
      documentTemplateId: documentTemplateId,
    },
    {
      enabled: !!documentTemplateId,
    }
  );

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const form = useFormContext();

  const value = form.watch(`${name}.${index}`);

  const team = useTeam();

  const { data, isLoading } = trpc.products.list.useQuery({
    pageNo: 1,
    pageSize: 20,
    search: debouncedSearch,
    include: value.productId ? [value.productId] : [],
  });
  const getTotal = () => {
    const quantity = value.quantity || 1;
    const price = value.price || 0;
    const tax = value.tax || 0;
    const multiplier = calculateMultiplierTotal({
      customFieldFields: customFields || [],
      customFieldValues: value.customFields || {},
    });

    const productTotal = quantity * price * multiplier;
    const taxTotal = (productTotal * tax) / 100;

    return Number((productTotal + taxTotal).toFixed(2));
  };

  const getSubtotal = () => {
    const quantity = value.quantity || 1;
    const price = value.price || 0;
    const multiplier = calculateMultiplierTotal({
      customFieldFields: customFields || [],
      customFieldValues: value.customFields || {},
    });
    const productTotal = quantity * price * multiplier;
    return Number(productTotal.toFixed(2));
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const setValue = (fieldName: string, value: unknown) => {
    form.setValue(`${name}.${index}.${fieldName}`, value, {
      shouldDirty: true,
    });
  };

  if (!team) {
    return <FullScreenSpinner />;
  }

  return (
    <div
      className={cn(
        "flex flex-col gap-3 items-center p-3 w-full bg-gray-50 rounded-lg border border-gray-200 sm:p-2 sm:flex-row",
        errors && "border-red-500"
      )}
      style={style}
    >
      <Drawer
        open={showScopeEditor}
        onClose={() => setShowScopeEditor(false)}
        width={1000}
      >
        <RichTextEditor
          value={value.scope || ""}
          onChange={(v) => setValue("scope", v)}
        />
      </Drawer>
      {showAddProductIndex !== undefined && (
        <AddProductDrawer
          open={true}
          onClose={(newProduct) => {
            if (showAddProductIndex !== undefined && newProduct && name) {
              setValue("name", newProduct?.name || "");
              setValue("productId", Number(newProduct?.id));
              setValue("description", newProduct?.description || "");
              setValue(
                "price",
                buyingPriceDefault
                  ? newProduct?.buyPrice || 0
                  : newProduct?.price || 0
              );
              setValue("quantity", 1);
              setValue("tax", defaultTax);
              if (type === "INVOICE") {
                setValue("buyPrice", newProduct?.buyPrice || 0);
              }
              if (type === "QUOTATION") {
                setValue("scope", newProduct?.documents?.contentHtml || "");
              }
              if (type === "JOB") {
                setValue("uuid", nanoid());
              }
            }
            setShowAddProductIndex(undefined);
          }}
        />
      )}
      <div
        className="self-start touch-none"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <GripVertical className="w-4 h-5" />
      </div>

      <div className="flex flex-col w-ful">
        <div className="sm:flex gap-2 !w-full grid grid-cols-2">
          <div className="col-span-2 sm:w-6/12">
            <div className="flex relative justify-between mt-0.5 mb-1">
              {editProduct && value.productId && (
                <EditProductDrawer
                  productId={value.productId}
                  onClose={() => setEditProduct(false)}
                />
              )}
              <Label>Product</Label>
              <div className="flex absolute right-0 bottom-0 gap-1">
                {value.productId && (
                  <Button
                    disabled={disabled}
                    onClick={() => setEditProduct(true)}
                    size="xs"
                    variant="primaryOutline"
                  >
                    Edit
                  </Button>
                )}
                {!value.productId && (
                  <Button
                    disabled={disabled}
                    onClick={() => setShowAddProductIndex(index)}
                    size="xs"
                    variant="primaryOutline"
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
            {value.productId ? (
              <div className="flex gap-2 justify-between items-center p-2 bg-white rounded-lg border">
                {
                  data?.products?.find(
                    (product) => product.id === value.productId
                  )?.name
                }
                <Button
                  disabled={disabled}
                  onClick={() => {
                    setValue("productId", undefined);
                    setValue("name", "");
                  }}
                  size="icon"
                  className="w-6 h-6"
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ) : (
              <InputWithDropDown
                disabled={disabled}
                onSearch={setProductSearch}
                isLoading={isLoading}
                hideOptionsFilter={(option) => !option.isActive}
                placeholder="Type or select product/service"
                className="w-full"
                allowClear={false}
                value={value?.name || ""}
                onChange={(v) => setValue("name", v)}
                onOptionSelected={(v) => {
                  if (v) {
                    const selectedProduct = data?.products?.find(
                      (product) => product.id === Number(v)
                    );
                    setValue("name", selectedProduct?.name || "");
                    setValue("productId", Number(v));
                    setValue("description", selectedProduct?.description || "");
                    setValue(
                      "price",
                      buyingPriceDefault
                        ? selectedProduct?.buyPrice || 0
                        : selectedProduct?.price || 0
                    );
                    if (type === "INVOICE") {
                      setValue("buyPrice", selectedProduct?.buyPrice || 0);
                    }
                    if (type === "QUOTATION") {
                      setValue(
                        "scope",
                        selectedProduct?.documents?.contentHtml || ""
                      );
                    }
                    if (type === "JOB") {
                      setValue("uuid", nanoid());
                    }
                  }
                }}
                options={
                  data?.products
                    ?.filter(
                      (product) =>
                        allowedProductIds.length === 0 ||
                        allowedProductIds.includes(product.id)
                    )
                    .map((product) => ({
                      ...product,
                      label: formatString(product.name),
                      value: product.id,
                    })) || []
                }
                render={(product) => (
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold">{product.name}</p>
                    <div className="flex gap-2">
                      <p className="text-xs">
                        Buy: {formatCurrency(product.buyPrice || 0, "AED")}
                      </p>
                      <p className="text-xs font-medium">
                        Price: {formatCurrency(product.price, "AED")}
                      </p>
                    </div>
                  </div>
                )}
              />
            )}
          </div>

          <div className="w-full sm:w-2/12">
            <Label>Qty</Label>
            <Input
              disabled={disabled}
              placeholder=""
              value={value.quantity}
              onChange={(v) => setValue("quantity", v.target.value)}
            />
          </div>
          {showBuyPrice && (
            <div className="w-full sm:w-2/12">
              <Label>Buy price</Label>
              <Input
                placeholder="Buy price"
                value={value.buyPrice}
                onChange={(v) => setValue("buyPrice", v.target.value)}
                disabled={disabled}
              />
            </div>
          )}
          <div className="w-full sm:w-2/12">
            <Label>Price</Label>
            <Input
              disabled={disabled}
              placeholder=""
              value={value.price}
              onChange={(v) => setValue("price", v.target.value)}
            />
          </div>
          <div className="w-full sm:w-2/12">
            <Label>Tax</Label>
            <Input
              disabled={disabled}
              placeholder=""
              suffix="%"
              value={value.tax}
              onChange={(v) => setValue("tax", v.target.value)}
            />
          </div>
          {customFields?.map((field) => {
            return (
              <div className="w-full text-sm sm:w-2/12" key={field.name}>
                <Label>{field.label}</Label>
                <CustomFieldInput
                  disabled={disabled}
                  field={field}
                  onChange={(v) => {
                    setValue(`customFields.${field.name}`, v);
                  }}
                  value={value.customFields?.[field.name]}
                />
              </div>
            );
          })}

          <div className="w-2/12 text-sm">
            <Label>Subtotal</Label>
            <p className="font-medium sm:mt-2">{getSubtotal().toFixed(2)}</p>
          </div>
          <div className="w-2/12 text-sm">
            <Label>Total</Label>
            <p className="font-medium sm:mt-2">{getTotal().toFixed(2)}</p>
          </div>
          <Button
            disabled={disabled || (required && fieldsLength === 1)}
            variant="ghost"
            onClick={() => remove(index)}
            className="px-0"
            size="xs"
          >
            <Trash2 className="!w-5 !h-5 text-red-400" />
          </Button>
        </div>

        <div className="col-span-2 mt-2 sm:w-7/12">
          <MiniRichTextEditor
            key={`${id}-${index}`}
            maxHeight={130}
            value={value.description || ""}
            onChange={(v) => setValue("description", v)}
            placeholder="Add description"
            disabled={disabled}
          />
          {type === "QUOTATION" && (
            <Button
              disabled={disabled}
              onClick={() => setShowScopeEditor(true)}
              size="sm"
              icon={Pencil}
              className="float-right mt-2"
            >
              Edit scope
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

type LineItemProps = {
  name?: string;
  required?: boolean;
  isEdit?: boolean;
  documentTemplateId?: number;
  buyingPriceDefault?: boolean;
  type: LineItemType;
  disabled?: boolean;
  addDefaultLineItem?: boolean;
  // allowed product ids
  allowedProductIds?: number[];
};

const LineItemSelectorNew = (props: LineItemProps) => {
  const [animationParent] = useAutoAnimate();

  const team = useTeam();

  const required = props.required !== undefined ? props.required : true;

  const form = useFormContext();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 600,
        tolerance: 5,
      },
    })
  );

  const currentLineItems = form.watch(props.name || "") || [];

  const defaultTax = team?.isTaxRegistered ? UAE_TAX : 0;

  const defaultProduct = {
    productId: undefined,
    quantity: 1,
    price: 0,
    description: "",
    tax: defaultTax,
    viewType: "LINE_ITEM",
    buyPrice: 0,
    position: 1,
    uuid: nanoid(),
  };

  const addDefaultLineItem =
    props.addDefaultLineItem !== undefined ? props.addDefaultLineItem : true;

  useEffect(() => {
    if (
      !props.isEdit &&
      team &&
      currentLineItems.length === 0 &&
      addDefaultLineItem &&
      props.name
    ) {
      form.setValue(props.name, [defaultProduct]);
    }
  }, [currentLineItems, team]);

  if (!team) {
    return <FullScreenSpinner />;
  }

  return (
    <FormList name={props.name || ""}>
      {({ append, fields, remove, move }) => {
        return (
          <div className="flex-wrap space-y-2" ref={animationParent}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={(event) => {
                const { active, over } = event;
                if (over && active.id !== over?.id) {
                  const activeIndex = active.data.current?.sortable?.index;
                  const overIndex = over.data.current?.sortable?.index;
                  if (activeIndex !== undefined && overIndex !== undefined) {
                    move(activeIndex, overIndex);
                  }
                }
              }}
            >
              <SortableContext
                items={fields}
                strategy={verticalListSortingStrategy}
              >
                {fields.map((field, index) => {
                  const lineItemValue = currentLineItems[index];

                  const fieldErrors =
                    // @ts-ignore
                    form.formState.errors[props.name || ""]?.[index];

                  return lineItemValue?.viewType === "LINE_ITEM" ? (
                    <SingleLineItem
                      key={field.id}
                      allowedProductIds={props.allowedProductIds}
                      id={field.id}
                      index={index}
                      remove={remove}
                      fieldsLength={currentLineItems.length}
                      required={required}
                      isEdit={props.isEdit}
                      name={props.name || ""}
                      documentTemplateId={props.documentTemplateId}
                      errors={fieldErrors}
                      buyingPriceDefault={props.buyingPriceDefault}
                      type={props.type}
                      defaultTax={defaultTax}
                      disabled={props.disabled}
                      showBuyPrice={
                        props.type === "INVOICE" &&
                        team.settings.invoice.showBuyPrice
                      }
                    />
                  ) : (
                    <HeadingLineItem
                      key={field.id}
                      name={props.name || ""}
                      id={field.id}
                      index={index}
                      remove={remove}
                      errors={fieldErrors}
                      disabled={props.disabled}
                    />
                  );
                })}
              </SortableContext>
            </DndContext>
            <div className="flex gap-2 pt-2">
              <Button
                onClick={() => {
                  append(defaultProduct);
                }}
                variant="primary"
                icon={Plus}
                size="sm"
              >
                Add line item
              </Button>
              <Button
                onClick={() => {
                  append(defaultHeading);
                }}
                size="sm"
                icon={AlignLeft}
              >
                Add heading
              </Button>
            </div>
          </div>
        );
      }}
    </FormList>
  );
};

export default LineItemSelectorNew;
