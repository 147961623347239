import DetailsPage from "@/components/details-page";
import FilterBar from "@/components/filters";
import KanbanBoard from "@/components/kanbanBoard/kanban";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { InvoiceList } from "@/pages/sales/invoices/list";
import enums from "@heffl/server/src/schemas/enums";
import appIcons from "@heffl/ui/components/appIcons";
import BigCalendar from "@heffl/ui/components/bigCalendar";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Card } from "@heffl/ui/components/primitives/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Switch } from "@heffl/ui/components/primitives/switch";
import StatsCard from "@heffl/ui/components/stats-cards";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { generateUniqueColor } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Archive,
  Calendar,
  CheckCircle,
  CheckCircle2,
  CircleDashed,
  CircleDollarSign,
  Kanban as KanbanIcon,
  List,
  MoreHorizontal,
  Paperclip,
  Pencil,
  Plus,
  Settings,
  StickyNote,
  Trash,
  UsersRoundIcon,
  XCircle,
} from "lucide-react";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import EditProjectDrawer from "../list/components/EditProjectDrawer";
import {
  AddProjectTaskModal,
  ProjectTaskDetailsModal,
} from "../tasks/components/project-task-modals";
import { AddTimesheetDrawer, TimesheetsTable } from "../timesheets/list";
import Files from "./components/Files";
import { taskStatuses } from "./components/kanban";
import TaskCard from "./components/kanban/TaskCard";
import SimpleTable from "@heffl/ui/components/simple-table";
import Plan from "./components/List";
import Notes, { NoteInput } from "./components/Notes";
import {
  AddExpenseModal,
  ExpensesTable,
} from "@/pages/purchases/expenses/list";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import useTeam from "@/lib/hooks/useTeam";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import RenderHtml from "@heffl/ui/components/render-html";
import { UserAvatar } from "@/components/UserAvatar";
import Empty from "@/components/Empty";

const statusOptions = [
  {
    value: "ACTIVE" as const,
    variant: "default" as const,
    icon: CircleDashed,
    label: "Active",
  },
  {
    value: "COMPLETED" as const,
    variant: "success" as const,
    icon: CheckCircle2,
    label: "Completed",
  },
  {
    value: "CANCELLED" as const,
    variant: "error" as const,
    icon: XCircle,
    label: "Cancelled",
  },
];

export const ProjectStatusBadge = ({
  status,
  small,
  onChange,
  allowUpdate = false,
}: {
  status: z.infer<typeof enums.projectStatsuses>;
  small?: boolean;
  onChange?: (status: z.infer<typeof enums.projectStatsuses>) => void;
  allowUpdate?: boolean;
}) => {
  const selected = statusOptions.find((s) => s.value === status);

  if (!selected) return null;

  if (!allowUpdate) {
    return (
      <Badge variant={selected.variant} icon={selected.icon} small={small}>
        {selected.label}
      </Badge>
    );
  }

  return (
    <Select
      hideSearch
      popoverClassName="w-fit"
      value={status}
      onChange={onChange}
      options={statusOptions.map((s) => ({
        label: (
          <Badge variant={s.variant} icon={s.icon} key={s.label}>
            {s.label}
          </Badge>
        ),
        value: s.value,
      }))}
      previewRender={() => (
        <Badge variant={selected.variant} icon={selected.icon} small={small}>
          {selected.label}
        </Badge>
      )}
    />
  );
};

type TaskFilter = {
  statuses: z.infer<typeof enums.projectTaskStatusTypes>[];
  dates: [Date, Date] | undefined;
  tags: number[];
  assignees: number[];
  showCompleted: boolean;
};

export const ProjectDetails = () => {
  const params = useParams();
  const confirm = useConfirm();
  const projectId = Number(params.projectId);
  const trpcUtils = trpc.useUtils();
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<TaskFilter>({
    dates: undefined,
    tags: [],
    assignees: [],
    statuses: [],
    showCompleted: true,
  });

  const [showAddExpense, setShowAddExpense] = useState(false);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const [editTaskId, setEditTaskId] = useState<number>();
  const [editProject, setEditProject] = useState(false);
  const [projectsTab, setSelectedProjectTab] = useState<
    | "files"
    | "notes"
    | "list"
    | "calendar"
    | "kanban"
    | "financials"
    | "timesheets"
    | "lineitems"
  >("kanban");
  const [showAddNote, setShowAddNote] = useState(false);
  const [addNote, setAddNote] = useState("");
  const [addTimesheet, setAddTimesheet] = useState(false);
  const taskFilters = useMemo(() => {
    return {
      projectId: projectId,
      orderBy:
        projectsTab === "kanban"
          ? ("position" as const)
          : ("createdAt" as const),
      showAllTasks: true,
      assignees: filters.assignees,
      tags: filters.tags,
      statuses: filters.statuses,
      dates: filters.dates,
      showAllProjectTasks: false,
      showCompleted: filters.showCompleted,
    };
  }, [filters, projectsTab]);

  const team = useTeam();

  const { data: project, isLoading } =
    trpc.projects.details.useQuery(projectId);
  const { data: tasks } = trpc.projects.tasks.list.useQuery(taskFilters);
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });

  const updateProjectMutation = trpc.projects.update.useMutation({
    onSuccess() {
      toast.success("Project updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const addNoteMutation = trpc.projects.notes.add.useMutation({
    onSuccess() {
      toast.success("Note added successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const bulkTaskUpdate = trpc.projects.tasks.bulkUpdate.useMutation({
    onSuccess() {
      toast.success("Tasks updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const deleteProjectMutation = trpc.projects.delete.useMutation({
    onSuccess() {
      toast.success("Project deleted successfully");
      navigate("/projects/board");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (isLoading || !project || !team) return <FullScreenSpinner />;

  const filtersUI = (
    <div className="flex gap-2 justify-between items-center w-full">
      <FilterBar
        onChange={() => {}}
        defaultFilters={["dates", "status"]}
        filters={[
          {
            key: "dates",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value as [Date, Date] }),
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({
                statuses: value as z.infer<
                  typeof enums.projectTaskStatusTypes
                >[],
              }),
            options: taskStatuses.map((status) => ({
              label: status.label,
              value: status.value,
            })),
          },
          {
            key: "assignees",
            type: "checkbox",
            label: "Assignees",
            value: filters.assignees,
            onChange: (value) => setFilters({ assignees: value as number[] }),
            options: users?.map((user) => ({
              label: heffl.format.name(user),
              value: user.id,
            })),
          },
          {
            key: "tags",
            type: "checkbox",
            label: "Tags",
            value: filters.tags,
            onChange: (value) => setFilters({ tags: value as number[] }),
            options: tags?.map((tag) => ({
              label: tag.name,
              value: tag.id,
            })),
          },
        ]}
      />
      <div className="flex gap-2 items-center">
        <Button
          variant="primary"
          size="sm"
          icon={CheckCircle}
          onClick={() => setAddTaskModalOpen(true)}
        >
          Add task
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="sm">
              <Settings className="h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem className="flex gap-2 items-center">
              Show completed tasks{" "}
              <Switch
                value={filters.showCompleted}
                onChange={(checked) => setFilters({ showCompleted: checked })}
              />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );

  return (
    <>
      {addTaskModalOpen && (
        <AddProjectTaskModal
          open={addTaskModalOpen}
          onClose={() => setAddTaskModalOpen(false)}
          projectId={projectId}
        />
      )}

      <AddTimesheetDrawer
        open={addTimesheet}
        onClose={() => setAddTimesheet(false)}
        defaultValues={{
          clientId: project.clients?.id,
          projectId,
        }}
      />

      <EditProjectDrawer
        open={editProject}
        onClose={() => setEditProject(false)}
        id={projectId}
      />
      {!!editTaskId && (
        <ProjectTaskDetailsModal
          open={true}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}
      <ModalDrawer
        open={showAddNote}
        onClose={() => {
          setShowAddNote(false);
          setAddNote("");
        }}
        title="Add note"
      >
        <NoteInput
          value={addNote}
          onSubmit={(v) => {
            addNoteMutation.mutate({
              projectId,
              note: v,
            });
            setShowAddNote(false);
            setAddNote("");
          }}
          loading={addNoteMutation.isLoading}
        />
      </ModalDrawer>
      <DetailsPage
        title={project.title}
        stage={{
          loading: updateProjectMutation.isLoading,
          stages:
            project.projectPipelineStages.projectPipelines.projectPipelineStages.map(
              (s) => ({
                id: s.id,
                text: s.name,
              })
            ),
          value: project.projectStageId,
          onChange: (value) => {
            updateProjectMutation.mutate({
              id: project.id,
              project: { projectStageId: value },
            });
          },
        }}
        widgets={[
          <Badge key="days-since" variant="neutral" small icon={Pencil}>
            Created {dayjs(project.createdAt).fromNow()}
          </Badge>,
          <ProjectStatusBadge
            small
            key="project-status"
            status={project.status}
          />,
          project.archived && (
            <Badge key="archived" variant="error" small icon={Archive}>
              Archived
            </Badge>
          ),
        ]}
        description={`Created on ${dayjs(project.createdAt).format(
          "DD MMM YYYY hh:mm a"
        )}`}
        attributes={[
          {
            section: "Details",
            icon: Pencil,
            onClick: () => setEditProject(true),
            items: [
              {
                label: "Title",
                value: project.title,
              },
              {
                label: "Start date",
                value: project.startDate
                  ? dayjs(project.startDate).format("DD/MM/YYYY")
                  : "Not set",
              },
              {
                label: "End date",
                value: project.endDate
                  ? dayjs(project.endDate).format("DD/MM/YYYY")
                  : "Not set",
              },
              {
                label: "Stage",
                value: (
                  <Select
                    hideSearch
                    popoverClassName="w-fit"
                    value={project.projectStageId}
                    onChange={(stage) => {
                      updateProjectMutation.mutate({
                        id: project.id,
                        project: { projectStageId: stage },
                      });
                    }}
                    options={project.projectPipelineStages.projectPipelines.projectPipelineStages.map(
                      (s) => ({
                        label: s.name,
                        value: s.id,
                        type: s.stageType,
                        closedStageType: s.closedStageType,
                      })
                    )}
                    previewRender={(selected) => (
                      <Badge
                        variant={
                          selected?.type === "OPEN"
                            ? "default"
                            : selected?.closedStageType === "LOST"
                            ? "error"
                            : "success"
                        }
                      >
                        {selected?.label}
                      </Badge>
                    )}
                  />
                ),
              },
              {
                label: "Status",
                value: (
                  <ProjectStatusBadge
                    allowUpdate
                    status={project.status}
                    onChange={(status) => {
                      if (status === "CANCELLED") {
                        heffl.modal.reason({
                          submitText: "Cancel project",
                          onSubmit: (reason) => {
                            updateProjectMutation.mutate({
                              id: project.id,
                              project: {
                                status,
                                cancelReason: reason.description,
                              },
                            });
                          },
                        });
                      } else {
                        updateProjectMutation.mutate({
                          id: project.id,
                          project: {
                            status,
                          },
                        });
                      }
                    }}
                  />
                ),
              },
              {
                label: "Client",
                value: (
                  <Badge
                    variant="outline"
                    small
                    icon={appIcons.sales.clients.icon}
                  >
                    {project.clients?.name || "Not added"}
                  </Badge>
                ),
              },
              {
                label: "Pipeline",
                value: project.projectPipelineStages.projectPipelines.name,
              },
              {
                hidden: project.status !== "CANCELLED",
                label: "Cancel reason",
                value: project.cancelReason,
              },
              {
                label: "Quotations",
                value: (
                  <div className="flex flex-wrap gap-2">
                    {project.projectQuotations.length > 0 ? (
                      project.projectQuotations.map((quotation) => (
                        <Badge
                          key={quotation.quotations.id}
                          variant="unique"
                          small
                          icon={appIcons.sales.quotes.icon}
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(
                              `/sales/quotations/details/${quotation.quotations.id}`
                            );
                          }}
                        >
                          #{quotation.quotations.number}
                        </Badge>
                      ))
                    ) : (
                      <p>No quotations linked</p>
                    )}
                  </div>
                ),
              },
              ...project.customFieldFields.map((field) => ({
                label: field.label,
                value: renderCustomFieldValue(
                  field,
                  project.customFields[field.name]
                ),
              })),
            ],
          },
          {
            section: "Assignees",
            children: (
              <div className="flex flex-col gap-2">
                {project.projectAssignees.length > 0 ? (
                  project.projectAssignees.map((assignee) => (
                    <Card className="flex gap-2 items-center" key={assignee.id}>
                      <UserAvatar user={assignee.users} size="sm" />
                      <div className="flex gap-2 justify-between items-center w-full">
                        <div>
                          <p>{heffl.format.name(assignee.users)}</p>
                          <p className="text-[12px] text-gray-500">
                            Assigned on{" "}
                            {heffl.format.dynamicDate(assignee.createdAt, true)}
                          </p>
                        </div>
                        {/* TODO fix assigneed server update */}
                        <div className="flex gap-1 items-center text-xs text-gray-500">
                          <CheckCircle className="w-4 h-4" />{" "}
                          {tasks?.tasks?.filter((t) =>
                            t.projectTaskAssignees.some(
                              (ta) => ta.users.id === assignee.users.id
                            )
                          ).length || 0}
                        </div>
                      </div>
                    </Card>
                  ))
                ) : (
                  <Empty
                    icon={UsersRoundIcon}
                    title="No assignees"
                    description="No one is assigned to this project"
                  />
                )}
              </div>
            ),
          },
        ]}
        tabs={{
          onChange: (tab) => setSelectedProjectTab(tab),
          items: [
            {
              key: "kanban",
              label: "Kanban",
              icon: KanbanIcon,
              children: (
                <div className="h-full">
                  {filtersUI}
                  <KanbanBoard
                    items={tasks?.tasks || []}
                    statusKey="status"
                    columns={taskStatuses.map((status) => ({
                      id: status.value,
                      label: status.label,
                      value: status.value,
                      color: status.color,
                    }))}
                    renderItem={(task) => (
                      <TaskCard
                        task={task}
                        onTaskClick={() => setEditTaskId(Number(task.id))}
                      />
                    )}
                    onDragging={(updated) => {
                      trpcUtils.projects.tasks.list.setData(
                        taskFilters,
                        (prev) => ({
                          meta: {
                            count: prev?.meta?.count || 0,
                          },
                          tasks: updated,
                        })
                      );
                    }}
                    onChange={(updated) => {
                      bulkTaskUpdate.mutate(
                        updated.map((item) => ({
                          id: Number(item.id),
                          position: item.position,
                          status: item.status as z.infer<
                            typeof enums.projectTaskStatusTypes
                          >,
                        }))
                      );
                    }}
                    className="h-[calc(100vh-178px)] mt-2"
                  />
                </div>
              ),
            },
            {
              key: "list",
              label: "List",
              icon: List,
              children: (
                <div className="h-full">
                  {filtersUI}
                  <Plan data={project} filters={taskFilters} />
                </div>
              ),
            },
            {
              key: "calendar",
              label: "Calendar",
              icon: Calendar,
              children: (
                <BigCalendar
                  className="w-full h-full"
                  events={
                    tasks?.tasks
                      ?.filter(
                        (task): task is typeof task & { date: Date } =>
                          task.date !== null
                      )
                      .map((task) => ({
                        id: task.id.toString(),
                        title: task.title,
                        start: task.date,
                        end: task.date,
                        backgroundColor: generateUniqueColor(task.title, 500),
                      })) ?? []
                  }
                  renderEvent={(event) => (
                    <div className="text-white rounded-md">
                      {event.event.title}
                    </div>
                  )}
                  onEventClick={(event) => {
                    setEditTaskId(Number(Number(event.id)));
                  }}
                />
              ),
            },
            {
              key: "files",
              label: "Files",
              icon: Paperclip,
              children: <Files />,
              count: project._count.projectFiles,
            },
            {
              key: "notes",
              label: "Notes",
              icon: StickyNote,
              children: <Notes />,
              count: project._count.projectNotes,
              actions: [
                {
                  icon: Plus,
                  label: "Add note",
                  onClick: () => setShowAddNote(true),
                },
              ],
              title: "Notes",
            },
            {
              key: "timesheets",
              label: "Timesheets",
              icon: heffl.icons.projects.timesheets.icon,
              count: project._count.timesheets,
              title: "Timesheets",
              actions: [
                {
                  icon: Plus,
                  label: "Timesheet",
                  onClick: () => setAddTimesheet(true),
                  buttonVariant: "primary",
                },
              ],
              children: (
                <div className="">
                  <TimesheetsTable
                    showStats
                    classes={{
                      dataGrid: "mt-14",
                      parent: "mt-2",
                    }}
                    filters={{ projectIds: [projectId] }}
                    name="timesheetsListProject"
                  />
                </div>
              ),
            },
            {
              key: "lineitems",
              label: "Line items",
              icon: appIcons.common.lineItems.icon,
              children: (
                <div>
                  <SimpleTable
                    idKey="id"
                    columns={
                      team.user.permissions.VIEW_PROJECTS_FINANCIALS.allowed
                        ? [
                            {
                              label: "No",
                              render: (_, index) => index + 1,
                              className: "w-10",
                            },
                            {
                              label: "Item",
                              key: "name",
                              render: (row) => (
                                <div className="flex flex-col">
                                  <div className="text-sm font-medium">
                                    {row.name}
                                  </div>
                                  <div className="text-xs text-gray-500">
                                    <RenderHtml>{row.description}</RenderHtml>
                                  </div>
                                </div>
                              ),
                            },
                            {
                              label: "Qty",
                              key: "quantity",
                              className: "w-10",
                            },
                            {
                              label: "Price",
                              render: (row) =>
                                heffl.format.currency(row.price, "AED"),
                              className: "w-10",
                            },
                            {
                              label: "Total",
                              render: (row) =>
                                heffl.format.currency(
                                  row.quantity * row.price,
                                  "AED"
                                ),
                              className: "w-10",
                            },
                          ]
                        : [
                            {
                              label: "No",
                              render: (_, index) => index + 1,
                              className: "w-10",
                            },
                            {
                              label: "Item",
                              key: "name",
                              render: (row) => (
                                <div className="flex flex-col">
                                  <div className="text-sm font-medium">
                                    {row.name}
                                  </div>
                                  <div className="text-xs text-gray-500">
                                    <RenderHtml>{row.description}</RenderHtml>
                                  </div>
                                </div>
                              ),
                              className: "w-1/2",
                            },
                            {
                              label: "Qty",
                              key: "quantity",
                            },
                          ]
                    }
                    rows={project.projectItems}
                  />
                  {team.user.permissions.VIEW_PROJECTS_FINANCIALS.allowed && (
                    <div className="flex justify-end w-full">
                      <table className="gap-8 border-separate border-spacing-y-2">
                        <tr>
                          <td className="w-52">Subtotal</td>
                          <td className="text-right">
                            {heffl.format.currency(
                              calculateLineItems({
                                lineItems: project.projectItems,
                                discount: project.discount,
                              }).subTotal,
                              "AED"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-52">Discount</td>
                          <td className="text-right">
                            {heffl.format.currency(project.discount, "AED")}
                          </td>
                        </tr>
                        <tr>
                          <td className="flex gap-2 items-center w-52">VAT</td>
                          <td className="text-right">
                            {heffl.format.currency(
                              calculateLineItems({
                                lineItems: project.projectItems,
                                discount: project.discount,
                              }).totalTax,
                              "AED"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-52">Total</td>
                          <td className="font-semibold text-right">
                            {heffl.format.currency(
                              calculateLineItems({
                                lineItems: project.projectItems,
                                discount: project.discount,
                              }).total,
                              "AED"
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              ),
            },
            {
              key: "financials",
              label: "Financials",
              disabled: !team.user.permissions.VIEW_PROJECTS_FINANCIALS.allowed,
              icon: CircleDollarSign,
              children: (
                <div className="!w-full">
                  <AddExpenseModal
                    open={showAddExpense}
                    onClose={() => setShowAddExpense(false)}
                    defaultValues={{
                      projectId,
                    }}
                  />
                  <StatsCard
                    items={[
                      {
                        title: "To invoice",
                        value: heffl.format.currency(
                          project.meta.stats.toInvoice,
                          "AED"
                        ),
                      },
                      {
                        title: "Total expenses",
                        value: heffl.format.currency(
                          project.meta.stats.totalExpenses,
                          "AED"
                        ),
                      },
                      {
                        title: "Total invoiced",
                        value: heffl.format.currency(
                          project.meta.stats.totalInvoiced,
                          "AED"
                        ),
                      },
                      {
                        title: "Payments pending",
                        value: heffl.format.currency(
                          project.meta.stats.totalPending,
                          "AED"
                        ),
                      },
                    ]}
                  />
                  <div className="flex justify-between my-2 !w-full !pb-3">
                    <h3 className="text-lg font-medium">Invoices</h3>
                    <Button
                      icon={Plus}
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        navigate(`/sales/invoices/add?projectId=${projectId}`);
                      }}
                    >
                      Invoice
                    </Button>
                  </div>
                  <div className="">
                    <InvoiceList
                      name="invoicesListProject"
                      classes={{
                        dataGrid: "mt-10",
                      }}
                      filters={{
                        projectIds: [projectId],
                        pageNo: 1,
                      }}
                      setInvoiceIds={() => {}}
                    />
                  </div>
                  <div className="mt-14">
                    <div className="flex justify-between my-2 !w-full !pb-3">
                      <h3 className="text-lg font-medium">Expenses</h3>
                      <Button
                        icon={Plus}
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          setShowAddExpense(true);
                        }}
                      >
                        Expense
                      </Button>
                    </div>
                    <ExpensesTable
                      filters={{
                        projectIds: [projectId],
                      }}
                      classes={{
                        datagrid: "mt-10",
                      }}
                    />
                  </div>
                </div>
              ),
            },
          ],
        }}
        actions={[
          {
            icon: MoreHorizontal,
            label: "More",
            onClick: () => {},
            dropdown: (
              <>
                <button
                  className="flex items-center gap-2 w-full px-2 py-1.5 text-sm text-left hover:bg-gray-100"
                  onClick={() => setEditProject(true)}
                >
                  <Pencil className="w-4 h-4" />
                  Edit
                </button>
                <button
                  className="flex items-center gap-2 w-full px-2 py-1.5 text-sm text-left hover:bg-gray-100"
                  onClick={() => {
                    updateProjectMutation.mutate({
                      id: project.id,
                      project: {
                        archived: !project.archived,
                      },
                    });
                  }}
                >
                  <Archive className="w-4 h-4" />
                  {project.archived ? "Unarchive" : "Archive"}
                </button>
                <button
                  className="flex items-center gap-2 w-full px-2 py-1.5 text-sm text-left text-red-500 hover:bg-gray-100"
                  onClick={async () => {
                    const confirmed = await confirm(
                      "Are you sure you want to delete this project?"
                    );
                    if (confirmed) {
                      deleteProjectMutation.mutate(project.id);
                    }
                  }}
                >
                  <Trash className="w-4 h-4" />
                  Delete
                </button>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
