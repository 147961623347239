import Page from "@/components/page";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { useState } from "react";
import CRMPersonalDashboard from "./dashboard";
import CRMAdminDashboard from "./admin";
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { isAdmin } from "@heffl/ui/lib/utils";

const CRMDashboard = () => {
  const [selectedTab, setSelectedTab] = useState("personal");

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  if (!currentUser) {
    return <FullScreenSpinner />;
  }

  return (
    <Page>
      {isAdmin(currentUser) && (
        <Tabs value={selectedTab} className="mb-3 w-fit">
          <TabsList className="grid grid-cols-2 w-full">
            <TabsTrigger
              value="personal"
              onClick={() => setSelectedTab("personal")}
            >
              Personal
            </TabsTrigger>
            <TabsTrigger value="admin" onClick={() => setSelectedTab("admin")}>
              Admin
            </TabsTrigger>
          </TabsList>
        </Tabs>
      )}
      {selectedTab === "personal" ? (
        <CRMPersonalDashboard />
      ) : (
        <CRMAdminDashboard />
      )}
    </Page>
  );
};

export default CRMDashboard;
