import DataGrid from "@/components/dataGrid/DataGrid";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";
import ExpenseDetailsModal from "../purchases/expenses/details-modal";
import PaymentMadeDetailsModal from "../purchases/payments-made/details-modal";
import PaymentReceivedDetailsModal from "../sales/paymetsReceived/details-modal";

const CashflowTransactions = () => {
  const { id } = useParams();

  const [showDetails, setShowDetails] = useState<
    | {
        type: "Expense" | "Payment Made" | "Payment Received";
        id: number;
      }
    | undefined
  >(undefined);

  const { data: transactions } =
    trpc.books.cashflowAccounts.transactions.list.useQuery({
      id: Number(id),
    });

  if (!transactions) return <FullScreenSpinner />;

  return (
    <Page
      title={transactions.accountDetails.name}
      className="!p-0"
      showBack
      fullWidth
      breadcrumbs={[
        {
          label: "Banking",
          path: "/books/banking",
        },
        {
          label: transactions.accountDetails.name,
          path: `/books/cashflow-accounts/transactions/${id}`,
        },
        {
          label: "Transactions",
          path: `/books/cashflow-accounts/transactions/${id}`,
        },
      ]}
    >
      {match(showDetails)
        .with({ type: "Expense" }, ({ id }) => {
          return (
            <ExpenseDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .with({ type: "Payment Made" }, ({ id }) => {
          return (
            <PaymentMadeDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .with({ type: "Payment Received" }, ({ id }) => {
          return (
            <PaymentReceivedDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .otherwise(() => null)}

      <DataGrid
        name="cashflowTransactionsListMain"
        className="h-[calc(100vh-50px)]"
        label="Cashflow Transactions"
        rowKey="transactionNo"
        show={{
          viewSettings: false,
          export: false,
        }}
        rows={transactions?.transactions || []}
        onCellClick={(row) => {
          setShowDetails({
            type: row.row.type,
            id: Number(row.row.id.split("-")[1]),
          });
        }}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 120,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "type",
            name: "Type",
            width: 120,
          },
          {
            key: "transactionNo",
            name: "Transaction #",
            width: 140,
            renderCell: ({ row }) => row.transactionNo,
          },
          {
            key: "refNo",
            name: "Ref.#",
            width: 120,
            renderCell: ({ row }) => row.refNo,
          },
          {
            key: "deposit",
            name: "Deposit",
            width: 140,
            renderCell: ({ row }) =>
              row.action === "DEPOSIT"
                ? heffl.format.currency(row.deposit, "AED")
                : "",
          },
          {
            key: "withdrawal",
            name: "Withdrawal",
            width: 140,
            renderCell: ({ row }) =>
              row.action === "WITHDRAWAL"
                ? heffl.format.currency(row.withdrawal, "AED")
                : "",
          },
          {
            key: "runningBalance",
            name: "Running Balance",
            width: 160,
            renderCell: ({ row }) =>
              heffl.format.currency(row.runningBalance, "AED"),
          },
        ]}
      />
    </Page>
  );
};

export default CashflowTransactions;
