import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import { calculateMultiplierTotal } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import InvoiceForm from "./components/proforma-invoice-form";

const AddProformaInvoice = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const quotationId = queryParams.get("quotationId");
  const invoiceId = queryParams.get("invoiceId");

  const { data: quotation } = trpc.quotations.details.useQuery(
    Number(quotationId)
  );
  const { data: invoice } = trpc.proformaInvoice.details.useQuery(
    Number(invoiceId),
    {
      enabled: !!invoiceId,
    }
  );

  const form = useForm<z.infer<typeof Schemas.sales.proformaInvoice>, unknown>({
    resolver: zodResolver(Schemas.sales.proformaInvoice),
    defaultValues: {
      date: new Date(),
      dueDate: dayjs().add(30, "days").toDate(),
      clientId: quotation?.clients?.id,
    },
  });

  const invoiceAddMutation = trpc.proformaInvoice.add.useMutation({
    onSuccess: (newInvoice) => {
      navigate(`/sales/proforma-invoices/details/${newInvoice.id}`);
    },
  });

  useEffect(() => {
    if (quotation) {
      form.setValue("clientId", quotation.clients.id);
      form.setValue(
        "proformaInvoiceProducts",
        quotation.quotationProducts.map((product) => ({
          ...product,
          quantity:
            calculateMultiplierTotal({
              customFieldFields: quotation.documentTemplates.customFields,
              customFieldValues: product.customFields,
            }) * product.quantity,
        }))
      );
      form.setValue("contactId", quotation.contactId);
      form.setValue("discount", quotation.discount);
    }
  }, [quotation]);

  useEffect(() => {
    if (invoice) {
      form.setValue("clientId", invoice.clients.id);
      form.setValue("proformaInvoiceProducts", invoice.proformaInvoiceProducts);
      form.setValue("contactId", invoice.contactId);
      form.setValue("discount", invoice.discount);
    }
  }, [invoice]);

  return (
    <Page
      showBack
      title="Add proforma invoice"
      description="Create proforma invoices and get them paid."
      suffix={
        <div>
          <ResponsivePrimaryButton
            onClick={() =>
              form.handleSubmit((values) => invoiceAddMutation.mutate(values))()
            }
            type="submit"
            variant="primary"
            icon={Save}
            loading={invoiceAddMutation.isLoading}
          >
            Add proforma invoice
          </ResponsivePrimaryButton>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            invoiceAddMutation.mutate(values);
          }}
        >
          {/* <UnsavedChangesPrompt /> */}
          <div className="flex justify-center px-3 py-5 w-full sm:px-0">
            <InvoiceForm form={form} />
          </div>
        </Form>
      </div>
    </Page>
  );
};

export default AddProformaInvoice;
