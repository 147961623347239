import Page from "@/components/page";
import SimpleTable from "@heffl/ui/components/simple-table";
// import { trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Report {
  title: string;
  description: string;
  slug: string;
}

const reports: Report[] = [
  {
    title: "Service/product Utilization",
    description: "Overview of service/product utilization.",
    slug: "service-product-utilization",
  },
  {
    title: "Vendor Summary Report",
    description: "Overview of jobs with vendors.",
    slug: "vendor-summary-report",
  },
  // {
  //   title: "Scheduled Schedules",
  //   description: "Overview of scheduled schedules.",
  //   slug: "scheduled-schedules",
  // },
  // {
  //   title: "Confirmed Schedules",
  //   description: "Overview of confirmed schedules.",
  //   slug: "confirmed-schedules",
  // },
  // {
  //   title: "In Progress Schedules",
  //   description: "Overview of in progress schedules.",
  //   slug: "inprogress-schedules",
  // },
  // {
  //   title: "Completed Schedules",
  //   description: "Overview of completed schedules.",
  //   slug: "completed-schedules",
  // },
  // {
  //   title: "Cancelled Schedules",
  //   description: "Overview of cancelled schedules.",
  //   slug: "cancelled-schedules",
  // },
  {
    title: "Contracts Expiring Jobs",
    description: "Overview of contracts expiring jobs.",
    slug: "contracts-expiring-jobs",
  },
];

const FieldServiceReports: React.FC = () => {
  const navigate = useNavigate();

  const team = useTeam();

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_JOBS_REPORTS.allowed)
    return <NoPermissionScreen />;

  return (
    <Page title="Field Service Reports">
      <SimpleTable
        columns={[
          {
            label: "Report Title",
            render: (report: Report) => (
              <span
                className="font-medium text-blue-400 cursor-pointer"
                onClick={() => {
                  navigate(`/field-service/reports/${report.slug}`);
                }}
              >
                {report.title}
              </span>
            ),
          },
          {
            label: "Description",
            render: (report: Report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            label: "Created By",
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rows={reports}
        idKey="slug"
      />
    </Page>
  );
};

export default FieldServiceReports;
