import { Button } from "@heffl/ui/components/primitives/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { Separator } from "@heffl/ui/components/primitives/separator";
import { cn } from "@heffl/ui/lib/utils";
import {
  ArrowDown01,
  ArrowUp10,
  ArrowUpDown,
  CheckCircle2,
  LucideIcon,
} from "lucide-react";
import { useState } from "react";

type SortOption<T extends string = string> = {
  label: string;
  value: T;
  icon: LucideIcon;
};

type SortBarProps<T extends string = string> = {
  value: { [key in T]?: "asc" | "desc" | undefined };
  options: SortOption<T>[];
  onChange: (value: { [key in T]?: "asc" | "desc" }) => void;
};

const SortBar = <T extends string = string>({
  options = [],
  value,
  onChange,
}: SortBarProps<T>) => {
  const [open, setOpen] = useState(false);

  const currentDirection = Object.values(value)[0];
  const currentKey = Object.keys(value)[0];
  const currentLabel = options.find((o) => o.value === currentKey)?.label;

  return (
    <div className="flex gap-2 items-center">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button icon={ArrowUpDown} size="sm">
            Sort
            {!!currentKey && (
              <>
                <span className="px-1 font-thin text-gray-300">|</span>
                <span className="text-xs font-medium text-primary-700">
                  {currentLabel} - {currentDirection as string}
                </span>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={cn("p-1 w-44")}
          align="start"
          side="bottom"
          sideOffset={4}
        >
          <div className="flex flex-col w-full">
            {options.map((option) => (
              <div
                className="flex justify-between items-center p-2 w-full rounded-md cursor-pointer hover:bg-primary-50"
                key={option.value}
                onClick={() => {
                  const nextDirection =
                    currentDirection === "asc" ? "desc" : "asc";

                  const newValue = {
                    [option.value]: nextDirection,
                  } as { [key in T]?: "asc" | "desc" };
                  onChange(newValue);
                  setOpen(false);
                }}
              >
                <div className="flex gap-2 items-center">
                  {option.icon && (
                    <option.icon className="w-4 h-4 text-gray-600" />
                  )}
                  {option.label}
                </div>
                {!!value[option.value] && (
                  <CheckCircle2 className="w-4 h-4 text-white rounded-full bg-primary-600" />
                )}
              </div>
            ))}
            <Separator className="my-1" />
            <div
              className="flex justify-between items-center p-2 w-full rounded-md cursor-pointer hover:bg-primary-50"
              onClick={() => {
                if (currentKey) {
                  const newValue = {
                    [currentKey]: "asc" as const,
                  } as { [key in T]?: "asc" | "desc" };
                  onChange(newValue);
                  setOpen(false);
                }
              }}
            >
              <div className="flex gap-2 items-center">
                <ArrowUp10 className="w-4 h-4 text-gray-600" />
                Ascending
              </div>
              {Object.values(value).every((v) => v === "asc") && (
                <CheckCircle2 className="w-4 h-4 text-white rounded-full bg-primary-600" />
              )}
            </div>
            <div
              className="flex justify-between items-center p-2 w-full rounded-md cursor-pointer hover:bg-primary-50"
              onClick={() => {
                if (currentKey) {
                  const newValue = {
                    [currentKey]: "desc" as const,
                  } as { [key in T]?: "asc" | "desc" };
                  onChange(newValue);
                  setOpen(false);
                }
              }}
            >
              <div className="flex gap-2 items-center">
                <ArrowDown01 className="w-4 h-4 text-gray-600" />
                Descending
              </div>
              {Object.values(value).every((v) => v === "desc") && (
                <CheckCircle2 className="w-4 h-4 text-white rounded-full bg-primary-600" />
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SortBar;
