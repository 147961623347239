import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { CheckCircle, XCircle } from "lucide-react";
import React, { useState } from "react";

interface JobCloseModalProps {
  jobId: number;
  open: boolean;
  onClose: () => void;
}

const JobCloseModal: React.FC<JobCloseModalProps> = ({
  jobId,
  open,
  onClose,
}) => {
  const [action, setAction] = useState<"cancel" | "complete" | null>(null);
  const confirm = useConfirm();

  const updateSchedulesMutation =
    trpc.fieldService.schedules.jobCompleteAction.useMutation({
      onSuccess: () => {
        onClose();
      },
    });

  const handleClose = async () => {
    if (!action) return;

    const confirmMessage = {
      cancel: "Are you sure you want to cancel all pending schedules?",
      complete: "Are you sure you want to complete all pending schedules?",
    }[action];

    const confirmed = await confirm({
      title: "Confirm Action",
      body: confirmMessage,
    });

    if (confirmed && action) {
      const status = action === "cancel" ? "CANCELLED" : "COMPLETED";
      updateSchedulesMutation.mutate(
        {
          fsJobId: jobId,
          status: status,
          cancelReason: action === "cancel" ? "Job completed" : undefined,
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  return (
    <ModalDrawer title="Close Job" open={open} onClose={onClose}>
      <div className="space-y-4">
        <p className="text-gray-600">
          This job has pending schedules. Please choose an action:
        </p>
        <div className="space-y-4">
          <div
            className={`flex items-center p-4 rounded-lg cursor-pointer  border-2 ${
              action === "cancel"
                ? "bg-white border-primary-500 shadow-md"
                : "bg-white border-gray-200"
            }`}
            onClick={() => setAction("cancel")}
          >
            <XCircle className="mr-3 w-6 h-6 text-red-500" />
            <span>Cancel all pending schedules</span>
          </div>
          <div
            className={`flex items-center p-4 rounded-lg cursor-pointer border-2 ${
              action === "complete"
                ? "bg-white border-primary-500 shadow-md"
                : "bg-white border-gray-200"
            }`}
            onClick={() => setAction("complete")}
          >
            <CheckCircle className="mr-3 w-6 h-6 text-green-500" />
            <span>Complete all pending schedules</span>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <Button onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleClose} disabled={!action} variant="primary">
            Confirm
          </Button>
        </div>
      </div>
    </ModalDrawer>
  );
};

export default JobCloseModal;
