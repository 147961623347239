import { z } from "zod";
import enums from "./enums";

export const form = z.object({
  title: z.string(),
  description: z.string().nullish(),
  entity: enums.entities.nullish(),
});

export const formItem = z.object({
  title: z.string(),
  description: z.string().nullish(),
  type: enums.formItemTypes,
  formId: z.number(),
});
