import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon, Plus } from "lucide-react";
import { Badge } from "./primitives/badge";
import { Button } from "./primitives/button";
import Select from "./primitives/select";

type Props = {
  className?: string;
  uniqueColor?: boolean;
  value: number[];
  emptyLabel?: string;
  empty?: React.ReactNode;
  tags?: { id: number; name: string; color?: string; icon?: LucideIcon }[];
  onChange?: (tags: number[]) => void;
  small?: boolean;
};

const TagsInput: React.FC<Props> = ({
  value,
  tags = [],
  onChange,
  uniqueColor,
  className,
  emptyLabel,
  small,
}) => {
  const selectedTags = tags.filter((tag) => value.includes(tag.id));
  const unselectedTags = tags.filter((tag) => !value.includes(tag.id));

  return (
    <div className={cn("flex flex-wrap gap-1.5 items-center", className)}>
      {emptyLabel && selectedTags.length === 0 && (
        <Badge small={small} variant="error">
          {emptyLabel}
        </Badge>
      )}
      {selectedTags.map((tag) => (
        <Badge
          small={small}
          icon={tag.icon}
          variant={uniqueColor ? "unique" : "default"}
          key={tag.id}
          background={tag.color}
          onRemove={
            onChange
              ? () =>
                  onChange(
                    value.filter((existingTag) => existingTag !== tag.id)
                  )
              : undefined
          }
        >
          {tag.name}
        </Badge>
      ))}
      {onChange && (
        <Select
          popoverClassName="w-[200px]"
          onSelect={(tag) => {
            onChange([...value, tag.value]);
          }}
          previewRender={() => (
            <Button
              size="icon"
              className={cn(
                "w-6 h-6 border-dashed hover:border-primary flex justify-center items-center",
                small && "w-4 h-4"
              )}
            >
              <Plus className="h-4 text-black" />
            </Button>
          )}
          options={unselectedTags.map((tag) => ({
            label: tag.name,
            value: tag.id,
          }))}
        />
      )}
    </div>
  );
};

export default TagsInput;
