import Empty from "@/components/Empty";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { formatName, isMobile } from "@heffl/ui/lib/utils";

import KanbanBoard from "@/components/kanbanBoard/kanban";
import { useParamsState } from "@/lib/hooks/useParamsState";
import enums from "@heffl/server/src/schemas/enums";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { Calendar, CheckSquare, KanbanIcon, List } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { P, match } from "ts-pattern";
import { z } from "zod";
import { taskStatuses } from "../details/components/kanban";
import { ProjectTaskDetailsModal } from "./components/project-task-modals";
import ProjectTaskList from "./list";
import TaskCard from "../details/components/kanban/TaskCard";
import SortBar from "@/components/sort-bar";
import heffl from "@/helpers/heffl";

export type ProjectTasksFilters = {
  pipelineIds: number[];
  statuses: z.infer<typeof enums.projectTaskStatusTypes>[];
  clients: number[];
  tags: number[];
  assignees: number[];
  pageNo?: number;
  pageSize?: number;
  dates: [Date, Date] | undefined;
  createdAt: [Date, Date] | undefined;
  type: "ALL" | "OVERDUE";
  assignedBy: number[];
  sortBy: {
    createdAt?: "asc" | "desc";
    position?: "asc" | "desc";
    dates?: "asc" | "desc";
  };
};

const Tasks = () => {
  const trpcUtils = trpc.useUtils();
  const navigate = useNavigate();
  const { view } = useParams<{ view: "board" | "table" }>();

  const [filters, setFilters] = useParamsState<ProjectTasksFilters>({
    pipelineIds: [],
    clients: [],
    tags: [],
    assignees: [],
    statuses: ["IN_PROGRESS", "ON_HOLD", "OPEN"],
    dates: undefined,
    createdAt: undefined,
    type: "ALL",
    sortBy: {
      position: "asc",
    },
    assignedBy: [],
    // pageNo: 1,
    // pageSize: 50,
  });

  const [clientSearch, setClientSearch] = useState("");
  const [editTaskId, setEditTaskId] = useState<number | undefined>();

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const bulkTaskUpdate = trpc.projects.tasks.bulkUpdate.useMutation();

  const tasksParams: RouterInputs["projects"]["tasks"]["list"] = {
    pipelineIds: filters.pipelineIds,
    clients: filters.clients,
    tags: filters.tags,
    assignees: filters.assignees,
    orderBy: view === "board" ? "position" : "createdAt",
    statuses: filters.statuses,
    dates: filters.dates,
    createdAt: filters.createdAt,
    type: filters.type,
    sortBy: filters.sortBy,
    pageNo: filters.pageNo,
    pageSize: filters.pageSize,
    assignedBy: filters.assignedBy,
  };

  const tasksQuery = trpc.projects.tasks.list.useQuery(tasksParams);

  return (
    <Page title="Tasks" fullWidth className="!p-0">
      {!!editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}
      <StripeTabs
        contentClassName="pt-0"
        className="pt-2 w-full"
        tabParentClassName="pl-4"
        value={view}
        onChange={(tab) => navigate(`/projects/tasks/${tab}`)}
        items={[
          { label: "Board", key: "board", icon: KanbanIcon },
          { label: "Table", key: "table", icon: List },
        ]}
      />
      <div className="flex gap-2 p-3 mt-0 mb-0">
        <SortBar
          options={[
            { label: "Position", value: "position", icon: List },
            { label: "Due date", value: "dates", icon: Calendar },
            { label: "Created at", value: "createdAt", icon: Calendar },
          ]}
          value={filters.sortBy}
          onChange={(value) => setFilters({ sortBy: value })}
        />
        <FilterBar
          defaultFilters={["type", "dates", "pipelineId", "assignees"]}
          filters={[
            {
              key: "type",
              type: "checkbox",
              label: "Type",
              value: [filters.type],
              multiple: false,
              onChange: (value) => {
                const typeUpdated = value.length
                  ? (value[0] as "ALL" | "OVERDUE")
                  : "ALL";
                setFilters({ type: typeUpdated });
                if (typeUpdated === "OVERDUE") {
                  setFilters({
                    dates: undefined,
                    statuses: ["IN_PROGRESS", "ON_HOLD", "OPEN"],
                  });
                }
              },
              options: [
                { label: "All", value: "ALL" },
                { label: "Overdue", value: "OVERDUE" },
              ],
            },
            {
              key: "dates",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value as [Date, Date] }),
            },
            {
              key: "createdAt",
              type: "date-range",
              label: "Created at",
              value: filters.createdAt,
              onChange: (value) => setFilters({ createdAt: value }),
            },
            {
              key: "pipelineId",
              type: "checkbox",
              label: "Pipeline",
              value: filters.pipelineIds,
              onChange: (value) =>
                setFilters({ pipelineIds: value as number[] }),
              options: pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              })),
            },
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<
                    typeof enums.projectTaskStatusTypes
                  >[],
                }),
              options: taskStatuses.map((status) => ({
                label: status.label,
                value: status.value,
              })),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              showSearch: true,
              value: filters.clients,
              onChange: (value) => setFilters({ clients: value as number[] }),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
              onSearch: (e) => setClientSearch(e),
            },
            {
              key: "assignees",
              type: "checkbox",
              label: "Assignees",
              value: filters.assignees,
              onChange: (value) => setFilters({ assignees: value as number[] }),
              options: [
                { label: "Unassigned", value: 0 },
                ...(users?.map((user) => ({
                  label: formatName(user),
                  value: user.id,
                })) || []),
              ],
            },
            {
              key: "assignedBy",
              type: "checkbox",
              label: "Assigned by",
              value: filters.assignedBy,
              onChange: (value) =>
                setFilters({ assignedBy: value as number[] }),
              options: users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })),
            },
            {
              key: "tags",
              type: "checkbox",
              label: "Tags",
              value: filters.tags,
              onChange: (value) => setFilters({ tags: value as number[] }),
              options: tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })),
            },
          ]}
        />
      </div>

      <div>
        {view === "board" ? (
          <div className="px-1 h-full">
            <KanbanBoard
              className="h-[calc(100vh-120px)] sm:h-[calc(100vh-163px)]"
              items={tasksQuery.data?.tasks || []}
              statusKey="status"
              columns={taskStatuses.map((status) => ({
                id: status.value,
                label: status.label,
                value: status.value,
                color: status.color,
              }))}
              renderItem={(task) => (
                <TaskCard
                  task={task}
                  onTaskClick={() => setEditTaskId(Number(task.id))}
                />
              )}
              onDragging={(updated) => {
                trpcUtils.projects.tasks.list.setData(tasksParams, (prev) => ({
                  meta: {
                    count: prev?.meta?.count || 0,
                  },
                  tasks: updated,
                }));
              }}
              onChange={(updated) => {
                bulkTaskUpdate.mutate(
                  updated.map((item) => ({
                    id: Number(item.id),
                    position: item.position,
                    status: item.status as z.infer<
                      typeof enums.projectTaskStatusTypes
                    >,
                  }))
                );
              }}
            />
          </div>
        ) : (
          match(tasksQuery)
            .with({ data: { tasks: [] } }, () => (
              <Empty
                title="No Pending tasks"
                icon={CheckSquare}
                description="Add tasks to get started with your projects."
              />
            ))
            .with({ data: { tasks: P.array() } }, ({ data: { tasks } }) =>
              isMobile() ? (
                <div className="flex flex-col gap-2 mt-4 mb-[100px] w-full px-3">
                  {tasks.map((task) => (
                    <TaskCard
                      key={task.id}
                      task={task}
                      onTaskClick={(id) => setEditTaskId(id)}
                      className="!w-full !max-w-none"
                    />
                  ))}
                </div>
              ) : (
                <ProjectTaskList
                  tasks={tasksQuery.data}
                  filters={filters}
                  setFilters={setFilters}
                />
              )
            )
            .with({ isLoading: true }, () => <FullScreenSpinner />)
            .otherwise(() => <FullScreenSpinner />)
        )}
      </div>
    </Page>
  );
};

export default Tasks;
