import { trpc } from "@/helpers/trpc";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button } from "@heffl/ui/components/primitives/button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddLostReasonDrawer } from "@/pages/settings/lostReasons";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import Select from "@heffl/ui/components/primitives/select";

export type ReasonModalProps = {
  title?: string;
  onClose?: () => void;
  onSubmit: (params: {
    reasonId?: number | null;
    description?: string | null;
  }) => void;
  submitText?: string;
  type?: "QUOTATION" | "DEAL" | "LEAD";
  defaultValues?: {
    reasonId: number | null;
    description: string | null;
  };
  showSkip?: boolean;
};

const reasonSchema = z.object({
  reasonId: z.number().nullish(),
  description: z.string().nullish(),
});

export const ReasonModal = NiceModal.create(
  ({
    title = "Select Reason",
    onClose,
    onSubmit: onChange,
    type,
    submitText = "Continue",
    defaultValues,
    showSkip = false,
  }: ReasonModalProps) => {
    const modal = useModal();

    const [showAddLostReason, setShowAddLostReason] = useState<boolean>(false);

    const { data: lostReasons } = trpc.lostReasons.list.useQuery(
      {
        type: type!,
      },
      {
        enabled: !!type,
      }
    );

    const form = useForm<z.infer<typeof reasonSchema>, unknown>({
      defaultValues: {
        reasonId: null as number | null,
        description: "",
      },
    });

    const description = form.watch("description");

    const selectedReason = form.watch("reasonId");

    const onModalClose = () => {
      onClose?.();
      form.reset({ reasonId: null, description: "" });
      modal.hide();
    };

    useEffect(() => {
      if (lostReasons && lostReasons.length && !selectedReason) {
        form.setValue("reasonId", lostReasons[0].id);
      }
    }, [lostReasons, form, selectedReason]);

    useEffect(() => {
      if (defaultValues && !selectedReason) {
        form.reset({ ...defaultValues });
      }
    }, [defaultValues, form]);

    const onSubmit = (values: z.infer<typeof reasonSchema>) => {
      onChange(values);
      onModalClose();
    };

    return (
      <ModalDrawer
        open={modal.visible}
        onClose={() => modal.hide()}
        title={title}
        footer={
          <div className="flex gap-2 w-full">
            {showSkip && (
              <Button
                variant="destructiveOutline"
                onClick={() => {
                  onChange({});
                  onModalClose();
                }}
              >
                Skip
              </Button>
            )}
            <Button
              className="w-full"
              variant="primary"
              onClick={() => form.handleSubmit(onSubmit)()}
              disabled={!showSkip && !description?.length}
            >
              {submitText}
            </Button>
          </div>
        }
      >
        {showAddLostReason && (
          <AddLostReasonDrawer
            open={showAddLostReason}
            onClose={(newLostReasonId) => {
              if (newLostReasonId) {
                form.setValue("reasonId", newLostReasonId);
              }
              setShowAddLostReason(false);
            }}
            type={type}
          />
        )}
        <Form {...form} onSubmit={onSubmit}>
          {type && (
            <FormField name="reasonId">
              <Select
                createButton={{
                  label: "Add new",
                  onClick: () => setShowAddLostReason(true),
                }}
                options={
                  lostReasons?.map((reason) => ({
                    label: reason.reason,
                    value: reason.id,
                  })) || []
                }
              />
            </FormField>
          )}
          <FormField name="description">
            <Textarea placeholder="Explain the reason..." />
          </FormField>
        </Form>
      </ModalDrawer>
    );
  }
);
