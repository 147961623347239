import { RouterOutputs } from "@/helpers/trpc";
import { DATE_TIME_WORD_FORMAT, getInvoiceStatus } from "@/lib/constants";
import { Card } from "@heffl/ui/components/primitives/card";
import { format } from "date-fns";

type TProformaInvoice =
  RouterOutputs["proformaInvoice"]["list"]["invoices"][number];
const ProformaInvoiceCard = ({
  data,
  onClick,
}: {
  data: TProformaInvoice;
  onClick: () => void;
}) => {
  return (
    <Card onClick={onClick}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-medium">
            {data.clients?.name ?? "No company"}
          </p>

          <div className="flex flex-row gap-2">
            <div
              className={`${
                getInvoiceStatus(data.status).color
              } hover:text-black rounded-full px-3 py-1 text-xs text-white font-medium w-fit flex items-center`}
            >
              {getInvoiceStatus(data.status).label}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Invoice number</p>
          <p className="text-xs text-gray-500">#{data.number}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created on</p>
          <p className="text-xs text-gray-500">
            {format(data.createdAt, DATE_TIME_WORD_FORMAT)}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default ProformaInvoiceCard;
