import {
  dynamicDateFormatting,
  formatCurrency,
  formatName,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { ReasonModal, ReasonModalProps } from "@/lib/reason-modal";
import NiceModal from "@ebay/nice-modal-react";
import toast from "react-hot-toast";
import appIcons from "@heffl/ui/components/appIcons";
import { ConfirmModal, ConfirmModalProps } from "@/lib/confirm-modal";
import duration from "dayjs/plugin/duration";
import { capitalize, sum } from "radash";

// rules - only nest 3 levels deep

dayjs.extend(duration);

const modal = {
  reason: (props: ReasonModalProps) =>
    NiceModal.show(ReasonModal, { ...props }),
  confirm: (props: ConfirmModalProps) =>
    NiceModal.show(ConfirmModal, { ...props }),
};

const math = {
  sum,
};

const icons = appIcons;

const date = {
  dynamicDate: (date: Date, showTime: boolean = false) =>
    dynamicDateFormatting(date, !showTime),
  date: (date: Date) => dayjs(date).format("DD/MM/YYYY"),
  dateTime: (date: Date) => dayjs(date).format("DD/MM/YYYY hh:mm A"),
  duration: (minutes: number) => {
    const duration = dayjs.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    if (hours === 0) return `${mins} mins`;
    if (mins === 0) return `${hours} hrs`;
    return `${hours} hrs ${mins} mins`;
  },
};

const array = {
  includes: <T>(array: T[], value: T): boolean => {
    return array.includes(value);
  },
};

const format = {
  name: formatName,
  currency: formatCurrency,

  capitalize: capitalize,
  ...date,
};

const heffl = { format, modal, toast, icons, math, array };

export default heffl;
