import FilterBar from "@/components/filters";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import dayjs from "dayjs";
import React from "react";

type ProfitLossFilter = {
  dates: [Date, Date] | undefined;
  basis: ("ACCRUAL" | "CASH")[];
};

const ProfitLossReport: React.FC = () => {
  const [filters, setFilters] = useParamsState<ProfitLossFilter>({
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
    basis: ["ACCRUAL"],
  });

  const { data: report } = trpc.sales.reports.profitLossReport.useQuery({
    dates: filters.dates,
    basis: filters.basis[0],
  });

  if (!report) return <FullScreenSpinner />;

  return (
    <Page title="Profit & Loss Report" fullWidth className="sm:!p-0 " showBack>
      <div className="flex flex-wrap items-center pt-3 w-full">
        <FilterBar
          className="px-3"
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value as [Date, Date] }),
              presets: [
                {
                  label: "Today",
                  value: [
                    dayjs().startOf("day").toDate(),
                    dayjs().endOf("day").toDate(),
                  ],
                },
                {
                  label: "This Week",
                  value: [
                    dayjs().startOf("week").toDate(),
                    dayjs().endOf("week").toDate(),
                  ],
                },
                {
                  label: "This Month",
                  value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                  ],
                },
                {
                  label: "This Year",
                  value: [
                    dayjs().startOf("year").toDate(),
                    dayjs().endOf("year").toDate(),
                  ],
                },
              ],
            },
            {
              key: "basis",
              type: "checkbox",
              label: "Report Basis",
              value: filters.basis,
              onChange: (value) =>
                setFilters({ basis: value as ("ACCRUAL" | "CASH")[] }),
              options: [
                { label: "Accrual", value: "ACCRUAL" },
                { label: "Cash", value: "CASH" },
              ],
              multiple: false,
            },
          ]}
        />
      </div>
      <div className="flex justify-center w-full">
        <div className="mt-3 w-full max-w-5xl bg-white">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 text-xs text-[#75777c] text-start font-[500]"
                  >
                    Account
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-xs text-[#75777c] text-start font-[500]"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 font-bold">Operating Income</td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Sales
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(report.totalSales, "AED", true)}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-medium">
                    Total for Operating Income
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(report.totalSales, "AED", true)}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-bold">Cost of Goods Sold</td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Bills
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.totalCostOfGoodsSold,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-medium">
                    Total for Cost of Goods Sold
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.totalCostOfGoodsSold,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-bold text-right">
                    Gross Profit
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.totalSales - report.totalCostOfGoodsSold,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-bold">Operating Expenses</td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Expenses
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(report.totalExpenses, "AED", true)}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-medium">
                    Total for Operating Expenses
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(report.totalExpenses, "AED", true)}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-bold text-right">
                    Operating Profit
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.totalSales -
                        report.totalCostOfGoodsSold -
                        report.totalExpenses,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProfitLossReport;
