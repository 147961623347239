import Page from "@/components/page";
import { cn } from "@heffl/ui/lib/utils";
import { useQueryState } from "nuqs";
import { Menu } from "lucide-react";
import { useState } from "react";
import TimesheetUserReport from "./timesheet-user-report";

interface SidebarItem {
  title: string;

  links: {
    id: string;
    label: string;
    description?: string;
    count?: number;
    children?: React.ReactNode;
  }[];
}

const sidebarData: SidebarItem[] = [
  {
    title: "Basic reports",
    links: [
      {
        id: "timesheet-user-report",
        label: "Timesheet User Report",
        children: <TimesheetUserReport />,
        description: "Overview of timesheet hours and costs by user",
      },
    ],
  },
];

const TimesheetReports = () => {
  const [activeLink, setActiveLink] = useQueryState("report", {
    defaultValue: "timesheet-user-report",
    parse: (value: string) => value,
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Page title="Timesheet Reports" className="flex !p-0">
      {/* Mobile Menu Button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed top-[var(--header-height)] left-2 z-50 p-2 bg-white rounded-md shadow-md md:hidden"
      >
        <Menu className="w-6 h-6" />
      </button>

      {/* Sidebar */}
      <div
        className={cn(
          "overflow-y-auto fixed z-40 w-64 bg-white border-r border-gray-200 transition-transform duration-300 h-[calc(100vh-var(--header-height))]",
          "md:translate-x-0",
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        <div className="p-4">
          {sidebarData.map((section) => (
            <div key={section.title} className="mb-6">
              <h3 className="mb-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                {section.title}
              </h3>
              <div className="space-y-1">
                {section.links.map((link) => (
                  <button
                    key={link.id}
                    onClick={() => {
                      setActiveLink(link.id);
                      setIsSidebarOpen(false);
                    }}
                    className={cn(
                      "flex justify-between items-center w-full px-3 py-2 text-sm rounded-md",
                      activeLink === link.id
                        ? "bg-primary-50 text-primary-600"
                        : "text-gray-600 hover:bg-gray-50"
                    )}
                  >
                    <span>{link.label}</span>
                    {link.count !== undefined && (
                      <span
                        className={cn(
                          "px-2 py-1 text-xs rounded-full",
                          activeLink === link.id
                            ? "bg-primary-100 text-primary-600"
                            : "bg-gray-100 text-gray-600"
                        )}
                      >
                        {link.count}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 md:ml-64">
        <div className="p-3 border-b border-gray-200">
          <p className="text-base font-medium text-gray-900">
            {sidebarData
              .flatMap((s) => s.links)
              .find((l) => l.id === activeLink)?.label || "Timesheet Reports"}
          </p>
          <p className="text-sm text-gray-500">
            {sidebarData
              .flatMap((s) => s.links)
              .find((l) => l.id === activeLink)?.description || ""}
          </p>
        </div>
        <div className="p-3">
          {sidebarData.flatMap((s) => s.links).find((l) => l.id === activeLink)
            ?.children || <div>No content</div>}
        </div>
      </div>

      {/* Overlay for mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black/20 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </Page>
  );
};

export default TimesheetReports;
