"use client";

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@heffl/ui/components/primitives/drawer";
import { ChevronDown, LucideIcon, PlusCircle, UserCircle2 } from "lucide-react";
import * as React from "react";
import { cn, isMobile } from "../../lib/utils";
import { Badge } from "./badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea, ScrollBar } from "./scroll-area";

interface Option<T> {
  label: string;
  value: T;
  color?: string;
  icon?: LucideIcon;
}

interface MultiSelectorProps<T> {
  options?: Option<T>[];
  icon?: LucideIcon;
  value?: T[];
  onChange?: (value: T[]) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onSearch?: (value: string) => void;
  createButton?: {
    onClick: () => void;
    label: string;
  };
}

export const MultiSelect = <T extends React.Key>({
  options = [],
  value = [],
  onChange,
  placeholder = "Select options...",
  className,
  disabled = false,
  icon: SelectIcon = UserCircle2,
  createButton,
  onSearch,
}: MultiSelectorProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const handleSelect = (optionValue: T) => {
    if (disabled) return;
    setSearch("");

    const newValue = value?.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onChange?.(newValue);
  };

  const selectPreview = (
    <div
      className={cn(
        "flex gap-1 justify-between items-center px-2 py-3 sm:py-2 sm:min-h-9 text-sm rounded-md border cursor-pointer group border-input ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
        className,
        disabled && "cursor-not-allowed opacity-50"
      )}
    >
      <div className="flex flex-wrap gap-1.5 items-center">
        {SelectIcon && <SelectIcon className="mr-1 w-5 h-5" />}
        {value?.length > 0 ? (
          value.map((v) => {
            const option = options?.find((opt) => opt.value === v);
            return (
              <Badge
                small={false}
                key={String(v)}
                onRemove={() => {
                  if (!disabled) handleSelect(v);
                }}
                variant={"unique"}
              >
                {option?.label}
              </Badge>
            );
          })
        ) : (
          <span className="text-muted-foreground">{placeholder}</span>
        )}
      </div>
      <ChevronDown className="w-4 h-4 opacity-50 shrink-0" />
    </div>
  );

  const selectOptions = (
    <Command>
      <CommandList>
        <CommandInput
          disabled={disabled}
          placeholder="Search options..."
          value={search}
          onValueChange={(value) => {
            if (!disabled) {
              setSearch(value);
              onSearch?.(value);
            }
          }}
        />
        <CommandEmpty>No options found.</CommandEmpty>
        <CommandGroup>
          <ScrollArea
            className={cn("max-h-[300px] sm:max-h-[190px] !overflow-auto")}
          >
            {options
              ?.filter((option) => !value?.includes(option.value))
              .map((option) => (
                <CommandItem
                  key={String(option.value)}
                  onSelect={() => handleSelect(option.value)}
                  disabled={disabled}
                >
                  {option.icon && <option.icon className="mr-2 w-4 h-4" />}
                  {option.label}
                </CommandItem>
              ))}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </CommandGroup>
      </CommandList>

      {createButton?.onClick && !disabled && (
        <div
          className="p-2.5 border-t border-gray-200 hover:text-white hover:bg-primary-300 flex items-center gap-1 cursor-pointer"
          key="create"
          onClick={() => {
            createButton?.onClick();
            setOpen(false);
          }}
        >
          <PlusCircle className="h-5 text-primary" />
          {createButton.label}
        </div>
      )}
    </Command>
  );

  if (!isMobile()) {
    return (
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <div onClick={() => !disabled && setOpen(true)}>{selectPreview}</div>
        </PopoverTrigger>
        <PopoverContent
          className={cn(
            "p-0 w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]"
          )}
          sideOffset={4}
          align="start"
          side="bottom"
          asChild
        >
          {selectOptions}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer
      open={open}
      onOpenChange={(newOpen) => !disabled && setOpen(newOpen)}
    >
      <DrawerTrigger className="w-full">{selectPreview}</DrawerTrigger>
      <DrawerContent>
        {placeholder && (
          <DrawerHeader>
            <DrawerTitle>{placeholder}</DrawerTitle>
          </DrawerHeader>
        )}
        <div className="mt-4 border-t">{selectOptions}</div>
      </DrawerContent>
    </Drawer>
  );
};
