// import FilterBar from "@/components/filters";
import EmptyState from "@/components/empty-state";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn, dynamicDateFormatting, makeEllipsis } from "@heffl/ui/lib/utils";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  Briefcase,
  Calendar,
  Check,
  CheckCircle,
  Clock,
  FileText,
  ListTodo,
  Loader,
  Pause,
  Pencil,
  PlayCircle,
  Target,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { taskDateColor } from "./details/components/kanban/TaskCard";
import { ProjectTaskDetailsModal } from "./tasks/components/project-task-modals";
import {
  AddTimesheetDrawer,
  convertToHoursAndMinutes,
  EditTimesheetDrawer,
} from "./timesheets/list";
import { Badge } from "@heffl/ui/components/primitives/badge";
import heffl from "@/helpers/heffl";
import appIcons from "@heffl/ui/components/appIcons";

const ProjectsDashboard = () => {
  const navigate = useNavigate();

  const [myTasksTab, setMyTasksTab] = useState<
    "pending" | "completed" | "inProgress" | "upcoming"
  >("pending");
  const [assignedTasksTab, setAssignedTasksTab] = useState<
    "pending" | "completed" | "inProgress" | "upcoming"
  >("pending");
  const [editTaskId, setEditTaskId] = useState<number | undefined>();

  const [addTimesheetDrawerOpen, setAddTimesheetDrawerOpen] = useState(false);
  const [editTimesheetId, setEditTimesheetId] = useState<number | undefined>();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const { data: dashboardData } =
    trpc.projects.dashboard.personalNew.useQuery();

  const { data: assignedTasks, isLoading: assignedTasksLoading } =
    trpc.projects.dashboard.tasksIAssigned.useQuery(
      {
        status: assignedTasksTab,
      },
      {
        keepPreviousData: true,
      }
    );
  const { data: myTasks, isLoading: myTasksLoading } =
    trpc.projects.dashboard.myTasks.useQuery(
      {
        status: myTasksTab,
      },
      {
        keepPreviousData: true,
      }
    );

  const updateNotesMutation = trpc.users.update.useMutation({
    onSuccess: () => {
      return { invalidate: false };
    },
  });

  const debouncedUpdateNotesMutation = debounce(
    updateNotesMutation.mutate,
    500
  );

  interface TaskTableProps {
    tasks: RouterOutputs["projects"]["dashboard"]["myTasks"]["tasks"];
    onTaskClick: (taskId: number) => void;
    emptyStateTitle: string;
    emptyStateDescription: string;
    showAssignees?: boolean;
  }

  const TaskTable = ({
    tasks,
    onTaskClick,
    emptyStateTitle,
    emptyStateDescription,
    showAssignees = false,
  }: TaskTableProps) => {
    if (!tasks?.length) {
      return (
        <div className="flex justify-center items-center !w-full">
          <EmptyState
            type="tasks"
            title={emptyStateTitle}
            description={emptyStateDescription}
          />
        </div>
      );
    }

    return (
      <div className="h-[calc(409px-120px)] overflow-auto mt-4 w-full">
        <table className="w-full">
          <thead className="sticky top-0 bg-white text-left text-gray-500 after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-[#E6EDFf] before:absolute before:top-0 before:left-0 before:w-full before:h-[1px] before:bg-[#E6EDFf]">
            <tr>
              <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                <div className="flex gap-2 items-center">
                  <FileText size={16} />
                  Title
                </div>
              </th>
              <th className="lg:w-40 p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                <div className="flex gap-2 items-center">
                  <Target size={16} />
                  Status
                </div>
              </th>
              <th className="p-3 font-medium tracking-wide whitespace-nowrap lg:w-40">
                <div className="flex gap-2 items-center">
                  <Calendar size={16} />
                  Due Date
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks?.map((task) => (
              <tr className="border-b border-[#E6EDFf]" key={task.id}>
                <Tooltip title={task.title} mouseEnterDelay={0.5}>
                  <td
                    className="p-3 whitespace-nowrap border-r border-[#E6EDFf] hover:text-primary-600 cursor-pointer"
                    onClick={() => onTaskClick(task.id)}
                  >
                    {makeEllipsis(task.title, 30)}

                    <span className="flex gap-1 items-center mt-1">
                      {showAssignees &&
                        task.projectTaskAssignees.map((assignee) => (
                          <Badge
                            key={assignee.users.id}
                            small
                            variant="outline"
                            avatar
                          >
                            {heffl.format.name(assignee.users)}
                          </Badge>
                        ))}
                      {!!task.projects?.clients && (
                        <Badge
                          small
                          variant="outline"
                          icon={appIcons.sales.clients.icon}
                        >
                          {makeEllipsis(task.projects.clients.name, 12)}
                        </Badge>
                      )}
                    </span>
                  </td>
                </Tooltip>
                <td className="p-3 whitespace-nowrap border-r border-[#E6EDFf]">
                  <span
                    className={cn(
                      "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 w-fit",
                      {
                        "bg-yellow-200 bg-opacity-40 text-yellow-500":
                          task.status === "IN_PROGRESS",
                        "bg-red-200 bg-opacity-40 text-red-500":
                          task.status === "ON_HOLD",
                        "bg-blue-200 bg-opacity-40 text-blue-500":
                          task.status === "OPEN",
                        "bg-green-200 bg-opacity-40 text-green-600":
                          task.status === "COMPLETED",
                      }
                    )}
                  >
                    {task.status === "IN_PROGRESS" ? (
                      <>
                        <PlayCircle size={14} /> In Progress
                      </>
                    ) : task.status === "ON_HOLD" ? (
                      <>
                        <Pause size={14} /> On Hold
                      </>
                    ) : task.status === "OPEN" ? (
                      <>
                        <Clock size={14} /> Open
                      </>
                    ) : task.status === "COMPLETED" ? (
                      <>
                        <CheckCircle size={14} /> Completed
                      </>
                    ) : (
                      task.status
                    )}
                  </span>
                </td>
                <td className="p-3 whitespace-nowrap">
                  {task.date && (
                    <p className={cn("", taskDateColor(task.date))}>
                      {dynamicDateFormatting(task.date, true)}
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (!dashboardData || !currentUser) return <FullScreenSpinner />;
  return (
    <Page fullWidth className="bg-[#F7F8FA]">
      <AddTimesheetDrawer
        open={addTimesheetDrawerOpen}
        onClose={() => setAddTimesheetDrawerOpen(false)}
      />
      {!!editTimesheetId && (
        <EditTimesheetDrawer
          open={!!editTimesheetId}
          onClose={() => setEditTimesheetId(undefined)}
          timesheetId={editTimesheetId}
        />
      )}
      {!!editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}
      <div className="space-y-4">
        <div className="flex flex-col gap-3 justify-center items-center w-full">
          <p className="font-normal opacity-80 lg:text-lg">
            {dayjs().format("dddd, MMMM D")}
          </p>
          <h5 className="text-2xl font-medium lg:text-3xl">
            {dayjs().hour() < 12
              ? "Good morning"
              : dayjs().hour() < 17
              ? "Good afternoon"
              : "Good evening"}
            , {currentUser.firstName}
          </h5>

          <div className="grid grid-cols-1 gap-6 mt-4 w-full lg:grid-cols-2">
            {/* MY TASKS👇 */}
            <div className="bg-white border border-[#E6EDFf] flex flex-col items-start h-[440px] rounded-xl">
              <div className="flex items-center gap-3 px-5 py-3 border-b border-[#E6EDFf] w-full">
                <h1 className="text-lg font-medium text-gray-800">My Tasks</h1>
              </div>
              <StripeTabs
                className="mt-1 w-full"
                value={myTasksTab}
                onChange={setMyTasksTab}
                items={[
                  {
                    key: "pending",
                    label: "Pending",
                    count: myTasks?.stats.pending,
                  },
                  {
                    key: "inProgress",
                    label: "In Progress",
                    count: myTasks?.stats.inProgress,
                  },
                  {
                    key: "upcoming",
                    label: "Upcoming",
                    count: myTasks?.stats.upcoming,
                  },
                  {
                    key: "completed",
                    label: "Completed",
                  },
                ]}
              />
              {myTasks && !myTasksLoading ? (
                <TaskTable
                  tasks={myTasks.tasks}
                  onTaskClick={setEditTaskId}
                  emptyStateTitle="No pending tasks"
                  emptyStateDescription="Your pending tasks will appear here"
                />
              ) : (
                <FullScreenSpinner />
              )}
            </div>

            {/* \\\\\\\\\\\\ */}
            {/* NOTES👇 */}

            <div className="items-start w-full bg-white rounded-xl border border-[#E6EDFf] h-[440px]">
              <h1 className="text-lg font-medium text-gray-800 px-5 border-b border-[#E6EDFf] pb-3 w-full py-3">
                Private Notes
              </h1>
              <div className="px-5 py-4 w-full">
                <MiniRichTextEditor
                  placeholder="Write your personal notes here..."
                  height={314}
                  value={currentUser.personalNotes || ""}
                  onChange={(value) =>
                    debouncedUpdateNotesMutation({
                      id: currentUser.id,
                      user: { personalNotes: value },
                    })
                  }
                />
                {updateNotesMutation.isLoading && (
                  <div className="flex gap-2 items-center mt-1 float-end">
                    <p>Updating</p>
                    <Loader className="h-5 animate-twSpin text-primary" />
                  </div>
                )}
              </div>
            </div>

            {/* TASKS I ASSIGNED👇 */}
            <div className="bg-white border border-[#E6EDFf] flex flex-col items-start h-[440px] rounded-xl">
              <div className="flex items-center gap-3 px-5 py-3 border-b border-[#E6EDFf] w-full">
                <h1 className="text-lg font-medium text-gray-800">
                  Tasks I Assigned
                </h1>
              </div>

              <StripeTabs
                className="mt-1 w-full"
                value={assignedTasksTab}
                onChange={setAssignedTasksTab}
                items={[
                  {
                    key: "pending",
                    label: "Pending",
                    count: assignedTasks?.stats.pending,
                  },
                  {
                    key: "inProgress",
                    label: "In Progress",
                    count: assignedTasks?.stats.inProgress,
                  },
                  {
                    key: "upcoming",
                    label: "Upcoming",
                    count: assignedTasks?.stats.upcoming,
                  },
                  {
                    key: "completed",
                    label: "Completed",
                  },
                ]}
              />
              {assignedTasks && !assignedTasksLoading ? (
                <TaskTable
                  tasks={assignedTasks.tasks}
                  onTaskClick={setEditTaskId}
                  emptyStateTitle="No pending tasks"
                  showAssignees
                  emptyStateDescription="Your pending tasks will appear here"
                />
              ) : (
                <FullScreenSpinner />
              )}
            </div>
            {/* \\\\\\\\\\\\\ */}
            {/* MY PROJECTS👇 */}

            <div className="flex flex-col items-start bg-white border border-[#E6EDFf] rounded-xl h-[440px]">
              <div className="flex items-center gap-3 px-5 py-3 border-b border-[#E6EDFf] w-full">
                <h1 className="text-lg font-medium text-gray-800">
                  My Projects
                </h1>
                {(dashboardData?.projects?.length ?? 0) > 0 && (
                  <span className="flex justify-center items-center w-6 h-6 text-xs font-medium text-gray-700 bg-gray-100 rounded-full">
                    {dashboardData?.projects?.length ?? 0}
                  </span>
                )}
              </div>
              {/* new project card */}
              <div className="overflow-auto px-5 my-4 w-full h-full">
                {!dashboardData?.projects?.length ? (
                  <EmptyState
                    type="projects"
                    title="No projects"
                    description="Your projects will appear here"
                  />
                ) : (
                  <table className="w-full">
                    <thead className="sticky top-0 bg-white text-left text-gray-500 after:absolute after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-[#E6EDFf] before:absolute before:top-0 before:left-0 before:w-full before:h-[1px] before:bg-[#E6EDFf]">
                      <tr>
                        <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                          <div className="flex gap-2 items-center">
                            <Briefcase size={16} />
                            Project
                          </div>
                        </th>
                        <th className="p-3 font-medium tracking-wide border-r border-[#E6EDFf] whitespace-nowrap">
                          <div className="flex gap-2 items-center">
                            <Target size={16} />
                            Status
                          </div>
                        </th>
                        <th className="p-3 font-medium tracking-wide whitespace-nowrap">
                          <div className="flex gap-2 items-center">
                            <ListTodo size={16} />
                            Tasks
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.projects?.map((project) => (
                        <tr
                          key={project.id}
                          className="border-b border-[#E6EDFf]"
                        >
                          <td
                            className="p-3  whitespace-nowrap border-r border-[#E6EDFf] hover:text-primary-600 cursor-pointer"
                            onClick={() => {
                              navigate(`/projects/details/${project.id}`);
                            }}
                          >
                            {project.title}
                          </td>
                          <td className="p-3  whitespace-nowrap border-r border-[#E6EDFf] ">
                            <span
                              className={`text-xs rounded px-2 py-1 ${
                                project.status === "ACTIVE"
                                  ? "bg-green-100 text-green-600"
                                  : project.status === "COMPLETED" ||
                                    project.status === "CANCELLED"
                                  ? "bg-red-100 text-red-600"
                                  : "bg-yellow-100 text-yellow-600"
                              }`}
                            >
                              {project.status === "ACTIVE"
                                ? "Active"
                                : project.status === "COMPLETED"
                                ? "Completed"
                                : project.status === "CANCELLED"
                                ? "Cancelled"
                                : "Inactive"}
                            </span>
                          </td>
                          <td className="p-3 whitespace-nowrap">
                            <div className="flex gap-2 items-center">
                              <span className="px-2 py-1 text-xs text-blue-600 bg-blue-50 rounded border border-blue-100">
                                Open:{" "}
                                <span className="font-semibold">
                                  {project.projectTasks?.filter(
                                    (task) => task.status === "OPEN"
                                  ).length || 0}
                                </span>
                              </span>
                              <span className="px-2 py-1 text-xs text-red-600 bg-red-50 rounded border border-red-100">
                                On Hold:{" "}
                                <span className="font-semibold">
                                  {project.projectTasks?.filter(
                                    (task) => task.status === "ON_HOLD"
                                  ).length || 0}
                                </span>
                              </span>
                              <span className="px-2 py-1 text-xs text-yellow-600 bg-yellow-50 rounded border border-yellow-100">
                                In Progress:{" "}
                                <span className="font-semibold">
                                  {project.projectTasks?.filter(
                                    (task) => task.status === "IN_PROGRESS"
                                  ).length || 0}
                                </span>
                              </span>
                              <span className="px-2 py-1 text-xs text-green-600 bg-green-50 rounded border border-green-100">
                                Completed:{" "}
                                <span className="font-semibold">
                                  {project.projectTasks?.filter(
                                    (task) => task.status === "COMPLETED"
                                  ).length || 0}
                                </span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* \\\\\\\\\\\\\\\ */}
            {/* MY TIMESHEETS👇 */}
            <div className="lg:col-span-2 bg-white border border-[#E6EDFf] flex flex-col items-start min-h-64 max-h-96 rounded-xl">
              <div className="flex items-center justify-between gap-3 px-5 py-3 border-b border-[#E6EDFf] w-full">
                <div className="flex gap-3 items-center">
                  <h1 className="text-lg font-medium text-gray-800">
                    My Timesheets
                  </h1>
                </div>
                <ResponsiveActionButton
                  onClick={() => setAddTimesheetDrawerOpen(true)}
                  text="Timesheet"
                />
              </div>
              <div className="overflow-hidden w-full">
                <div className="flex overflow-x-auto gap-4 items-center px-5 py-3 w-full whitespace-nowrap scrollbar-hide">
                  <div className="flex-shrink-0 flex gap-2 items-center px-2 py-1 bg-slate-50 border border-[#E6EDFf] border-opacity-40 rounded-lg">
                    <span className="text-gray-600">Billable</span>
                    <span className="font-semibold">
                      {convertToHoursAndMinutes(
                        dashboardData?.timesheets?.stats.billableMinutes || 0
                      )}{" "}
                      hours
                    </span>
                  </div>
                  <div className="flex-shrink-0 flex gap-2 items-center px-2 py-1 bg-slate-50 border border-[#E6EDFf] border-opacity-40 rounded-lg">
                    <span className="text-gray-600">Non-billable</span>
                    <span className="font-semibold">
                      {convertToHoursAndMinutes(
                        dashboardData?.timesheets?.stats.nonBillableMinutes || 0
                      )}{" "}
                      hours
                    </span>
                  </div>
                  <div className="flex-shrink-0 flex gap-2 items-center px-2 py-1 bg-slate-50 border border-[#E6EDFf] border-opacity-40 rounded-lg">
                    <span className="text-gray-600">Total</span>
                    <span className="font-semibold">
                      {convertToHoursAndMinutes(
                        dashboardData?.timesheets?.stats.totalMinutes || 0
                      )}{" "}
                      hours
                    </span>
                  </div>
                </div>
              </div>
              <div className="overflow-auto px-5 pb-5 w-full">
                {!dashboardData?.timesheets?.list?.length ? (
                  <EmptyState
                    type="timesheets"
                    title="No timesheets"
                    description="Your timesheets will appear here"
                  />
                ) : (
                  <SimpleTable
                    idKey="id"
                    className="mt-4 h-80"
                    borderless
                    rows={dashboardData?.timesheets?.list}
                    actions={(row) => [
                      {
                        type: "item",
                        label: "Edit",
                        icon: Pencil,
                        onClick: () => setEditTimesheetId(row.id),
                      },
                    ]}
                    columns={[
                      {
                        label: "Project",
                        icon: Briefcase,
                        render: (row) => (
                          <p
                            className="cursor-pointer hover:text-primary-600"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/projects/details/${row.projectTasks?.projects?.id}`
                              );
                            }}
                          >
                            {row.projectTasks?.projects?.title}
                          </p>
                        ),
                      },
                      {
                        label: "Task",
                        icon: ListTodo,
                        render: (row) => (
                          <p
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditTaskId(row.projectTasks?.id);
                            }}
                            className="cursor-pointer hover:text-primary-600"
                          >
                            {row.projectTasks?.title}
                          </p>
                        ),
                      },
                      {
                        label: "Hours",
                        icon: Clock,
                        render: (row) => (
                          <p>
                            {convertToHoursAndMinutes(row.minutesWorked)} Hrs
                          </p>
                        ),
                      },
                      {
                        label: "Status",
                        icon: Target,
                        render: (row) => (
                          <span
                            className={cn(
                              "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 w-fit",
                              {
                                "bg-blue-200 bg-opacity-40 text-blue-500":
                                  row.approvalStatus === "OPEN",
                                "bg-yellow-200 bg-opacity-40 text-yellow-600":
                                  row.approvalStatus === "APPROVED",
                                "bg-red-200 bg-opacity-40 text-red-500":
                                  row.approvalStatus === "REJECTED",
                              }
                            )}
                          >
                            {row.approvalStatus === "OPEN" ? (
                              <>
                                <Clock size={14} /> Open
                              </>
                            ) : row.approvalStatus === "APPROVED" ? (
                              <>
                                <Check size={14} /> Approved
                              </>
                            ) : row.approvalStatus === "REJECTED" ? (
                              <>
                                <Pause size={14} /> Rejected
                              </>
                            ) : (
                              row.approvalStatus
                            )}
                          </span>
                        ),
                      },
                      {
                        label: "Date",
                        icon: Calendar,
                        render: (row) =>
                          dayjs(row.createdAt).format("DD MMM YYYY"),
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProjectsDashboard;
