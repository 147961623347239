import { RouterOutputs } from "../trpc";

type ValidateCustomFieldsProps = {
  customFields?: RouterOutputs["customizations"]["customFields"]["list"];
  customFieldsValues: Record<string, string>;
  form: unknown | null;
  name?: string;
};

const validateCustomFields = ({
  customFields,
  customFieldsValues,
  form,
  name = "customFields",
}: ValidateCustomFieldsProps) => {
  console.log(customFieldsValues, customFields, "customFieldsValues");
  if (!customFields) return { isValid: true, errors: [] };

  const customFieldErrors: {
    name: string;
    message: string;
  }[] = [];

  customFields.forEach((field) => {
    const value = customFieldsValues[field.name];
    if (field.required && !value) {
      customFieldErrors.push({
        name: `${name}.${field.name}`,
        message: `${field.label} is required`,
      });
    }

    if (field.dataType === "NUMBER") {
      const isInteger =
        field.dataType === "NUMBER" && value && Number.isInteger(Number(value));
      if (!isInteger && value) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} is not a valid number`,
        });
      }
      if (field.isMultiplier && Number(value || 0) <= 0) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} must be greater than 0`,
        });
      }
    }
    if (field.dataType === "DATE") {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} is not a valid date`,
        });
      }
    }
    if (field.dataType === "SINGLE_OPTION" && value) {
      if (!field.values?.includes(value)) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} is not a valid option`,
        });
      }
    }

    if (field.dataType === "EMAIL" && value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} is not a valid email`,
        });
      }
    }
  });
  if (customFieldErrors.length) {
    form &&
      customFieldErrors.forEach((error) =>
        // @ts-ignore
        form.setError(error.name, { message: error.message })
      );
    return { isValid: false, errors: customFieldErrors };
  }
  return { isValid: true, errors: [] };
};

export default validateCustomFields;
