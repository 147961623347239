import { styled } from "goober";
import { groupSchedules } from "./calendar";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import dayjs from "dayjs";
import { getJobId } from "../jobs/list";
import { formatValue } from "@heffl/ui/lib/utils";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { convert } from "html-to-text";

type Props = {
  schedulesData?: RouterOutputs["fieldService"]["schedules"]["list"];
  schedulePrintTeam: string;
  filters: {
    startDate: Date;
  };
};

export const formatPropertyAddress = (property: {
  name: string | null;
  city: string;
  address: string;
  countryStates: {
    name: string;
  };
}) => {
  const items = [
    property.name,
    property.city,
    property.address,
    property.countryStates.name,
  ];

  const uniqueItems = [...new Set(items)];
  return uniqueItems
    .filter(Boolean)
    .map((item) => item?.trim().replace(/[-,]$/, ""))
    .join(", ");
};

export const getPrintPageMargins = () => {
  return `@page { margin: 1cm !important; }`;
};

export const PrintWrapper = styled("div")`
  table {
    border-collapse: collapse;
    font-size: 12px !important;
  }
  td,
  th {
    border: 1px solid gray !important;
    padding: 5px !important;
    font-size: 12px !important;
    vertical-align: top;
  }
  td {
    break-inside: avoid;
  }

  @media print {
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;

export const getSegragtedSchedules = (
  selectedDaySchedulesGrouped: ReturnType<typeof groupSchedules>,
  schedulePrintTeam: string
) => {
  return selectedDaySchedulesGrouped.resourcesList
    .filter((team, index) => {
      if (
        schedulePrintTeam === "all-schedules" ||
        schedulePrintTeam === "all-assigned-schedules"
      ) {
        return index === 0;
      }
      return (
        (schedulePrintTeam === "all-teams" && team.id !== "unassigned") ||
        (Array.isArray(team.id)
          ? team.id.join(",") === schedulePrintTeam
          : team.id === schedulePrintTeam)
      );
    })
    .map((team) => {
      let teamSchedules;
      if (schedulePrintTeam === "all-schedules") {
        teamSchedules = selectedDaySchedulesGrouped.schedulesList.sort(
          (a, b) => a.startDate.getTime() - b.startDate.getTime()
        );
      } else if (schedulePrintTeam === "all-assigned-schedules") {
        teamSchedules = selectedDaySchedulesGrouped.schedulesList
          .filter((s) => s.fsScheduleAssignees.length)
          .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
      } else {
        teamSchedules = selectedDaySchedulesGrouped.schedulesList.filter(
          (schedule) => {
            const teamId = Array.isArray(team.id) ? team.id.join(",") : team.id;
            return schedule.resourceIds.includes(teamId);
          }
        );
      }
      return { teamSchedules, team };
    });
};

const SchedulesExport = ({
  schedulesData,
  schedulePrintTeam,
  filters,
}: Props) => {
  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  // schedules for exporting with selected date startDate only, no schedules that started yesterday
  const selectedDaySchedulesGrouped = groupSchedules(
    schedulesData?.schedules?.filter((s) =>
      dayjs(filters.startDate).isSame(s.startDate, "day")
    ) || []
  );

  // TODO: Bug schedules print will not be reflected if calendar changed, dosent refresh data, trpc

  return (
    <PrintWrapper className="hidden print:block">
      <style>{getPrintPageMargins()}</style>
      {getSegragtedSchedules(
        selectedDaySchedulesGrouped,
        schedulePrintTeam
      ).map(({ teamSchedules, team }, index) => (
        <div key={team.title}>
          <h1 className="text-2xl font-bold text-center">
            {teamDetails?.name}
          </h1>
          {schedulePrintTeam !== "all-schedules" &&
            schedulePrintTeam !== "all-assigned-schedules" && (
              <div className="flex justify-between items-start">
                <p className="text-base">
                  Booking date :{" "}
                  {dayjs(filters.startDate).format("DD MMM YYYY")}
                </p>
                <p>Team : {team.title}</p>
              </div>
            )}
          {index === 0 &&
            (schedulePrintTeam === "all-schedules" ||
              schedulePrintTeam === "all-assigned-schedules") && (
              <div className="flex justify-between items-start">
                <p className="text-base">
                  Booking date :{" "}
                  {dayjs(filters.startDate).format("DD MMM YYYY")}
                </p>
                <p>Team : All</p>
              </div>
            )}

          <table className="mt-3">
            <thead>
              <tr>
                <th className="w-[50px]">Id</th>
                <th className="w-[200px]">Customer</th>
                {/* <th className="w-[200px]">Phone</th> */}
                <th className="w-[300px]">Address</th>
                <th className="w-[180px]">Time</th>
                <th className="w-[100px]">Amount</th>
                <th className="w-[200px]">Team</th>
                <th className="w-[300px]">Notes</th>
              </tr>
            </thead>
            <tbody>
              {teamSchedules.map((schedule) => (
                <tr key={schedule.id}>
                  <td>{getJobId(schedule.fsJobs, "")}</td>
                  <td>{schedule.fsJobs.fsProperties.clients.name}</td>
                  {/* <td>
                    <b>
                      {schedule.fsJobs.siteContactPerson?.mobile ||
                        "No Mobile"}
                    </b>
                    <br />
                    {schedule.fsJobs.siteContactPerson
                      ? formatValue.personName(
                          schedule.fsJobs.siteContactPerson
                        )
                      : ""}
                  </td> */}
                  <td>{formatPropertyAddress(schedule.fsJobs.fsProperties)}</td>
                  <td>
                    {dayjs(schedule.startDate).format("h:mm a")} -{" "}
                    {dayjs(schedule.endDate).format("h:mm a")}
                  </td>
                  <td>
                    {calculateLineItems({
                      lineItems: schedule.fsJobs.fsJobServices,
                      discount: schedule.fsJobs.discount,
                    }).total.toFixed(2)}
                    <br />
                    {schedule.fsJobs.paymentMethods && (
                      <span className="font-bold underline">
                        {schedule.fsJobs.paymentMethods.name}
                      </span>
                    )}
                  </td>
                  <td>
                    {schedule.fsScheduleAssignees
                      .map(
                        (assignee) =>
                          `${formatValue.personName(assignee.users)} - ${dayjs(
                            assignee.startDate
                          ).format("h:mm a")}`
                      )
                      .join(", ")}
                  </td>
                  <td>
                    <p>
                      <p className="font-medium underline">
                        {schedule.fsJobs.fsJobServices
                          .map(
                            (service) => service.name || service?.products?.name
                          )
                          .join(", ")}
                      </p>
                      <br />
                      <p className="text-xs">
                        {convert(schedule.fsJobs.instructions || "")}
                      </p>
                    </p>

                    <p>{convert(schedule.notes || "")}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {schedulePrintTeam === "all-teams" && (
            <div style={{ pageBreakAfter: "always" }}></div>
          )}
        </div>
      ))}
    </PrintWrapper>
  );
};

export default SchedulesExport;
