import { getActivityColumnLabels } from "@heffl/server/src/helpers/userActivities/columnLabels";
import {
  Entity,
  UserActivity,
} from "@heffl/server/src/helpers/userActivities/recordActivity";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Timeline } from "@heffl/ui/components/primitives/Timeline";
import dayjs from "dayjs";
import { convert } from "html-to-text";
import { capitalize } from "lodash";
import { isNumber } from "radash";
import { ReactNode } from "react";
import heffl from "../heffl";

const actionLabels = {
  ADD: "created",
  UPDATE: "updated",
  DELETE: "deleted",
};

const updateTypeLabels = {
  CREATE: "added",
  REMOVE: "removed",
  CHANGE: "updated",
};

export const renderUpdateTitle = (userActivity: UserActivity) => {
  return (
    <p>
      <span className="font-medium">{userActivity.users.firstName}</span>{" "}
      {actionLabels[userActivity.action]}{" "}
      <span className="font-medium">
        {capitalize(
          getActivityColumnLabels(
            userActivity.entity,
            userActivity.entity.toLowerCase().replace("_", " ")
          )
        )}
      </span>
    </p>
  );
};

// list of items that shows updated but dosent show value
const hideValueChanged: { [key in Entity]: string[] } = {
  QUOTATION: ["dealId"],
  INVOICE: ["paymentsRecieved"],
  PURCHASE_ORDER: [],
  BILL: ["billProducts"],
  FS_JOB: [],
  PROFORMA_INVOICE: [],
  SALES_ORDER: [],
  LEAD: [],
  DEAL: [],
};

const formatValue = (key: unknown, value: unknown) => {
  if (key === "status") {
    return (
      <Badge small variant="unique">
        {capitalize(String(value))}
      </Badge>
    );
  }

  if (dayjs(value as Date).isValid()) {
    return heffl.format.date(value as Date);
  }

  return ["string", "number"].includes(typeof value)
    ? typeof value === "number"
      ? value
      : convert(String(value))
    : "";
};

export const renderUpdateDescription = (userActivity: UserActivity) => {
  const description = userActivity.updates.map((update, index) => (
    <li
      key={index}
      className="py-1 pl-1 text-sm leading-relaxed list-disc text-gray-700 transition-colors marker:text-yellow-500 hover:text-gray-900"
    >
      <span className="font-medium text-gray-900">
        {`${capitalize(
          update.path
            .filter((path) => !isNumber(path))
            .map((path) => getActivityColumnLabels(userActivity.entity, path))
            .join(" > ")
        )}`}
      </span>
      <span className="text-gray-600">
        {` ${updateTypeLabels[update.type]}`}
      </span>
      {"value" in update &&
        !hideValueChanged[userActivity.entity].includes(
          update.path[0].toString()
        ) &&
        update.type === "CHANGE" && (
          <span className="ml-1">
            to{" "}
            <span className="font-semibold text-blue-600">
              {formatValue(update.path[0], update.value)}
            </span>
          </span>
        )}
    </li>
  ));

  if (userActivity.action === "ADD") {
    return (
      <div>
        <span className="text-xs">
          {dayjs(userActivity.createdAt).fromNow()}
        </span>
      </div>
    );
  }
  if (userActivity.action === "UPDATE") {
    return (
      <div>
        <ul>
          {userActivity.updates.length > 2 ||
          userActivity.updates.some(
            (update) => "value" in update && String(update.value).length > 100
          ) ? (
            <Accordion type="single" collapsible>
              <AccordionItem value="updates">
                <AccordionTrigger
                  className="text-xs font-medium"
                  iconPosition="end"
                >
                  <div className="flex gap-3 items-center">
                    {userActivity.updates.length} updates done
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <ul>{description}</ul>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <ul>{description}</ul>
          )}
        </ul>
        <span className="text-xs">
          {dayjs(userActivity.createdAt).fromNow()}
        </span>
      </div>
    );
  }
  return "";
};

const RenderTimeline = ({
  userActivities,
}: {
  userActivities: UserActivity[];
}) => {
  const items: {
    title: ReactNode;
    description: ReactNode;
  }[] = [];

  userActivities.forEach((activity) => {
    items.push({
      title: renderUpdateTitle(activity),
      description: renderUpdateDescription(activity),
    });
  });
  return <Timeline activeItem={0} items={items} />;
};

export default RenderTimeline;
