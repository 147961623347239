import {
  Briefcase,
  Building,
  Calendar,
  ClipboardList,
  Component,
  CreditCard,
  DollarSign,
  LucideIcon,
  MapPin,
  Receipt,
  ScrollText,
  ShoppingCart,
  UserSquare,
  Wrench,
} from "lucide-react";

export type DynamicTags = {
  Common: ["PageNumber", "TodaysDate"];
  Client: [
    "Number",
    "Name",
    "TaxNo",
    "Website",
    "BillingAddress.Landmark",
    "BillingAddress.Address",
    "BillingAddress.City",
    "BillingAddress.Country",
    "BillingAddress.State",
    "BillingAddress.Formatted"
  ];
  Vendor: [
    "Name",
    "TaxNo",
    "BillingAddress.Landmark",
    "BillingAddress.Address",
    "BillingAddress.City",
    "BillingAddress.Country",
    "BillingAddress.State",
    "BillingAddress.Formatted"
  ];
  ServiceProperty: ["Name", "City", "Address"];
  Contact: ["Name", "Mobile", "Email", "JobTitle"];
  ServiceJob: [
    "StartDate",
    "EndDate",
    "Client.Name",
    "TotalAmount",
    "TotalAmountWords",
    "ServiceProperty.Address",
    "ServiceProperty.City",
    "ServiceProperty.Name",
    "LineItems",
    "Contract.PaymentTermsWords",
    "Contract.ScheduleFrequencyWords",
    "ContactPerson.Name",
    "ContactPerson.Mobile",
    "ContactPerson.Email",
    "ContactPerson.JobTitle",
    "LPONumber"
  ];
  ServiceSchedule: ["StartDate", "EndDate", "StartTime", "EndTime"];
  Quote: [
    "Number",
    "Date",
    "Subject",
    "Content",
    "Subtotal",
    "TotalAmount",
    "LineItems",
    "ProductDocuments",
    "SalesPerson.FirstName",
    "SalesPerson.LastName",
    "SalesPerson.Mobile",
    "SalesPerson.Email",
    "ExpiryDate",
    "CustomFields"
  ];
  Invoice: [
    "Number",
    "Date",
    "DueDate",
    "Content",
    "Subtotal",
    "TotalAmount",
    "TotalAmountWords",
    "LineItems",
    "Payments.Recieved",
    "Payments.Pending"
  ];
  SalesOrder: [
    "Number",
    "Date",
    "Content",
    "Subtotal",
    "TotalAmount",
    "LineItems",
    "SalesPerson.FirstName",
    "SalesPerson.LastName",
    "SalesPerson.Mobile",
    "SalesPerson.Email"
  ];
  ProformaInvoice: [
    "Number",
    "Date",
    "Content",
    "Subtotal",
    "TotalAmount",
    "LineItems"
  ];
  Payment: [
    "Number",
    "Date",
    "Amount",
    "RefNo",
    "Notes",
    "PaymentMethod",
    "CollectedBy",
    "Invoices.Numbers",
    "Allocations"
  ];
  PurchaseOrder: [
    "Number",
    "Date",
    "LineItems",
    "TotalAmount",
    "Content",
    "Subtotal",
    "ExpectedDeliveryDate"
  ];
  Bill: ["Number", "Date", "LineItems", "TotalAmount", "Content", "Subtotal"];
};

export type DynamicTagInjectFormat = {
  [key in keyof DynamicTags]?: Record<
    `${key}.${DynamicTags[key][number]}`,
    string | object | undefined | null | number
  >;
};

export type DynamicTagsType = {
  [key in keyof DynamicTags]: {
    title: key;
    icon: LucideIcon;
    menu: Array<{
      title: `${string}`;
      value: `${key}.${DynamicTags[key][number]}`;
    }>;
  };
};

export type DynamicTagsArray = Array<{
  title: keyof DynamicTags;
  icon: LucideIcon;
  menu: Array<{
    title: `${keyof DynamicTags} ${string}`;
    value: `${keyof DynamicTags}.${DynamicTags[keyof DynamicTags][number]}`;
  }>;
}>;

export const dynamicTags: DynamicTagsType = {
  Common: {
    title: "Common",
    icon: Component,
    menu: [
      { title: "Page Number", value: "Common.PageNumber" },
      { title: "Todays Date", value: "Common.TodaysDate" },
    ],
  },
  Client: {
    title: "Client",
    icon: Building,
    menu: [
      { title: "Client Number", value: "Client.Number" },
      { title: "Client Name", value: "Client.Name" },
      {
        title: "Client Tax No",
        value: "Client.TaxNo",
      },
      {
        title: "Client Website",
        value: "Client.Website",
      },
      {
        title: "Client Billing Landmark",
        value: "Client.BillingAddress.Landmark",
      },
      {
        title: "Client Billing Address",
        value: "Client.BillingAddress.Address",
      },
      {
        title: "Client Billing City",
        value: "Client.BillingAddress.City",
      },
      {
        title: "Client Billing State",
        value: "Client.BillingAddress.State",
      },
      {
        title: "Client Billing Country",
        value: "Client.BillingAddress.Country",
      },
      {
        title: "Client Billing Formatted",
        value: "Client.BillingAddress.Formatted",
      },
    ],
  },
  Vendor: {
    title: "Vendor",
    icon: Briefcase,
    menu: [
      {
        title: "Vendor Name",
        value: "Vendor.Name",
      },
      {
        title: "Vendor Tax No",
        value: "Vendor.TaxNo",
      },
      {
        title: "Vendor Billing Landmark",
        value: "Vendor.BillingAddress.Landmark",
      },
      {
        title: "Vendor Billing Address",
        value: "Vendor.BillingAddress.Address",
      },
      {
        title: "Vendor Billing City",
        value: "Vendor.BillingAddress.City",
      },
      {
        title: "Vendor Billing State",
        value: "Vendor.BillingAddress.State",
      },
      {
        title: "Vendor Billing Country",
        value: "Vendor.BillingAddress.Country",
      },
      {
        title: "Vendor Billing Formatted",
        value: "Vendor.BillingAddress.Formatted",
      },
    ],
  },
  Contact: {
    title: "Contact",
    icon: UserSquare,
    menu: [
      {
        title: "Contact Name",
        value: "Contact.Name",
      },
      {
        title: "Contact Mobile",
        value: "Contact.Mobile",
      },
      {
        title: "Contact Email",
        value: "Contact.Email",
      },
      {
        title: "Contact Job Title",
        value: "Contact.JobTitle",
      },
    ],
  },
  SalesOrder: {
    title: "SalesOrder",
    icon: ShoppingCart,
    menu: [
      {
        title: "SalesOrder Number",
        value: "SalesOrder.Number",
      },
      {
        title: "SalesOrder Date",
        value: "SalesOrder.Date",
      },
      { title: "SalesOrder Content", value: "SalesOrder.Content" },
      {
        title: "SalesOrder Line Items",
        value: "SalesOrder.LineItems",
      },
      {
        title: "SalesOrder Subtotal",
        value: "SalesOrder.Subtotal",
      },
      {
        title: "SalesOrder Total Amount",
        value: "SalesOrder.TotalAmount",
      },
      {
        title: "SalesOrder Sales Person First Name",
        value: "SalesOrder.SalesPerson.FirstName",
      },
      {
        title: "SalesOrder Sales Person Last Name",
        value: "SalesOrder.SalesPerson.LastName",
      },
      {
        title: "SalesOrder Sales Person Mobile",
        value: "SalesOrder.SalesPerson.Mobile",
      },
      {
        title: "Quote Sales Person Email",
        value: "SalesOrder.SalesPerson.Email",
      },
    ],
  },
  Quote: {
    title: "Quote",
    icon: ScrollText,
    menu: [
      {
        title: "Quote Number",
        value: "Quote.Number",
      },
      {
        title: "Quote Date",
        value: "Quote.Date",
      },
      {
        title: "Quote Subject",
        value: "Quote.Subject",
      },
      { title: "Quote Content", value: "Quote.Content" },
      {
        title: "Quote Line Items",
        value: "Quote.LineItems",
      },
      {
        title: "Quote Product Documents",
        value: "Quote.ProductDocuments",
      },
      { title: "Quote Subtotal", value: "Quote.Subtotal" },
      {
        title: "Quote Total Amount",
        value: "Quote.TotalAmount",
      },
      {
        title: "Quote Sales Person First Name",
        value: "Quote.SalesPerson.FirstName",
      },
      {
        title: "Quote Sales Person Last Name",
        value: "Quote.SalesPerson.LastName",
      },
      {
        title: "Quote Sales Person Mobile",
        value: "Quote.SalesPerson.Mobile",
      },
      {
        title: "Quote Sales Person Email",
        value: "Quote.SalesPerson.Email",
      },
      {
        title: "Quote Expiry Date",
        value: "Quote.ExpiryDate",
      },
      {
        title: "Quote Custom Fields",
        value: "Quote.CustomFields",
      },
    ],
  },
  Invoice: {
    title: "Invoice",
    icon: DollarSign,
    menu: [
      {
        title: "Invoice Number",
        value: "Invoice.Number",
      },
      {
        title: "Invoice Date",
        value: "Invoice.Date",
      },
      {
        title: "Invoice Due Date",
        value: "Invoice.DueDate",
      },
      { title: "Invoice Content", value: "Invoice.Content" },
      {
        title: "Invoice Line Items",
        value: "Invoice.LineItems",
      },
      { title: "Invoice Subtotal", value: "Invoice.Subtotal" },
      {
        title: "Invoice Total Amount",
        value: "Invoice.TotalAmount",
      },
      {
        title: "Invoice Total Amount Words",
        value: "Invoice.TotalAmountWords",
      },
      {
        title: "Invoice Payments Recieved",
        value: "Invoice.Payments.Recieved",
      },
      {
        title: "Invoice Payments Pending",
        value: "Invoice.Payments.Pending",
      },
    ],
  },
  Payment: {
    title: "Payment",
    icon: CreditCard,
    menu: [
      { title: "Payment Number", value: "Payment.Number" },
      { title: "Payment Date", value: "Payment.Date" },
      { title: "Payment Amount", value: "Payment.Amount" },
      { title: "Payment RefNo", value: "Payment.RefNo" },
      { title: "Payment Notes", value: "Payment.Notes" },
      {
        title: "Payment PaymentMethod",
        value: "Payment.PaymentMethod",
      },
      {
        title: "Payment CollectedBy",
        value: "Payment.CollectedBy",
      },
      {
        title: "Payment Invoices Numbers",
        value: "Payment.Invoices.Numbers",
      },
      {
        title: "Payment Allocations",
        value: "Payment.Allocations",
      },
    ],
  },
  ProformaInvoice: {
    title: "ProformaInvoice",
    icon: DollarSign,
    menu: [
      {
        title: "ProformaInvoice Number",
        value: "ProformaInvoice.Number",
      },
      {
        title: "ProformaInvoice Date",
        value: "ProformaInvoice.Date",
      },
      { title: "ProformaInvoice Content", value: "ProformaInvoice.Content" },
      {
        title: "ProformaInvoice Line Items",
        value: "ProformaInvoice.LineItems",
      },
      {
        title: "ProformaInvoice Subtotal",
        value: "ProformaInvoice.Subtotal",
      },
      {
        title: "ProformaInvoice Total Amount",
        value: "ProformaInvoice.TotalAmount",
      },
    ],
  },
  PurchaseOrder: {
    title: "PurchaseOrder",
    icon: ClipboardList,
    menu: [
      { title: "PurchaseOrder Number", value: "PurchaseOrder.Number" },
      { title: "PurchaseOrder Date", value: "PurchaseOrder.Date" },
      {
        title: "PurchaseOrder Expected Delivery Date",
        value: "PurchaseOrder.ExpectedDeliveryDate",
      },
      { title: "PurchaseOrder Line Items", value: "PurchaseOrder.LineItems" },
      {
        title: "PurchaseOrder Total Amount",
        value: "PurchaseOrder.TotalAmount",
      },
      { title: "PurchaseOrder Content", value: "PurchaseOrder.Content" },
      { title: "PurchaseOrder Subtotal", value: "PurchaseOrder.Subtotal" },
      {
        title: "PurchaseOrder Expected Delivery Date",
        value: "PurchaseOrder.ExpectedDeliveryDate",
      },
    ],
  },
  Bill: {
    title: "Bill",
    icon: Receipt,
    menu: [
      { title: "Bill Number", value: "Bill.Number" },
      { title: "Bill Date", value: "Bill.Date" },
      { title: "Bill Line Items", value: "Bill.LineItems" },
      { title: "Bill Total Amount", value: "Bill.TotalAmount" },
      { title: "Bill Content", value: "Bill.Content" },
      { title: "Bill Subtotal", value: "Bill.Subtotal" },
    ],
  },
  ServiceProperty: {
    title: "ServiceProperty",
    icon: MapPin,
    menu: [
      { title: "ServiceProperty Name", value: "ServiceProperty.Name" },
      { title: "ServiceProperty City", value: "ServiceProperty.City" },
      { title: "ServiceProperty Address", value: "ServiceProperty.Address" },
    ],
  },
  ServiceJob: {
    title: "ServiceJob",
    icon: Wrench,
    menu: [
      { title: "ServiceJob Start Date", value: "ServiceJob.StartDate" },
      { title: "ServiceJob End Date", value: "ServiceJob.EndDate" },
      { title: "ServiceJob Total Amount", value: "ServiceJob.TotalAmount" },
      { title: "ServiceJob Line Items", value: "ServiceJob.LineItems" },
      {
        title: "ServiceJob Contact Person Name",
        value: "ServiceJob.ContactPerson.Name",
      },
      {
        title: "ServiceJob Contact Person Mobile",
        value: "ServiceJob.ContactPerson.Mobile",
      },
      {
        title: "ServiceJob Contact Person Email",
        value: "ServiceJob.ContactPerson.Email",
      },
      {
        title: "ServiceJob Contact Person Job Title",
        value: "ServiceJob.ContactPerson.JobTitle",
      },
      {
        title: "ServiceJob Contract Payment Terms Words",
        value: "ServiceJob.Contract.PaymentTermsWords",
      },
      {
        title: "ServiceJob Contract Schedule Frequency Words",
        value: "ServiceJob.Contract.ScheduleFrequencyWords",
      },
      {
        title: "ServiceJob Service Property Address",
        value: "ServiceJob.ServiceProperty.Address",
      },
      {
        title: "ServiceJob Service Property City",
        value: "ServiceJob.ServiceProperty.City",
      },
      {
        title: "ServiceJob LPO Number",
        value: "ServiceJob.LPONumber",
      },
      // {
      //   title: "ServiceJob Service Property Name",
      //   value: "ServiceJob.ServiceProperty.Name",
      // },
    ],
  },
  ServiceSchedule: {
    title: "ServiceSchedule",
    icon: Calendar,
    menu: [
      {
        title: "ServiceSchedule Start Date",
        value: "ServiceSchedule.StartDate",
      },
      { title: "ServiceSchedule End Date", value: "ServiceSchedule.EndDate" },
    ],
  },
};
