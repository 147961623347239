import { trpc } from "@/helpers/trpc";
import { formatAddress, formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { DollarSign, Printer } from "lucide-react";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import { RouterOutputs } from "@/helpers/trpc";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import FilterBar from "@/components/filters";
import { getS3URL } from "@/helpers/s3Helpers";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  getPrintPageMargins,
  PrintWrapper,
} from "@/pages/field-service/schedules/schedules-export";
import { Button } from "@heffl/ui/components/primitives/button";
import RenderHtml from "@heffl/ui/components/render-html";
import DataGrid from "@/components/dataGrid/DataGrid";

type StatementItem = RouterOutputs["clients"]["statement"]["statement"][number];

interface StatementOfAccountsProps {
  clientId: number;
}

const StatementOfAccounts: React.FC<StatementOfAccountsProps> = ({
  clientId,
}) => {
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useImmer({
    dates: {
      startDate: dayjs().startOf("month").toDate(),
      endDate: dayjs().endOf("month").toDate(),
    },
  });

  const { data: teamData } = trpc.teams.currentTeam.useQuery();
  const { data, isLoading } = trpc.clients.statement.useQuery({
    clientId,
    startDate: filters.dates.startDate,
    endDate: filters.dates.endDate,
  });

  const printStatement = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `SOA - ${data?.client?.name} - ${dayjs(
      filters.dates.startDate
    ).format("DD-MM-YYYY")} to ${dayjs(filters.dates.endDate).format(
      "DD-MM-YYYY"
    )}`,
  });

  const calculateBalance = (items: StatementItem[], index: number): number => {
    return items.slice(0, index + 1).reduce((acc, curr) => {
      if (curr.type === "invoice") {
        return (
          acc +
          calculateInvoice({
            invoiceProducts: curr.data.invoiceProducts,
            discount: curr.data.discount,
            paymentReceivedAllocations: curr.data.paymentReceivedAllocations,
          }).invoiceTotal
        );
      } else {
        return acc - curr.data.amount;
      }
    }, 0);
  };

  if (isLoading) {
    return <p className="text-gray-600">Loading...</p>;
  }

  if (!data) {
    return (
      <div className="flex flex-col justify-center items-center p-4 bg-gray-100 rounded-lg">
        <DollarSign className="mb-2 w-12 h-12 text-gray-400" />
        <h3 className="mb-1 text-lg font-semibold text-gray-700">
          No statement data
        </h3>
        <p className="text-sm text-gray-500">
          No statement data available for the selected period.
        </p>
      </div>
    );
  }

  const totalBalance =
    calculateBalance(data.statement, data.statement.length - 1) +
    (data.openingBalance || 0);
  const statementData = [
    {
      id: -1,
      type: "opening_balance",
      data: {
        id: -1,
      },
      date: dayjs(filters.dates.startDate).format("DD/MM/YYYY"),
      description: "Opening Balance",
      debit: formatCurrency(data.openingBalance || 0, "AED"),
      credit: "",
      balance: formatCurrency(data.openingBalance || 0, "AED"),
    },
    ...data.statement.map((item, index) => ({
      id: index,
      type: item.type,
      data: item.data,
      date: dayjs(item.date).format("DD/MM/YYYY"),
      description:
        item.type === "invoice"
          ? `Invoice #${item.data.number}${
              item.data.dueDate && item.data.status !== "PAID"
                ? ` (Due on ${dayjs(item.data.dueDate).format("DD/MM/YYYY")})`
                : ""
            }`
          : `Payment #${item.data.number} ${item.data.paymentReceivedAllocations
              .map((allocation) =>
                allocation.type === "INVOICE"
                  ? `against #${allocation.invoices?.number}`
                  : allocation.type === "OPENING_BALANCE"
                  ? "against Opening Balance"
                  : ""
              )
              .join("<br />")}`,
      debit:
        item.type === "invoice"
          ? formatCurrency(calculateInvoice(item.data).invoiceTotal, "AED")
          : "",
      credit:
        item.type === "payment" ? formatCurrency(item.data.amount, "AED") : "",
      balance: formatCurrency(
        calculateBalance(data.statement, index) + (data.openingBalance || 0),
        "AED"
      ),
    })),
  ];

  return (
    <>
      <div className="flex justify-between px-4 mb-12">
        <FilterBar
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              presets: [
                {
                  label: "Today",
                  value: [
                    dayjs().startOf("day").toDate(),
                    dayjs().endOf("day").toDate(),
                  ],
                },
                {
                  label: "This week",
                  value: [
                    dayjs().startOf("week").toDate(),
                    dayjs().endOf("week").toDate(),
                  ],
                },
                {
                  label: "This month",
                  value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                  ],
                },
                {
                  label: "This year",
                  value: [
                    dayjs().startOf("year").toDate(),
                    dayjs().endOf("year").toDate(),
                  ],
                },
              ],
              value: [filters.dates.startDate, filters.dates.endDate],
              onChange: (value) =>
                value &&
                setFilters({
                  dates: {
                    startDate: value[0],
                    endDate: value[1],
                  },
                }),
            },
          ]}
        />
        <Button
          size="sm"
          onClick={printStatement}
          icon={Printer}
          variant="primaryOutline"
        >
          Print statement
        </Button>
      </div>
      <div ref={printRef} className="hidden print:block">
        <PrintWrapper>
          <style>{getPrintPageMargins()}</style>
          <div className="flex justify-between items-start pb-6 border-b border-gray-200">
            <div className="flex gap-4 items-center">
              {teamData?.files?.fields?.team_logo && (
                <img
                  src={getS3URL(teamData?.files?.fields?.team_logo[0])}
                  alt="logo"
                  className="object-contain w-36"
                />
              )}
            </div>
            <div className="text-sm text-right text-gray-600 w-[350px] break-words">
              <p className="text-lg font-bold text-black">{teamData?.name}</p>
              <RenderHtml>{teamData?.address || ""}</RenderHtml>
            </div>
          </div>
          <div className="flex flex-col mt-6 mb-6 space-y-4">
            <div className="flex justify-between items-start">
              <div className="flex flex-col w-4/5">
                <p className="w-full font-medium">To</p>
                <p className="text-base font-medium">{data.client?.name}</p>
                <p className="w-44 text-xs">
                  {!!data.client.clientAddresses?.length &&
                    formatAddress(data.client?.clientAddresses[0])}
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className="text-2xl font-bold">Statement of Accounts</h1>
                <p className="text-sm font-medium text-gray-800">
                  {dayjs(filters.dates.startDate).format("DD/MM/YYYY")} To{" "}
                  {dayjs(filters.dates.endDate).format("DD/MM/YYYY")}
                </p>
                <table className="mt-4 w-80">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="p-2 text-left border border-gray-300">
                        Account Summary
                      </th>
                      <th className="p-2 text-right border border-gray-300">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2 border border-gray-300">
                        Opening Balance
                      </td>
                      <td className="p-2 text-right border border-gray-300">
                        {formatCurrency(data.openingBalance || 0, "AED")}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-gray-300">
                        Invoiced Amount
                      </td>
                      <td className="p-2 text-right border border-gray-300">
                        {formatCurrency(data.periodInvoicesTotal, "AED")}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border border-gray-300">
                        Amount Received
                      </td>
                      <td className="p-2 text-right border border-gray-300">
                        {formatCurrency(data.periodPaymentsTotal, "AED")}
                      </td>
                    </tr>
                    <tr className="font-bold">
                      <td className="p-2 border border-gray-300">
                        Balance Due
                      </td>
                      <td className="p-2 text-right border border-gray-300">
                        {formatCurrency(data.balanceDue, "AED")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <table className="w-full !text-xs border-collapse mt-4">
            <thead>
              <tr className="text-white bg-gray-800">
                <th className="p-2 text-left">Date</th>
                <th className="p-2 text-left">Description</th>
                <th className="p-2 text-right">Debit</th>
                <th className="p-2 text-right">Credit</th>
                <th className="p-2 text-right">Balance</th>
              </tr>
            </thead>
            <tbody>
              {statementData.map((item, index) => (
                <tr
                  key={index}
                  className={(index + 1) % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="p-3">{item.date}</td>
                  <td
                    className="p-3 cursor-pointer hover:underline"
                    onClick={() => {
                      if (item.type === "payment") {
                        navigate(
                          `/sales/payments/?editPaymentId=${item.data.id}`
                        );
                      } else if (item.type === "invoice") {
                        navigate(`/sales/invoices/details/${item.data.id}`);
                      }
                    }}
                  >
                    <RenderHtml>{item.description}</RenderHtml>
                  </td>
                  <td className="p-3 text-right">{item.debit}</td>
                  <td className="p-3 text-right">{item.credit}</td>
                  <td className="p-3 text-right">{item.balance}</td>
                </tr>
              ))}
              <tr className="font-bold">
                <td colSpan={4} className="p-3 text-right">
                  Total Balance Due:
                </td>
                <td className="p-3 text-right">
                  {formatCurrency(totalBalance, "AED")}
                </td>
              </tr>
            </tbody>
          </table>
        </PrintWrapper>
      </div>
      <DataGrid
        name="clientChartOfAccounts"
        label="Statement of Accounts"
        rowKey="id"
        rows={statementData}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 120,
          },
          {
            key: "description",
            name: "Description",
            width: 300,
            renderCell: ({ row }) => (
              <div
                className="cursor-pointer hover:underline"
                onClick={() => {
                  const item = data.statement[row.id];
                  if (item.type === "payment") {
                    navigate(`/sales/payments/?editPaymentId=${item.data.id}`);
                  } else if (item.type === "invoice") {
                    navigate(`/sales/invoices/details/${item.data.id}`);
                  }
                }}
              >
                {row.description}
              </div>
            ),
          },
          {
            key: "debit",
            name: "Debit",
            width: 120,
            cellClass: "text-right",
          },
          {
            key: "credit",
            name: "Credit",
            width: 120,
            cellClass: "text-right",
          },
          {
            key: "balance",
            name: "Balance",
            width: 120,
            cellClass: "text-right",
          },
        ]}
      />
      <div className="flex gap-12 justify-end mt-3">
        <p className="font-semibold">Balance due</p>
        <p className="">{formatCurrency(totalBalance, "AED", true)}</p>
      </div>
    </>
  );
};

export default StatementOfAccounts;
