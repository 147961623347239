import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import Select from "@heffl/ui/components/primitives/select";

import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import {
  findPrimaryContact,
  formatAddress,
  formatCurrency,
  formatName,
  isMobile,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Building, Link, Mail, Phone, Trash2, User } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar, { CheckboxFilterType } from "@/components/filters";
import Page from "@/components/page";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { COUNTRIES_UAE_ID } from "@/lib/dbIds";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import {
  GOOGLE_MAPS_API_KEY,
  isValidUrl,
  mapPlaceFormatter,
} from "@/pages/field-service/components/property-selector";
import {
  formatCustomFields,
  renderCustomFieldValue,
} from "@heffl/server/src/helpers/customFields";
import TagsInput from "@heffl/ui/components/TagInput";
import { Icons } from "@heffl/ui/components/icons";
import { Label } from "@heffl/ui/components/primitives/label";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { PhoneInput } from "@heffl/ui/components/primitives/phone-input";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import RadioGrid from "@heffl/ui/components/radio-grid";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import Fuse from "fuse.js";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useNavigate } from "react-router-dom";
import CustomFieldsInputForm from "../../../helpers/customFields/custom-fields-input-form";
import { ClientCard } from "../deals/details";
import { Badge } from "@heffl/ui/components/primitives/badge";
import appIcons from "@heffl/ui/components/appIcons";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import heffl from "@/helpers/heffl";

export const AddressForm = ({
  parent = "billingAddress",
}: {
  parent?: string;
}) => {
  const form = useFormContext();
  const countryId = form.watch("billingAddress.countryId") as
    | number
    | null
    | undefined;

  const { data: countries } = trpc.countries.list.useQuery();

  const googleMapsToPlaceIdMutation =
    trpc.fieldService.properties.googleMapsToPlaceId.useMutation();

  const selectedCountry = countries?.find(
    (country) => country.id === countryId
  );

  const onPlaceSelect = async (placeId: string, link?: string) => {
    const result = await geocodeByPlaceId(placeId);
    const formattedAddress = mapPlaceFormatter(result[0]);
    if (selectedCountry) {
      const fuse = new Fuse(selectedCountry.countryStates, { keys: ["name"] });
      const result = fuse.search(formattedAddress.state);
      const stateId = result.length > 0 ? result[0].item.id : undefined;
      if (stateId) {
        form.setValue("billingAddress.stateId", stateId);
      }
    }

    form.setValue("billingAddress.address", formattedAddress.address);
    form.setValue("billingAddress.city", formattedAddress.city);
    form.setValue("billingAddress.latitude", formattedAddress.latitude);
    form.setValue("billingAddress.longitude", formattedAddress.longitude);
    form.setValue("billingAddress.googleMapsPlaceId", formattedAddress.placeId);
    form.setValue("billingAddress.googleMapsLink", link);
  };

  const _name = (fieldName: string) => `${parent}.${fieldName}`;

  return (
    <div className="space-y-3">
      <div className="flex gap-2">
        <div className="relative w-full">
          <Icons.google className="absolute top-1.5 left-1.5 z-50 w-6 h-6" />
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_MAPS_API_KEY}
            selectProps={{
              styles: {
                input: (provided) => ({
                  ...provided,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  paddingLeft: 35,
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "6px",
                  border: "1px solid #E2E8F0",
                }),
              },
              placeholder: "Search google maps...",
              onChange: (e) => e && onPlaceSelect(e.value?.place_id),
            }}
            apiOptions={{
              language: "en",
              region: "ae",
            }}
          />
        </div>
        <Button
          loading={googleMapsToPlaceIdMutation.isLoading}
          onClick={async () => {
            try {
              const mapsUrl = await navigator.clipboard.readText();
              if (!isValidUrl(mapsUrl)) {
                return toast.error("Invalid google maps link");
              }
              const mapResponse = await googleMapsToPlaceIdMutation.mutateAsync(
                mapsUrl
              );
              if (mapResponse) onPlaceSelect(mapResponse.placeId, mapsUrl);
              else toast.error("Failed, check your maps link");
            } catch (err) {
              console.error("Failed to read clipboard contents: ", err);
            }
          }}
          size="icon"
          variant="primaryOutline"
        >
          <Link className="w-5 h-5" />
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <FormField name={_name("landmark")} label="Apt / Unit / Floor">
          <Input placeholder="Apt / Unit / Floor" />
        </FormField>
        <FormField name={_name("city")} label="City">
          <Input placeholder="City" />
        </FormField>
      </div>
      <FormField name={_name("address")} label="Address">
        <Textarea placeholder="Address" />
      </FormField>
      <div className="grid grid-cols-2 gap-2">
        <FormField name={_name("countryId")} label="Country">
          <Select
            options={
              countries?.map((country) => ({
                label: country.name,
                value: country.id,
              })) || []
            }
            allowClear={false}
          />
        </FormField>
        <FormField name={_name("stateId")} label="State">
          <Select
            options={
              countries
                ?.find((c) => c.id === countryId)
                ?.countryStates.map((state) => ({
                  label: state.name,
                  value: state.id,
                })) || []
            }
            allowClear={false}
          />
        </FormField>
      </div>
    </div>
  );
};

export const ContactForm = ({ name }: { name: string }) => {
  const _name = (fieldName: string) => `${name}.${fieldName}`;
  return (
    <>
      <div className="flex gap-2">
        {/* <FormField name={_name("salutation")} className="w-2/3">
          <Input placeholder="Salutation" />
        </FormField> */}
        <FormField name={_name("firstName")}>
          <Input placeholder="First name" />
        </FormField>
        <FormField name={_name("lastName")}>
          <Input placeholder="Last name" />
        </FormField>
      </div>
      <div className="grid gap-2 sm:grid-cols-2">
        <FormField name={_name("mobile")}>
          <PhoneInput placeholder="Mobile" defaultCountry="AE" />
        </FormField>
        <FormField name={_name("email")}>
          <Input placeholder="Email" prefix={<Mail className="h-5" />} />
        </FormField>
      </div>
    </>
  );
};

const ClientForm = () => {
  const navigate = useNavigate();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "CLIENT",
  });

  const { data: crmSources } = trpc.sources.list.useQuery();

  return (
    <>
      <FormField name="type" label="Type">
        <RadioGrid
          items={[
            {
              id: "BUSINESS",
              value: "BUSINESS",
              label: "Business",
              Icon: Building,
              description: "Companies, Family Trusts, Nonprofits",
            },
            {
              id: "INDIVIDUAL",
              value: "INDIVIDUAL",
              label: "Individual",
              Icon: User,
              description: "Individuals, Families and Sole Traders",
            },
          ]}
          cols={2}
        />
      </FormField>
      <FormField name="name" label="Name">
        <Input placeholder="Client name" />
      </FormField>
      <div className="flex gap-2 items-center">
        <FormField name="clientTags" label="Tags" className="w-2/3">
          <MultiSelect
            placeholder="Add tags"
            options={
              tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) || []
            }
          />
        </FormField>
        <FormField name="crmSourceId" label="Source" className="w-1/3">
          <Select
            createButton={{
              label: "Add new",
              onClick: () => navigate("/settings/sources"),
            }}
            placeholder="Select source"
            options={
              crmSources?.map((source) => ({
                label: source.name,
                value: source.id,
              })) || []
            }
          />
        </FormField>
      </div>
      <Label>
        Contact{" "}
        <Badge variant="success" small>
          Primary
        </Badge>
      </Label>
      <ContactForm name="primaryContact" />
      <StripeTabs
        className="px-1 w-full"
        items={[
          {
            key: "other-details",
            label: "Other details",
            children: (
              <div className="flex flex-col gap-3">
                <FormField name="openingBalance" label="Opening balance">
                  <Input placeholder="Opening balance" />
                </FormField>
                <FormField name="taxNumber" label="TRN number">
                  <Input placeholder="TRN number" />
                </FormField>
                <FormField name="website" label="Website">
                  <Input placeholder="Website" />
                </FormField>
              </div>
            ),
          },
          {
            key: "billing-address",
            label: "Billing Address",
            children: <AddressForm />,
          },
        ]}
      />
      <CustomFieldsInputForm section="CLIENT" />
    </>
  );
};

export const AddClientDrawer = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: (id: number | undefined) => void;
  defaultValues?: Partial<z.infer<typeof Schemas.crm.client>>;
}) => {
  const clientAddMutation = trpc.clients.add.useMutation({
    onSuccess(newClient) {
      toast.success("Succesfully added client.");
      form.reset();
      onClose(newClient.id);
    },
    onError(er) {
      toast.error(er.message);
    },
  });

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "CLIENT",
    }
  );

  const form = useForm<z.infer<typeof Schemas.crm.client>>({
    resolver: zodResolver(Schemas.crm.client),
    defaultValues: {
      type: "BUSINESS",
      billingAddress: {
        type: "BILLING",
        countryId: COUNTRIES_UAE_ID,
      },
      ...defaultValues,
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.client>) => {
    const { isValid } = validateCustomFields({
      customFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    clientAddMutation.mutate(values);
  };

  return (
    <ModalDrawer
      closeOnOverlayClick={false}
      className="max-w-xl"
      open={open}
      onClose={() => {
        form.reset();
        onClose(undefined);
      }}
      title="Add Client"
      footer={
        <Button
          loading={clientAddMutation.isLoading}
          className="w-full"
          type="submit"
          variant="primary"
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
          size="md"
        >
          Add client
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ClientForm />
      </Form>
    </ModalDrawer>
  );
};

export const EditClientDrawer = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "CLIENT",
    }
  );

  const { data: client } = trpc.clients.details.useQuery(id, {
    refetchOnWindowFocus: false,
  });

  const clientDeleteMutation = trpc.clients.delete.useMutation({
    onSuccess() {
      navigate("/sales/clients");
      toast.success("Succesfully deleted client.");
      onClose();
    },
    onError(er) {
      toast.error(
        er.message || "Delete all associated resources of this client first."
      );
    },
  });
  const clientUpdateMutation = trpc.clients.update.useMutation({
    onSuccess() {
      toast.success("Succesfully updated client.");
      onClose();
    },
    onError(er) {
      toast.error(er.message);
    },
  });
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.crm.client>>({
    resolver: zodResolver(Schemas.crm.client),
  });

  useEffect(() => {
    if (client) {
      form.reset({
        ...client,
        primaryContact: client.contacts.find((contact) => contact.isPrimary),
        clientTags: client.clientTags.map((tag) => tag.tagId),
        billingAddress: client.clientAddresses.find(
          (address) => address.type === "BILLING"
        ) || { countryId: COUNTRIES_UAE_ID, type: "BILLING" },
      });
    }
  }, [client, form]);

  const onSubmit = (values: z.infer<typeof Schemas.crm.client>) => {
    const { isValid } = validateCustomFields({
      customFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    clientUpdateMutation.mutate({
      id,
      client: values,
    });
  };

  return (
    <ModalDrawer
      closeOnOverlayClick={false}
      className="max-w-xl"
      open={true}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Edit client"
      footer={
        <div className="flex gap-2 mt-2 w-full">
          <Button
            loading={clientDeleteMutation.isLoading}
            variant="destructive"
            onClick={async () => {
              const confirmed = await confirm(
                "Are you sure you want to delete this client?"
              );
              if (confirmed) {
                clientDeleteMutation.mutate(id);
              }
            }}
            icon={Trash2}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              form.handleSubmit(onSubmit)();
            }}
            loading={clientUpdateMutation.isLoading}
            variant="primary"
            className="w-full"
            size="md"
          >
            Update
          </Button>
        </div>
      }
    >
      {!client ? (
        <FullScreenSpinner />
      ) : (
        <Form {...form} onSubmit={onSubmit}>
          <ClientForm />
        </Form>
      )}
    </ModalDrawer>
  );
};

type CustomFieldFilter = {
  openingBalance?: {
    min: number | undefined;
    max: number | undefined;
  };
  search: string;
  pageNo: number;
  pageSize: number;
  dates: [Date, Date] | undefined;
  customFields: {
    [key: string]: (string | number)[];
  };
  createdBy: number[];
};

const Clients = () => {
  const [editClient, setEditClient] = useState<undefined | number>(undefined);
  const [addClient, setAddClient] = useState(false);
  const [filters, setFilters] = useParamsState<CustomFieldFilter>({
    search: "",
    pageNo: 1,
    pageSize: 20,
    dates: undefined,
    customFields: {},
    openingBalance: undefined,
    createdBy: [],
  });

  const navigate = useNavigate();
  const permissions = usePermissions();

  const { data, isLoading: isLoadingCompanies } = trpc.clients.list.useQuery({
    pageNo: filters.pageNo,
    pageSize: filters.pageSize,
    search: filters.search,
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
    openingBalance: filters.openingBalance,
    customFields: filters.customFields,
    createdBy: filters.createdBy,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "CLIENT",
  });
  const { data: users } = trpc.users.list.useQuery();

  const { data: clientCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "CLIENT",
    });

  const updateClientMutation = trpc.clients.update.useMutation({
    onSuccess() {
      toast.success("Succesfully updated client.");
    },
    onError(er) {
      toast.error(er.message);
    },
  });

  if (!tags || !permissions) return <FullScreenSpinner />;
  if (!permissions.VIEW_CLIENTS.allowed) {
    return <NoPermissionScreen />;
  }

  const customFieldFilters: CheckboxFilterType[] =
    clientCustomFields
      ?.filter((field) => field.dataType === "SINGLE_OPTION")
      .map((field) => ({
        multiple: false,
        type: "checkbox",
        key: field.name,
        label: field.label,
        value: filters.customFields[field.name] || [],
        onChange: (value) =>
          setFilters({
            customFields: {
              ...filters.customFields,
              [field.name]: value,
            },
          }),
        options: field.values.map((o) => ({
          label: o,
          value: o,
        })),
      })) || [];

  return (
    <Page title="Clients" fullWidth className="sm:!p-0 ">
      {!!editClient && (
        <EditClientDrawer
          id={editClient}
          onClose={() => setEditClient(undefined)}
        />
      )}
      <AddClientDrawer open={addClient} onClose={() => setAddClient(false)} />
      <div className="flex flex-row justify-between border-gray-200 sm:p-3 sm:border-b">
        <SearchInput
          value={filters.search}
          onChange={(v) =>
            setFilters({
              search: v,
            })
          }
          placeholder="Search clients..."
        />

        {permissions.CREATE_CLIENTS.allowed && (
          <ResponsiveActionButton
            onClick={() => setAddClient(true)}
            text="Client"
          />
        )}
      </div>
      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        className="py-3 sm:p-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Created at",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
          {
            key: "createdBy",
            type: "checkbox",
            label: "Created by",
            value: filters.createdBy,
            onChange: (value) => setFilters({ createdBy: value as number[] }),
            options: users?.map((user) => ({
              label: heffl.format.name(user),
              value: user.id,
            })),
          },
          {
            key: "openingBalance",
            type: "number-range",
            label: "Opening balance",
            value: filters.openingBalance,
            onChange: (value) =>
              setFilters({
                openingBalance: value,
              }),
          },
          ...customFieldFilters,
        ]}
      />
      {data ? (
        isMobile() ? (
          <div className="flex flex-col gap-2">
            {data.clients.map((company) => (
              <ClientCard key={company.id} company={company} />
            ))}
          </div>
        ) : (
          <div>
            <DataGrid
              allowExport={permissions.EXPORT_CLIENTS.allowed}
              rowKey="id"
              name="clientsListMain"
              className="h-[calc(100vh-120px-var(--header-height))]"
              label="Clients"
              loading={isLoadingCompanies}
              rows={data?.clients || []}
              columns={[
                {
                  key: "id",
                  name: "#",
                  width: 80,
                  renderCell: ({ row }) => `#${row.number}`,
                },
                {
                  key: "name",
                  name: "Client",
                  width: 200,
                  renderCell: ({ row }) => {
                    return <p>{row.name}</p>;
                  },
                },
                {
                  key: "contactPersonName",
                  name: "Person",
                  width: 160,
                  renderCell: ({ row }) => {
                    const primaryContact = findPrimaryContact(row.contacts);
                    return primaryContact ? (
                      <Badge variant="outline" icon={appIcons.common.user.icon}>
                        {formatName(primaryContact)}
                      </Badge>
                    ) : (
                      ""
                    );
                  },
                },
                {
                  key: "contactPersonMobile",
                  name: "Mobile",
                  width: 160,
                  renderCell: ({ row }) => {
                    const primaryContact = findPrimaryContact(row.contacts);
                    return primaryContact?.mobile ? (
                      <Badge
                        variant="outline"
                        icon={Phone}
                        iconClassName="!text-green-600"
                      >
                        {primaryContact.mobile}
                      </Badge>
                    ) : (
                      ""
                    );
                  },
                },
                {
                  key: "contactPersonEmail",
                  name: "Email",
                  width: 160,
                  renderCell: ({ row }) => {
                    const primaryContact = findPrimaryContact(row.contacts);
                    return primaryContact?.email ? (
                      <Badge
                        variant="outline"
                        icon={Mail}
                        iconClassName="!text-red-600"
                      >
                        {primaryContact.email}
                      </Badge>
                    ) : (
                      ""
                    );
                  },
                },
                {
                  key: "address",
                  name: "Address",
                  width: 180,
                  renderCell: ({ row }) => {
                    const address = row.clientAddresses.find(
                      (address) => address.type === "BILLING"
                    );
                    return (
                      <div className="flex gap-2 items-center py-1">
                        <p className="text-sm line-clamp-2">
                          {address ? formatAddress(address) : "No address"}
                        </p>
                      </div>
                    );
                  },
                },
                {
                  key: "unUsedCredits",
                  name: "Unused credits",
                  width: 160,
                  renderCell: ({ row }) =>
                    formatCurrency(row.unUsedCredits, "AED"),
                },
                {
                  key: "openingBalance",
                  name: "Opening balance",
                  width: 160,
                  renderCell: ({ row }) => Number(row.openingBalance),
                },
                {
                  key: "tags",
                  name: "Tags",
                  width: 100,
                  renderCell: ({ row }) => (
                    <TagsInput
                      value={row.clientTags.map((tag) => tag.tagId)}
                      onChange={(tags) => {
                        updateClientMutation.mutate({
                          id: row.id,
                          client: { clientTags: tags },
                        });
                      }}
                      tags={tags}
                    />
                  ),
                },
                ...(clientCustomFields ?? []).map((field) => ({
                  key: field.name,
                  name: field.label,
                  // @ts-ignore
                  renderCell: ({ row }) =>
                    renderCustomFieldValue(
                      field,
                      formatCustomFields({
                        customFields: row.customFields,
                        customFieldFields: clientCustomFields || [],
                      })[field.name]
                    ),
                })),
              ]}
              onCellClick={(client) => navigate(`details/${client.row.id}`)}
              empty={{
                icon: Building,
                title: "No clients",
                description: "Please add some clients to get started.",
                actionText: "Add your first client",
                onAction: () => setAddClient(true),
                buttonSize: "sm",
                hideAction: !permissions.CREATE_CLIENTS.allowed,
              }}
              pagination={{
                pageNo: filters.pageNo,
                count: data.count,
                pageSize: filters.pageSize,
                setPageNo: (pageNo) => setFilters({ pageNo }),
              }}
            />
          </div>
        )
      ) : (
        <FullScreenSpinner />
      )}
    </Page>
  );
};

export default Clients;
