import DataTable from "@/components/DataTable";
import { UserInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import Schemas from "@heffl/server/src/schemas";
import appIcons from "@heffl/ui/components/appIcons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import Select from "@heffl/ui/components/primitives/select";
import { cn, formatName } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { Goal, Plus, ThumbsUp } from "lucide-react";
import { useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const goalMeasures = {
  FIELD_SERVICE_JOB: [
    {
      icon: Plus,
      label: "Added",
      value: "ADDED",
    },
  ],
  CRM_DEAL: [
    // {
    //   icon: ThumbsUp,
    //   label: "Deal Won",
    //   value: "WON",
    // },
    {
      icon: ThumbsUp,
      label: "Quotation Accepted",
      value: "QUOTATION_ACCEPTED",
    },
  ],
  QUOTATION: [
    {
      icon: ThumbsUp,
      label: "Quotation Accepted",
      value: "QUOTATION_ACCEPTED",
    },
  ],
};

export const SalesGoalForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.salesGoal>, unknown>;
  edit?: boolean;
}) => {
  const measures = form.watch("type");

  const { data: users } = trpc.users.list.useQuery();

  const startDate = form.watch("startDate");
  const targetFor = form.watch("targetFor");

  if (!users) return <FullScreenSpinner />;

  return (
    <div className="space-y-2">
      <FormField name="title" label="Title">
        <Input placeholder="Enter title for the goal" />
      </FormField>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <FormField name="type" label="Type">
          <Select
            options={[
              {
                label: "Field Service Job",
                value: "FIELD_SERVICE_JOB",
                icon: appIcons.fieldService.job.icon,
              },
              {
                label: "CRM Deal",
                value: "CRM_DEAL",
                icon: appIcons.sales.deals.icon,
              },
              {
                label: "Quotation",
                value: "QUOTATION",
                icon: appIcons.sales.quotes.icon,
              },
            ]}
            onChange={() => {
              // @ts-ignore
              form.setValue("measure", null);
            }}
          />
        </FormField>
        <FormField name="measure" label="Measure">
          {/* @ts-ignore */}
          <Select
            options={goalMeasures[measures]}
            placeholder="Select goal measure"
          />
        </FormField>
      </div>

      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <FormField name="startDate" label="Start Date">
          <DatePicker />
        </FormField>
        <FormField name="endDate" label="End Date">
          <DatePicker
            placeholder="No end date"
            fromDate={startDate}
            allowClear
            presets={[
              {
                label: "1 week",
                value: dayjs(startDate).add(1, "week").toDate(),
              },
              {
                label: "1 month",
                value: dayjs(startDate).add(1, "month").toDate(),
              },
              {
                label: "2 months",
                value: dayjs(startDate).add(2, "months").toDate(),
              },
              {
                label: "3 months",
                value: dayjs(startDate).add(3, "months").toDate(),
              },
            ]}
          />
        </FormField>
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <FormField name="frequency" label="Frequency">
          <Select
            options={[
              { label: "Weekly", value: "WEEKLY" },
              { label: "Monthly", value: "MONTHLY" },
            ]}
          />
        </FormField>
        <FormField name="targetFor" label="Target For">
          <Select
            placeholder="Select target for"
            options={[
              { label: "User", value: "USER" },
              { label: "Team", value: "TEAM" },
            ]}
            onChange={(value) => {
              if (value === "TEAM") {
                form.setValue("userId", null);
              }
            }}
          />
        </FormField>
        {targetFor === "USER" && (
          <UserInput
            className="w-full"
            name="userId"
            label="User"
            type={["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"]}
          />
        )}
        <FormField
          name="goal"
          label="Goal"
          className={cn(targetFor === "USER" ? "w-full" : "sm:col-span-2")}
        >
          <Input type="number" placeholder="Enter the goal" prefix="AED" />
        </FormField>
        {/* <FormField name="trackingMetric" label="Tracking Metric">
          <Select
            options={[
              // { label: "Count", value: "COUNT" },
              { label: "Value", value: "VALUE" },
            ]}
          />
        </FormField> */}
      </div>
    </div>
  );
};

const AddSalesGoal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.salesGoal>>({
    resolver: zodResolver(Schemas.crm.salesGoal),
    defaultValues: {
      type: "FIELD_SERVICE_JOB",
      trackingMetric: "VALUE",
      frequency: "WEEKLY",
      startDate: new Date(),
    },
  });

  const addSalesGoal = trpc.salesGoals.add.useMutation({
    onSuccess: () => {
      toast.success("Sales goal added successfully");
      onClose();
      form.reset();
    },
  });

  const onSubmit = (data: z.infer<typeof Schemas.crm.salesGoal>) => {
    addSalesGoal.mutate(data);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Add Sales Goal"
      footer={
        <Button
          type="submit"
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          icon={Plus}
          loading={addSalesGoal.isLoading}
          className="w-full"
        >
          Add Sales Goal
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit} id="sales-goal-form">
        <SalesGoalForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

const GoalsList = () => {
  const team = useTeam();

  const [open, setOpen] = useState(false);

  const { data: salesGoals } = trpc.salesGoals.list.useQuery();

  const navigate = useNavigate();

  if (!salesGoals || !team) return <FullScreenSpinner />;

  return (
    <Page title="Sales Goals" fullWidth>
      {team.user.permissions.CREATE_SALES_GOALS.allowed && (
        <div className="flex justify-end">
          <Button variant="primary" icon={Plus} onClick={() => setOpen(true)}>
            Sales goal
          </Button>
        </div>
      )}
      <AddSalesGoal open={open} onClose={() => setOpen(false)} />
      <DataTable
        className="mt-4"
        onRowClick={(row) => navigate(`/crm/goals/details/${row.id}`)}
        mobileColumns={[0, 1, 3]}
        empty={{
          icon: Goal,
          title: "No sales goals found",
          description:
            "Add your first sales goal to start tracking performance",
        }}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
          },
          {
            title: "Target For",
            render: (row) => {
              return (
                <div>
                  {row.targetFor === "USER"
                    ? row.users && formatName(row.users)
                    : "Team"}
                </div>
              );
            },
          },
          {
            title: "Type",
            dataIndex: "type",
            render: (row) => {
              return <div>{capitalize(row.type.split("_").join(" "))}</div>;
            },
          },
          {
            title: "Measure",
            dataIndex: "measure",
            render: (row) => {
              return <div>{capitalize(row.measure.split("_").join(" "))}</div>;
            },
          },
          {
            title: "Frequency",
            dataIndex: "frequency",
            render: (row) => {
              return <div>{capitalize(row.frequency)}</div>;
            },
          },
          {
            title: "Start Date",
            dataIndex: "startDate",
            render: (row) => {
              return <div>{dayjs(row.startDate).format("DD MMM YYYY")}</div>;
            },
          },
          {
            title: "End Date",
            dataIndex: "endDate",
            render: (row) => {
              return (
                <div>
                  {row.endDate
                    ? dayjs(row.endDate).format("DD MMM YYYY")
                    : "No End Date"}
                </div>
              );
            },
          },
        ]}
        data={salesGoals || []}
        rowKey="id"
      />
    </Page>
  );
};

export default GoalsList;
