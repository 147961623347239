import DataTable from "@/components/DataTable";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { capitalize } from "lodash";
import { Pencil, Save } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const moduleNumberSchema = teamSettingsSchema.pick({
  moduleNumbers: true,
});

const ModuleNumbers = () => {
  const form = useForm<z.infer<typeof moduleNumberSchema>>({
    resolver: zodResolver(moduleNumberSchema),
  });

  const [showEdit, setShowEdit] = useState<string | null>(null);

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const updateTeamSettings = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Module numbers updated successfully");
      setShowEdit(null);
      form.reset();
    },
  });

  const onUpdate = async (values: z.infer<typeof moduleNumberSchema>) => {
    updateTeamSettings.mutate(values);
  };

  return (
    <Page
      title="Module numbers"
      description="Set module starting numbers and prefixes."
    >
      {showEdit && (
        <ModalDrawer
          title={`Edit ${capitalize(showEdit)} `}
          open={!!showEdit}
          onClose={() => setShowEdit(null)}
          footer={
            <Button
              loading={form.formState.isSubmitting}
              type="submit"
              onClick={() => form.handleSubmit(onUpdate)()}
              icon={Save}
              variant="primary"
            >
              Save
            </Button>
          }
        >
          <Form {...form} onSubmit={onUpdate}>
            <FormField name={`moduleNumbers.${showEdit}.prefix`} label="Prefix">
              <Input />
            </FormField>
            <FormField
              name={`moduleNumbers.${showEdit}.startingNumber`}
              label="Next number"
            >
              <Input />
            </FormField>
          </Form>
        </ModalDrawer>
      )}
      <DataTable
        rowKey="module"
        loading={teamDetails === undefined}
        columns={[
          {
            title: "Module",
            dataIndex: "label",
          },
          {
            title: "Prefix",
            dataIndex: "prefix",
          },
          {
            title: "Next number",
            dataIndex: "startingNumber",
          },
          {
            title: "Preview",
            dataIndex: "preview",
          },
          {
            title: "Actions",
            render(row) {
              return (
                <Button
                  size="sm"
                  icon={Pencil}
                  onClick={() => {
                    setShowEdit(row.module);
                    form.reset({
                      moduleNumbers: teamDetails?.teamSettings.moduleNumbers,
                    });
                  }}
                >
                  Edit
                </Button>
              );
            },
          },
        ]}
        data={[
          {
            label: "Invoice",
            module: "invoice",
            startingNumber:
              teamDetails?.teamSettings.moduleNumbers.invoice.startingNumber,
            prefix: teamDetails?.teamSettings.moduleNumbers.invoice.prefix,
            preview: `${teamDetails?.teamSettings.moduleNumbers.invoice.prefix}${teamDetails?.teamSettings.moduleNumbers.invoice.startingNumber}`,
          },
          {
            label: "Proforma invoice",
            module: "proformaInvoice",
            startingNumber:
              teamDetails?.teamSettings.moduleNumbers.proformaInvoice
                .startingNumber,
            prefix:
              teamDetails?.teamSettings.moduleNumbers.proformaInvoice.prefix,
            preview: `${teamDetails?.teamSettings.moduleNumbers.proformaInvoice.prefix}${teamDetails?.teamSettings.moduleNumbers.proformaInvoice.startingNumber}`,
          },
          {
            label: "Payment",
            module: "payment",
            startingNumber:
              teamDetails?.teamSettings.moduleNumbers.payment.startingNumber,
            prefix: teamDetails?.teamSettings.moduleNumbers.payment.prefix,
            preview: `${teamDetails?.teamSettings.moduleNumbers.payment.prefix}${teamDetails?.teamSettings.moduleNumbers.payment.startingNumber}`,
          },
          {
            label: "Quotation",
            module: "quotation",
            startingNumber:
              teamDetails?.teamSettings.moduleNumbers.quotation.startingNumber,
            prefix: teamDetails?.teamSettings.moduleNumbers.quotation.prefix,
            preview: `${teamDetails?.teamSettings.moduleNumbers.quotation.prefix}${teamDetails?.teamSettings.moduleNumbers.quotation.startingNumber}`,
          },
          {
            label: "Purchase order",
            module: "purchaseOrder",
            startingNumber:
              teamDetails?.teamSettings.moduleNumbers.purchaseOrder
                .startingNumber,
            prefix:
              teamDetails?.teamSettings.moduleNumbers.purchaseOrder.prefix,
            preview: `${teamDetails?.teamSettings.moduleNumbers.purchaseOrder.prefix}${teamDetails?.teamSettings.moduleNumbers.purchaseOrder.startingNumber}`,
          },
          {
            label: "Client",
            module: "client",
            startingNumber:
              teamDetails?.teamSettings.moduleNumbers.client.startingNumber,
            prefix: teamDetails?.teamSettings.moduleNumbers.client.prefix,
            preview: `${teamDetails?.teamSettings.moduleNumbers.client.prefix}${teamDetails?.teamSettings.moduleNumbers.client.startingNumber}`,
          },
        ]}
      />
    </Page>
  );
};

export default ModuleNumbers;
