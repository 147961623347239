import { z } from "zod";

export const email = z.object({
  to: z
    .array(z.string().email("Invalid email address"))
    .min(1, "Email is required"),
  cc: z.array(z.string().email("Invalid email address")).optional().default([]),
  bcc: z
    .array(z.string().email("Invalid email address"))
    .optional()
    .default([]),
  subject: z.string().min(1, "Subject is required"),
  html: z.string().min(1, "Email body is required"),
  ctx: z.object({
    teamId: z.number(),
    userId: z.number(),
  }),
});
