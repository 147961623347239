/* eslint-disable @typescript-eslint/no-explicit-any */
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus, Tally2, TrashIcon } from "lucide-react";
import { nanoid } from "nanoid";
import { useEffect, useRef } from "react";
import { UseFieldArrayRemove, UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import Schemas from "@heffl/server/src/schemas";
import { cn } from "@heffl/ui/lib/utils";

export const convertToPlural = (singular: string): string => {
  if (!singular) return "";

  // Common irregular plurals
  const irregulars: { [key: string]: string } = {
    child: "children",
    person: "people",
    man: "men",
    woman: "women",
    tooth: "teeth",
    foot: "feet",
    mouse: "mice",
    goose: "geese",
  };

  if (irregulars[singular.toLowerCase()]) {
    return irregulars[singular.toLowerCase()];
  }

  // Rules for regular plurals
  if (
    singular.endsWith("y") &&
    !["ay", "ey", "iy", "oy", "uy"].some((ending) => singular.endsWith(ending))
  ) {
    return singular.slice(0, -1) + "ies";
  } else if (
    singular.endsWith("s") ||
    singular.endsWith("x") ||
    singular.endsWith("z") ||
    singular.endsWith("ch") ||
    singular.endsWith("sh")
  ) {
    return singular + "es";
  } else {
    return singular + "s";
  }
};

export const pipelineSchema = Schemas.project.projectPipeline.extend({
  projectPipelineStages: Schemas.project.projectPipelineStage
    .extend({
      id: z.union([z.string(), z.number()]),
    })
    .array(),
});
export const DraggableBoard = ({
  _name,
  index,
  form,
  remove,
}: {
  remove: UseFieldArrayRemove;
  index: number;
  _name: (index: number, fieldName: string) => string;
  form: UseFormReturn<z.infer<typeof pipelineSchema>, unknown>;
}) => {
  // @ts-ignore
  const stageId = form.watch(_name(index, "id"));
  const newProjectStages = form.watch("projectPipelineStages");
  const { attributes, listeners, setNodeRef, transform, transition, isOver } =
    useSortable({ id: String(stageId) });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // @ts-ignore
  const boardName: string = form.watch(_name(index, "name")) as string;
  // @ts-ignore
  const stageType = form.watch(_name(index, "stageType")) as string;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`min-w-[250px] h-full  flex flex-col px-4 rounded-lg py-3 bg-gray-100 relative ${
        isOver && "bg-gray-200"
      }`}
      ref={(node) => {
        setNodeRef(node);
        //@ts-ignore
        ref.current = node;
      }}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="flex flex-row justify-between px-1 py-3 border-b border-gray-300">
        <p className="text-sm font-semibold">{boardName}</p>
        {/* header */}
        <div className="flex flex-row">
          <Tally2 className="h-4" />
        </div>
        {/* header */}
      </div>
      <div className="flex flex-col gap-3 py-4">
        <FormField name={_name(index, "name")} label="Name">
          <Input />
        </FormField>
        <FormField name={_name(index, "stageType")} label="Stage type">
          <Select
            onChange={(value) => {
              if (value === "CLOSED") {
                //@ts-ignore
                form.setValue(_name(index, "closedStageType"), "WON");
              } else {
                //@ts-ignore
                form.setValue(_name(index, "closedStageType"), null);
              }
            }}
            options={["OPEN", "CLOSED"].map((type) => ({
              label: type,
              value: type,
            }))}
          />
        </FormField>
        {stageType === "CLOSED" && (
          <FormField
            name={_name(index, "closedStageType")}
            label="Closed state"
          >
            <Select
              options={["WON", "LOST"].map((type) => ({
                label: type,
                value: type,
              }))}
              render={({ label }) => (
                <p
                  className={cn(
                    "text-sm font-medium",
                    label === "WON" ? "text-green-500" : "text-red-500"
                  )}
                >
                  {label}
                </p>
              )}
            />
          </FormField>
        )}
        <Button
          className="bg-white"
          onClick={() => remove(index)}
          variant="destructiveOutline"
          disabled={newProjectStages.length < 2}
        >
          <TrashIcon className="mr-2 w-4" />
          Delete stage
        </Button>
      </div>
    </div>
  );
};

export const ProjectPipelineForm = ({
  form,
  edit = false,
}: {
  edit?: boolean;
  form: UseFormReturn<z.infer<typeof pipelineSchema>>;
}) => {
  const boardContainerRef = useRef<HTMLDivElement>(null);

  const singularName = form.watch("singularName");

  useEffect(() => {
    if (singularName && !edit) {
      form.setValue("pluralName", convertToPlural(singularName));
    }
  }, [singularName]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  const projectStageNew = form.watch("projectPipelineStages") || [];

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (String(active.id) !== String(over.id)) {
      const oldPipelines = form.getValues().projectPipelineStages;
      if (!oldPipelines) return;
      const oldIndex = oldPipelines.findIndex(
        (item) => String(item.id) === String(active.id)
      );
      const newIndex = oldPipelines.findIndex(
        (item) => String(item.id) === String(over.id)
      );
      const updatedPipelins = arrayMove(oldPipelines, oldIndex, newIndex);
      form.setValue("projectPipelineStages", updatedPipelins);
    }
  };

  return (
    <div>
      <div className="flex gap-3 mt-3">
        <FormField name="name" className="w-60" label="Pipeline name">
          <Input placeholder="eg. Product complaints" />
        </FormField>
        <FormField name="singularName" className="w-40" label="Singular name">
          <Input placeholder="eg. Complaint" />
        </FormField>
        <FormField name="pluralName" className="w-40" label="Plural name">
          <Input placeholder="eg. Complaints" />
        </FormField>
      </div>

      <div className="flex gap-6 mt-4">
        <p className="text-base font-medium">Pipeline stages</p>
        <Button
          size="sm"
          onClick={() => {
            const currentStages = form.getValues("projectPipelineStages");
            form.setValue("projectPipelineStages", [
              ...currentStages,
              {
                id: nanoid(),
                name: "New stage",
                position: 0,
                stageType: "OPEN",
                closedStageType: null,
              },
            ]);
            setTimeout(() => {
              if (boardContainerRef.current) {
                boardContainerRef.current.scrollTo({
                  left: boardContainerRef.current.scrollWidth,
                  behavior: "smooth",
                });
              }
            }, 100);
          }}
          variant="primaryOutline"
          icon={Plus}
        >
          Add stage
        </Button>
      </div>

      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={rectIntersection}
      >
        <SortableContext
          items={projectStageNew.map((s) => String(s.id))}
          strategy={horizontalListSortingStrategy}
        >
          <FormList name="projectPipelineStages">
            {({ fields, _name, remove }) => {
              return (
                <div
                  ref={boardContainerRef}
                  className="flex overflow-x-auto flex-row gap-4 p-1 py-3 w-full rounded-lg"
                >
                  {fields.map((field, index) => (
                    <DraggableBoard
                      key={field.id}
                      index={index}
                      _name={_name}
                      form={form}
                      remove={remove}
                    />
                  ))}
                </div>
              );
            }}
          </FormList>
        </SortableContext>
      </DndContext>
    </div>
  );
};

const ProjectPipelineAdd = () => {
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();

  const form = useForm<z.infer<typeof pipelineSchema>>({
    resolver: zodResolver(pipelineSchema),
    defaultValues: {
      projectPipelineStages: [
        {
          id: nanoid(),
          name: "Planning",
          position: 1,
          stageType: "OPEN",
          closedStageType: null,
        },
        {
          id: nanoid(),
          name: "Implementation",
          position: 1,
          stageType: "OPEN",
          closedStageType: null,
        },
        {
          id: nanoid(),
          name: "Review",
          position: 3,
          stageType: "OPEN",
          closedStageType: null,
        },
        {
          id: nanoid(),
          name: "Closing",
          position: 4,
          stageType: "CLOSED",
          closedStageType: "WON",
        },
      ],
    },
  });

  const pipelineAddMutation = trpc.projects.pipelines.add.useMutation({
    onSuccess: () => {
      toast.success("Pipeline added successfully");
      navigate(`/projects/board`);
    },
    onError(error) {
      toast.error(error.message);
      form.reset();
      trpcUtils.projects.pipelines.details.invalidate();
    },
  });

  const onSubmit = (values: z.infer<typeof pipelineSchema>) => {
    pipelineAddMutation.mutate({
      ...values,
      projectPipelineStages: values.projectPipelineStages.map(
        (stage, index) => ({
          ...stage,
          position: index + 1,
          id: isNaN(Number(stage.id)) ? 0 : Number(stage.id),
        })
      ),
    });
  };

  return (
    <Page
      fullWidth
      title="Add pipeline"
      showBack
      breadcrumbs={[
        {
          label: "Projects",
          path: "/projects",
        },
        {
          label: "Pipelines",
          path: "/projects/pipelines",
        },
        {
          label: "Add pipeline",
          path: "/projects/pipelines/add",
        },
      ]}
      suffix={
        <Button
          type="submit"
          variant="primary"
          loading={pipelineAddMutation.isLoading}
          icon={Plus}
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
        >
          Add pipeline
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectPipelineForm form={form} />
      </Form>
    </Page>
  );
};

export default ProjectPipelineAdd;
