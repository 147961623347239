import { cn } from "@heffl/ui/lib/utils";
import { Loader } from "lucide-react";

const FullScreenSpinner = ({
  text = "",
  className,
}: {
  text?: string;
  className?: string;
}) => (
  <div className={cn("grid place-items-center pt-12 w-full", className)}>
    <div className="grid place-items-center">
      <Loader className="animate-twSpin text-primary animate-infinite" />
      <p className="mt-2 text-sm font-medium text-primary">{text}</p>
    </div>
  </div>
);

export default FullScreenSpinner;
