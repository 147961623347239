import "@event-calendar/core/index.css";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { cn, formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Building2,
  CalendarClock,
  Info,
  MapPin,
  UserRoundPlus,
  UserRoundX,
  Wrench,
} from "lucide-react";
import { getJobId } from "../../jobs/list";
import { useState } from "react";
import ScheduleDetailsModal from "./schduleDetailsModal";
import { trpc } from "@/helpers/trpc";
import { Drawer } from "antd";

type PendingSchedulesModalProps = {
  open: boolean;
  onClose: () => void;
};

const PendingSchedulesModal = ({
  open,
  onClose,
}: PendingSchedulesModalProps) => {
  const [pendingTillDate, setPendingTillDate] = useState(
    dayjs().add(2, "weeks").toDate()
  );
  const [scheduleDetailsId, setScheduleDetailsId] = useState<number | null>(
    null
  );

  const { data: pendingSchedules } =
    trpc.fieldService.schedules.listPending.useQuery({
      endDate: pendingTillDate,
    });

  return (
    <Drawer
      destroyOnClose={true}
      zIndex={50}
      open={open}
      onClose={onClose}
      title="Schedules Pending"
      extra={
        <DatePicker
          className="mt-1"
          value={pendingTillDate}
          onChange={(date) => date && setPendingTillDate(date)}
        />
      }
    >
      {!!scheduleDetailsId && (
        <ScheduleDetailsModal
          open={true}
          id={scheduleDetailsId}
          onClose={() => setScheduleDetailsId(null)}
        />
      )}

      <div className="space-y-4">
        {pendingSchedules?.schedules.map((schedule) => (
          <div
            key={schedule.id}
            className="p-4 rounded-lg border shadow-sm transition-shadow cursor-pointer hover:shadow-md"
            onClick={() => setScheduleDetailsId(schedule.id)}
          >
            <div className="flex justify-between items-start mb-3">
              <h3 className="font-semibold">{getJobId(schedule.fsJobs)}</h3>
              <div className="flex gap-1">
                {schedule.status !== "CONFIRMED" && (
                  <Badge
                    variant="warning"
                    className="text-xs"
                    icon={CalendarClock}
                  >
                    Unconfirmed
                  </Badge>
                )}
                {schedule.fsScheduleAssignees.length === 0 && (
                  <Badge icon={UserRoundX} variant="error" className="text-xs">
                    Unassigned
                  </Badge>
                )}
              </div>
            </div>

            <div className="flex gap-2 items-center font-medium cursor-pointer hover:text-primary-900">
              {schedule.timeDisabled ? (
                <span>
                  {" "}
                  {dayjs(schedule.startDate).format("DD MMM")} -{" "}
                  <span className="text-red-500">No time added</span>
                </span>
              ) : (
                <>
                  {dayjs(schedule.startDate).format(
                    `DD MMM${
                      dayjs(schedule.startDate).isSame(schedule.endDate, "day")
                        ? ""
                        : ", hh:mm A"
                    }`
                  )}
                  {dayjs(schedule.startDate).isSame(schedule.endDate, "day")
                    ? `${dayjs(schedule.startDate).format(
                        ", hh:mm A"
                      )} - ${dayjs(schedule.endDate).format(" hh:mm A")}`
                    : dayjs(schedule.endDate).format(" - DD MMM, hh:mm A")}
                </>
              )}
            </div>

            <div className="grid grid-cols-2 gap-2 mt-2 text-sm">
              <div className="flex items-center">
                <Building2 className="mr-2 w-4 h-4 text-gray-400" />
                <span className="truncate">
                  {schedule.fsJobs.fsProperties?.clients?.name || "N/A"}
                </span>
              </div>
              <div className="flex items-center">
                <MapPin className="mr-2 w-4 h-4 text-gray-400" />
                <span className="truncate">
                  {schedule.fsJobs.fsProperties?.name || "N/A"}
                </span>
              </div>
              <div className="flex items-center">
                <Wrench className="mr-2 w-4 h-4 text-gray-400" />
                <span>{schedule.fsJobs.fsJobServices.length} Services</span>
              </div>
              <div className="flex items-center">
                <UserRoundPlus className="mr-2 w-4 h-4 text-gray-400" />
                <span
                  className={cn(
                    schedule.fsScheduleAssignees.length
                      ? "text-green-500"
                      : "text-red-500"
                  )}
                >
                  {schedule.fsScheduleAssignees.length || "Unassigned"}{" "}
                </span>
              </div>
            </div>

            <div className="flex items-center mt-2 text-xs text-gray-500">
              <Info className="mr-1 w-3 h-3" />
              <span>
                Created by: {formatName(schedule.createdBy)} -{" "}
                {dayjs(schedule.createdAt).fromNow()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default PendingSchedulesModal;
