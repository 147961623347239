import { trpc } from "@/helpers/trpc";
import enums from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import TagsInput from "@heffl/ui/components/TagInput";
import {
  cn,
  dynamicDateFormatting,
  formatName,
  makeEllipsis,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Building,
  CalendarDays,
  CheckSquare,
  Paperclip,
  StickyNote,
  Tag,
  UserCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type TProject = {
  id: number;
  title: string;
  status: z.infer<typeof enums.projectStatsuses>;
  endDate: Date | null;
  projectAssignees: { userId: number }[];
  clients?: {
    id: number;
    name: string;
  } | null;
  projectTags: { tags: { id: number } }[];
  _count: {
    projectNotes: number;
    projectFiles: number;
    projectActivities: number;
    projectTasks: number;
  };
};

interface Props {
  project: TProject;
  dragOverlay?: boolean;
  onProjectClick?: (id: number) => void;
  className?: string;
}

const dateColor = (date: Date | null) => {
  if (!date) return "text-gray-500";
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return "text-red-500";
  } else if (dayjs(date).isSame(dayjs(), "day")) {
    return "text-green-500";
  } else {
    return "text-gray-500";
  }
};

const ProjectCard: React.FC<Props> = ({
  project,
  dragOverlay,
  onProjectClick,
  className,
}) => {
  const navigate = useNavigate();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });

  const { data: users } = trpc.users.list.useQuery();

  const updateProjectMutation = trpc.projects.update.useMutation();

  return (
    <div
      className={cn(
        "px-3 py-2 bg-white rounded-md border border-gray-200 shadow-sm cursor-pointer !max-w-[260px] !overflow-hidden",
        className,
        dragOverlay && "cursor-grabbing"
      )}
      onClick={() => onProjectClick && onProjectClick(project.id)}
    >
      <div className="flex gap-2 justify-between">
        <p className="w-4/5 text-sm font-medium hover:text-primary-600">
          {project.title}
        </p>
        {project.status !== "ACTIVE" && (
          <Badge
            small
            className="h-fit"
            variant={project.status === "COMPLETED" ? "success" : "error"}
          >
            {project.status}
          </Badge>
        )}
      </div>
      <div className="flex gap-1 mt-0.5 justify-between items-baseline">
        <div className="flex gap-2 items-baseline">
          <p className="text-xs font-medium text-gray-600">#{project.id}</p>
          {project.endDate && (
            <p className={cn("text-xs", dateColor(project.endDate))}>
              {dynamicDateFormatting(project.endDate, true)}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col mt-3">
        <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
          <UserCircle className="w-3.5 h-3.5 text-gray-500 flex-shrink-0" />
          <TagsInput
            small
            tags={users?.map((user) => ({
              id: user.id,
              name: makeEllipsis(formatName(user), 8),
            }))}
            uniqueColor
            value={project.projectAssignees.map((assignee) => assignee.userId)}
            onChange={(assignees) => {
              updateProjectMutation.mutate({
                id: project.id,
                project: {
                  projectAssignees: assignees,
                },
              });
            }}
          />
        </div>
        {project.clients && (
          <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
            <Building className="w-3.5 h-3.5 text-gray-500" />
            <p
              onClick={() =>
                navigate(`/crm/clients/details/${project.clients?.id}`)
              }
              className="!text-xs truncate hover:text-primary-600 hover:underline"
            >
              {makeEllipsis(project.clients.name, 30)}
            </p>
          </div>
        )}
        <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
          <Tag className="w-3.5 h-3.5 text-gray-500" />
          <TagsInput
            small
            tags={tags}
            value={project.projectTags.map((tag) => tag.tags.id)}
            onChange={(tags) => {
              updateProjectMutation.mutate({
                id: project.id,
                project: {
                  projectTags: tags,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2 mt-2">
        <div className="flex flex-row gap-1 items-center">
          <StickyNote className="w-3.5 h-3.5 text-orange-500" />
          <p className="text-xs text-gray-500">{project._count.projectNotes}</p>
        </div>
        <div className="flex flex-row gap-1 items-center">
          <Paperclip className="w-3.5 h-3.5 text-pink-500" />
          <p className="text-xs text-gray-500">{project._count.projectFiles}</p>
        </div>
        <div className="flex flex-row gap-1 items-center">
          <CalendarDays className="w-3.5 h-3.5 text-blue-500" />
          <p className="text-xs text-gray-500">
            {project._count.projectActivities}
          </p>
        </div>
        <div className="flex flex-row gap-1 items-center">
          <CheckSquare className="w-3.5 h-3.5 text-green-500" />
          <p className="text-xs text-gray-500">{project._count.projectTasks}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
