import { trpc } from "@/helpers/trpc";
import { customFieldIcons } from "@/pages/settings/custom-fields";
import Schemas from "@heffl/server/src/schemas";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import appIcons from "@heffl/ui/components/appIcons";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { capitalizeWords } from "@heffl/ui/lib/utils";
import { LucideIcon, PlusIcon, Route, Share2, Trash } from "lucide-react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import slugify from "slugify";
import { z } from "zod";

export const customFieldSections: {
  value: z.infer<typeof enums.customFieldSections>;
  label: string;
  icon: LucideIcon;
}[] = [
  {
    value: "CLIENT",
    icon: appIcons.sales.clients.icon,
    label: "Clients",
  },
  {
    value: "LEAD",
    icon: appIcons.sales.leads.icon,
    label: "Leads",
  },
  {
    value: "DEAL",
    icon: appIcons.sales.deals.icon,
    label: "Deals",
  },
  {
    value: "PROJECT",
    icon: appIcons.projects.project.icon,
    label: "Projects",
  },
  {
    value: "QUOTATION_LINE_ITEM",
    icon: appIcons.common.lineItems.icon,
    label: "Quotation Line Items",
  },
  {
    value: "CUSTOM_OBJECT",
    icon: appIcons.customizations.customObjects.icon,
    label: "Custom Objects",
  },
  {
    value: "QUOTATION",
    icon: appIcons.sales.quotes.icon,
    label: "Quotations",
  },
];

const customFieldDataTypes: {
  value: z.infer<typeof enums.customFieldTypes>;
  label: string;
}[] = [
  { value: "TEXT", label: "Text" },
  { value: "NUMBER", label: "Number" },
  { value: "DATE", label: "Date" },
  { value: "SINGLE_OPTION", label: "Single Option" },
  { value: "MULTIPLE_OPTION", label: "Multiple Option" },
  { value: "FILE_PICKER", label: "File Picker" },
  { value: "LONG_TEXT", label: "Long Text" },
  { value: "MOBILE", label: "Mobile" },
  { value: "EMAIL", label: "Email" },
];

const FormQuestionForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<
    z.infer<typeof Schemas.customFields.customFields>,
    unknown
  >;
  edit?: boolean;
}) => {
  const label = form.watch("label");
  const dataType = form.watch("dataType");
  const name = form.watch("name");
  const section = form.watch("section");
  const values = form.watch("values");
  const targetFieldName = form.watch("customFieldLink.targetFieldName");
  const targetFieldLabel = form.watch("customFieldLink.targetFieldLabel");

  const { data: dealPipelines } = trpc.deals.pipelines.list.useQuery(
    undefined,
    {
      enabled: section === "DEAL",
    }
  );
  const { data: projectPipelines } = trpc.projects.pipelines.list.useQuery(
    undefined,
    {
      enabled: section === "PROJECT",
    }
  );
  const { data: documentTemplates } = trpc.templates.list.useQuery(
    {
      type: "QUOTATION",
    },
    {
      enabled: section === "QUOTATION_LINE_ITEM" || section === "QUOTATION",
    }
  );

  useEffect(() => {
    if (label && !edit) {
      form.setValue(
        "name",
        slugify(label || "", {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [label, form, edit]);

  useEffect(() => {
    if (targetFieldLabel && !edit) {
      form.setValue(
        "customFieldLink.targetFieldName",
        slugify(targetFieldLabel || "", {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [targetFieldLabel, form, edit]);

  // useEffect(() => {
  //   if (!edit) {
  //     form.reset({
  //       dataType,
  //     });
  //   }
  // }, [dataType]);

  useEffect(() => {
    if (name) {
      form.setValue(
        "name",
        slugify(name, {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [name, form]);

  useEffect(() => {
    if (targetFieldName) {
      form.setValue(
        "customFieldLink.targetFieldName",
        slugify(targetFieldName || "", {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [targetFieldName, form]);

  return (
    <>
      <FormField name="label" label="Question" className="w-full">
        <Input placeholder="Question" />
      </FormField>
      <FormField name="dataType" label="Answer type">
        <Select
          onSelect={({ value }) =>
            form.setValue(
              "values",
              value === "SINGLE_OPTION" || value === "MULTIPLE_OPTION"
                ? [{ value: "", isNew: true }]
                : []
            )
          }
          options={customFieldDataTypes.map((field) => ({
            label: field.label,
            value: field.value,
            icon: customFieldIcons[field.value],
          }))}
          disabled={edit}
        />
      </FormField>
      <div className="grid grid-cols-2 gap-3">
        <FormField
          name="name"
          label="Field name"
          info="Used as unique identifier"
        >
          <Input prefix="cf_" placeholder="Field name" disabled={edit} />
        </FormField>
        <FormField
          name="placeholder"
          label="Helper text"
          info="Shows as a placeholder in the input"
        >
          <Input placeholder="Placeholder" />
        </FormField>
        <FormField label="Required" name="required">
          <Switch />
        </FormField>
        {section === "DEAL" && (
          <FormField name="dealPipelineId" label="Deal Pipeline">
            <Select
              disabled={edit}
              options={dealPipelines?.map((deal) => ({
                label: deal.name,
                value: deal.id,
              }))}
            />
          </FormField>
        )}
        {(section === "QUOTATION_LINE_ITEM" || section === "QUOTATION") && (
          <FormField name="documentTemplateId" label="Document Template">
            <Select
              disabled={edit}
              options={documentTemplates?.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
            />
          </FormField>
        )}
        {section === "PROJECT" && (
          <FormField name="projectPipelineId" label="Project Pipeline">
            <Select
              disabled={edit}
              options={projectPipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              }))}
            />
          </FormField>
        )}

        {section === "QUOTATION_LINE_ITEM" && dataType === "NUMBER" && (
          <FormField
            name="isMultiplier"
            label="Is Multiplier"
            info="If true, the value will be multipled with the line item quantity"
          >
            <Switch disabled={edit} />
          </FormField>
        )}
      </div>

      {dataType === "LINK_ENTITY" && (
        <div className="grid grid-cols-2 gap-3">
          <FormField name="customFieldLink.type" label="Link type">
            <Select
              disabled={edit}
              placeholder="Select link type"
              options={[
                {
                  label: "One to one",
                  value: "ONE_TO_ONE",
                  icon: Route,
                },
                {
                  label: "One to many",
                  value: "ONE_TO_MANY",
                  icon: Share2,
                },
              ]}
            />
          </FormField>
          <FormField
            name="customFieldLink.targetEntity"
            label="Link to"
            disabled={edit}
          >
            <Select
              disabled={edit}
              placeholder="Select entity"
              options={enumsToOptions(enums.entities, (label) =>
                capitalizeWords(label.replace(/_/g, " "))
              )}
            />
          </FormField>
          <FormField
            name="customFieldLink.targetFieldLabel"
            label="Target field label"
            disabled={edit}
          >
            <Input placeholder="Target field label" />
          </FormField>
          <FormField
            name="customFieldLink.targetFieldName"
            label="Target field name"
            disabled={edit}
          >
            <Input placeholder="Target field API name" disabled={edit} />
          </FormField>
        </div>
      )}

      {(dataType === "SINGLE_OPTION" || dataType === "MULTIPLE_OPTION") && (
        <FormField name="values" label="Options">
          <FormList>
            {({ fields, append, remove, _name }) => {
              return (
                <div className="space-y-2">
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.id}>
                      <FormField name={_name(index, "value")}>
                        <Input
                          type="text"
                          placeholder="Option name"
                          disabled={edit && !values[index].isNew}
                        />
                      </FormField>
                      <Button
                        disabled={
                          (edit && !values[index].isNew) || fields.length === 1
                        }
                        variant="link"
                        onClick={() => remove(index)}
                        icon={Trash}
                        iconClassName="text-red-500"
                      />
                    </div>
                  ))}
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => append({ value: "", isNew: true })}
                    className="font-medium"
                  >
                    <PlusIcon className="h-4" />
                    Add option
                  </Button>
                </div>
              );
            }}
          </FormList>
        </FormField>
      )}
      <FormField name="description" label="Description">
        <MiniRichTextEditor placeholder="Description" />
      </FormField>
    </>
  );
};

export default FormQuestionForm;
