import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import InputTags from "@heffl/ui/components/tag-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Mail } from "lucide-react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { trpc } from "@/helpers/trpc";
import { toast } from "react-hot-toast";
import Schemas from "@heffl/server/src/schemas";

type SendEmailModalProps = {
  open: boolean;
  onClose: () => void;
};

const SendEmailModal = ({ open, onClose }: SendEmailModalProps) => {
  const emailSendMutation = trpc.emails.send.useMutation({
    onSuccess: () => {
      toast.success("Email sent successfully");
      onClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [showCC, setShowCC] = useState(false);

  const form = useForm<z.infer<typeof Schemas.common.email>>({
    resolver: zodResolver(Schemas.common.email),
    defaultValues: {
      to: [],
      cc: [],
      subject: "",
      html: "",
      ctx: {
        teamId: 0,
        userId: 0,
      },
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.common.email>) => {
    console.log(values);
    emailSendMutation.mutate(values);
  };

  return (
    <ModalDrawer
      title="Send email"
      open={open}
      onClose={onClose}
      modalClassName="max-w-3xl"
      footer={
        <div className="flex gap-2 justify-end">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            icon={Mail}
            onClick={form.handleSubmit(onSubmit)}
            disabled={emailSendMutation.isLoading}
            loading={emailSendMutation.isLoading}
          >
            Send email
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <div className="flex flex-col gap-1.5">
          <FormField name="to" label="To">
            <InputTags
              placeholder="Enter email addresses"
              options={[
                {
                  label: "Nasim",
                  value: "muhammednasimda@gmail.com",
                },
              ]}
            />
          </FormField>
          {!showCC && (
            <div className="flex justify-end w-full">
              <Button variant="link" size="sm" onClick={() => setShowCC(true)}>
                Add CC
              </Button>
            </div>
          )}
          {showCC && (
            <FormField name="cc" label="CC">
              <InputTags placeholder="Enter CC email addresses" />
            </FormField>
          )}
          <FormField name="subject" label="Subject">
            <Input placeholder="Enter subject" />
          </FormField>
          <FormField name="html" label="Message">
            <MiniRichTextEditor placeholder="Compose your email" height={200} />
          </FormField>
        </div>
      </Form>
    </ModalDrawer>
  );
};

export default SendEmailModal;
