import { trpc } from "@/helpers/trpc";
import { formatName } from "@heffl/ui/lib/utils";
import TagsInput from "@heffl/ui/components/TagInput";
import { cn, dynamicDateFormatting, makeEllipsis } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Building,
  ClipboardList,
  GitMerge,
  Tag,
  Timer,
  UserCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { TTask } from "./SortableTaskCard";

export const taskDateColor = (date: Date, completed: boolean = false) => {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (completed) {
    return "text-gray-500";
  } else if (dayjs(date).isSame(dayjs(), "day")) {
    return "text-green-500";
  } else if (diffDays < 0) {
    return "text-red-500";
  } else {
    return "text-gray-500";
  }
};

const TaskCard = ({
  task,
  onTaskClick,
  inProject = false,
  className,
}: {
  task: TTask;
  onTaskClick: (id: number) => void;
  inProject?: boolean;
  className?: string;
}) => {
  const navigate = useNavigate();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });
  const { data: users } = trpc.users.list.useQuery();

  const updateTaskMutation = trpc.projects.tasks.update.useMutation();

  return (
    <div
      className={cn(
        "px-3 py-2 bg-white rounded-md border border-gray-200 shadow-sm cursor-pointer !max-w-[260px] !overflow-hidden",
        className
      )}
      onClick={() => onTaskClick(task.id)}
    >
      <p className="text-sm font-medium hover:text-primary-600">{task.title}</p>
      <div className="flex gap-1 mt-0.5 justify-between items-baseline">
        <div className="flex gap-2 items-baseline">
          <p className="text-xs font-medium text-gray-600">{task.number}</p>
          {task.date && (
            <p
              className={cn(
                "text-xs ",
                taskDateColor(task.date, task.status === "COMPLETED")
              )}
            >
              {dynamicDateFormatting(task.date, true)}
            </p>
          )}
          {task.timesheets.length > 0 && (
            <span className="flex gap-0.5 text-xs text-primary-700">
              <Timer className="w-3.5 h-3.5 " />
              {task.timesheets.length}
            </span>
          )}
        </div>
      </div>
      {task.projectSubTasks.length > 0 && (
        <>
          <p className="text-[10px] font-medium mt-0.5">SUBTASKS</p>
          <ul className="pl-3 -mt-1">
            {task.projectSubTasks.map((subtask) => (
              <li
                key={subtask.id}
                className="text-[10px] font-medium text-gray-500"
              >
                {subtask.title}
              </li>
            ))}
          </ul>
        </>
      )}
      <div className="flex flex-col mt-3">
        <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
          <UserCircle className="w-3.5 h-3.5 text-gray-500" />
          <TagsInput
            small
            tags={users
              ?.filter(
                (user) =>
                  task.projects.projectAssignees?.some(
                    (projectAssignee) => projectAssignee.userId === user.id
                  ) ?? true
              )
              .map((user) => ({
                id: user.id,
                name: formatName(user),
              }))}
            uniqueColor
            value={task.projectTaskAssignees.map(
              (assignee) => assignee.users.id
            )}
            onChange={(newUsers) => {
              updateTaskMutation.mutate({
                id: task.id,
                task: {
                  projectTaskAssignees: newUsers,
                },
              });
            }}
          />
        </div>
        {!inProject && (
          <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
            <ClipboardList className="w-3.5 h-3.5 text-gray-500" />
            <p
              onClick={() => navigate(`/projects/details/${task.projectId}`)}
              className="!text-xs truncate hover:text-primary-600 hover:underline"
            >
              {makeEllipsis(task.projects.title, 30)}
            </p>
          </div>
        )}
        {task.projects.clients && (
          <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
            <Building className="w-3.5 h-3.5 text-gray-500" />
            <p
              onClick={() =>
                navigate(`/crm/clients/details/${task.projects.clients?.id}`)
              }
              className="!text-xs truncate hover:text-primary-600 hover:underline"
            >
              {task.projects.clients?.name}
            </p>
          </div>
        )}
        <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
          <Tag className="w-3.5 h-3.5 text-gray-500" />
          <TagsInput
            small
            tags={tags}
            value={task.projectTaskTags.map((tag) => tag.tags.id)}
            onChange={(tags) => {
              updateTaskMutation.mutate({
                id: task.id,
                task: {
                  projectTaskTags: tags,
                },
              });
            }}
          />
        </div>
        {!!task.projectSubTasks.length && (
          <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
            <GitMerge className="w-3.5 h-3.5 text-gray-500" />
            <p className="!text-xs truncate hover:text-primary-600 hover:underline">
              {task.projectSubTasks.length} Subtasks
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskCard;
