import { LucideIcon } from "lucide-react";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "../components/primitives/context-menu";
import { cn } from "../lib/utils";

type RightClickMenuProps = {
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  items: (
    | {
        type: "item";
        icon?: LucideIcon;
        label: string;
        onClick: () => void;
        className?: string;
      }
    | {
        type: "separator";
      }
  )[];
  contentClassName?: string;
};

export const RightClickMenu: React.FC<RightClickMenuProps> = ({
  children,
  items,
  className,
  disabled,
  contentClassName,
}) => {
  return (
    <ContextMenu>
      <ContextMenuTrigger disabled={disabled} className={className}>
        {children}
      </ContextMenuTrigger>
      <ContextMenuContent className={cn("w-32", contentClassName)}>
        {items.map((item) => (
          <>
            {item.type === "separator" && <ContextMenuSeparator />}
            {item.type === "item" && (
              <ContextMenuItem
                onClick={() => {
                  item.onClick();
                }}
                className={item.className}
              >
                {item.icon && <item.icon className="mr-2 w-4 h-4" />}
                {item.label}
              </ContextMenuItem>
            )}
          </>
        ))}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default RightClickMenu;
