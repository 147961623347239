import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { AddProductDrawer } from "../products/components/addProductDrawer";
import { SalesOrderForm } from "./add";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";

const EditSalesOrder = () => {
  const params = useParams();
  const salesOrderId = Number(params.id);
  const navigate = useNavigate();

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const form = useForm<z.infer<typeof Schemas.sales.salesOrder>, unknown>({
    resolver: zodResolver(Schemas.sales.salesOrder),
  });

  const { data: salesOrderDetails } = trpc.sales.salesOrders.details.useQuery(
    salesOrderId,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: quotationCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "SALES_ORDER",
      documentTemplateId: salesOrderDetails?.templateId,
    });

  const salesOrderUpdateMutation = trpc.sales.salesOrders.update.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Sales Order updated successfully");
      navigate(-1);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    // check if default values is already set
    if (!form.getValues("clientId") && salesOrderDetails) {
      form.reset({
        ...salesOrderDetails,
      });
    }
  }, [salesOrderDetails, form]);

  if (!salesOrderDetails) return <FullScreenSpinner />;

  const onSubmit = (values: z.infer<typeof Schemas.sales.salesOrder>) => {
    // validate quotation custom fields
    const { isValid } = validateCustomFields({
      customFields: quotationCustomFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    salesOrderUpdateMutation.mutate({
      id: salesOrderId,
      salesOrder: values,
    });
  };

  return (
    <Page
      showBack
      className="max-w-screen-lg"
      title="Edit Sales Order"
      description="Update sales order details"
      suffix={
        <>
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            loading={salesOrderUpdateMutation.isLoading}
            variant="primary"
            icon={Save}
          >
            Update Sales Order
          </ResponsivePrimaryButton>
        </>
      }
    >
      {showAddProductDialog && (
        <AddProductDrawer
          open={showAddProductDialog}
          onClose={() => setShowAddProductDialog(false)}
        />
      )}
      <Form {...form} onSubmit={onSubmit}>
        <div>
          <SalesOrderForm form={form} edit />
        </div>
      </Form>
    </Page>
  );
};

export default EditSalesOrder;
