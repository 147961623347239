import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import React from "react";

const TrialBalance: React.FC = () => {
  const { data: report } = trpc.books.reports.trialBalance.useQuery();

  if (!report) return <FullScreenSpinner />;

  return (
    <Page title="Trial Balance" fullWidth className="sm:!p-0" showBack>
      <div className="flex justify-center w-full">
        <div className="mt-3 w-full max-w-5xl bg-white">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 text-xs text-[#75777c] text-start font-[500]"
                  >
                    Account
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-xs text-[#75777c] text-start font-[500]"
                  >
                    Left Total
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-xs text-[#75777c] text-start font-[500]"
                  >
                    Right Total
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 font-bold">Assets</td>
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Accounts Receivable
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.assets.accountsReceivable,
                      "AED",
                      true
                    )}
                  </td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Input VAT
                  </td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(report.assets.inputVAT, "AED", true)}
                  </td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-medium">Total for Assets</td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(report.assets.total, "AED", true)}
                  </td>
                  <td className="px-6 py-4"></td>
                </tr>

                <tr>
                  <td className="px-6 py-4 font-bold">Liabilities</td>
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4"></td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Accounts Payable
                  </td>
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.liabilities.accountsPayable,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 pl-12 font-medium text-primary-900">
                    Output VAT
                  </td>
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.liabilities.outputVAT,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 font-medium">
                    Total for Liabilities
                  </td>
                  <td className="px-6 py-4"></td>
                  <td className="px-6 py-4">
                    {heffl.format.currency(
                      report.liabilities.total,
                      "AED",
                      true
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default TrialBalance;
