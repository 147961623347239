import DataGrid from "@/components/dataGrid/DataGrid";
import heffl from "@/helpers/heffl";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import {
  formatCustomFields,
  renderCustomFieldValue,
} from "@heffl/server/src/helpers/customFields";
import appIcons from "@heffl/ui/components/appIcons";
import { Badge } from "@heffl/ui/components/primitives/badge";
import TagsInput from "@heffl/ui/components/TagInput";
import dayjs from "dayjs";
import { CircleDot, KanbanSquare } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ProjectTable = ({
  projects,
  pageNo,
  pageSize,
  setPageNo,
}: {
  projects?: RouterOutputs["projects"]["list"];
  pageNo: number;
  pageSize: number;
  setPageNo: (pageNo: number) => void;
}) => {
  const navigate = useNavigate();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });

  const { data: projectCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "PROJECT",
      projectPipelineId: null,
    });

  const updateProjectMutation = trpc.projects.update.useMutation();

  return (
    <DataGrid
      rowKey="id"
      className="h-[calc(100vh-165px)]"
      name="projectsListMain"
      label="Projects"
      rows={projects?.projects || []}
      columns={[
        {
          key: "title",
          name: "Title",
          width: 400,
          renderCell: ({ row }) => (
            <div
              className="font-medium cursor-pointer hover:text-primary-600"
              onClick={() => navigate(`/projects/details/${row.id}`)}
            >
              {row.title}
            </div>
          ),
        },
        {
          key: "board",
          name: "Board",
          width: 150,
          renderCell: ({ row }) => (
            <Badge icon={KanbanSquare} variant="outline">
              {row.projectPipelines.name}
            </Badge>
          ),
        },
        {
          key: "phase",
          name: "Phase",
          width: 150,
          renderCell: ({ row }) => (
            <Badge variant="outline" icon={CircleDot}>
              {row.projectPipelineStages?.name}
            </Badge>
          ),
        },
        {
          key: "tags",
          name: "Tags",
          width: 150,
          renderCell: ({ row }) => (
            <TagsInput
              tags={tags}
              value={row.projectTags?.map((tag) => tag.tagId)}
              onChange={(tags) => {
                updateProjectMutation.mutate({
                  id: row.id,
                  project: {
                    projectTags: tags,
                  },
                });
              }}
            />
          ),
        },
        {
          key: "startDate",
          name: "Start Date",
          width: 120,
          renderCell: ({ row }) => (
            <div>{row.startDate?.toDateString() || ""}</div>
          ),
        },
        {
          key: "endDate",
          name: "End Date",
          width: 120,
          renderCell: ({ row }) => (
            <div>{row.endDate?.toDateString() || ""}</div>
          ),
        },
        {
          key: "assignee",
          name: "Assignee",
          width: 200,
          renderCell: ({ row }) => (
            <Badge variant="outline" icon={appIcons.common.user.icon}>
              <div>
                {row.projectAssignees
                  ?.map((assignee) => assignee.users.firstName)
                  .join(", ")}
              </div>
            </Badge>
          ),
        },
        {
          key: "client",
          name: "Client",
          width: 200,
          renderCell: ({ row }) =>
            row.clients && (
              <Badge
                avatar
                className="hover:cursor-pointer"
                variant="outline"
                onClick={() => {
                  navigate(`/crm/clients/details/${row.clientId}`);
                }}
              >
                {row.clients.name}
              </Badge>
            ),
        },
        {
          key: "createdAt",
          name: "Created At",
          width: 120,
          renderCell: ({ row }) => (
            <div>{dayjs(row.createdAt).format("DD/MM/YYYY hh:mm a")}</div>
          ),
        },
        {
          key: "createdBy",
          name: "Created By",
          width: 200,
          renderCell: ({ row }) => (
            <Badge variant="outline" icon={appIcons.common.user.icon}>
              {heffl.format.name(row.createdBy)}
            </Badge>
          ),
        },
        ...(projectCustomFields ?? []).map((field) => ({
          key: field.name,
          name: field.label,
          // @ts-ignore
          renderCell: ({ row }) =>
            renderCustomFieldValue(
              field,
              formatCustomFields({
                customFields: row.customFields,
                customFieldFields: projectCustomFields || [],
              })[field.name]
            ),
        })),
        // {
        //   key: "progressBar",
        //   name: "Progress",
        //   width: 200,
        //   renderCell: ({ row }) => (
        //     <div className="flex flex-col w-full">
        //       <div className="flex justify-between mb-1 text-xs">
        //         <span>{`${row.completed}/${row.totalTasks}`}</span>
        //         <span>{`${Math.round(
        //           (row.completed / row.totalTasks) * 100
        //         )}%`}</span>
        //       </div>
        //       <div className="w-full bg-gray-200 rounded-full h-2.5">
        //         <div
        //           className="bg-blue-600 h-2.5 rounded-full"
        //           style={{
        //             width: `${(row.completed / row.totalTasks) * 100}%`,
        //           }}
        //         ></div>
        //       </div>
        //     </div>
        //   ),
        // },
      ]}
      pagination={{
        pageNo,
        pageSize,
        setPageNo,
        count: projects?.meta?.count || 0,
      }}
    />
  );
};

export default ProjectTable;
