import DataGrid from "@/components/dataGrid/DataGrid";
import InfoItemsVT from "@/components/InfoItemVT";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import ResponsiveActions from "@heffl/ui/components/responsive-actions";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatAddress } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  FileText,
  List,
  Pencil,
  Plus,
  ShoppingBag,
  Trash2,
  Wrench,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { PurchaseOrderList } from "../purchaseOrders/list";
import { EditVendorDrawer } from "./list";
import FilterBar from "@/components/filters";

type Tab = "purchases" | "service" | "statement";

const VendorDetails = () => {
  const params = useParams();
  const vendorId = Number(params.id);
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [selectedTab, setSelectedTab] = useState<Tab>("purchases");
  const [showVendorEdit, setShowVendorEdit] = useState(false);
  const [statementDates, setStatementDates] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  });

  const { data: vendor } = trpc.purchases.vendors.details.useQuery(vendorId);
  const { data: statement } = trpc.purchases.vendors.statement.useQuery({
    vendorId,
    startDate: statementDates.startDate,
    endDate: statementDates.endDate,
  });

  const deleteVendorMutation = trpc.purchases.vendors.delete.useMutation({
    onSuccess: () => {
      toast.success("Vendor deleted successfully");
      navigate("/purchases/vendors");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  if (!vendor || !vendorId) {
    return <FullScreenSpinner />;
  }

  const billingAddress = vendor.clientAddresses.length
    ? vendor.clientAddresses.find((address) => address.type === "BILLING")
    : vendor.clientAddresses[0];

  return (
    <Page
      showBack
      title={vendor?.name}
      description={`Added ${dayjs(vendor?.createdAt).fromNow()}`}
      fullWidth
      className="!p-0"
      suffix={
        <div className="flex flex-col gap-2 sm:flex-row">
          <ResponsiveActions
            actions={[
              {
                label: "Delete",
                buttonVariant: "destructive",
                loading: deleteVendorMutation.isLoading,
                icon: Trash2,
                onClick: async () => {
                  const confirmed = await confirm({
                    title: "Are you sure to delete this vendor?",
                    body: `This will delete the vendor "${vendor.name}" and its history from the system.`,
                    actionButton: "Delete",
                  });
                  if (confirmed) {
                    deleteVendorMutation.mutate(vendorId);
                  }
                },
              },
              {
                label: "Edit",
                icon: Pencil,
                onClick: () => setShowVendorEdit(true),
              },
            ]}
          />
        </div>
      }
    >
      {showVendorEdit && (
        <EditVendorDrawer
          id={vendorId}
          onClose={() => setShowVendorEdit(false)}
        />
      )}
      <div className="flex sm:h-[calc(100vh-64px)]  h-full">
        <div className="sm:w-3/4">
          <StripeTabs
            tabParentClassName="p-3"
            className="mt-2"
            value={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            items={[
              { label: "Purchases", key: "purchases", icon: ShoppingBag },
              { label: "Service", key: "service", icon: Wrench },
              {
                label: "Statement of Accounts",
                key: "statement",
                icon: FileText,
              },
            ]}
          />
          <div className="px-3 mt-3">
            {match(selectedTab)
              .with("purchases", () => (
                <Card
                  title="Purchases"
                  icon={<ShoppingBag />}
                  suffix={
                    <Button
                      icon={Plus}
                      variant="primary"
                      onClick={() => {
                        navigate(
                          `/purchases/purchase-orders/add?vendorId=${vendorId}`
                        );
                      }}
                    >
                      Purchase Order
                    </Button>
                  }
                >
                  <PurchaseOrderList
                    classes={{
                      dataGrid: "h-full mt-8",
                    }}
                    filters={{
                      vendors: [vendorId],
                    }}
                  />
                </Card>
              ))
              .with("service", () => (
                <Card title="Jobs" icon={<Wrench />}>
                  {/* <JobList
                    setTotal={() => {}}
                    classes={{
                      dataGrid: "h-full mt-8",
                    }}
                    filters={{
                      vendors: [vendorId],
                    }}
                  /> */}
                </Card>
              ))
              .with("statement", () => (
                <>
                  <FilterBar
                    filters={[
                      {
                        key: "date",
                        type: "date-range",
                        label: "Date",
                        value: [
                          statementDates.startDate,
                          statementDates.endDate,
                        ],
                        onChange: (value) =>
                          value &&
                          setStatementDates({
                            startDate: value[0],
                            endDate: value[1],
                          }),
                        presets: [
                          {
                            label: "Today",
                            value: [
                              dayjs().startOf("day").toDate(),
                              dayjs().endOf("day").toDate(),
                            ],
                          },
                          {
                            label: "This Week",
                            value: [
                              dayjs().startOf("week").toDate(),
                              dayjs().endOf("week").toDate(),
                            ],
                          },
                          {
                            label: "This Month",
                            value: [
                              dayjs().startOf("month").toDate(),
                              dayjs().endOf("month").toDate(),
                            ],
                          },
                          {
                            label: "This Year",
                            value: [
                              dayjs().startOf("year").toDate(),
                              dayjs().endOf("year").toDate(),
                            ],
                          },
                        ],
                      },
                    ]}
                  />

                  <DataGrid
                    name="vendorChartOfAccounts"
                    label="Vendor Transactions"
                    rowKey="id"
                    className="mt-8 h-[calc(100vh-210px)]"
                    rows={statement?.statement || []}
                    columns={[
                      {
                        key: "date",
                        name: "Date",
                        renderCell: ({ row }) =>
                          dayjs(row.date).format("DD/MM/YYYY"),
                      },
                      {
                        key: "description",
                        name: "Description",
                      },
                      {
                        key: "credit",
                        name: "Credit",
                        renderCell: ({ row }) => {
                          if (
                            row.type === "bill" ||
                            row.type === "openingBalance"
                          ) {
                            return heffl.format.currency(
                              row.amount,
                              "AED",
                              true
                            );
                          }
                        },
                      },
                      {
                        key: "debit",
                        name: "Debit",
                        renderCell: ({ row }) => {
                          if (row.type === "payment") {
                            return heffl.format.currency(
                              row.payment,
                              "AED",
                              true
                            );
                          }
                          return "";
                        },
                      },
                      {
                        key: "runningBalance",
                        name: "Balance",
                        renderCell: ({ row }) =>
                          heffl.format.currency(
                            row.runningBalance,
                            "AED",
                            true
                          ),
                      },
                    ]}
                  />
                  <div className="flex gap-12 justify-end mt-3">
                    <p className="font-semibold">Balance due</p>
                    <p className="">
                      {heffl.format.currency(
                        statement?.balanceDue || 0,
                        "AED",
                        true
                      )}
                    </p>
                  </div>
                </>
              ))
              .exhaustive()}
          </div>
        </div>
        <ScrollArea className="block overflow-auto w-full h-full border-l border-gray-200 sm:w-1/4 sm:block">
          <StripeTabs
            tabParentClassName="px-4"
            className="mt-2"
            items={[{ label: "Details", key: "details", icon: List }]}
          />
          <div className="p-3">
            <InfoItemsVT
              items={[
                {
                  label: "Name",
                  value: vendor.name,
                },
                {
                  label: "Tax No",
                  value: vendor.taxNumber ?? "No tax number added",
                  allowCopy: true,
                },
                {
                  label: "Billing Address",
                  value: billingAddress
                    ? formatAddress(billingAddress)
                    : "No address added",
                },
              ]}
            />
          </div>
        </ScrollArea>
      </div>
    </Page>
  );
};

export default VendorDetails;
