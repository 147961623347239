import { z } from "zod";
import enums from "../../schemas/enums";
import { calculateLineItems } from "./calculateLineItems";
import { sum } from "radash";

export type CalculateBill = {
  billItems: {
    price: number;
    quantity: number;
    tax: number;
    viewType: z.infer<typeof enums.lineItemViewType>;
  }[];
  discount: number;
  paymentMadeAllocations: { amount: number }[];
};

const calculateBill = (bill: CalculateBill) => {
  try {
    const lineItemsCal = calculateLineItems({
      lineItems: bill.billItems,
      discount: bill.discount,
    });
    const billSubTotal = lineItemsCal.subTotal;
    const billTotal = lineItemsCal.total;
    const taxTotal = lineItemsCal.totalTax;
    const paymentsTotal = sum(bill.paymentMadeAllocations, (a) => a.amount);
    const pendingTotal = billTotal - paymentsTotal;
    const totalBuyPrice = lineItemsCal.totalBuyPrice;
    return {
      billSubTotal: Number(billSubTotal.toFixed(2)),
      pendingTotal: Number((pendingTotal < 0 ? 0 : pendingTotal).toFixed(2)),
      billTotal: Number(billTotal.toFixed(2)),
      paymentsTotal: Number(paymentsTotal.toFixed(2)),
      taxTotal: Number(taxTotal.toFixed(2)),
      totalBuyPrice: Number(totalBuyPrice.toFixed(2)),
    };
  } catch (error) {
    return {
      billSubTotal: 0,
      pendingTotal: 0,
      billTotal: 0,
      paymentsTotal: 0,
      taxTotal: 0,
      totalBuyPrice: 0,
    };
  }
};

export default calculateBill;
