import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import dayjs from "dayjs";
import InfoItems from "@heffl/ui/components/info-items";
import heffl from "@/helpers/heffl";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { isMobile } from "@heffl/ui/lib/utils";
import { EditExpenseModal } from "./list";
import { Link } from "react-router-dom";

type ExpenseDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  id: number;
};

const ExpenseDetailsModal = ({
  open,
  onClose,
  id,
}: ExpenseDetailsModalProps) => {
  const [showEdit, setShowEdit] = useState(false);

  const { data: expense } = trpc.purchases.expenses.details.useQuery({ id });

  return (
    <>
      {showEdit && (
        <EditExpenseModal
          open={showEdit}
          onClose={() => setShowEdit(false)}
          id={id}
        />
      )}
      <ModalDrawer
        title="Expense Details"
        open={open}
        onClose={onClose}
        modalClassName="max-w-2xl"
        actions={[
          {
            label: "Edit",
            icon: Pencil,
            onClick: () => setShowEdit(true),
          },
        ]}
        defaultActionsButtonSize="xs"
      >
        {!expense && <FullScreenSpinner />}
        {expense && (
          <div className="flex flex-col gap-6">
            <div className="text-sm text-gray-500">Amount</div>
            <div className="-mt-6 text-2xl font-semibold">
              {heffl.format.currency(expense.amount, "AED")}
            </div>

            <InfoItems
              column={isMobile() ? 1 : 2}
              size="small"
              items={[
                {
                  label: "Expense Date",
                  children: dayjs(expense.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Reference #",
                  children: expense.refNo || "-",
                },
                {
                  label: "Expense #",
                  children: `#${expense.number}`,
                },
                {
                  label: "Created At",
                  children: dayjs(expense.createdAt).format("DD/MM/YYYY"),
                },
                {
                  label: "Vendor",
                  children: expense.vendorId ? (
                    <Link
                      to={`/purchases/vendors/details/${expense.vendorId}`}
                      className="text-primary-600 hover:text-primary-700"
                    >
                      {expense.vendors?.name}
                    </Link>
                  ) : (
                    "-"
                  ),
                },
                {
                  label: "Notes",
                  children: expense.notes || "-",
                },
              ]}
            />

            <SimpleTable
              columns={[
                { label: "Expense Account", key: "expenseAccount" },
                { label: "Paid Through Account", key: "paidThroughAccount" },
                {
                  label: "Amount",
                  key: "amount",
                  render: (row) =>
                    heffl.format.currency(row.amount, "AED", true),
                },
              ]}
              rows={[
                {
                  id: expense.id,
                  expenseAccount: expense.expenseAccount.name,
                  paidThroughAccount: expense.paidThroughAccount.name,
                  amount: expense.amount,
                },
              ]}
              idKey="id"
            />

            <div className="flex flex-col gap-2 items-end">
              <div className="flex gap-8 pb-2 border-b">
                <div className="text-sm text-gray-500">Subtotal</div>
                <div className="text-sm text-gray-500">
                  {heffl.format.currency(expense.amount, "AED", true)}
                </div>
              </div>
              <div className="flex gap-8 pb-2 border-b">
                <div className="text-sm font-medium">Total</div>
                <div className="text-sm font-medium">
                  {heffl.format.currency(expense.amount, "AED", true)}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalDrawer>
    </>
  );
};

export default ExpenseDetailsModal;
