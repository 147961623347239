import { RouterOutputs } from "@/helpers/trpc";
import dayjs from "dayjs";
import { tailwindColors } from "./constants";

export const generateUniqueColor = (
  str: string,
  shade: 300 | 400 | 600 | 200 | 100 | 500 | 700 = 400
) => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash % tailwindColors[shade].length);
  return tailwindColors[shade][index];
};

export function isObjectEmpty(obj: object) {
  if (obj === null || obj === undefined) return true;
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export type DealsListType =
  RouterOutputs["deals"]["pipelines"]["details"]["dealPipelineStages"];
export type DealType =
  RouterOutputs["deals"]["pipelines"]["details"]["dealPipelineStages"][number]["deals"][number];
export const moveBetweenLists = (
  dealLists: DealsListType,
  activeListId: number,
  activeIndex: number,
  overListId: number,
  overIndex: number,
  task: DealType
) =>
  dealLists.map((dealList) => {
    if (dealList.id === activeListId)
      return {
        ...dealList,
        deals: [
          ...dealList.deals.slice(0, activeIndex),
          ...dealList.deals.slice(activeIndex + 1),
        ],
      };
    else if (dealList.id === overListId)
      return {
        ...dealList,
        deals: [
          ...dealList.deals.slice(0, overIndex),
          task,
          ...dealList.deals.slice(overIndex),
        ],
      };
    else return dealList;
  });

export const convertFileSize = (sizeInBytes: number) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  if (sizeInBytes < kilobyte) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < megabyte) {
    return (sizeInBytes / kilobyte).toFixed(1) + " KB";
  } else {
    return (sizeInBytes / megabyte).toFixed(2) + " MB";
  }
};

export const getDomainFromURL = (url: string) => {
  const withoutProtocol = url.replace(/^(https?:|)\/\//, "");
  const domain = withoutProtocol.split("/")[0];
  const parts = domain.split(".");
  const topLevelDomain = parts[parts.length - 1];
  const secondLevelDomain = parts[parts.length - 2];
  if (topLevelDomain.length === 2 || topLevelDomain.length === 3) {
    return secondLevelDomain + "." + topLevelDomain;
  } else {
    return domain;
  }
};

export function calculateTimeDifference(
  startTime: string,
  endTime: string
): number {
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  const duration = end.diff(start, "hour", true);

  return duration;
}

const widthPerHour = 270;

export function calculateTaskCardWidth(totalHours: number): number {
  return totalHours * widthPerHour;
}

export function calculateTaskCardMargin(startTime: string): number {
  const start = dayjs(startTime);
  const marginInHours = start.hour() + start.minute() / 60;

  return marginInHours * widthPerHour;
}
