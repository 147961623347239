import { formatCurrency } from "@heffl/ui/lib/utils";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CashflowChart = ({
  data,
  finalBalance,
}: {
  data: {
    month: string;
    amount: number;
  }[];
  finalBalance: number;
}) => {
  return (
    <div className="space-y-6 h-fit">
      <div>
        <div className="text-sm font-medium text-gray-500">FINAL BALANCE</div>
        <div className="flex gap-2 items-center">
          <div className="text-2xl font-bold text-gray-800">
            {formatCurrency(finalBalance, "AED")}
          </div>
          {/* <div className="rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-600">
            +4.51%
          </div> */}
        </div>
      </div>
      <div className="h-[240px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            className="-ml-5"
          >
            <defs>
              <linearGradient id="colorAmount" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#3b82f6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: "#6B7280", dy: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: "#6B7280" }}
              tickFormatter={(value) => `${value / 1000}k`}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#fff",
                border: "1px solid #e5e7eb",
                borderRadius: "6px",
              }}
            />
            <Area
              type="monotone"
              dataKey="amount"
              stroke="#3b82f6"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorAmount)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CashflowChart;
