import { CustomFieldInput } from "@/helpers/customFields/custom-fields-input-form";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { trpc } from "@/helpers/trpc";
import schemas from "@heffl/server/src/routes/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

type EditCustomObjectRecordProps = {
  recordId: number;
  onClose: () => void;
};

export const EditCustomObjectRecordModal = ({
  recordId,
  onClose,
}: EditCustomObjectRecordProps) => {
  const form =
    useForm<z.infer<typeof schemas.customizations.customObjectRecord>>();
  const [loading, setLoading] = useState(false);

  const { data: record, isLoading } =
    trpc.customizations.objects.custom.records.details.useQuery(recordId);

  const updateRecordMutation =
    trpc.customizations.objects.custom.records.update.useMutation({
      onSuccess: () => {
        toast.success("Record updated successfully");
        onClose();
      },
      onError: (error) => {
        toast.error(error.message);
        setLoading(false);
      },
    });

  useEffect(() => {
    if (record) {
      form.reset({
        data: record.data as Record<string, unknown>,
      });
    }
  }, [record]);

  if (isLoading || !record) return <FullScreenSpinner />;

  const onSubmit = async (
    values: z.infer<typeof schemas.customizations.customObjectRecord>
  ) => {
    setLoading(true);
    const { isValid } = validateCustomFields({
      customFields: record.customObjects.customFields.filter(
        (field) => field.dataType !== "FILE_PICKER"
      ),
      customFieldsValues: values.data,
      form: form,
      name: "data",
    });

    if (isValid) {
      updateRecordMutation.mutate({
        id: recordId,
        customObjectRecord: values,
      });
    }
    setLoading(false);
  };

  return (
    <ModalDrawer
      open={true}
      onClose={onClose}
      className="w-full sm:w-1/3"
      title="Edit Record"
      closeOnOverlayClick={false}
      footer={
        <Button
          onClick={form.handleSubmit(onSubmit)}
          variant="primary"
          loading={loading}
          className="w-full"
        >
          Update Record
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        {record.customObjects.customFields
          .filter((field) => field.dataType !== "FILE_PICKER")
          .map((field) => (
            <div key={field.id} className="mb-4">
              <p className="text-lg font-semibold">
                {field.label}
                {field.required && <span className="text-red-500">*</span>}
              </p>
              {field.description && (
                <p className="text-sm text-gray-500">{field.description}</p>
              )}
              <FormField name={`data.${field.name}`} className="mt-2">
                <CustomFieldInput field={field} />
              </FormField>
            </div>
          ))}
      </Form>
    </ModalDrawer>
  );
};
