import { NextUIProvider } from "@nextui-org/system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { createElement, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import superjson from "superjson";

import { AlertDialogProvider } from "@heffl/ui/components/use-confirm-dialog-provider";
import { setup } from "goober";
import { AlertTriangle } from "lucide-react";
import { RouterProvider } from "react-router-dom";
import routes from "./app-routes";
import { doLogout } from "./helpers/auth";
import { trpc } from "./helpers/trpc";
import { useRealtime } from "./lib/hooks/useSocket";
import NiceModal from "@ebay/nice-modal-react";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import { PostHogProvider } from "posthog-js/react";

dayjs.extend(utc);
dayjs.extend(timezone);

setup(createElement);

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

function App() {
  const { connect } = useRealtime();

  useEffect(() => {
    connect();
  }, [connect]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            retry(failureCount: number, error: any) {
              if (error.data.code !== "UNAUTHORIZED" && failureCount < 3) {
                return true;
              }
              return false;
            },

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: async (error: any) => {
              if (error.data.code === "UNAUTHORIZED") {
                // await trpcUtils.invalidate();
                doLogout();
                location.replace("/login");
              }
            },
          },
          mutations: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: async (error: any) => {
              if (error.data.code === "UNAUTHORIZED") {
                // await trpcUtils.invalidate();
                doLogout();
                location.replace("/login");
              }
            },
          },
        },
      })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: `${import.meta.env.VITE_SERVER_URL}/api/trpc`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
          headers() {
            return {
              authorization: localStorage.getItem("hefflToken") || "",
            };
          },
        }),
      ],
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider
          apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <NuqsAdapter>
            <NextUIProvider>
              <AlertDialogProvider>
                <NiceModal.Provider>
                  <RouterProvider router={routes} />
                  {/* TODO :make toast big like sonner and only go on close */}
                  <Toaster
                    containerClassName="!top-10"
                    toastOptions={{
                      error: {
                        duration: 5000,
                        className:
                          "!bg-red-50 border border-red-500 shadow-2xl text-black font-medium",
                        icon: (
                          <AlertTriangle className="p-1 text-white bg-red-500 rounded" />
                        ),
                      },
                    }}
                  />
                </NiceModal.Provider>
              </AlertDialogProvider>
            </NextUIProvider>
          </NuqsAdapter>
        </PostHogProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
