import { z } from "zod";
import enums from "../../schemas/enums";
import { calculateLineItems } from "./calculateLineItems";
import { sum } from "radash";

export type CalculateInvoice = {
  invoiceProducts: {
    price: number;
    quantity: number;
    tax: number;
    viewType: z.infer<typeof enums.lineItemViewType>;
  }[];
  discount: number;
  paymentReceivedAllocations: { amount: number }[];
};
const calculateInvoice = (invoice: CalculateInvoice) => {
  try {
    const lineItemsCal = calculateLineItems({
      lineItems: invoice.invoiceProducts,
      discount: invoice.discount,
    });
    const invoiceSubTotal = lineItemsCal.subTotal;
    const invoiceTotal = lineItemsCal.total;
    const taxTotal = lineItemsCal.totalTax;
    const paymentsTotal = sum(
      invoice.paymentReceivedAllocations,
      (a) => a.amount
    );
    const pendingTotal = invoiceTotal - paymentsTotal;
    const totalBuyPrice = lineItemsCal.totalBuyPrice;
    return {
      invoiceSubTotal: Number(invoiceSubTotal.toFixed(2)),
      pendingTotal: Number((pendingTotal < 0 ? 0 : pendingTotal).toFixed(2)),
      invoiceTotal: Number(invoiceTotal.toFixed(2)),
      paymentsTotal: Number(paymentsTotal.toFixed(2)),
      taxTotal: Number(taxTotal.toFixed(2)),
      totalBuyPrice: Number(totalBuyPrice.toFixed(2)),
    };
  } catch (error) {
    return {
      invoiceSubTotal: 0,
      pendingTotal: 0,
      invoiceTotal: 0,
      paymentsTotal: 0,
      taxTotal: 0,
      totalBuyPrice: 0,
    };
  }
};

export default calculateInvoice;
