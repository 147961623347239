import { RouterOutputs, trpc } from "@/helpers/trpc";
import { AvatarsGroup } from "@/components/UserAvatar";
import DataGrid from "@/components/dataGrid/DataGrid";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { cn } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getProjectTaskStatus,
  taskStatuses,
} from "../details/components/kanban";
import { ProjectTaskDetailsModal } from "./components/project-task-modals";
import heffl from "@/helpers/heffl";
import { Building, Calendar, Clock, User2, UserCircle } from "lucide-react";
import appIcons from "@heffl/ui/components/appIcons";
import { ProjectTasksFilters } from ".";

const ProjectTaskList = ({
  tasks,
}: {
  tasks?: RouterOutputs["projects"]["tasks"]["list"];
  filters: ProjectTasksFilters;
  setFilters: (filters: ProjectTasksFilters) => void;
}) => {
  const navigate = useNavigate();

  const [editTaskId, setEditTaskId] = useState<number | undefined>();

  const taskUpdateMutation = trpc.projects.tasks.update.useMutation();

  const getDueDateColor = (date: Date | null) => {
    if (!date) return "";
    const today = dayjs();
    const dueDate = dayjs(date);
    if (dueDate.isBefore(today, "day")) {
      return "text-red-600";
    }
    if (dueDate.isSame(today, "day")) {
      return "text-amber-600";
    }
    return "text-gray-700";
  };

  return (
    <>
      {!!editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}

      <DataGrid
        rowKey="id"
        className="h-[calc(100vh-105px-var(--header-height))]"
        name="tasksListMain"
        label="Tasks"
        rows={tasks?.tasks || []}
        // pagination={{
        //   pageNo: filters.pageNo,
        //   pageSize: filters.pageSize,
        //   count: tasks?.meta?.count,
        //   setPageNo: (pageNo) =>
        //     setFilters({
        //       ...filters,
        //       pageNo,
        //     }),
        //   setPageSize: (pageSize) =>
        //     setFilters({
        //       ...filters,
        //       pageSize,
        //     }),
        // }}
        allowSelect={true}
        columns={[
          {
            key: "number",
            name: "Id",
            width: 80,
            renderCell: ({ row }) => (
              <span className="font-medium text-gray-600">#{row.number}</span>
            ),
          },
          {
            key: "title",
            name: "Subject",
            width: 300,
            renderCell: ({ row }) => (
              <div
                onClick={() => setEditTaskId(row.id)}
                className="font-medium text-gray-700 truncate rounded-md cursor-pointer hover:text-primary-600"
              >
                {row.title}
              </div>
            ),
          },
          {
            key: "client",
            name: "Client",
            icon: Building,
            width: 150,
            renderCell(props) {
              return (
                <Badge variant="outline" icon={UserCircle}>
                  <div
                    onClick={() =>
                      navigate(
                        `/crm/clients/details/${props.row.projects.clients?.id}`
                      )
                    }
                    className="truncate cursor-pointer hover:text-primary-600"
                  >
                    {props.row.projects.clients?.name}
                  </div>
                </Badge>
              );
            },
          },
          {
            key: "project",
            name: "Project",
            width: 150,
            renderCell(props) {
              return (
                <Badge variant="outline" icon={appIcons.projects.project.icon}>
                  <div
                    onClick={() =>
                      navigate(`/projects/details/${props.row.projectId}`)
                    }
                    className="truncate cursor-pointer hover:text-primary-600"
                  >
                    {props.row.projects.title}
                  </div>
                </Badge>
              );
            },
          },
          {
            key: "status",
            name: "Status",
            width: 140,
            editable: true,
            cellClass: "px-0",
            renderCell: ({ row: task }) => (
              <Popover>
                <PopoverTrigger className="w-full h-full">
                  <div
                    className={cn(
                      "w-full h-full flex items-center justify-center text-white font-medium",
                      getProjectTaskStatus(task.status).color
                    )}
                  >
                    {getProjectTaskStatus(task.status).label}
                  </div>
                </PopoverTrigger>
                <PopoverContent className="space-y-2 w-56" sideOffset={0}>
                  <PopoverArrow className="fill-white" />
                  {taskStatuses.map((status) => (
                    <div
                      key={status.value}
                      onClick={() => {
                        taskUpdateMutation.mutate({
                          id: task.id,
                          task: {
                            status: status.value,
                          },
                        });
                      }}
                      className={cn(
                        "p-2 cursor-pointer w-full flex items-center justify-center rounded-sm text-white font-medium",
                        status.color
                      )}
                      style={{
                        backgroundColor: status.hex,
                      }}
                    >
                      {status.label}
                    </div>
                  ))}
                </PopoverContent>
              </Popover>
            ),
          },
          {
            key: "assignee",
            name: "Assignee",
            icon: User2,
            width: 120,
            renderCell: ({ row }) => (
              <AvatarsGroup
                size="xs"
                users={row.projectTaskAssignees.map(
                  (assignee) => assignee.users
                )}
              />
            ),
          },
          {
            key: "dueDate",
            name: "Due Date",
            icon: Calendar,
            width: 120,
            renderCell: ({ row }) => (
              <div className={cn("font-medium", getDueDateColor(row.date))}>
                {row.date ? dayjs(row.date).format("DD MMM YYYY") : "-"}
              </div>
            ),
          },
          {
            key: "dueDays",
            name: "Due days",
            width: 120,
            renderCell: ({ row }) => {
              const diffDays = dayjs().diff(
                dayjs(row.date).subtract(1, "day"),
                "day"
              );

              const isDue = diffDays > 0 && row.status !== "COMPLETED";

              return (
                <span className={cn("text-gray-600", isDue && "text-red-600")}>
                  {isDue ? `${diffDays} days` : "-"}
                </span>
              );
            },
          },

          {
            key: "createdAt",
            name: "Created At",
            icon: Clock,
            width: 120,
            renderCell: ({ row }) => (
              <div className="text-gray-600">
                {dayjs(row.createdAt).format("DD/MM/YYYY hh:mm a")}
              </div>
            ),
          },
          {
            key: "createdBy",
            name: "Created By",
            icon: User2,
            width: 200,
            renderCell: ({ row }) => (
              <Badge variant="outline" avatar>
                {heffl.format.name(row.createdBy)}
              </Badge>
            ),
          },
        ]}
      />
    </>
  );
};

export default ProjectTaskList;
