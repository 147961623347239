/* eslint-disable react-refresh/only-export-components */
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { trpc } from "@/helpers/trpc";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import {
  Activity,
  AlarmClock,
  ChevronDown,
  ChevronUp,
  Mail,
  Phone,
  StickyNote,
  Users,
} from "lucide-react";
import { useState } from "react";

type TType = "event" | "task" | "email" | "phone" | "meeting" | "note";

export const historyIcons: Record<TType, React.ReactNode> = {
  task: <AlarmClock className="h-4" />,
  event: <Activity className="h-4" />,
  email: <Mail className="h-4" />,
  phone: <Phone className="h-4" />,
  meeting: <Users className="h-4" />,
  note: <StickyNote className="h-4" />,
};

const LeadHistory = ({ leadId }: { leadId: number }) => {
  const [open, setOpen] = useState(true);
  const [selectedHistory, setSeletedHistory] = useState("all");

  const { data: leadHistory } = trpc.leads.history.useQuery({
    leadId,
  });

  if (!leadHistory) return <FullScreenSpinner />;

  return (
    <Collapsible className="mt-4" open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger>
        <div className="flex flex-row gap-1 items-center">
          {open ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <h1 className="text-sm font-semibold">History</h1>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <Tabs
          className="hidden mt-3 mb-4 sm:block"
          value={selectedHistory}
          onValueChange={setSeletedHistory}
        >
          <TabsList className="bg-gray-200">
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="activity">Activities</TabsTrigger>
            <TabsTrigger value="note">Notes</TabsTrigger>
          </TabsList>
        </Tabs>
        <ActivityTimeline
          activities={leadHistory.filter(
            ({ type }) =>
              selectedHistory === "all" ||
              selectedHistory === type ||
              (selectedHistory === "activity" &&
                ["email", "phone", "meeting", "task"].includes(type))
          )}
        />
      </CollapsibleContent>
    </Collapsible>
  );
};

export default LeadHistory;
