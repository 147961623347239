import { trpc } from "@/helpers/trpc";
import Select from "@heffl/ui/components/primitives/select";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useImmer } from "use-immer";
import { periodOptions } from "../dashboard";
import dayjs from "dayjs";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import heffl from "@/helpers/heffl";

const formatYAxis = (value: number) => {
  if (value === 0) return "0";
  return `${value / 1000}K`;
};

const IncomeExpenseChart = () => {
  const [options, setOptions] = useImmer<{
    period: keyof typeof periodOptions;
    type: "accrual" | "cash";
  }>({
    period: "last_12_months",
    type: "accrual",
  });

  const { data: incomeExpenseDataMonthly } =
    trpc.books.dashboards.incomeExpenseDataMonthly.useQuery({
      startDate: periodOptions[options.period].startDate,
      endDate: periodOptions[options.period].endDate,
      type: options.type,
    });

  const chartData =
    incomeExpenseDataMonthly?.map((d) => ({
      ...d,
      month: dayjs(d.date).format("MMM YYYY"),
      income: Number(d.income) || 0,
      expense: Number(d.expense) || 0,
    })) || [];

  return (
    <div className="w-full h-fit">
      <div className="flex flex-col gap-4 sm:flex-row sm:justify-between sm:items-center">
        <h2 className="text-lg font-semibold text-gray-800 sm:text-xl">
          Income & Expenses
        </h2>
        <div className="flex flex-col gap-2 sm:flex-row">
          <TabsInput
            value={options.type}
            onChange={(value) =>
              setOptions((draft) => {
                draft.type = value as "accrual" | "cash";
              })
            }
            options={[
              {
                label: "Accrual",
                value: "accrual",
              },
              {
                label: "Cash",
                value: "cash",
              },
            ]}
          />
          <Select
            value={options.period}
            onChange={(value) =>
              setOptions((draft) => {
                draft.period = value as keyof typeof periodOptions;
              })
            }
            options={[
              {
                label: "This month",
                value: "this_month",
              },
              {
                label: "Last month",
                value: "last_month",
              },
              {
                label: "Last 6 months",
                value: "last_6_months",
              },
              {
                label: "Last 12 months",
                value: "last_12_months",
              },
            ]}
            placeholder="Select period"
          />
        </div>
      </div>
      <div className="h-[250px] sm:h-[300px] mt-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 10,
              left: 0,
              bottom: 5,
            }}
            barGap={0}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month"
              tick={{ fontSize: 10, fill: "#666" }}
              tickLine={false}
              axisLine={false}
              interval="preserveStartEnd"
              angle={-45}
              textAnchor="end"
              height={50}
            />
            <YAxis
              tickFormatter={formatYAxis}
              tick={{ fontSize: 10, fill: "#666" }}
              tickLine={false}
              axisLine={false}
              width={40}
            />
            <Tooltip
              formatter={(value: number) => [
                heffl.format.currency(value, "AED"),
                "",
              ]}
              contentStyle={{
                backgroundColor: "white",
                border: "none",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                fontSize: "12px",
              }}
            />
            <Bar
              dataKey="income"
              fill="#4ade80"
              radius={[4, 4, 0, 0]}
              maxBarSize={40}
            />
            <Bar
              dataKey="expense"
              fill="#fb923c"
              radius={[4, 4, 0, 0]}
              maxBarSize={40}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <p className="mt-4 text-xs text-gray-500 sm:text-sm">
        * Income and expense values displayed are exclusive of taxes.
      </p>
    </div>
  );
};

export default IncomeExpenseChart;
