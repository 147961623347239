import { Prisma } from "@prisma/client";
import { calculateLineItems } from "./calculateLineItems";
import dayjs from "dayjs";

type TScheduleRevenue = Prisma.fsSchedulesGetPayload<{
  select: {
    startDate: true;
    fsScheduleServices: {
      select: {
        quantity: true;
        price: true;
        tax: true;
        viewType: true;
      };
    };
    fsScheduleAssignees: {
      select: {
        id: true;
      };
    };
    fsJobs: {
      select: {
        discount: true;
        fsSchedulePricingType: true;
        fsJobServices: {
          select: {
            quantity: true;
            price: true;
            tax: true;
            viewType: true;
          };
        };
        fsSchedules: {
          select: {
            fsScheduleServices: true;
            fsScheduleAssignees: {
              select: {
                id: true;
              };
            };
          };
        };
      };
    };
  };
}>;

// TODO: if 1st schedule drain total job revenue, reflect on next schedules

export const calculateScheduleRevenue = (
  row: TScheduleRevenue,
  dates: {
    startDate: Date;
    endDate: Date;
  }
) => {
  const startDate = dayjs(row.startDate).tz("Asia/Dubai").startOf("day");
  const rangeStart = dayjs(dates.startDate).tz("Asia/Dubai").startOf("day");
  const rangeEnd = dayjs(dates.endDate).tz("Asia/Dubai").endOf("day");

  if (startDate.isBefore(rangeStart) || startDate.isAfter(rangeEnd)) {
    return 0;
  }

  const schedulesWithServices = row.fsJobs.fsSchedules.filter(
    (schedule) => schedule.fsScheduleServices.length
  );

  const totalSchedulesCountWithoutServices =
    row.fsJobs.fsSchedules.length - schedulesWithServices.length;

  const totalJobRevenue = calculateLineItems({
    lineItems: row.fsJobs.fsJobServices,
    discount: row.fsJobs.discount,
  }).total;

  const totalScheduleServicesRevenue = schedulesWithServices.length
    ? schedulesWithServices.reduce((acc, schedule) => {
        const scheduleServicesRevenue = calculateLineItems({
          lineItems: schedule.fsScheduleServices,
          discount: 0,
        });
        return acc + scheduleServicesRevenue.total;
      }, 0)
    : 0;

  const currentScheduleServicesRevenue = row.fsScheduleServices.length
    ? calculateLineItems({
        lineItems: row.fsScheduleServices,
        discount: 0,
      })
    : undefined;

  if (row.fsJobs.fsSchedulePricingType === "SCHEDULE") {
    if (currentScheduleServicesRevenue) {
      return currentScheduleServicesRevenue.total;
    }
    return (
      (totalJobRevenue - totalScheduleServicesRevenue) /
      totalSchedulesCountWithoutServices
    );
  }
  const totalAssigneesCount = row.fsJobs.fsSchedules.reduce(
    (acc, schedule) => acc + schedule.fsScheduleAssignees.length,
    0
  );
  if (row.fsJobs.fsSchedulePricingType === "FIELD_STAFF") {
    const scheduleAssigneePercentage =
      row.fsScheduleAssignees.length / totalAssigneesCount;
    return totalJobRevenue * scheduleAssigneePercentage;
  }
  return 0;
};
