import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { formatCurrency, objectToParamsJSON } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Box, ShoppingCart } from "lucide-react";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";

interface PageFilters {
  dates?: [Date, Date];
}

export const reportDatePresets: {
  label: string;
  value: [Date, Date];
}[] = [
  {
    label: "Today",
    value: [dayjs().startOf("day").toDate(), dayjs().endOf("day").toDate()],
  },
  {
    label: "This Week",
    value: [dayjs().startOf("week").toDate(), dayjs().endOf("week").toDate()],
  },
  {
    label: "This Month",
    value: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
  },
  {
    label: "This Year",
    value: [dayjs().startOf("year").toDate(), dayjs().endOf("year").toDate()],
  },
  {
    label: "Previous Week",
    value: [
      dayjs().subtract(1, "week").startOf("week").toDate(),
      dayjs().subtract(1, "week").endOf("week").toDate(),
    ],
  },
  {
    label: "Previous Month",
    value: [
      dayjs().subtract(1, "month").startOf("month").toDate(),
      dayjs().subtract(1, "month").endOf("month").toDate(),
    ],
  },
  {
    label: "Previous Year",
    value: [
      dayjs().subtract(1, "year").startOf("year").toDate(),
      dayjs().subtract(1, "year").endOf("year").toDate(),
    ],
  },
];

const SalesByItemReport: FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<PageFilters>({
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
  });

  const { data: report, isLoading } = trpc.books.reports.salesByItem.useQuery({
    dates: filters.dates!,
  });

  return (
    <Page title="Sales By Item Report" className="!p-0" fullWidth showBack>
      <FilterBar
        onChange={() => {}}
        className="px-3 pt-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
            disableClear: true,
            presets: reportDatePresets,
          },
        ]}
      />
      <DataGrid
        rowKey="productId"
        name="salesByItemReport"
        label="Sales By Item"
        className="h-[calc(100vh-140px)] mt-12"
        loading={isLoading}
        empty={{
          icon: ShoppingCart,
          title: "No Sales",
          description: "No sales found for the selected filters.",
          buttonSize: "sm",
        }}
        rows={report || []}
        columns={[
          {
            key: "name",
            name: "Item Name",
            renderCell: ({ row }) => (
              <div
                onClick={() => {
                  navigate(
                    `/sales/invoices?${objectToParamsJSON({
                      statuses: ["SENT", "PARTIALLY_PAID", "PAID"],
                      ...(row.type === "ITEM"
                        ? { search: row.name }
                        : { productIds: [row.productId] }),
                      dates: filters.dates,
                    })}`
                  );
                }}
                className="flex gap-1 items-center cursor-pointer hover:underline text-primary-800"
              >
                {row.name}{" "}
                {row.type === "PRODUCT" && (
                  <Box size={16} className="text-gray-500" />
                )}
              </div>
            ),
            width: 350,
          },
          {
            key: "_count",
            name: "Quantity",
            width: 140,
            renderCell: ({ row }) => row._sum?.quantity || 0,
          },
          {
            key: "_sum",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._sum?.price || 0, "AED", true),
          },
          {
            key: "tax",
            name: "Tax",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._sum?.tax || 0, "AED", true),
          },
          {
            key: "buyPrice",
            name: "Buy Price",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._sum?.buyPrice || 0, "AED", true),
          },
          {
            key: "_avg",
            name: "Average Price",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._avg?.price || 0, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default SalesByItemReport;
