import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import SimpleTable from "@heffl/ui/components/simple-table";
import { isAdmin } from "@heffl/ui/lib/utils";
import { useNavigate } from "react-router-dom";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";

interface Report {
  title: string;
  description: string;
  slug: string;
}

const reports: Report[] = [
  {
    title: "Invoice Summary Report",
    description: "Overview of invoice status and key financial metrics.",
    slug: "/sales/reports/invoice-summary-report",
  },
  {
    title: "Trial Balance",
    description: "Overview of trial balance.",
    slug: "/books/reports/trial-balance",
  },
  {
    title: "Sales by Item Report",
    description: "Overview of sales by item.",
    slug: "/books/reports/sales-by-item",
  },
  {
    title: "Purchases by Item Report",
    description: "Overview of purchases by item.",
    slug: "/books/reports/purchases-by-item",
  },
  {
    title: "Profit and Loss Report",
    description: "Overview of profit and loss.",
    slug: "/sales/reports/profit-loss-report",
  },
  {
    title: "Payments Received Report",
    description: "Detailed report on payments received.",
    slug: "/sales/reports/payments-received-report",
  },
  {
    title: "Vendor Transactions Report",
    description: "Detailed report on vendor transactions.",
    slug: "/purchases/reports/vendor-transactions",
  },
  {
    title: "Quotes Summary Report",
    description: "Overview of quote status and key financial metrics.",
    slug: "/sales/reports/quotes-summary-report",
  },
  {
    title: "Vendor Summary Report",
    description: "Overview of vendor status and key financial metrics.",
    slug: "/sales/reports/vendor-summary-report",
  },
  {
    title: "Customer Balances Report",
    description: "Overview of customer balances.",
    slug: "/sales/reports/customer-balances-report",
  },
  {
    title: "Vendor Balances Report",
    description: "Overview of vendor balances.",
    slug: "/sales/reports/vendor-balances-report",
  },
  {
    title: "Expenses Report",
    description: "Overview of expenses.",
    slug: "/sales/reports/expense-report",
  },
  {
    title: "Typing Center Report",
    description: "Overview of typing center status and key financial metrics.",
    slug: "/sales/reports/typing-center-report",
  },
];

const BooksReports = () => {
  const navigate = useNavigate();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  if (!currentUser) return <FullScreenSpinner />;

  if (!isAdmin(currentUser)) return <NoPermissionScreen />;

  return (
    <Page title="Books Reports">
      <SimpleTable
        columns={[
          {
            label: "Report Title",
            render: (report: Report) => (
              <span
                className="font-medium text-blue-400 cursor-pointer"
                onClick={() => {
                  navigate(report.slug);
                }}
              >
                {report.title}
              </span>
            ),
          },
          {
            label: "Description",
            render: (report: Report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            label: "Created By",
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rows={reports}
        idKey="slug"
      />
    </Page>
  );
};

export default BooksReports;
