import {
  AddTaskModal,
  TaskDetailsModal,
} from "@/components/actionsModals/tasks-modals";
import FilterBar from "@/components/filters";
import { SearchInput } from "@/components/FormComponents";
import KanbanBoard from "@/components/kanbanBoard/kanban";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { TaskLinkBadge } from "@/pages/personal-dashboard";
import { taskStatuses } from "@/pages/projects/details/components/kanban";
import { taskDateColor } from "@/pages/projects/details/components/kanban/TaskCard";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import Empty from "@/components/Empty";
import enums from "@heffl/server/src/schemas/enums";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import TagsInput from "@heffl/ui/components/TagInput";
import {
  cn,
  dynamicDateFormatting,
  formatName,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  AlarmClock,
  Calendar,
  CheckSquareIcon,
  KanbanIcon,
  List,
  UserCircle,
} from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { z } from "zod";
import TaskList from "./list";

const tailwindDateColor = (date: Date): string => {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "text-green-500"; // Today
  } else if (diffDays < 0) {
    return "text-red-500"; // Past
  } else {
    return "text-gray-500"; // Upcoming
  }
};

type TaskFilter = {
  assignees: number[];
  createdBy: number[];
  statuses: z.infer<typeof enums.projectTaskStatusTypes>[];
  search: string;
};

const TaskMobileCard = ({
  task,
  onClick,
  onChange,
}: {
  task: RouterOutputs["tasks"]["list"][number];
  onClick: () => void;
  onChange: (status: z.infer<typeof enums.projectTaskStatusTypes>) => void;
}) => {
  return (
    <div
      key={task.id}
      className="p-3 w-full border-t border-gray-200"
      onClick={onClick}
    >
      <div className="flex gap-2">
        <Checkbox
          className="rounded-full"
          value={task.status === "COMPLETED"}
          onChange={() => {
            onChange(task.status === "COMPLETED" ? "OPEN" : "COMPLETED");
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <div className="w-full">
          <p className="text-sm">{task.title}</p>
          <div className="flex gap-2 justify-between items-center">
            <div className="flex gap-2 items-center">
              <span
                className={cn(
                  "flex gap-1 items-center -mt-0.5",
                  tailwindDateColor(task.date)
                )}
              >
                <Calendar className="w-3 h-3" />
                <p className="text-[11px] ">
                  {dynamicDateFormatting(task.date, true)}
                </p>
              </span>
              <div className="flex gap-1 text-[11px] text-gray-500 items-center">
                <UserCircle className="w-3 h-3" />
                {task.taskAssignees
                  .map((assignee) => assignee.users.firstName)
                  .join(", ")}
                {task.taskAssignees.length === 0 && (
                  <span className="text-gray-400">Unassigned</span>
                )}
              </div>
            </div>
            <TaskLinkBadge
              task={task}
              className="mt-1 !bg-white !border-white w-fit"
              variant="outline"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const Tasks = () => {
  const { view } = useParams<{ view: "board" | "table" }>();
  const [animationParent] = useAutoAnimate();
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();

  const [taskDetailsId, setTaskDetailsId] = useState<number | undefined>();
  const [showAddTaskDrawer, setShowAddTaskDrawer] = useState(false);
  const [filters, setFilters] = useParamsState<TaskFilter>({
    assignees: [],
    createdBy: [],
    statuses: ["IN_PROGRESS", "ON_HOLD", "OPEN"],
    search: "",
  });

  const updateTaskMutation = trpc.tasks.update.useMutation();
  const bulkUpdateTaskMutation = trpc.tasks.bulkUpdate.useMutation();

  const taskParams = useMemo(() => {
    return {
      assignees: filters.assignees,
      createdBy: filters.createdBy,
      statuses: filters.statuses,
      search: filters.search,
      orderBy:
        view === "board" ? ("position" as const) : ("createdAt" as const),
    };
  }, [filters, view]);

  const { data: tasks, isLoading: tasksLoading } =
    trpc.tasks.list.useQuery(taskParams);
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const renderTask = (task: RouterOutputs["tasks"]["list"][number]) => {
    return (
      <div
        className={cn(
          "px-3 py-2 bg-white rounded-md border border-gray-200 shadow-sm cursor-pointer !max-w-[260px] !overflow-hidden hover:text-primary-500"
        )}
        onClick={() => setTaskDetailsId(Number(task.id))}
      >
        <p className="text-sm font-medium hover:text-primary-6000">
          {task.title}
        </p>
        <div className="flex gap-1 mt-0.5 justify-between items-baseline">
          <div className="flex gap-2 items-baseline">
            <p className="text-xs font-medium text-gray-600">TASK-{task.id}</p>
            {task.date && (
              <p className={cn("text-xs ", taskDateColor(task.date))}>
                {dynamicDateFormatting(task.date, true)}
              </p>
            )}
            {task.reminders.length > 0 && (
              <span className="flex gap-0.5 text-xs text-primary-700">
                <AlarmClock className="w-3.5 h-3.5 " />
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col mt-3">
          <div className="flex gap-2 items-center py-1 rounded-md hover:bg-gray-100">
            <UserCircle className="w-3.5 h-3.5 text-gray-500" />
            <TagsInput
              small
              tags={users
                ?.filter(
                  (user) =>
                    task.taskAssignees?.some(
                      (taskAssignee) => taskAssignee.userId === user.id
                    ) ?? true
                )
                .map((user) => ({
                  id: user.id,
                  name: formatName(user),
                }))}
              uniqueColor
              value={task.taskAssignees.map((assignee) => assignee.userId)}
              onChange={(tags) => {
                updateTaskMutation.mutate({
                  id: task.id,
                  task: {
                    taskAssignees: tags,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Page title="Tasks" className="!p-0" fullWidth>
      {!!taskDetailsId && (
        <TaskDetailsModal
          open={!!taskDetailsId}
          onClose={() => setTaskDetailsId(undefined)}
          taskId={taskDetailsId}
        />
      )}
      <AddTaskModal
        open={showAddTaskDrawer}
        onClose={() => setShowAddTaskDrawer(false)}
      />

      <div className="flex justify-between items-center p-2 border-b border-gray-200">
        <SearchInput
          value={filters.search}
          onChange={(value) => setFilters({ search: value })}
          placeholder="Search tasks"
          className="w-full"
        />
        <ResponsiveActionButton
          onClick={() => setShowAddTaskDrawer(true)}
          text="Task"
        />
      </div>
      {!isMobile() && (
        <StripeTabs
          contentClassName="pt-0"
          className="pt-2 w-full"
          tabParentClassName="pl-4"
          value={view}
          onChange={(tab) => navigate(`/crm/tasks/${tab}`)}
          items={[
            { label: "Board", key: "board", icon: KanbanIcon },
            { label: "Table", key: "table", icon: List },
          ]}
        />
      )}

      <FilterBar
        className="px-3 pb-1 mt-3 sm:mb-3"
        onChange={() => {}}
        filters={[
          {
            key: "statuses",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({
                statuses: value as z.infer<
                  typeof enums.projectTaskStatusTypes
                >[],
              }),
            options: taskStatuses.map((status) => ({
              label: status.label,
              value: status.value,
            })),
          },
          {
            key: "assignees",
            type: "checkbox",
            label: "Assignees",
            value: filters.assignees,
            onChange: (value) => setFilters({ assignees: value as number[] }),
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
          },
          {
            key: "createdBy",
            type: "checkbox",
            label: "Created by",
            value: filters.createdBy,
            onChange: (value) => setFilters({ createdBy: value as number[] }),
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
          },
        ]}
      />
      {match({ view, isMobile: isMobile(), tasks, tasksLoading })
        .with({ tasksLoading: true }, () => <FullScreenSpinner />)
        .with({ tasks: [] }, () => (
          <Empty
            title="No tasks found"
            description="Create a new task to get started"
            icon={CheckSquareIcon}
          />
        ))
        .with({ view: "board", isMobile: false }, () => (
          <KanbanBoard
            items={tasks}
            statusKey="status"
            columns={taskStatuses.map((status) => ({
              ...status,
              color: status.hex,
            }))}
            renderItem={renderTask}
            onDragging={(updated) => {
              trpcUtils.tasks.list.setData(taskParams, () => updated);
            }}
            onChange={(updated) => {
              bulkUpdateTaskMutation.mutate(
                updated.map((item) => ({
                  id: Number(item.id),
                  position: item.position,
                  status: item.status as z.infer<
                    typeof enums.projectTaskStatusTypes
                  >,
                }))
              );
            }}
            className="px-2 h-[calc(100vh-120px)] sm:h-[calc(100vh-208px)]"
          />
        ))
        .with({ view: "table", isMobile: false }, () => (
          <TaskList
            tasks={tasks || []}
            onTaskClick={(id) => setTaskDetailsId(id)}
            className="h-[calc(100vh-120px)] sm:h-[calc(100vh-208px)]"
          />
        ))
        .with({ isMobile: true }, () => {
          const todayTasks =
            tasks?.filter((task) => dayjs(task.date).isSame(dayjs(), "day")) ||
            [];
          const overdueTasks =
            tasks?.filter((task) => dayjs(task.date).isBefore(dayjs())) || [];
          const upcomingTasks =
            tasks?.filter((task) => dayjs(task.date).isAfter(dayjs())) || [];
          return (
            <div className="flex flex-col" ref={animationParent}>
              {!!filters.search.length && (
                <div className="flex flex-col mt-3">
                  {tasks?.map((task) => (
                    <TaskMobileCard
                      key={task.id}
                      task={task}
                      onClick={() => setTaskDetailsId(task.id)}
                      onChange={(status) => {
                        updateTaskMutation.mutate({
                          id: task.id,
                          task: { status },
                        });
                      }}
                    />
                  ))}
                </div>
              )}
              {!!overdueTasks.length && !filters.search.length && (
                <>
                  <div className="px-3 py-2 font-medium">Overdue</div>
                  {overdueTasks.map((task) => (
                    <TaskMobileCard
                      key={task.id}
                      task={task}
                      onClick={() => setTaskDetailsId(task.id)}
                      onChange={(status) => {
                        updateTaskMutation.mutate({
                          id: task.id,
                          task: { status },
                        });
                      }}
                    />
                  ))}
                </>
              )}
              {!!todayTasks.length && !filters.search.length && (
                <>
                  <div className="px-3 py-2 font-medium">
                    {dayjs().format("MMM D")} • Today
                  </div>
                  {todayTasks?.map((task) => (
                    <TaskMobileCard
                      key={task.id}
                      task={task}
                      onClick={() => setTaskDetailsId(task.id)}
                      onChange={(status) => {
                        updateTaskMutation.mutate({
                          id: task.id,
                          task: { status },
                        });
                      }}
                    />
                  ))}
                </>
              )}
              {!!upcomingTasks.length && !filters.search.length && (
                <>
                  <div className="px-3 py-2 font-medium">Upcoming</div>
                  {upcomingTasks?.map((task) => (
                    <TaskMobileCard
                      key={task.id}
                      task={task}
                      onClick={() => setTaskDetailsId(task.id)}
                      onChange={(status) => {
                        updateTaskMutation.mutate({
                          id: task.id,
                          task: { status },
                        });
                      }}
                    />
                  ))}
                </>
              )}
            </div>
          );
        })
        .otherwise(() => (
          <div>No view</div>
        ))}
    </Page>
  );
};

export default Tasks;
