import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const QuotationSettings = () => {
  const form = useForm<z.infer<typeof teamSettingsSchema>>({
    resolver: zodResolver(teamSettingsSchema),
  });

  const [initialTags, setInitialTags] = useState<number[]>([]);

  const { data: tags } = trpc.tags.list.useQuery({
    type: "QUOTATION",
  });
  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const updateTeamSettingsMutation = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Settings updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof teamSettingsSchema>) => {
    updateTeamSettingsMutation.mutate({
      quotation: data.quotation,
    });
  };

  useEffect(() => {
    if (teamDetails?.teamSettings) {
      form.reset({
        ...teamDetails.teamSettings,
      });
    }
  }, [teamDetails]);

  return (
    <Page title="Quotation">
      <Form {...form} onSubmit={onSubmit}>
        <FormField
          name="quotation.initialTags"
          label="Initial Tags"
          description="Tags added on quotation creation."
        >
          <MultiSelect
            className="mt-2 w-96"
            placeholder="Select tags"
            options={tags?.map((tag) => ({ label: tag.name, value: tag.id }))}
            value={initialTags}
            onChange={(v) => setInitialTags(v as number[])}
          />
        </FormField>
        <FormField
          name="quotation.requiredFields.dealId"
          label="Deal Required"
          description="Whether to require a deal ID on quotation creation."
        >
          <Switch />
        </FormField>
        <div className="flex justify-end">
          <Button
            loading={updateTeamSettingsMutation.isLoading}
            type="submit"
            icon={Save}
            variant="primary"
          >
            Update settings
          </Button>
        </div>
      </Form>
    </Page>
  );
};

export default QuotationSettings;
