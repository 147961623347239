import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import Modal from "@heffl/ui/components/primitives/modal";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { MailPlus } from "lucide-react";
import { useEffect, useState } from "react";
import SendEmailModal from "@/components/actionsModals/send-email.modal";

const msalConfig: Configuration = {
  auth: {
    clientId: "3701e902-6a03-4c43-8677-a1e24d3f3b9c",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${import.meta.env.VITE_HOST}/crm/send-email?emailConnected=1`,
    navigateToLoginRequestUrl: false,
  },
  cache: { cacheLocation: "localStorage" },
};

const pca = new PublicClientApplication(msalConfig);
const EmailConnectModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (v: boolean) => void;
}) => {
  const { instance, accounts } = useMsal();
  const query = useQueryParams();

  const [emailConnected, setEmailConnected] = useState(false);

  const userUpdateMutation = trpc.users.updateCurrent.useMutation({
    onSuccess() {
      onClose(false);
      setEmailConnected(false);
    },
  });

  const updateAccessToken = async () => {
    if (accounts.length > 0) {
      await pca.initialize();
      const tokenResponse = await instance.acquireTokenSilent({
        account: accounts[0],
        scopes: ["Mail.Read", "Mail.Send", "Contacts.Read", "offline_access"],
      });
      if (tokenResponse) {
        userUpdateMutation.mutate({
          outlookAccessToken: tokenResponse.accessToken,
        });
      }
    }
    return null;
  };

  useEffect(() => {
    const emailConnected = Number(query.get("emailConnected"));
    if (emailConnected === 1) {
      setEmailConnected(true);
      updateAccessToken();
    }
  }, [query]);

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      title="Connect your email provider"
      description="Choose your email provider form below."
    >
      {emailConnected ? (
        <FullScreenSpinner
          className="h-32"
          text="Connection succesfull, updating"
        />
      ) : (
        <div
          onClick={() =>
            instance.acquireTokenRedirect({
              scopes: [
                "Mail.Read",
                "Mail.Send",
                "Contacts.Read",
                "offline_access",
              ],
            })
          }
          className="flex gap-3 items-center p-4 mt-2 text-lg rounded-md border shadow-lg cursor-pointer hover:border-primary"
        >
          <img
            className="h-8"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/2203px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
          />
          Outlook
        </div>
      )}
    </Modal>
  );
};

const SendEmail = () => {
  const query = useQueryParams();

  const [showEmailConnect, setShowEmailConnect] = useState(false);

  useEffect(() => {
    if (query.get("emailConnected")) {
      setShowEmailConnect(true);
    }
  }, [query]);

  return (
    <div>
      <MsalProvider instance={pca}>
        <EmailConnectModal
          open={showEmailConnect}
          onClose={setShowEmailConnect}
        />
      </MsalProvider>
      <SendEmailModal open={true} onClose={() => {}} />
      <Button onClick={() => setShowEmailConnect(true)}>
        <MailPlus className="h-3.5" /> Connect Email Provider
      </Button>
    </div>
  );
};
export default SendEmail;
