import InfoItemsHZ from "@/components/InfoItemHZ";
import ResponsiveActions from "@heffl/ui/components/responsive-actions";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
// import { DATE_TIME_WORD_FORMAT_DAYJS } from "@/lib/constants";
import { cn, formatName } from "@heffl/ui/lib/utils";
import TemplateRender from "@heffl/ui/components/template-render";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  Copy,
  Info,
  MailCheck,
  MoreHorizontal,
  PencilIcon,
  Printer,
  Trash,
} from "lucide-react";
import { ReactNode, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { downloadPdf } from "../invoices/details";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";

export const InfoItemHZ = ({
  label,
  value,
  onClick,
}: {
  label: string;
  value?: string | ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div className="flex justify-between w-full">
      <p className="text-gray-500">{label}</p>
      {typeof value === "string" ? (
        <p
          onClick={onClick}
          className={cn(
            "text-sm text-gray-800",
            onClick && "text-primary cursor-pointer"
          )}
        >
          {value}
        </p>
      ) : (
        value
      )}
    </div>
  );
};

export const generateInvoiceName = (invoice: {
  date: Date;
  number: string;
  clients: {
    name: string;
  };
}) => {
  return `Proforma Invoice ${invoice.number}-${invoice.clients.name}-${dayjs(
    invoice.date
  ).format("DD-MM-YYYY")}`;
};

const ProformaInvoiceDetails = () => {
  const params = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const invoicePrintRef = useRef(null);
  const invoiceId = Number(params.id);

  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const team = useTeam();

  const { data: proformaInvoice } =
    trpc.proformaInvoice.details.useQuery(invoiceId);

  const printInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
    documentTitle: proformaInvoice ? generateInvoiceName(proformaInvoice) : "",
  });

  const invoiceUpdateMutation = trpc.proformaInvoice.update.useMutation({
    onSuccess() {
      toast.success("Invoice updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const invoiceDeleteMutation = trpc.proformaInvoice.delete.useMutation({
    onSuccess() {
      toast.success("Invoice deleted successfully");
      navigate("/sales/proforma-invoices");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (!proformaInvoice || !team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_PROFORMA_INVOICES.allowed) {
    return <NoPermissionScreen />;
  }

  const suffix = (
    <div className="flex flex-col gap-2 sm:flex-row">
      <ResponsiveActions
        actions={[
          {
            label: "Print",
            buttonClassName: "sm:rounded-r-none",
            iconClassName: "text-purple-500",
            icon: Printer,
            onClick: () => {
              setPreviewMode(false);
              setTimeout(() => {
                printInvoice();
                setPreviewMode(true);
              }, 100);
            },
          },
          {
            buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
            label: "Download",
            hideLabelOnDesktop: true,
            icon: ArrowDownToLine,
            onClick: async () => {
              setDownloadLoading(true);
              await downloadPdf({
                name: generateInvoiceName(proformaInvoice),
                url: `print?proforma-invoice=${proformaInvoice.uuid}`,
              });
              setTimeout(() => {
                setDownloadLoading(false);
              }, 3000);
            },
            loading: downloadLoading,
          },
          {
            buttonClassName: "sm:rounded-l-none sm:-ml-2",
            label: "Duplicate",
            hideLabelOnDesktop: true,
            icon: Copy,
            onClick: () =>
              navigate(
                `/sales/proforma-invoices/add?invoiceId=${proformaInvoice.id}`
              ),
          },
          {
            hide: proformaInvoice.status !== "DRAFT",
            label: "Mark as Sent",
            iconClassName: "text-green-500",
            loading: invoiceUpdateMutation.isLoading,
            icon: MailCheck,
            onClick: () => {
              invoiceUpdateMutation.mutate({
                id: proformaInvoice.id,
                proformaInvoice: {
                  status: "SENT",
                },
              });
            },
          },
          {
            icon: MoreHorizontal,
            label: "More",
            onClick: () => {},
            dropdown: (
              <>
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={() =>
                    navigate(
                      `/sales/proforma-invoices/edit/${proformaInvoice.id}`
                    )
                  }
                >
                  <PencilIcon className="mr-2 w-5" />
                  Edit
                </Button>
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={async () => {
                    const confirmed = await confirm({
                      title: "Are you sure to delete this invoice?",
                      body: `This will delete the invoice "#${proformaInvoice.number}" and its history from the system.`,
                      actionButton: "Delete",
                    });
                    if (confirmed) {
                      invoiceDeleteMutation.mutate(proformaInvoice.id);
                    }
                  }}
                >
                  <Trash className="mr-2 w-5 text-red-500" />
                  Delete
                </Button>
              </>
            ),
          },
        ]}
      />
    </div>
  );

  //   const timeLineItems = () => {
  //     const items = [];
  //     items.push({
  //       title: "Invoice created",
  //       description: dayjs(invoice.createdAt).format(DATE_TIME_WORD_FORMAT_DAYJS),
  //     });
  //     if (invoice.markedSentOn) {
  //       items.push({
  //         title: "Invoice sent",
  //         description: dayjs(invoice.markedSentOn).format(
  //           DATE_TIME_WORD_FORMAT_DAYJS
  //         ),
  //       });
  //       return items.reverse();
  //     }
  //   };

  return (
    <Page
      fullWidth
      title={`Proforma Invoice #${proformaInvoice.number}`}
      description={`Created on ${format(
        proformaInvoice.createdAt,
        "dd MMM yyyy hh:mm a"
      )}`}
      showBack
      suffix={suffix}
    >
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-8">
        <div className="flex justify-center w-full sm:col-span-6">
          <div className="w-full rounded-lg border border-gray-200 shadow-lg sm:w-fit">
            <div ref={invoicePrintRef}>
              <TemplateRender
                contentHtml={proformaInvoice.contentInjected}
                template={proformaInvoice.documentTemplates}
                previewMode={previewMode}
              />
            </div>
          </div>
        </div>
        <div className="space-y-4 w-full sm:col-span-2">
          <Card
            title="Details"
            icon={<Info className="h-5 text-blue-500" />}
            className="space-y-3"
          >
            <InfoItemsHZ
              items={[
                {
                  label: "Date",
                  value: dayjs(proformaInvoice.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Client",
                  value: proformaInvoice.clients.name,
                  onClick: () =>
                    navigate(
                      `/sales/clients/details/${proformaInvoice.clients.id}`
                    ),
                },
                {
                  label: "Due date",
                  value: dayjs(proformaInvoice.dueDate).format("DD/MM/YYYY"),
                },
                {
                  label: "Created by",
                  value: formatName(proformaInvoice.createdBy),
                },
              ]}
            />
          </Card>
          {/* <Card
            title="Timeline"
            icon={<CalendarDays className="h-5 text-green-500" />}
          >
            <Timeline activeItem={-1} items={timeLineItems()} />
          </Card> */}
        </div>
      </div>
    </Page>
  );
};

export default ProformaInvoiceDetails;
