import Empty from "@/components/Empty";
import {
  ActivityDetailsModal,
  AddActivityModal,
} from "@/components/actionsModals/activities-modals";
import {
  AddTaskModal,
  EditTaskModal,
  TaskDetailsModal,
} from "@/components/actionsModals/tasks-modals";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import RenderHtml from "@heffl/ui/components/render-html";
import RightClickMenu from "@heffl/ui/components/right-click-menu";
import NextTabs from "@heffl/ui/components/tabs";
import {
  cn,
  dynamicDateFormatting,
  generateUniqueColor,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  AlarmClock,
  AtSign,
  Building2,
  CheckCircle,
  CheckSquare,
  CircleDashed,
  CircleUserRound,
  Contact,
  ExternalLink,
  Handshake,
  Pin,
  PinOff,
  Plus,
  Target,
  Users,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";
import CircleTick from "./crm/deals/details/components/circle-tick";
import DayEventCalendar from "./field-service/components/DayEventCalendar";
import { apps, defaultApps } from "./home/apps-list";

const dates = {
  pending: {
    startDate: dayjs().subtract(1, "month").startOf("day").toDate(),
    endDate: dayjs().add(1, "month").endOf("day").toDate(),
  },
  completed: {
    startDate: dayjs().subtract(1, "month").startOf("day").toDate(),
    endDate: dayjs().add(1, "month").endOf("day").toDate(),
  },
  assigned: {
    startDate: dayjs().subtract(1, "month").startOf("day").toDate(),
    endDate: dayjs().add(1, "month").endOf("day").toDate(),
  },
};

export const remindersToPlainText = (date: Date, dateType: string) => {
  if (dateType === "CUSTOM_DATE") {
    return `On ${dayjs(date).format("DD/MM/YY - hh:mm A")}`;
  }
  return capitalize(dateType.split("_").join(" ").toLowerCase());
};

import useNav from "@/stores/useNav";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import { makeEllipsis } from "@heffl/ui/lib/utils";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import useTeam from "@/lib/hooks/useTeam";
import enums from "@heffl/server/src/schemas/enums";

interface TaskLinkBadgeProps {
  task: {
    deals?: { title: string; id: number } | null;
    leads?: { title: string; id: number } | null;
    clients?: { name: string; id: number } | null;
    contacts?: { firstName: string; id: number } | null;
  };
  className?: string;
  tooltipSide?: "top" | "bottom" | "left" | "right";
  variant?: "badge" | "outline";
  small?: boolean;
}

export const TaskLinkBadge: React.FC<TaskLinkBadgeProps> = ({
  task,
  className,
  tooltipSide = "bottom",
  variant = "badge",
  small = isMobile(),
}) => {
  const navigate = useNavigate();

  const taskMapping = [
    {
      condition: task.deals,
      icon: Handshake,
      text: task.deals?.title,
      path: `/crm/deals/details/${task.deals?.id}`,
    },
    {
      condition: task.leads,
      icon: Target,
      text: task.leads?.title,
      path: `/crm/leads/details/${task.leads?.id}`,
    },
    {
      condition: task.clients,
      icon: Building2,
      text: task.clients?.name,
      path: `/crm/clients/details/${task.clients?.id}`,
    },
    {
      condition: task.contacts,
      icon: Contact,
      text: task.contacts?.firstName,
      path: `/crm/contacts/details/${task.contacts?.id}`,
    },
  ];

  const { icon, text, path } =
    taskMapping.find(({ condition }) => condition) || {};

  const Icon = icon;

  return icon && text && path && variant === "badge" ? (
    <Badge
      icon={icon}
      variant="unique"
      onClick={() => navigate(path)}
      className={cn("cursor-pointer", className)}
      tooltip={{
        content: text,
        side: tooltipSide,
      }}
      small={small}
    >
      {makeEllipsis(text, 20)}
    </Badge>
  ) : Icon && text && path && variant === "outline" ? (
    <div
      className="flex gap-1 items-center text-gray-500"
      style={{ color: generateUniqueColor(text, 600) }}
      onClick={(e) => {
        e.stopPropagation();
        navigate(path);
      }}
    >
      <p className="text-[11px]">{makeEllipsis(text, 14)}</p>
      <Icon className="w-2.5 h-2.5" />
    </div>
  ) : null;
};

interface ActivityLinkBadgeProps {
  activity: {
    deals?: { title: string; id: number } | null;
    leads?: { title: string; id: number } | null;
    clients?: { name: string; id: number } | null;
    contacts?: { firstName: string; id: number } | null;
  };
  className?: string;
  tooltipSide?: "top" | "bottom" | "left" | "right";
  small?: boolean;
}

export const ActivityLinkBadge: React.FC<ActivityLinkBadgeProps> = ({
  activity,
  className,
  tooltipSide = "bottom",
  small = false,
}) => {
  const navigate = useNavigate();

  const taskMapping = [
    {
      condition: activity.deals,
      icon: Handshake,
      text: activity.deals?.title,
      path: `/crm/deals/details/${activity.deals?.id}`,
    },
    {
      condition: activity.leads,
      icon: Target,
      text: activity.leads?.title,
      path: `/crm/leads/details/${activity.leads?.id}`,
    },
    {
      condition: activity.clients,
      icon: Building2,
      text: activity.clients?.name,
      path: `/crm/clients/details/${activity.clients?.id}`,
    },
    {
      condition: activity.contacts,
      icon: Contact,
      text: activity.contacts?.firstName,
      path: `/crm/contacts/details/${activity.contacts?.id}`,
    },
  ];

  const { icon, text, path } =
    taskMapping.find(({ condition }) => condition) || {};

  return icon && text && path ? (
    <Badge
      icon={icon}
      variant="unique"
      onClick={() => navigate(path)}
      className={cn("cursor-pointer", className)}
      tooltip={{
        content: text,
        side: tooltipSide,
      }}
      small={small}
    >
      {makeEllipsis(text, 20)}
    </Badge>
  ) : null;
};
type TaskCard = {
  task: RouterOutputs["users"]["personal"]["tasks"][number];
  onClick: (id: number) => void;
};
export const TaskCard = ({ task, onClick }: TaskCard) => {
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const taskUpdateMutation = trpc.tasks.update.useMutation({
    onSuccess: () => {
      toast.success("Task updated");
    },
  });

  return (
    <div
      key={task.id}
      className="relative p-3 w-full rounded-md border shadow-sm cursor-pointer sm:bg-white h-fit"
      onClick={() => onClick(task.id)}
    >
      <EditTaskModal
        open={showEditTaskModal}
        onClose={() => setShowEditTaskModal(false)}
        taskId={task.id}
      />

      <div className="flex gap-1 items-center cursor-pointer">
        <CircleTick
          checked={task.status === "COMPLETED"}
          loading={
            taskUpdateMutation.variables?.id === task.id &&
            taskUpdateMutation.isLoading
          }
          onClick={(e) => {
            e.stopPropagation();
            taskUpdateMutation.mutate({
              id: task.id,
              task: {
                status: task.status === "COMPLETED" ? "OPEN" : "COMPLETED",
              },
            });
          }}
        />
        <p className="text-base font-medium line-clamp-2">{task.title}</p>
      </div>
      <div
        className="text-sm text-gray-500 line-clamp-2"
        dangerouslySetInnerHTML={{
          __html: task.description || "",
        }}
      />
      <div className="flex flex-row gap-1.5 items-center mt-1 text-xs text-gray-600">
        <TaskLinkBadge task={task} />
        <Badge
          className="w-fit"
          variant={
            dayjs(task.date).isSame(dayjs(), "day")
              ? "success"
              : task.status === "COMPLETED"
              ? "success"
              : dayjs(task.date).isBefore(dayjs(), "day")
              ? "error"
              : "neutral"
          }
        >
          {dynamicDateFormatting(task.date, true)}
        </Badge>
        {!!task.reminders.length && (
          <Tooltip
            content={remindersToPlainText(
              task.reminders[0].date,
              task.reminders[0].dateType
            )}
            side="right"
          >
            <AlarmClock className="h-4 text-purple-500" />
          </Tooltip>
        )}

        {task.taskAssignees.length ? (
          <div className="flex items-center">
            <Users className="h-3.5" />
            {task.taskAssignees.map((user) => user.users.firstName).join(", ")}
          </div>
        ) : null}
      </div>
      {task.status === "COMPLETED" && task.completedOn ? (
        <p className="pt-2 text-xs text-gray-500">
          Completed {dayjs(task.completedOn).fromNow()}
        </p>
      ) : null}
    </div>
  );
};

export const getTeamApps = (installedAppsNames: string[], userType: string) => {
  const installedApps = apps.filter((app) =>
    installedAppsNames.includes(app.id)
  );
  const allTeamApps = [...installedApps, ...defaultApps];

  const adminApps: z.infer<typeof enums.appKeys>[] = ["EMPLOYEES", "SETTINGS"];

  const userApps = allTeamApps.filter(
    (app) =>
      !adminApps.includes(app.id) ||
      userType === "SUPER_ADMIN" ||
      userType === "ADMIN"
  );
  return userApps;
};

const PersonalDashboard = () => {
  const navigate = useNavigate();

  const setSelectedPage = useNav((state) => state.setSelectedPage);

  const [selectedTab, setSelectedTab] = useState<
    "pending" | "completed" | "assigned"
  >("pending");
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState<
    undefined | Partial<z.infer<typeof Schemas.crm.activity>>
  >(undefined);
  const [showDetailsActivityModal, setShowDetailsActivityModal] = useState<
    null | number
  >(null);
  const [mobileSelectedTab, setMobileSelectedTab] = useState<
    "activities" | "tasks" | "mentions"
  >("activities");
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState<
    null | number
  >(null);

  const team = useTeam();

  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const { data } = trpc.users.personal.useQuery({
    startDate: dates[selectedTab].startDate,
    endDate: dates[selectedTab].endDate,
    type: selectedTab,
  });
  const activityUpdateMutation = trpc.activities.update.useMutation({
    onSuccess: () => {
      toast.success("Activity updated");
    },
  });

  const updateUserMutation = trpc.users.updateCurrent.useMutation();

  if (!currentUser || !teamDetails || !team) return <FullScreenSpinner />;

  if (currentUser.type === "FIELD_STAFF") {
    navigate("/field-service/staff-app/dashboard");
  }

  const groupedTasks = {
    due:
      data?.tasks.filter((task) => dayjs(task.date).isBefore(dayjs(), "day")) ||
      [],
    today:
      data?.tasks.filter((task) => dayjs(task.date).isSame(dayjs(), "day")) ||
      [],
    upcoming:
      data?.tasks.filter((task) => dayjs(task.date).isAfter(dayjs(), "day")) ||
      [],
  };

  const activityResources = [
    {
      id: dayjs().format("DD-MM-YYYY"),
      title: "Today",
    },
    {
      id: dayjs().add(1, "day").format("DD-MM-YYYY"),
      title: "Tomorrow",
    },
    {
      id: dayjs().add(2, "day").format("DD-MM-YYYY"),
      title: dayjs().add(2, "day").format("dddd"),
    },
  ];

  const groupedActivities = (
    data?.activities.filter((activity) => {
      const activityDate = dayjs(activity.startDate);
      return (
        activityDate.isSame(dayjs(), "day") ||
        activityDate.isSame(dayjs().add(1, "day"), "day") ||
        activityDate.isSame(dayjs().add(2, "day"), "day")
      );
    }) || []
  ).map((activity) => ({
    id: activity.id,
    start: activity.startDate,
    end: activity.endDate,
    title: activity.title,
    backgroundColor: generateUniqueColor(activity.id.toString(), 400),
    allDay: false,
    resourceId: dayjs(activity.startDate).format("DD-MM-YYYY"),
  }));

  const renderTasks = (
    tasks: RouterOutputs["users"]["personal"]["tasks"],
    label: string,
    color: string,
    onTaskClick: (id: number) => void
  ) => {
    return (
      <>
        {!!tasks.length && (
          <p className={`font-medium text-${color}-500`}>{label}</p>
        )}
        {tasks.map((task) => (
          <TaskCard key={task.id} task={task} onClick={onTaskClick} />
        ))}
      </>
    );
  };

  const tasksList = (
    <ScrollArea className="overflow-auto sm:w-1/3 sm:h-[calc(100vh-260px)]">
      {!data?.tasks.length ? (
        <Empty
          icon={CheckSquare}
          title="You have no tasks"
          description="Hoooray! You have no tasks. Please add one"
        />
      ) : selectedTab === "pending" ? (
        <div className="flex flex-col gap-3">
          {renderTasks(groupedTasks.due, "Overdue", "red", (id) => {
            setShowTaskDetailsModal(id);
          })}
          {renderTasks(groupedTasks.today, "Today", "green", (id) => {
            setShowTaskDetailsModal(id);
          })}
          {renderTasks(groupedTasks.upcoming, "Upcoming", "blue", (id) => {
            setShowTaskDetailsModal(id);
          })}
        </div>
      ) : selectedTab === "completed" ? (
        <div className="flex flex-col gap-3">
          <p className="font-medium text-green-500">Completed</p>
          {data?.tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              onClick={() => {
                setShowTaskDetailsModal(task.id);
              }}
            />
          ))}
        </div>
      ) : selectedTab === "assigned" ? (
        <div className="flex flex-col gap-3">
          <p className="font-medium text-blue-500">Assigned</p>
          {data?.tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              onClick={() => {
                setShowTaskDetailsModal(task.id);
              }}
            />
          ))}
        </div>
      ) : null}
    </ScrollArea>
  );

  const activitiesList = (
    <div className="flex flex-col gap-3">
      <DayEventCalendar
        className="sm:h-[calc(100vh-257px)]"
        resources={activityResources}
        events={groupedActivities}
        view="timeGridWeek"
        // hides days other than today and next two days
        hiddenDays={
          isMobile()
            ? Array.from({ length: 7 }, (_, i) => i).filter(
                (day) =>
                  ![dayjs().day()].includes(day as 0 | 1 | 2 | 3 | 4 | 5 | 6)
              )
            : Array.from({ length: 7 }, (_, i) => i).filter(
                (day) =>
                  ![dayjs().day(), dayjs().add(1, "day").day()].includes(
                    day as 0 | 1 | 2 | 3 | 4 | 5 | 6
                  )
              )
        }
        onEventClick={(event) => {
          setShowDetailsActivityModal(Number(event.id));
        }}
        onEventUpdate={(event) => {
          activityUpdateMutation.mutate({
            id: Number(event.id),
            activity: {
              startDate: event.start,
              endDate: {
                date: event.end,
                dateType: "CUSTOM_DATE",
              },
            },
          });
        }}
        onEventCreate={(event) => {
          setShowAddActivityModal({
            startDate: event.start,
            endDate: {
              date: event.end,
              dateType: "CUSTOM_DATE",
            },
          });
        }}
        date={dayjs().toDate()}
      />
    </div>
  );

  const mentions = (
    <Card
      icon={<AtSign className="h-4 text-blue-600" />}
      title="Mentions and Assignments"
      parentClassName="sm:w-3/12 flex flex-col sm:h-[calc(100vh-207px)]"
    >
      <ScrollArea className="flex overflow-auto flex-col gap-3 sm:h-[460px]">
        {!data?.assignments.length && (
          <Empty
            icon={AtSign}
            title="No mentions/assignments"
            description="Its empty over here."
          />
        )}
        {data?.assignments.map((notification) => (
          <div
            className="p-1 w-full rounded-lg cursor-pointer hover:bg-gray-50"
            key={notification.id}
            onClick={() => {
              notification.url && navigate(notification.url);
            }}
          >
            <p className="font-medium hover:text-primary hover:underline">
              {notification.title}
            </p>
            <RenderHtml className="text-gray-500">
              {notification.body || ""}
            </RenderHtml>
            <div className="flex justify-between items-center">
              <p className="text-gray-500">
                {dayjs().to(dayjs(notification.createdAt))}
              </p>
              <div className="flex">
                {!!notification.url && (
                  <Button size="sm" variant="link">
                    Open
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </ScrollArea>
    </Card>
  );

  return (
    <Page fullWidth>
      <AddTaskModal
        open={showAddTaskModal}
        onClose={() => setShowAddTaskModal(false)}
      />
      {!!showTaskDetailsModal && (
        <TaskDetailsModal
          open={!!showTaskDetailsModal}
          onClose={() => setShowTaskDetailsModal(null)}
          taskId={showTaskDetailsModal}
        />
      )}
      <AddActivityModal
        open={!!showAddActivityModal}
        onClose={() => setShowAddActivityModal(undefined)}
        defaultValues={showAddActivityModal}
      />
      {!!showDetailsActivityModal && (
        <ActivityDetailsModal
          open={!!showDetailsActivityModal}
          onClose={() => setShowDetailsActivityModal(null)}
          id={showDetailsActivityModal}
        />
      )}
      {isMobile() && mobileSelectedTab !== "mentions" && (
        <ResponsiveActionButton
          onClick={() => {
            if (mobileSelectedTab === "activities") {
              setShowAddActivityModal({});
            } else if (mobileSelectedTab === "tasks") {
              setShowAddTaskModal(true);
            }
          }}
          text="Task"
          className="bottom-8"
        />
      )}

      <div className="grid grid-cols-4 gap-4 sm:flex sm:flex-wrap sm:gap-16">
        {getTeamApps(teamDetails.installedApps, currentUser.type)
          .sort((a, b) => {
            const pinnedApps = currentUser.pinnedApps || [];
            const aPinnedIndex = pinnedApps.indexOf(a.id);
            const bPinnedIndex = pinnedApps.indexOf(b.id);
            if (aPinnedIndex !== -1 && bPinnedIndex === -1) return -1;
            if (aPinnedIndex === -1 && bPinnedIndex !== -1) return 1;
            if (aPinnedIndex !== -1 && bPinnedIndex !== -1) {
              return aPinnedIndex - bPinnedIndex;
            }
            return 0;
          })
          .map((app) => (
            <RightClickMenu
              key={app.name}
              items={[
                {
                  type: "item",
                  icon: ExternalLink,
                  label: "Open",
                  onClick: () => navigate(app.path),
                },
                {
                  type: "item",
                  icon: currentUser.pinnedApps?.includes(app.id) ? PinOff : Pin,
                  label: currentUser.pinnedApps?.includes(app.id)
                    ? "Unpin"
                    : "Pin",
                  onClick: () => {
                    const pinnedApps = currentUser.pinnedApps || [];
                    const newPinnedApps = currentUser.pinnedApps?.includes(
                      app.id
                    )
                      ? pinnedApps.filter((id) => id !== app.id)
                      : [...pinnedApps, app.id];
                    updateUserMutation.mutate({
                      pinnedApps: Array.from(new Set(newPinnedApps)),
                    });
                  },
                },
              ]}
            >
              <div
                className="flex flex-col justify-center items-center font-medium text-gray-700 cursor-pointer sm:gap-3"
                onClick={() => {
                  console.log(app.path.split("/").pop(), "selected path");
                  setSelectedPage(app.path.split("/").pop() || "");
                  navigate(app.path);
                }}
              >
                <div
                  className={cn(
                    "p-2.5 w-12 relative h-12 bg-white rounded-full shadow-md transition-all duration-100 sm:w-12 sm:h-12 hover:scale-110 hover:shadow-lg sm:rounded-2xl"
                  )}
                  style={{
                    backgroundColor: app.color,
                  }}
                >
                  {currentUser.pinnedApps?.includes(app.id) && (
                    <Pin className="absolute top-0 -left-2 p-0.5 text-white w-4 h-4 rounded-full bg-primary-700 border-white border" />
                  )}
                  <app.icon
                    className={cn(
                      "w-full h-full text-white transition-all duration-75 hover:rotate-12"
                    )}
                  />
                </div>
                <p className="mt-2 text-xs sm:mt-0">{app.name}</p>
              </div>
            </RightClickMenu>
          ))}
      </div>

      {!isMobile() && (
        <div className="flex gap-4 mt-8">
          <div className="flex flex-col gap-3 w-9/12">
            <div className="flex justify-between">
              <TabsInput
                className="self-center"
                options={[
                  {
                    value: "pending",
                    label: "Pending",
                    icon: CircleDashed,
                    count: data?.count.pendingTasks,
                  },
                  { value: "completed", label: "Completed", icon: CheckCircle },
                  {
                    value: "assigned",
                    label: "Assigned",
                    icon: CircleUserRound,
                  },
                ]}
                value={selectedTab}
                onChange={(value) =>
                  setSelectedTab(value as "pending" | "completed" | "assigned")
                }
              />
              <div className="flex gap-2">
                <Button
                  icon={Plus}
                  onClick={() => setShowAddTaskModal(true)}
                  variant="primary"
                  size="sm"
                >
                  Task
                </Button>
                <Button
                  size="sm"
                  icon={Plus}
                  onClick={() => setShowAddActivityModal({})}
                  variant="primary"
                >
                  Activity
                </Button>
              </div>
            </div>
            <div className="flex gap-3">
              {tasksList}
              <div className="w-2/3">{activitiesList}</div>
            </div>
          </div>
          {mentions}
        </div>
      )}
      {isMobile() && (
        <NextTabs
          value={mobileSelectedTab}
          onChange={(value) =>
            setMobileSelectedTab(value as "activities" | "tasks" | "mentions")
          }
          className="mt-5"
          tabs={[
            { id: "activities", label: "Activities", content: activitiesList },
            { id: "tasks", label: "Tasks", content: tasksList },
            { id: "mentions", label: "Mentions", content: mentions },
          ]}
        />
      )}
    </Page>
  );
};

export default PersonalDashboard;
