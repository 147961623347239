import { Icons } from "@heffl/ui/components/icons";
import Schemas from "@heffl/server/src/schemas";
import dayjs from "dayjs";
import { Calendar, CheckSquare, LucideIcon, Mail, Phone } from "lucide-react";
import { z } from "zod";
import enums from "@heffl/server/src/schemas/enums";

export type LeadStatus = z.infer<typeof Schemas.crm.lead>["stage"];

type Lead = {
  value: LeadStatus;
  label: string;
  color: string;
  bg: string;
  textColor: string;
};

export const leadStages: Lead[] = [
  {
    label: "New",
    value: "NEW",
    color: "bg-orange-400",
    textColor: "text-orange-400",
    bg: "orange",
  },
  {
    label: "Contacted",
    value: "CONTACTED",
    color: "bg-violet-400",
    textColor: "text-violet-400",
    bg: "violet",
  },
  {
    label: "Working",
    value: "WORKING",
    color: "bg-green-400",
    textColor: "text-green-400",
    bg: "green",
  },

  {
    label: "Qualified",
    value: "CONVERTED",
    color: "bg-green-600",
    textColor: "text-green-600",
    bg: "green",
  },
  {
    label: "Unqualified",
    value: "UNQUALIFIED",
    color: "bg-red-600",
    textColor: "text-red-600",
    bg: "red",
  },
];

export type ActivityStatus = "todo" | "meeting" | "call" | "email";

export type Activity = {
  label: string;
  value: ActivityStatus;
  icon: LucideIcon;
};

export const activityItems: Activity[] = [
  {
    label: "Todo",
    value: "todo",
    icon: CheckSquare,
  },
  {
    label: "Call",
    value: "call",
    icon: Phone,
  },
  {
    label: "Email",
    value: "email",
    icon: Mail,
  },
  {
    label: "Meeting",
    value: "meeting",
    icon: Calendar,
  },
];

export const getActivity = (activity: string): Activity =>
  activityItems.find((a) => a.value === activity) as Activity;

export const getLeadStage = (status: LeadStatus): Lead =>
  leadStages.find((l) => l.value === status) as Lead;
export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_TIME_FORMAT = "dd/MM/yyyy hh:mm a";
export const DATE_WORD_FORMAT = "MMMM dd, yyyy";
export const DATE_TIME_WORD_FORMAT = "MMMM dd, yyyy, HH:mm";
export const DATE_TIME_WORD_FORMAT_DAYJS = "MMMM DD, YYYY, hh:mm a";
export const TIME_FORMAT = "hh:mm a";

export const UAE_TAX = 5;

export const dealPriorityColors = {
  LOW: "#10B981",
  MEDIUM: "#EAB308",
  HIGH: "#EF4444",
};

export const QUOTATION_STATUS: {
  label: string;
  color: string;
  value: z.infer<typeof enums.quotationStatus>;
}[] = [
  {
    label: "Draft",
    color: "bg-gray-400",
    value: "DRAFT",
  },
  {
    label: "Sent",
    color: "bg-yellow-400",
    value: "SENT",
  },
  {
    label: "Accepted",
    color: "bg-green-400",
    value: "ACCEPTED",
  },
  {
    label: "Rejected",
    color: "bg-red-400",
    value: "REJECTED",
  },
];

export const getQuoteStatus = (
  status: z.infer<typeof enums.quotationStatus>
) => {
  return (
    QUOTATION_STATUS.find((s) => s.value === status) || QUOTATION_STATUS[0]
  );
};

export const INVOICE_STATUS: {
  label: string;
  color: string;
  value: z.infer<typeof enums.invoiceStatus>;
}[] = [
  {
    label: "Draft",
    color: "bg-gray-400",
    value: "DRAFT",
  },
  {
    label: "Sent",
    color: "bg-yellow-400",
    value: "SENT",
  },
  {
    label: "Paid",
    color: "bg-green-400",
    value: "PAID",
  },
  {
    label: "Cancelled",
    color: "bg-red-400",
    value: "CANCELLED",
  },
];

export const getInvoiceStatus = (
  status: z.infer<typeof enums.invoiceStatus>
) => {
  return INVOICE_STATUS.find((s) => s.value === status) || INVOICE_STATUS[0];
};

export type ProviderNames = "drive" | "dropbox" | "zoho";

type Provider = {
  name: ProviderNames;
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const externalFileProviders: Provider[] = [
  {
    name: "drive",
    label: "Google Drive",
    icon: Icons.drive,
  },
  {
    name: "dropbox",
    label: "Dropbox",
    icon: Icons.dropbox,
  },
  {
    name: "zoho",
    label: "Zoho",
    icon: Icons.zoho,
  },
];

export const getExternalFileProvider = (name: "drive" | "dropbox" | "zoho") => {
  return externalFileProviders.find(
    (provider) => provider.name === name
  ) as Provider;
};

export const accountTypes = [
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "Super Admin",
    value: "SUPER_ADMIN",
  },
  {
    label: "Owner",
    value: "OWNER",
  },
  {
    label: "Staff",
    value: "STAFF",
  },
  {
    label: "Field Worker",
    value: "FIELD_STAFF",
  },
  {
    label: "Driver",
    value: "DRIVER",
  },
  {
    label: "Supervisor",
    value: "SUPERVISOR",
  },
];

export const tailwindColors = {
  100: [
    "#ebf8ff",
    "#fff5f5",
    "#f0fff4",
    "#fffff0",
    "#faf5ff",
    "#fff5f7",
    "#ebf4ff",
    "#ffe4e6",
    "#f3f4f6",
    "#e6fffa",
  ],
  200: [
    "#bee3f8",
    "#fed7d7",
    "#c6f6d5",
    "#fefcbf",
    "#e9d8fd",
    "#fed7e2",
    "#c3dafe",
    "#fed4e6",
    "#e2e8f0",
    "#b2f5ea",
  ],
  300: [
    "#90cdf4",
    "#feb2b2",
    "#9ae6b4",
    "#faf089",
    "#d6bcfa",
    "#fbb6ce",
    "#a3bffa",
    "#fda4af",
    "#cbd5e0",
    "#81e6d9",
  ],
  400: [
    "#63b3ed",
    "#fc8181",
    "#68d391",
    "#f6e05e",
    "#b794f4",
    "#f687b3",
    "#7f9cf5",
    "#fb7185",
    "#a0aec0",
    "#4fd1c5",
  ],
  500: [
    "#4299e1",
    "#f56565",
    "#48bb78",
    "#ecc94b",
    "#9f7aea",
    "#ed64a6",
    "#667eea",
    "#f687b3",
    "#718096",
    "#38b2ac",
  ],
  600: [
    "#3182ce",
    "#e53e3e",
    "#38a169",
    "#d69e2e",
    "#805ad5",
    "#d53f8c",
    "#5a67d8",
    "#e53e3e",
    "#4a5568",
    "#319795",
  ],
  700: [
    "#2b6cb0",
    "#c53030",
    "#2f855a",
    "#b7791f",
    "#6b46c1",
    "#b83280",
    "#4c51bf",
    "#c53030",
    "#2d3748",
    "#2c7a7b",
  ],
};

export const DEFAULT_DATE = dayjs().set("hour", 18).set("minute", 0).toDate();

export const hoursInDay: string[] = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
];

export const UAE_CURRENCY_ID = 1;

export const tremorColors = [
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
  "slate",
  "gray",
  "zinc",
  "neutral",
  "stone",
];
