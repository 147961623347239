import { z } from "zod";
import enums from "./enums";
export const folder = z.object({
  name: z.string(),
  parentFolderId: z.number().nullish(),
});

export const fileItem = z.object({
  id: z.number(),
  name: z.string(),
  link: z.string(),
  size: z.number(),
  format: z.string(),
});

export const filesInput = z.object({
  new: z.array(
    z.object({
      name: z.string(),
      format: z.string(),
      size: z.number(),
      link: z.string().default(""),
      file: z.instanceof(File).nullish(),
    })
  ),
  existing: z.array(fileItem).default([]),
  deleted: z.array(z.object({ id: z.number(), link: z.string() })),
});

export const file = z.object({
  name: z.string(),
  format: z.string(),
  link: z.string(),
  size: z.number(),
  linkProvider: z.string().optional(),
  parentFolderId: z.number().nullable(),
  section: enums.S3Folders,
  sectionId: z.number().nullish(),
  entity: enums.entities,
  entityId: z.number().nullable(),
  fileField: z
    .object({
      // name: enums.systemFileFields,
      name: z.string(),
    })
    .optional(),
});
