import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import {
  Form,
  FormField,
  FormLabel,
} from "@heffl/ui/components/primitives/form";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import dayjs from "dayjs";
import {
  CalendarDays,
  Pencil,
  Plus,
  Tag,
  Trash2,
  Wrench,
  X,
} from "lucide-react";
import { useEffect, useState } from "react";

import heffl from "@/helpers/heffl";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import Select from "@heffl/ui/components/primitives/select";
import { Switch } from "@heffl/ui/components/primitives/switch";
import TimePicker from "@heffl/ui/components/primitives/time-picker";
import { makeEllipsis } from "@heffl/ui/lib/utils";
import { Drawer } from "antd";
import { produce } from "immer";
import { sum } from "radash";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import {
  FsSchedule,
  jobSchema,
  rruleSchedulesGenerator,
  syncStartEndDate,
} from "./job-form";
import FrequencySelector from "./job-frequency-selector";
import { zodResolver } from "@hookform/resolvers/zod";
import { nanoid } from "nanoid";

const ScheduleEditModal = ({
  open,
  onClose,
  value,
  onChange,
  number,
  form,
}: {
  open: boolean;
  onClose: () => void;
  value: FsSchedule;
  onChange: (value: FsSchedule) => void;
  number: number;
  form: UseFormReturn<z.infer<typeof jobSchema>>;
}) => {
  const allSchedules = form.watch("fsSchedules");
  const fsJobServices = form.watch("fsJobServices");

  const scheduleServices = value.fsScheduleServices || [];

  const jobItems = fsJobServices?.map((service) => ({
    ...service,
    availableQuantity:
      service.quantity -
      sum(
        allSchedules
          ?.filter((s) => s.uuid !== value.uuid)
          .map(
            (schedule) =>
              schedule.fsScheduleServices.find(
                (s) => s.fsJobServiceIdUuid === service.uuid
              )?.quantity || 0
          ) || []
      ),
  }));

  const onModalClose = () => {
    onClose();
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title={
        <div className="flex gap-2">
          Edit schedule - {dayjs(value.startDate).format("DD/MM/YYYY")} -{" "}
          {number}
        </div>
      }
      modalClassName="max-w-2xl"
    >
      <div className="overflow-x-auto mt-2 w-full">
        <p className="text-sm font-semibold">Schedule Line items</p>
        <table className="mt-2 w-full table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-right">Quantity</th>
              <th className="px-4 py-2 text-right">Price</th>
              <th className="px-4 py-2 text-right">Tax</th>
              <th className="px-4 py-2 text-right">Total</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobItems?.map((jobService) => {
              const scheduleService = scheduleServices?.find(
                (service) => service.fsJobServiceIdUuid === jobService.uuid
              );
              const scheduleServiceIndex = scheduleServices?.findIndex(
                (service) => service.fsJobServiceIdUuid === jobService.uuid
              );
              return (
                <tr key={jobService.uuid} className="border-b">
                  <td className="px-4 py-2">{jobService.name}</td>
                  <td className="px-4 py-2 w-20 text-right">
                    <Select
                      hideSearch
                      disabled={!scheduleService}
                      placeholder="NIL"
                      value={scheduleService?.quantity?.toString()}
                      onChange={(val) => {
                        onChange(
                          produce(value, (draft) => {
                            draft.fsScheduleServices[
                              scheduleServiceIndex
                            ].quantity = Number(val);
                          })
                        );
                      }}
                      options={Array.from(
                        { length: jobService.availableQuantity },
                        (_, i) => ({
                          label: (i + 1).toString(),
                          value: (i + 1).toString(),
                        })
                      )}
                    />
                  </td>
                  <td className="px-4 py-2 text-right">
                    <Input
                      type="number"
                      className="ml-auto w-24"
                      value={jobService.price}
                      onChange={(e) => {
                        onChange(
                          produce(value, (draft) => {
                            draft.fsScheduleServices[
                              scheduleServiceIndex
                            ].price = Number(e.target.value);
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-4 py-2 text-right">
                    {jobService.tax ? `${jobService.tax}%` : "NIL"}
                  </td>
                  <td className="px-4 py-2 text-right">
                    {(
                      jobService.price *
                      (scheduleService?.quantity || 0) *
                      (1 + (jobService.tax || 0) / 100)
                    ).toFixed(2)}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <Button
                      onClick={() => {
                        if (scheduleService) {
                          onChange(
                            produce(value, (draft) => {
                              draft.fsScheduleServices =
                                draft.fsScheduleServices.filter(
                                  (service) =>
                                    service.fsJobServiceIdUuid !==
                                    jobService.uuid
                                );
                            })
                          );
                        } else if (jobService) {
                          if (jobService.availableQuantity < 1) {
                            return heffl.toast.error(
                              "Not enough quantity available for this service"
                            );
                          }

                          const prev = value.fsScheduleServices || [];
                          onChange(
                            produce(value, (draft) => {
                              draft.fsScheduleServices = [
                                ...prev,
                                {
                                  ...jobService,
                                  fsJobServiceIdUuid: jobService.uuid,
                                  quantity: 1,
                                  type: "SERVICE",
                                  viewType: "LINE_ITEM",
                                },
                              ];
                            })
                          );
                        }
                      }}
                      variant={scheduleService ? "destructive" : "primary"}
                      icon={scheduleService ? Trash2 : Plus}
                      size="sm"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <FormField name="notes" label="Notes" className="mt-4">
        <MiniRichTextEditor height={130} placeholder="Schedule related notes" />
      </FormField>
    </ModalDrawer>
  );
};
const addScheduleSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  startTime: z.date().nullish(),
  endTime: z.date().nullish(),
  fsScheduleServices: z.array(z.string()).optional(),
  frequencyRule: z
    .string({
      message: "Please choose a frequency",
    })
    .min(1),
});

export const ScheduleAddModal = ({
  open,
  onClose,
  form,
}: {
  open: boolean;
  onClose: () => void;
  form: UseFormReturn<z.infer<typeof jobSchema>>;
}) => {
  const jobStartDate = form.watch("startDate");
  const jobEndDate = form.watch("endDate");
  const jobStartTime = form.watch("startTime");
  const jobEndTime = form.watch("endTime");
  const fsJobServices = form
    .watch("fsJobServices")
    ?.filter((service) => service.viewType === "LINE_ITEM");

  const addSchedulesForm = useForm<z.infer<typeof addScheduleSchema>>({
    resolver: zodResolver(addScheduleSchema),
    defaultValues: {
      startDate: jobStartDate,
      endDate: jobEndDate || jobStartDate,
      startTime: jobStartTime,
      endTime: jobEndTime,
      fsScheduleServices: [],
      frequencyRule: undefined,
    },
  });

  const scheduleStartDate = addSchedulesForm.watch("startDate");
  const scheduleEndDate = addSchedulesForm.watch("endDate");
  const scheduleFrequencyRule = addSchedulesForm.watch("frequencyRule");
  const scheduleStartTime = addSchedulesForm.watch("startTime");
  const scheduleEndTime = addSchedulesForm.watch("endTime");
  const scheduleServices = addSchedulesForm.watch("fsScheduleServices");
  const [schedulesAdded, setSchedulesAdded] = useState<Date[]>([]);

  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });

  useEffect(() => {
    if (scheduleStartDate && scheduleEndDate && scheduleFrequencyRule) {
      const schedules = rruleSchedulesGenerator({
        startDate: scheduleStartDate,
        endDate: scheduleEndDate,
        frequencyRule: scheduleFrequencyRule,
      });
      setSchedulesAdded(schedules);
    }
  }, [scheduleStartDate, scheduleEndDate, scheduleFrequencyRule]);

  const onSubmit = (data: z.infer<typeof addScheduleSchema>) => {
    console.log(data);
    if (schedulesAdded.length === 0) {
      return heffl.toast.error("Please add at least one schedule");
    }
    form.setValue("fsSchedules", [
      ...(form.getValues("fsSchedules") || []),
      ...schedulesAdded.map((schedule) => {
        const { startDate, endDate } = syncStartEndDate({
          startDate: schedule,
          endDate: schedule,
          startTime: scheduleStartTime,
          endTime: scheduleEndTime,
          type: "CONTRACT",
          schedules: schedulesAdded,
        });

        return {
          uuid: nanoid(),
          startDate,
          endDate,
          timeDisabled: !data.startTime || !data.endTime,
          status: "SCHEDULED" as const,
          invoiceRequired: false,
          fsScheduleTags: [],
          fsScheduleServices:
            scheduleServices?.map((service) => ({
              price: 0,
              name: "",
              tax: 0,
              type: "SERVICE" as const,
              ...fsJobServices?.find((s) => s.uuid === String(service)),
              fsJobServiceIdUuid: fsJobServices?.find(
                (s) => s.uuid === String(service)
              )?.uuid,
              quantity: 1,
              viewType: "LINE_ITEM" as const,
            })) || [],
          fsScheduleAssignees: [],
          fsScheduleParts: [],
        };
      }),
    ]);
    addSchedulesForm.reset();
    onClose();
  };

  return (
    <ModalDrawer
      modalClassName="max-w-3xl"
      open={open}
      onClose={() => {
        onClose();
        addSchedulesForm.reset();
      }}
      title="Add schedules"
      footer={
        <Button
          onClick={addSchedulesForm.handleSubmit(onSubmit)}
          type="submit"
          variant="primary"
          className="w-full"
        >
          Add schedules{" "}
          {schedulesAdded.length ? `(${schedulesAdded.length})` : ""}
        </Button>
      }
    >
      <Form {...addSchedulesForm} onSubmit={onSubmit}>
        <div className="grid grid-cols-4 gap-3">
          <FormField name="startDate" label="Start date">
            <DatePicker
              fromDate={jobStartDate}
              toDate={jobEndDate || undefined}
            />
          </FormField>
          <FormField name="endDate" label="End date">
            <DatePicker
              fromDate={jobStartDate}
              toDate={jobEndDate || undefined}
            />
          </FormField>
          <FormField name="startTime" label="Start time">
            <TimePicker
              onChange={(v) => {
                if (
                  !form.getValues("endTime") ||
                  dayjs(form.getValues("endTime")).isBefore(dayjs(v))
                ) {
                  form.setValue("endTime", dayjs(v).add(1, "hour").toDate());
                }
              }}
            />
          </FormField>
          <FormField name="endTime" label="End time">
            <TimePicker disabled={!scheduleStartTime} />
          </FormField>
          <FormField
            name="frequencyRule"
            label="Frequency rule"
            className="col-span-2"
          >
            <FrequencySelector />
          </FormField>
          <FormField name="fsScheduleTags" label="Tags" className="col-span-2">
            <MultiSelect
              icon={Tag}
              placeholder="Add tags"
              options={
                tags?.map(({ name, id, color }) => ({
                  label: name,
                  value: id,
                  color,
                })) || []
              }
            />
          </FormField>
          <FormField
            name="fsScheduleServices"
            label="Services"
            className="col-span-4"
          >
            <MultiSelect
              icon={Wrench}
              placeholder="Add services"
              options={
                fsJobServices?.map(({ name, uuid }) => ({
                  label: name,
                  value: uuid || "",
                })) || []
              }
            />
          </FormField>
        </div>
      </Form>
    </ModalDrawer>
  );
};

export const SchedulesInput = ({
  value = [],
  name,
  form,
  edit,
}: {
  name?: string;
  value?: FsSchedule[];
  onChange?: (value: FsSchedule[]) => void;
  form: UseFormReturn<z.infer<typeof jobSchema>>;
  edit?: boolean;
}) => {
  const [showSchedulesList, setShowSchedulesList] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [showScheduleEdit, setShowScheduleEdit] = useState<{
    uuid: string;
    number: number;
  } | null>(null);

  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });

  const scheduleToEdit = value?.find(
    (schedule) => schedule.uuid === showScheduleEdit?.uuid
  );

  return (
    <>
      {scheduleToEdit && showScheduleEdit && (
        <ScheduleEditModal
          form={form}
          open={showScheduleEdit !== null}
          onClose={() => setShowScheduleEdit(null)}
          value={scheduleToEdit}
          onChange={(updatedSchedule) => {
            const updatedValue = produce(value, (draft) => {
              const scheduleIndex = draft.findIndex(
                (schedule) => schedule.uuid === updatedSchedule.uuid
              );
              if (scheduleIndex !== -1) {
                draft[scheduleIndex] = updatedSchedule;
              }
            });
            form.setValue("fsSchedules", updatedValue);
          }}
          number={showScheduleEdit.number}
        />
      )}
      <Drawer
        title="Edit schedules"
        open={showSchedulesList}
        onClose={() => {
          setShowSchedulesList(false);
          setShowAddModal(false);
        }}
        width={1200}
        zIndex={50}
        extra={
          <div className="flex gap-2">
            <Button
              onClick={() => {
                heffl.modal.confirm({
                  title: "Are you sure you want to clear all schedules?",
                  description:
                    "This action will remove all schedules from the list.",
                  onConfirm: () => {
                    form.setValue("fsSchedules", []);
                  },
                });
              }}
              icon={X}
              variant="destructiveOutline"
              size="sm"
            >
              Clear all
            </Button>
            <Button
              onClick={() => {
                form.setValue("fsSchedules", [
                  ...(form.getValues("fsSchedules") || []),
                  {
                    startDate: dayjs().toDate(),
                    endDate: dayjs().toDate(),
                    timeDisabled: true,
                    status: "SCHEDULED" as const,
                    invoiceRequired: false,
                    fsScheduleAssignees:
                      value && value.length ? value[0].fsScheduleAssignees : [],
                    fsScheduleTags: [],
                    fsScheduleParts: [],
                    fsScheduleServices: [],
                  },
                ]);
              }}
              icon={Plus}
              variant="primary"
              size="sm"
            >
              Schedule
            </Button>
            <Button
              onClick={() => {
                setShowAddModal(true);
              }}
              icon={Plus}
              variant="primaryOutline"
              size="sm"
            >
              Bulk Schedule
            </Button>
          </div>
        }
        destroyOnClose
        classNames={{
          body: "bg-gray-50",
        }}
      >
        <ScheduleAddModal
          open={showAddModal}
          onClose={() => setShowAddModal(false)}
          form={form}
        />
        <FormList name={name || ""}>
          {({ _name, remove }) => {
            return (
              <div className="flex flex-col gap-2">
                {/* <Button
                  onClick={() =>
                    append({
                      startDate: dayjs().toDate(),
                      endDate: dayjs().toDate(),
                      timeDisabled: true,
                      status: "SCHEDULED" as const,
                      invoiceRequired: false,
                      fsScheduleAssignees:
                        value && value.length
                          ? value[0].fsScheduleAssignees
                          : [],
                    })
                  }
                  variant="primary"
                  size="md"
                  className="self-end"
                >
                  <Plus className="h-5" />
                  Add new schedule
                </Button> */}
                <div className="grid grid-cols-4 gap-3">
                  {value?.map((schedule, index) => (
                    <Card
                      key={schedule.startDate.toString()}
                      className="relative gap-2 items-start !px-2"
                    >
                      <div className="">
                        <div className="flex gap-2 justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <span className="rounded-full w-4 text-center h-4 bg-[#22577A] text-white text-[11px] flex items-center justify-center">
                              {index + 1}
                            </span>
                            <FormLabel>Start date</FormLabel>
                          </div>
                          <div className="flex gap-2 items-center">
                            <Button
                              onClick={() =>
                                setShowScheduleEdit({
                                  uuid: schedule.uuid || "",
                                  number: index + 1,
                                })
                              }
                              size="xs"
                              icon={Pencil}
                              disabled={edit}
                            >
                              Edit
                            </Button>
                            <Button
                              size="xs"
                              icon={Trash2}
                              variant="destructive"
                              onClick={() => remove(index)}
                              className="px-1 w-fit"
                            />
                          </div>
                        </div>
                        <FormField
                          name={_name(index, "startDate")}
                          className="mt-3"
                        >
                          <DatePicker />
                        </FormField>
                        <FormField name={_name(index, "fsScheduleTags")}>
                          <MultiSelect
                            icon={Tag}
                            placeholder="Add tags"
                            options={
                              tags?.map(({ name, id, color }) => ({
                                label: name,
                                value: id,
                                color,
                              })) || []
                            }
                          />
                        </FormField>
                        <div className="flex justify-between items-center">
                          <Label>Invoice required</Label>
                          <FormField
                            name={_name(index, "invoiceRequired")}
                            className="w-fit"
                          >
                            <Switch size="small" />
                          </FormField>
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {schedule.fsScheduleServices?.map((service) => (
                            <Badge
                              small
                              key={service.fsJobServiceIdUuid}
                              variant="unique"
                              icon={Wrench}
                            >
                              {makeEllipsis(service.name, 15)} x{" "}
                              <span className="font-medium">
                                {service.quantity}
                              </span>
                            </Badge>
                          ))}
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            );
          }}
        </FormList>
      </Drawer>
      <div className="flex justify-between mt-6 w-full">
        <Label>Schedules</Label>
        <Button
          onClick={() => setShowSchedulesList(true)}
          size="xs"
          className="flex gap-1 items-center"
        >
          <span className="flex items-center">
            <Pencil className="h-3.5" /> Edit schedules
          </span>
          <span className="text-xs">({value?.length})</span>
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-2 justify-between pt-1 w-full">
        {value?.slice(0, 5).map((schedule) => (
          <div
            className="flex gap-2 justify-center items-center p-2 w-full text-xs rounded-md border shadow-sm"
            key={schedule.startDate.toString()}
          >
            <CalendarDays className="w-4 h-4 text-yellow-600" />
            <p>{dayjs(schedule.startDate).format("DD/MM/YYYY")}</p>
          </div>
        ))}
        {value && value.length > 5 && (
          <div
            onClick={() => setShowSchedulesList(true)}
            className="flex gap-2 justify-center items-center p-2 w-full text-xs rounded-md border shadow-sm cursor-pointer text-primary-800"
          >
            +{value.length - 5} more
          </div>
        )}
      </div>
    </>
  );
};
