import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import {
  RadioGroup,
  RadioGroupItem,
} from "@heffl/ui/components/primitives/radio-group";
import Select from "@heffl/ui/components/primitives/select";
import { cn } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import Fuse from "fuse.js";
import {
  CalendarIcon,
  ChevronDown,
  Filter,
  Hash,
  ListChecks,
  Tag,
} from "lucide-react";
import { ReactElement, useEffect, useState } from "react";
import { match } from "ts-pattern";
import Empty from "./Empty";
import { SearchInput } from "./FormComponents";

export type NumberRangeFilterType = {
  key: string;
  label: string;
  type: "number-range";
  disableClear?: boolean;
  value:
    | {
        min: number | undefined;
        max: number | undefined;
      }
    | undefined;
  onChange: (
    value:
      | {
          min: number | undefined;
          max: number | undefined;
        }
      | undefined
  ) => void;
};

export type CheckboxFilterType = {
  key: string;
  multiple?: boolean;
  loading?: boolean;
  showSearch?: boolean;
  label: string;
  type: "checkbox";
  disableClear?: boolean;
  value: (string | number)[];
  onChange: (value: (string | number)[]) => void;
  onSearch?: (search: string) => void;
  onClose?: () => void;
  options?: {
    label: string;
    value: string | number;
  }[];
};

type DateRangeFilterType = {
  presetType?: "past" | "future";
  key: string;
  label: string;
  type: "date-range";
  disableClear?: boolean;
  endDateOnly?: boolean;
  presets?: {
    label: string;
    value: [Date, Date];
  }[];
  value: [Date, Date] | undefined;
  onChange: (value: [Date, Date] | undefined) => void;
};

const NumberRangeFilter = ({ filter }: { filter: NumberRangeFilterType }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="sm" className="rounded-lg">
          {filter.label} <ChevronDown className="ml-2 w-4 h-4" />
          {filter.value && (
            <span className="text-xs font-medium text-primary-700">
              <span className="px-1 font-thin text-gray-300">|</span>
              {filter.value.min} - {filter.value.max}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-4 w-56 rounded-lg" align="start">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Input
              type="number"
              placeholder="Min"
              value={filter.value?.min ?? ""}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                  filter.onChange({
                    min: undefined,
                    max: filter.value?.max ?? undefined,
                  });
                  return;
                }
                const min = Number(value);
                filter.onChange({
                  min,
                  max: filter.value?.max ?? undefined,
                });
              }}
            />
            <Input
              type="number"
              placeholder="Max"
              value={filter.value?.max ?? ""}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                  filter.onChange({
                    min: filter.value?.min ?? undefined,
                    max: undefined,
                  });
                  return;
                }
                const max = Number(value);
                filter.onChange({
                  min: filter.value?.min ?? undefined,
                  max,
                });
              }}
            />
          </div>
          <Button
            size="sm"
            variant="link"
            className="self-start px-0 text-secondary-800"
            onClick={() => filter.onChange(undefined)}
            disabled={filter.disableClear}
          >
            Clear
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const CheckboxFilter = ({ filter }: { filter: CheckboxFilterType }) => {
  const [search, setSearch] = useState("");

  const fuse = new Fuse(filter.options || [], {
    keys: ["label"],
    threshold: 0.3,
  });

  const filteredOptions = search
    ? fuse.search(search).map((result) => result.item)
    : filter.options || [];

  const showSearch =
    filter.showSearch !== undefined
      ? filter.showSearch
      : filter?.options?.length
      ? filter?.options?.length > 6
      : false;

  return (
    <div>
      <Popover
        onOpenChange={() => {
          setSearch("");
          filter.onClose?.();
        }}
      >
        <PopoverTrigger asChild>
          <Button size="sm" className="rounded-lg">
            {filter.label} <ChevronDown className="ml-2 w-4 h-4" />
            {filter.value && filter.value.length > 0 && (
              <span className="text-xs font-medium text-primary-700">
                <span className="px-1 font-thin text-gray-300">|</span>
                {filter.value.length <= 2 && filter.options
                  ? filter.options
                      .filter((option) => filter.value.includes(option.value))
                      .map((option) => option.label)
                      .join(", ")
                  : `${filter.value.length} selected`}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-3 w-48 rounded-lg" align="start">
          {showSearch && (
            <SearchInput
              value={search}
              onChange={(value) => {
                setSearch(value);
                filter.onSearch?.(value);
              }}
            />
          )}
          <div
            className={cn(
              "flex flex-col gap-2 mt-0 overflow-y-auto max-h-72 pr-[2px] [&::-webkit-scrollbar]:w-2",
              "[&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100",
              "[&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300",
              showSearch && "mt-3"
            )}
          >
            {!filter.value ||
              (filteredOptions.length === 0 && (
                <Empty
                  icon={Tag}
                  loading={filter.loading || false}
                  title=""
                  description="No results"
                  className="!pt-2"
                  iconSize={16}
                />
              ))}
            {!filter.loading &&
              filteredOptions.map((option) => (
                <div
                  key={option.label}
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <Checkbox
                    id={option.value.toString()}
                    value={filter.value.includes(option.value)}
                    onChange={(checked) => {
                      const newValue = checked
                        ? [...filter.value, option.value]
                        : filter.value.filter((v) => v !== option.value);
                      if (filter.multiple === false) {
                        filter.onChange(checked ? [option.value] : []);
                      } else {
                        filter.onChange(newValue);
                      }
                    }}
                  />
                  <label
                    htmlFor={option.value.toString()}
                    className="cursor-pointer"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
          </div>
          <div className="flex justify-between pt-2">
            <Button
              disabled={filter.value.length === 0 || filter.disableClear}
              size="sm"
              variant="link"
              className="px-0 text-secondary-800"
              onClick={() => filter.onChange([])}
            >
              Clear
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const defaultPastPresets = [
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").toDate(),
      dayjs().subtract(1, "day").toDate(),
    ] as [Date, Date],
  },
  {
    label: "Today",
    value: [dayjs().toDate(), dayjs().toDate()] as [Date, Date],
  },
  {
    label: "Tomorrow",
    value: [dayjs().add(1, "day").toDate(), dayjs().add(1, "day").toDate()] as [
      Date,
      Date
    ],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().subtract(7, "day").toDate(), dayjs().toDate()] as [
      Date,
      Date
    ],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().subtract(30, "day").toDate(), dayjs().toDate()] as [
      Date,
      Date
    ],
  },
];

const defaultFuturePresets = [
  {
    label: "Today",
    value: [dayjs().toDate(), dayjs().toDate()] as [Date, Date],
  },
  {
    label: "Tomorrow",
    value: [dayjs().add(1, "day").toDate(), dayjs().add(1, "day").toDate()] as [
      Date,
      Date
    ],
  },
  {
    label: "Next 7 Days",
    value: [dayjs().toDate(), dayjs().add(7, "day").toDate()] as [Date, Date],
  },
  {
    label: "Next 30 Days",
    value: [dayjs().toDate(), dayjs().add(30, "day").toDate()] as [Date, Date],
  },
];
const DateRangeFilter = ({ filter }: { filter: DateRangeFilterType }) => {
  const defaultPresets =
    filter?.presetType === "future" ? defaultFuturePresets : defaultPastPresets;

  const [selectedPreset, setSelectedPreset] = useState<string | undefined>(
    undefined
  );

  const [open, setOpen] = useState(false);

  const value =
    filter.value && filter.value[0] && filter.value[1]
      ? [filter.value?.[0], filter.value?.[1]]
      : undefined;

  const currentPresets = filter.presets || defaultPresets;
  useEffect(() => {
    if (!value?.[0] || !value?.[1]) return;
    const matchingPreset = currentPresets.find(
      (preset) =>
        dayjs(preset.value[0]).isSame(value?.[0], "day") &&
        dayjs(preset.value[1]).isSame(value?.[1], "day")
    );
    if (matchingPreset && selectedPreset !== "custom") {
      setSelectedPreset(matchingPreset.label);
    } else if (value?.[0]) {
      setSelectedPreset("custom");
    }
  }, [filter.value, currentPresets]);

  const isDatesSame = value && dayjs(value[0]).isSame(value?.[1], "day");

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button size="sm" className="rounded-lg">
          {filter.label} <ChevronDown className="ml-2 w-4 h-4" />
          {value?.[0] && (
            <span className="text-xs font-medium text-primary-700">
              <span className="px-1 font-thin text-gray-300">|</span>
              {!filter.endDateOnly &&
                dayjs(value[0]).format("MMM D, YYYY")}{" "}
              {(!isDatesSame || filter.endDateOnly) && (
                <>
                  {!filter.endDateOnly && "-"}
                  {dayjs(value[1]).format("MMM D, YYYY")}
                </>
              )}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3 w-48 rounded-lg" align="start">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <RadioGroup
                value={selectedPreset || ""}
                //@ts-ignore
                onChange={(value: string) => {
                  if (value === "custom") {
                    setSelectedPreset(value);
                    return;
                  }
                  setSelectedPreset(value);
                  const preset = currentPresets.find((p) => p.label === value);
                  if (preset) {
                    filter.onChange(
                      filter.endDateOnly
                        ? [preset.value[1], preset.value[1]]
                        : preset.value
                    );
                    setOpen(false);
                  }
                }}
                className="gap-3"
              >
                {currentPresets.map((preset) => (
                  <div
                    key={preset.label}
                    className="flex gap-2 items-center cursor-pointer"
                  >
                    <RadioGroupItem
                      key={preset.label}
                      value={preset.label}
                      id={preset.label}
                    />
                    <Label
                      htmlFor={preset.label}
                      className="font-normal cursor-pointer"
                    >
                      {preset.label}
                    </Label>
                  </div>
                ))}
                <div className="flex gap-2 items-center cursor-pointer">
                  <RadioGroupItem value="custom" id="custom" />
                  <Label
                    htmlFor="custom"
                    className="font-normal cursor-pointer"
                  >
                    Custom
                  </Label>
                </div>
              </RadioGroup>
            </div>
            {selectedPreset === "custom" && (
              <div className="flex flex-col gap-1.5 mt-2">
                {!filter.endDateOnly && (
                  <>
                    <p>Starting</p>
                    <DatePicker
                      className="rounded-lg"
                      value={value?.[0]}
                      onChange={(updatedValue: Date | undefined) => {
                        if (updatedValue) {
                          const endDate = value?.[1]
                            ? dayjs(value[1]).isAfter(updatedValue)
                              ? value[1]
                              : updatedValue
                            : updatedValue;

                          filter.onChange([updatedValue, endDate]);
                        }
                      }}
                    />
                  </>
                )}
                <p>Ending</p>
                <DatePicker
                  disabled={value?.[0] === undefined}
                  className="rounded-lg"
                  fromDate={!filter.endDateOnly ? value?.[0] : undefined}
                  value={value?.[1]}
                  onChange={(updatedValue: Date | undefined) => {
                    const startDate = filter.endDateOnly
                      ? updatedValue
                      : value?.[0] || updatedValue;

                    if (updatedValue) {
                      filter.onChange([startDate as Date, updatedValue]);
                      setOpen(false);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between pt-2">
          <Button
            disabled={!selectedPreset || filter.disableClear}
            size="sm"
            variant="link"
            className="px-0 text-secondary-800"
            onClick={() => {
              filter.onChange(undefined);
              setSelectedPreset("");
              setOpen(false);
            }}
          >
            Clear
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const iconMap = {
  "date-range": CalendarIcon,
  checkbox: ListChecks,
  "number-range": Hash,
};

type FilterType = {
  defaultFilters?: string[];
  filters: (CheckboxFilterType | DateRangeFilterType | NumberRangeFilterType)[];
  className?: string;
  suffix?: ReactElement;
  onChange?: () => void;
};

const FilterBar = ({
  filters,
  className,
  suffix,
  onChange,
  defaultFilters = [],
}: FilterType) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);

  return (
    <div className={cn("flex flex-wrap gap-2 items-center", className)}>
      <Select
        previewRender={() => (
          <Button icon={Filter} size="sm">
            Filters
          </Button>
        )}
        popoverClassName="w-44"
        options={filters
          .sort((a, b) => {
            if (a.type === "date-range") return -1;
            if (b.type === "date-range") return 1;
            return 0;
          })
          .map((filter) => ({
            label: filter.label,
            value: filter.key,
            icon: iconMap[filter.type],
          }))}
        onChange={(value) => {
          setSelected(value);
        }}
      />
      {filters
        .filter((filter) => {
          if (defaultFilters.includes(filter.key)) {
            return true;
          }
          if (selected === filter.key) {
            return true;
          }
          if (Array.isArray(filter.value)) {
            return filter.value.length > 0;
          }
          if (typeof filter.value === "object" && filter.value !== null) {
            return Object.keys(filter.value).length > 0;
          }
          return filter.value !== undefined;
        })
        .map((filter) =>
          match(filter.type)
            .with("checkbox", () => (
              <CheckboxFilter
                key={filter.label}
                filter={{
                  ...(filter as CheckboxFilterType),
                  onChange: (value) => {
                    (filter as CheckboxFilterType).onChange(value);
                    onChange?.();
                  },
                }}
              />
            ))
            .with("date-range", () => (
              <DateRangeFilter
                key={filter.label}
                filter={{
                  ...(filter as DateRangeFilterType),
                  onChange: (value) => {
                    (filter as DateRangeFilterType).onChange(value);
                    onChange?.();
                  },
                }}
              />
            ))
            .with("number-range", () => (
              <NumberRangeFilter
                key={filter.label}
                filter={{
                  ...(filter as NumberRangeFilterType),
                }}
              />
            ))
            .exhaustive()
        )}
      {suffix}
    </div>
  );
};

export default FilterBar;
