import { Check, Loader2 } from "lucide-react";

type TProps = {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  loading?: boolean;
  checked?: boolean;
};

const CircleTick = ({
  loading = false,
  checked = true,
  onClick = () => {},
}: TProps) => {
  return !loading ? (
    <div
      onClick={(e) => (!loading ? onClick(e) : () => {})}
      className={`
        flex items-center justify-center w-5 h-5 cursor-pointer rounded-full
        transition-all duration-200 ease-in-out
        border-2 border-gray-300
        ${checked ? "bg-green-600 border-green-600" : "hover:border-green-600"}
        group
      `}
    >
      <Check
        className={`
          w-3 h-3 
          transition-all duration-200
          ${
            checked ? "text-white scale-100" : "scale-0  !hover:text-green-600"
          } group-hover:scale-100`}
        strokeWidth={3}
      />
    </div>
  ) : (
    <Loader2 className="w-4 h-4 text-green-600 animate-twSpin" />
  );
};

export default CircleTick;
