import { convertFileSize } from "@heffl/ui/lib/utils";
import Schemas from "@heffl/server/src/schemas";
import { produce } from "immer";
import { Trash2, Upload } from "lucide-react";
import React, { ChangeEvent, useRef } from "react";
import { z } from "zod";
import { Button } from "@heffl/ui/components/primitives/button";
import FileIcon from "./file-icon";
import { cn } from "@heffl/ui/lib/utils";

type NewFile = {
  name: string;
  size: number;
  format: string;
  file: File;
};

type ExistingFile = z.infer<typeof Schemas.files.fileItem>;
type DeletedFile = { id: number; link: string };

export type FileTypes = {
  existing: ExistingFile[];
  new: NewFile[];
  deleted: DeletedFile[];
};

const fileSizes = [
  {
    name: "5MB" as const,
    value: 5 * 1024 * 1024,
  },
  {
    name: "10MB" as const,
    value: 10 * 1024 * 1024,
  },
];

type Props = {
  allowMultiple?: boolean;
  value?: FileTypes;
  onChange?: (file: FileTypes) => void;
  accept?: string;
  // TODO setup maxSize functionality
  maxSize?: (typeof fileSizes)[number]["name"];
  label?: string;
  className?: string;
  showPreview?: boolean;
  buttonVariant?: "outline" | "ghost";
};

const fileToObject = (file: File) => ({
  name: file.name,
  size: file.size,
  format: file.type,
  file,
});

const FilePicker = ({
  value = { new: [], deleted: [], existing: [] },
  onChange,
  allowMultiple = false,
  accept,
  label,
  className,
  showPreview = true,
  buttonVariant = "outline",
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange || !value) return;
    let updatedFiles = value;
    if (e.target.files && e.target.files.length) {
      const filesObject = Array.from(e.target.files).map(fileToObject);
      if (allowMultiple) {
        updatedFiles = produce(updatedFiles, (draft) => {
          draft.new.push(...filesObject);
        });
      } else {
        updatedFiles = produce(updatedFiles, (draft) => {
          if (draft.existing.length > 0 || draft.new.length > 0) {
            // @ts-ignore
            draft.deleted.push(...draft.existing);
            draft.existing = [];
            draft.new = [];
          }
          draft.new.push(filesObject[0]);
        });
      }
      onChange(updatedFiles);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    e.stopPropagation();
  };

  return (
    <>
      <input
        onClick={(e) => {
          e.stopPropagation();
          // for fixing file not selected if same second time
          if (e.target && e.target) {
            // @ts-ignore
            e.target.value = null;
          }
        }}
        type="file"
        ref={fileInputRef}
        className="!hidden"
        onChange={handleFileSelect}
        accept={accept}
        multiple={allowMultiple}
      />
      <div onClick={handleClick} className="w-full">
        <Button className={cn(className)} variant={buttonVariant}>
          <Upload className="h-4" />
          {label || "Upload image"}
        </Button>
      </div>
      {showPreview && (
        <div className="flex flex-col gap-2 pt-2 w-full">
          {[...value.existing, ...value.new].map((file) => (
            <div
              className="flex gap-4 justify-between p-2 bg-gray-50 rounded-md border"
              key={file.name}
            >
              <div className="flex gap-4 items-center">
                <FileIcon
                  ext={file.name.split(".").pop() || ""}
                  className="border shadow-sm"
                />
                <div className="flex flex-col justify-between h-11">
                  <p>{file.name}</p>
                  <p className="text-xs text-gray-500">
                    {convertFileSize(file.size)}
                  </p>
                </div>
              </div>
              <Trash2
                onClick={() => {
                  if (!onChange || !value) return;
                  const updatedFiles = produce(value, (draft) => {
                    if ("id" in file && typeof file.id === "number") {
                      // @ts-ignore
                      draft.deleted.push(file as FileTypes["existing"][number]);
                      draft.existing = draft.existing.filter(
                        (f) => f.id !== file.id
                      );
                    } else {
                      draft.new = draft.new.filter((f) => f.name !== file.name);
                    }
                  });
                  onChange(updatedFiles);
                }}
                className="h-5 text-red-500 cursor-pointer"
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FilePicker;
