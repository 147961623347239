import { z } from "zod";
import { fileItem } from "../../schemas/files";
import enums from "../../schemas/enums";

const filesInput = z.object({
  new: z.array(
    z.object({
      name: z.string(),
      format: z.string(),
      size: z.number(),
      link: z.string().default(""),
      file: z.instanceof(File).nullish(),
      fileField: z.string(),
    })
  ),
  existing: z.array(fileItem).default([]),
  deleted: z.array(z.object({ id: z.number(), link: z.string() })),
});

const customObject = z.object({
  name: z.string(),
  singularLabel: z.string(),
  pluralLabel: z.string(),
});

const customObjectRecord = z.object({
  customObjectId: z.number(),
  data: z.record(z.string(), z.any()),
  files: z.record(z.string(), filesInput).optional(),
});

const objectTemplateField = z.object({
  name: z.string(),
  label: z.string(),
  position: z.number().default(1),
  width: z.number(),
});

const objectTemplate = z.object({
  name: z.string(),
  position: z.number().default(1),
  entity: enums.entities,
  customObjectId: z.number().nullish(),
  objectTemplateFields: z.array(objectTemplateField),
});

export default {
  customObject,
  customObjectRecord,
  objectTemplate,
  objectTemplateField,
};
