import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { Card } from "@heffl/ui/components/primitives/card";
import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { FileText } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuotationStatusBadge } from "../quotations/list";
import enums from "@heffl/server/src/schemas/enums";
import { z } from "zod";

const QuotesSummaryReport: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useParamsState<
    RouterInputs["sales"]["reports"]["quoteSummaryReport"]
  >({
    search: "",
    statuses: ["ACCEPTED", "SENT"],
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
    clients: [],
    tags: [],
    salesPersons: [],
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: tags } = trpc.tags.list.useQuery({
    type: "QUOTATION",
  });
  const { data: salesPersons } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  const { data, isLoading } = trpc.sales.reports.quoteSummaryReport.useQuery({
    ...filters,
    statuses: filters.statuses,
    dates: filters.dates,
    clients: filters.clients,
    tags: filters.tags,
    salesPersons: filters.salesPersons,
  });

  const summary = useMemo(() => {
    if (!data) return null;
    return [
      {
        title: "Total Quotes",
        value: data.summary.totalQuotations,
      },
      {
        title: "Total Quote Value",
        value: formatCurrency(data.summary.totalQuoteValue || 0, "AED"),
      },
      {
        title: "Average Quote Value",
        value: formatCurrency(data.summary.averageQuoteValue || 0, "AED"),
      },
      {
        title: "Unique Clients",
        value: data.summary.uniqueClients,
      },
      {
        title: "Quote Conversion Rate",
        value: `${data.summary.quoteConversionRate.toFixed(2)}%`,
      },
    ];
  }, [data]);

  return (
    <Page title="Quotes Summary Report" fullWidth className="sm:!p-0" showBack>
      <FilterBar
        className="p-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
          {
            key: "markedAcceptedOn",
            type: "date-range",
            label: "Accepted On",
            value: filters.markedAcceptedOn,
            onChange: (value) => setFilters({ markedAcceptedOn: value }),
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses || [],
            onChange: (value) =>
              setFilters({
                statuses: value as z.infer<typeof enums.quotationStatus>[],
              }),
            options: [
              { label: "Draft", value: "DRAFT" },
              { label: "Sent", value: "SENT" },
              { label: "Accepted", value: "ACCEPTED" },
              { label: "Rejected", value: "REJECTED" },
            ],
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            showSearch: true,
            value: filters.clients || [],
            onChange: (value) => {
              setFilters({ clients: value as number[] });
            },
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
            onSearch: (e) => setClientSearch(e),
          },
          {
            key: "salesPersons",
            label: "Sales Persons",
            type: "checkbox",
            value: filters.salesPersons || [],
            options: salesPersons?.map((user) => ({
              label: heffl.format.name(user),
              value: user.id,
            })),
            onChange: (e) => {
              setFilters({ salesPersons: e as number[] });
            },
          },
          {
            key: "tags",
            label: "Tags",
            type: "checkbox",
            value: filters.tags || [],
            options:
              tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) ?? [],
            onChange: (e) => {
              setFilters({ tags: e as number[] });
            },
          },
        ]}
      />

      <>
        <div className="grid grid-cols-2 gap-4 px-3 sm:grid-cols-3 lg:grid-cols-6">
          {summary?.map((item, index) => (
            <Card key={index}>
              <h3 className="text-xs font-medium text-gray-500">
                {item.title}
              </h3>
              <p className="mt-1 text-xl font-semibold text-gray-900">
                {item.value}
              </p>
            </Card>
          ))}
        </div>

        <DataGrid
          name="quoteSummaryReport"
          rows={data?.quotations || []}
          loading={isLoading}
          className="h-[calc(100vh-190px-var(--header-height))] mt-12"
          columns={[
            {
              key: "number",
              name: "Quote #",
              width: 150,
              renderCell: ({ row }) => (
                <div
                  className="flex gap-2 items-center cursor-pointer hover:underline hover:text-blue-500"
                  onClick={() =>
                    navigate(`/sales/quotations/details/${row.id}`)
                  }
                >
                  <FileText size={16} />
                  {row.number}
                </div>
              ),
            },
            {
              key: "date",
              name: "Date",
              width: 150,
              renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
            },
            {
              key: "client",
              name: "Client",
              width: 200,
              renderCell: ({ row }) => row.clients.name,
            },
            {
              key: "total",
              name: "Total",
              width: 150,
              renderCell: ({ row }) =>
                formatCurrency(
                  calculateLineItems({
                    lineItems: row.quotationProducts,
                    discount: row.discount,
                  }).total,
                  row.currencies.code
                ),
            },
            {
              key: "status",
              name: "Status",
              width: 150,
              renderCell: ({ row }) => (
                <QuotationStatusBadge status={row.status} />
              ),
            },
            {
              key: "markedAcceptedOn",
              name: "Accepted On",
              width: 150,
              renderCell: ({ row }) =>
                row.markedAcceptedOn && row.status === "ACCEPTED"
                  ? heffl.format.dateTime(row.markedAcceptedOn)
                  : "-",
            },
            {
              key: "salesPerson",
              name: "Sales Person",
              width: 150,
              renderCell: ({ row }) =>
                row.salesPerson ? formatName(row.salesPerson) : "-",
            },
          ]}
          rowKey="id"
          label="Quotes Summary Report"
        />
      </>
    </Page>
  );
};

export default QuotesSummaryReport;
