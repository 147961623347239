import { Card } from "@heffl/ui/components/primitives/card";
import { DATE_TIME_WORD_FORMAT, getInvoiceStatus } from "@/lib/constants";
import { formatCurrency } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import enums from "@heffl/server/src/schemas/enums";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";

type TInvoice = {
  id: number;
  number: string;
  clients?: {
    name: string;
  };
  status: z.infer<typeof enums.invoiceStatus>;
  createdAt: Date;
  currencies: {
    symbol?: string;
  };
  invoiceProducts: {
    price: number;
    quantity: number;
    tax: number;
    viewType: z.infer<typeof enums.lineItemViewType>;
  }[];
  discount: number;
  paymentReceivedAllocations: { amount: number }[];
};

const InvoiceCard = ({ data }: { data: TInvoice }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/sales/invoices/details/${data.id}`)}
      className="cursor-pointer"
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-medium">
            {data.clients?.name ?? "No company"}
          </p>

          <div className="flex flex-row gap-2">
            <div
              className={`${
                getInvoiceStatus(data.status).color
              } hover:text-black rounded-full px-3 py-1 text-xs text-white font-medium w-fit flex items-center`}
            >
              {getInvoiceStatus(data.status).label}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Invoice number</p>
          <p className="text-sm font-medium text-gray-700">#{data.number}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created on</p>
          <p className="text-sm text-gray-500">
            {format(data.createdAt, DATE_TIME_WORD_FORMAT)}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Total</p>
          <p className="text-sm font-medium text-primary">
            {formatCurrency(
              calculateInvoice(data).invoiceTotal,
              data.currencies.symbol || "AED"
            )}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default InvoiceCard;
