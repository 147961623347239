import {
  Popover,
  PopoverContent,
} from "@heffl/ui/components/primitives/popover";
import { DEFAULT_DATE } from "@/lib/constants";
import { cn, dynamicDateFormatting } from "@heffl/ui/lib/utils";
import * as chrono from "chrono-node";
import formatReminderDate, {
  DateTypeString,
} from "@heffl/server/src/helpers/utils/formatReminderDate";
import { PopoverAnchor } from "@radix-ui/react-popover";
import dayjs from "dayjs";
import { Calendar as CalendarIcon, LucideIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Calendar } from "@heffl/ui/components/primitives/calendar";
import TimePicker from "@heffl/ui/components/primitives/time-picker";
import { Button } from "@heffl/ui/components/primitives/button";
import { Input } from "@heffl/ui/components/primitives/input";

export type ReminderDateValue = {
  date: Date;
  dateType: DateTypeString;
};

type Props = {
  ogDate: Date | null;
  value?: ReminderDateValue;
  onChange?: (v: ReminderDateValue | undefined) => void;
  options?: { label: string; value: DateTypeString }[];
  icon?: LucideIcon;
  placeholder?: string;
  renderClassName?: string;
};

const SelectableDateicker = ({
  placeholder = "Select a value",
  ...dateProps
}: Props) => {
  const [showPopover, setShowPopover] = useState<
    "select" | "date" | undefined
  >();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const Icon = dateProps.icon || CalendarIcon;

  useEffect(() => {
    if (
      dateProps.onChange &&
      dateProps.ogDate &&
      dateProps.value &&
      dateProps.value.dateType &&
      dateProps.value.date
    ) {
      dateProps.onChange(
        formatReminderDate({
          date: dateProps.value.date,
          dateType: dateProps.value.dateType,
          ogDate: dateProps.ogDate,
        })
      );
    }
  }, [dateProps.ogDate]);

  useEffect(() => {
    if (showPopover === "date") {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
    setInputValue("");
  }, [showPopover]);

  return (
    <div>
      <Popover
        open={!!showPopover}
        onOpenChange={() => setShowPopover(undefined)}
      >
        <PopoverAnchor asChild>
          <Button
            onClick={() => setShowPopover("select")}
            className={cn(
              "w-full justify-start text-left !font-normal relative px-2 sm:py-2 py-3 h-12 sm:h-9 ",
              dateProps.renderClassName
            )}
          >
            <Icon className="h-4" />
            {dateProps.value &&
              dateProps.value.date &&
              dateProps.value.dateType === "CUSTOM_DATE" && (
                <p>{dynamicDateFormatting(dateProps.value.date)}</p>
              )}

            {!!dateProps.value &&
              !!dateProps.value.dateType &&
              dateProps.value.dateType !== "CUSTOM_DATE" && (
                <p>
                  {dateProps.options &&
                    dateProps.options.find(
                      (o) => o.value === dateProps?.value?.dateType
                    )?.label}
                </p>
              )}
            {!dateProps.value && <p>{placeholder}</p>}
          </Button>
        </PopoverAnchor>

        <PopoverContent
          className={`flex flex-col !p-1 ${showPopover === "select" && "w-52"}`}
        >
          {showPopover === "select" && (
            <>
              <div
                className="w-full p-1.5 cursor-pointer hover:bg-gray-50 rounded"
                onClick={() => {
                  setShowPopover("date");
                }}
              >
                Custom Date
              </div>
              {dateProps.options?.map((option) => (
                <div
                  key={option.value}
                  className="w-full p-1.5 cursor-pointer hover:bg-gray-50 rounded"
                  onClick={() => {
                    setShowPopover(undefined);
                    if (
                      dateProps.onChange &&
                      dateProps.ogDate &&
                      option.value !== "CUSTOM_DATE"
                    ) {
                      dateProps.onChange(
                        formatReminderDate({
                          date: dateProps.value?.date || new Date(),
                          dateType: option.value,
                          ogDate: dateProps.ogDate,
                        })
                      );
                    }
                  }}
                >
                  {option.label}
                </div>
              ))}
            </>
          )}
          {showPopover === "date" && (
            <>
              <div className="p-2">
                <Input
                  ref={inputRef}
                  className="w-full !h-8"
                  placeholder="Next week, next month"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      const parsed = chrono.parseDate(inputValue);
                      if (parsed) {
                        dateProps.onChange &&
                          dateProps.onChange({
                            date: parsed,
                            dateType: "CUSTOM_DATE",
                          });
                        setInputValue("");
                        setShowPopover(undefined);
                      }
                    }
                  }}
                />
              </div>
              <Calendar
                mode="single"
                selected={dateProps.value?.date}
                onSelect={(newValue) => {
                  const time = dayjs(dateProps.value?.date || DEFAULT_DATE);
                  dateProps.onChange &&
                    newValue &&
                    dateProps.onChange({
                      date: dayjs(newValue)
                        .set("hour", time.hour())
                        .set("minute", time.minute())
                        .toDate(),
                      dateType: "CUSTOM_DATE",
                    });

                  setShowPopover(undefined);
                }}
                initialFocus
              />
              <hr className="my-1" />
              <div className="p-1.5 space-y-1">
                <p className="text-sm font-medium">Time</p>
                <TimePicker
                  value={dateProps.value?.date || DEFAULT_DATE}
                  onChange={(newTimeValue) => {
                    const time = dayjs(newTimeValue);
                    if (
                      dateProps.onChange &&
                      newTimeValue &&
                      dateProps.value?.date
                    ) {
                      dateProps.onChange({
                        date: dayjs(dateProps.value.date)
                          .set("hour", time.hour())
                          .set("minute", time.minute())
                          .toDate(),
                        dateType: "CUSTOM_DATE",
                      });
                    }
                  }}
                />
              </div>
            </>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SelectableDateicker;
