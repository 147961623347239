import { z } from "zod";
import enums from "./enums";

export const chartOfAccounts = z.object({
  type: enums.accounting.chartOfAccountsType,
  name: z.string(),
  accountCode: z.string().nullish(),
  description: z.string().optional(),
  isActive: z.boolean().default(true),
  parentAccountId: z.number().nullish(),
});
