import { Switch as AntSwitch, ConfigProvider } from "antd";

type SwitchProps = {
  value?: boolean;
  onChange?: (checked: boolean) => void;
  size?: "small" | "medium";
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

const Switch = ({
  value,
  onChange,
  size = "medium",
  checkedChildren,
  unCheckedChildren,
  className,
  disabled,
}: SwitchProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#56CB99",
        },
      }}
    >
      <AntSwitch
        checked={value}
        onChange={(checked) => onChange?.(checked)}
        size={size === "medium" ? "default" : size}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        className={className}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};

export { Switch };
