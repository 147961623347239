import Empty from "@/components/Empty";
import InfoItemsHZ from "@/components/InfoItemHZ";
import Page from "@/components/page";
import PrintPreview from "@/components/print-preview";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import useIntegrations from "@/lib/hooks/useIntegrations";
import useTeam from "@/lib/hooks/useTeam";
import { ApplyUnusedCreditsModal } from "@/pages/crm/clients/details/newDetails";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { getJobId } from "@/pages/field-service/jobs/list";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import Schemas from "@heffl/server/src/schemas";
import appIcons from "@heffl/ui/components/appIcons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Timeline } from "@heffl/ui/components/primitives/Timeline";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import ShineBorder from "@heffl/ui/components/primitives/shine-border";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import ResponsiveActions from "@heffl/ui/components/responsive-actions";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import messageToMobileApp from "@heffl/ui/lib/messageToMobileApp";
import {
  cn,
  dynamicDateFormatting,
  formatCurrency,
  formatName,
  isMobile,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  ArrowLeftToLine,
  Ban,
  CalendarDays,
  Copy,
  CreditCard,
  Download,
  Eye,
  Info,
  Link,
  MailCheck,
  MoreHorizontal,
  PanelTopOpen,
  PencilIcon,
  PlusIcon,
  Printer,
  Receipt,
  Sparkles,
  SquarePen,
  Trash,
  UserCircle,
} from "lucide-react";
import { sum } from "radash";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import slugify from "slugify";
import { z } from "zod";
import { MobileInvoice } from "../quotations/clientShare/MobileQuote";
import {
  AddPaymentReceivedModal,
  EditPaymentReceivedModal,
} from "./components/payment-modals";
import { InvoiceStatusBadge } from "./list";
import { useAutoAnimate } from "@formkit/auto-animate/react";
export const InfoItemHZ = ({
  label,
  value,
  onClick,
}: {
  label: string;
  value?: string | ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div className="flex justify-between w-full">
      <p className="text-gray-500">{label}</p>
      {typeof value === "string" ? (
        <p
          onClick={onClick}
          className={cn(
            "text-sm text-gray-800",
            onClick && "text-primary cursor-pointer"
          )}
        >
          {value}
        </p>
      ) : (
        value
      )}
    </div>
  );
};

export const generateInvoiceName = (invoice: {
  date: Date;
  number: string | number;
  clients: {
    name: string;
  };
}) => {
  return `Invoice ${invoice.number}-${invoice.clients.name}-${dayjs(
    invoice.date
  ).format("DD-MM-YYYY")}`;
};

export const downloadPdf = async ({
  name,
  url,
}: {
  name: string;
  url: `print?${
    | "payment"
    | "invoice"
    | "proforma-invoice"
    | "quotation"
    | "purchase-order"
    | "bill"
    | "sales-order"
    | "document"}=${string}`;
}) => {
  const userAgent = navigator.userAgent;

  const isDev = import.meta.env.VITE_HOST.includes("localhost");
  const pdfViewURL = isDev
    ? "https://clienthub.heffl.com/print/invoices/3c16fe12-4019-43ed-a987-ae720d1230a4"
    : `https://clienthub.heffl.com/${url}`;

  const pdfFileName = `${slugify(name)}.pdf`;

  const pdfDownloadURL = `${
    import.meta.env.VITE_SERVER_URL
  }/api/render-pdf?url=${pdfViewURL}&name=${pdfFileName}`;

  if (userAgent.startsWith("hefflApp")) {
    return messageToMobileApp({
      action: "downloadFile",
      data: {
        url: pdfDownloadURL,
        fileName: pdfFileName,
      },
    });
  }

  const maxRetries = 3;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await axios.get(pdfDownloadURL, {
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", pdfFileName);
      fileLink.setAttribute("target", "_blank");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      return;
    } catch (error) {
      console.error(`Download attempt ${retries + 1} failed:`, error);
      retries++;
      if (retries === maxRetries) {
        console.error("Max retries reached. Download failed.");
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
    }
  }
};

export const AddPaymentLinkModal = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues: Partial<z.infer<typeof Schemas.sales.paymentLink>>;
}) => {
  const form = useForm<z.infer<typeof Schemas.sales.paymentLink>>({
    resolver: zodResolver(Schemas.sales.paymentLink),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const addPaymentLinkMutation = trpc.paymentLinks.add.useMutation({
    onSuccess(response) {
      if (response.active) {
        const paymentLink = `https://clienthub.heffl.com/payment-links/${response.uuid}`;
        copyToClipboard(paymentLink, false);
        toast.success("Payment link created successfully and copied");
      } else {
        console.log(response, "response");
        toast.error("Issue creating payment link");
      }
      onClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.sales.paymentLink>) => {
    addPaymentLinkMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Create payment link"
      footer={
        <Button
          onClick={() => form.handleSubmit(onSubmit)()}
          loading={addPaymentLinkMutation.isLoading}
          className="w-full"
          variant="primary"
          icon={Copy}
        >
          Create & Copy link
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <FormField name="amount" label="Amount">
          <Input prefix="AED" placeholder="00.00" prefixFilled />
        </FormField>
        <FormField name="description" label="Description">
          <Textarea placeholder="Payment for invoice #1234" />
        </FormField>
      </Form>
    </ModalDrawer>
  );
};

const InvoiceDetails = () => {
  const params = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const invoicePrintRef = useRef(null);
  const invoiceId = Number(params.id);
  const [animationRef] = useAutoAnimate();

  const integrations = useIntegrations();

  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [editPaymentModal, setEditPaymentModal] = useState<number | null>(null);
  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [paymentDownloadLoading, setPaymentDownloadLoading] = useState(false);
  const [showAddPaymentLinkModal, setShowAddPaymentLinkModal] = useState(false);
  const [showApplyCreditsModal, setShowApplyCreditsModal] = useState(false);
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState<
    { number: string; uuid: string } | undefined
  >(undefined);

  const { data: invoice } = trpc.invoices.details.useQuery(invoiceId);
  const { data: client } = trpc.clients.details.useQuery(
    invoice?.clientId as number,
    {
      enabled: !!invoice?.clientId,
    }
  );

  const team = useTeam();

  const printInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
    documentTitle: invoice ? generateInvoiceName(invoice) : "",
  });

  const invoiceUpdateMutation = trpc.invoices.update.useMutation({
    onSuccess() {
      toast.success("Invoice updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const invoiceDeleteMutation = trpc.invoices.delete.useMutation({
    onSuccess() {
      toast.success("Invoice deleted successfully");
      navigate("/sales/invoices");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (!invoice || !team || !client) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_INVOICES.allowed) {
    return <NoPermissionScreen />;
  }

  const suffix = (
    <div className="flex flex-col gap-2 sm:flex-row">
      <ResponsiveActions
        actions={[
          {
            label: "Print",
            buttonClassName: "sm:rounded-r-none",
            iconClassName: "text-purple-500",
            icon: Printer,
            onClick: () => {
              setPreviewMode(false);
              setTimeout(() => {
                printInvoice();
                setPreviewMode(true);
              }, 100);
            },
          },
          {
            buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
            label: "Download",
            hideLabelOnDesktop: true,
            icon: ArrowDownToLine,
            onClick: async () => {
              setDownloadLoading(true);
              await downloadPdf({
                name: generateInvoiceName(invoice),
                url: `print?invoice=${invoice.uuid}`,
              });
              setTimeout(() => {
                setDownloadLoading(false);
              }, 3000);
            },
            loading: downloadLoading,
          },
          {
            buttonClassName: "sm:rounded-l-none sm:-ml-2",
            label: "Duplicate",
            hideLabelOnDesktop: true,
            icon: Copy,
            onClick: () =>
              navigate(`/sales/invoices/add?invoiceId=${invoice.id}`),
          },
          {
            hide:
              invoice.status !== "SENT" && invoice.status !== "PARTIALLY_PAID",
            label: "Collect Payment",
            iconClassName: "",
            icon: PanelTopOpen,
            buttonVariant: "primary",
            onClick: () => setShowPaymentModal(true),
          },
          {
            hide: invoice.status !== "CANCELLED",
            label: "Convert to Draft",
            iconClassName: "text-blue-500",
            icon: SquarePen,
            loading: invoiceUpdateMutation.isLoading,
            onClick: async () => {
              const confirmed = await confirm({
                title: "Are you sure to convert this invoice to draft?",
                body: "This will convert the invoice to draft and allow you to edit it again.",
                actionButton: "Convert",
              });
              if (confirmed) {
                invoiceUpdateMutation.mutate({
                  id: invoice.id,
                  invoice: {
                    status: "DRAFT",
                  },
                });
              }
            },
          },
          {
            hide:
              invoice.status !== "SENT" || !integrations.get("telr").integrated,
            label: "Payment Link",
            hideLabelOnDesktop: true,
            iconClassName: "text-green-500",
            icon: Link,
            onClick: () => setShowAddPaymentLinkModal(true),
          },
          {
            hide: invoice.status !== "DRAFT",
            label: "Mark as Sent",
            iconClassName: "text-green-500",
            loading: invoiceUpdateMutation.isLoading,
            icon: MailCheck,
            onClick: () => {
              invoiceUpdateMutation.mutate({
                id: invoice.id,
                invoice: {
                  status: "SENT",
                },
              });
            },
          },
          {
            icon: MoreHorizontal,
            label: "More",
            onClick: () => {},
            dropdown: (
              <>
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={() => navigate(`/sales/invoices/edit/${invoice.id}`)}
                >
                  <PencilIcon className="mr-2 w-5" />
                  Edit
                </Button>
                {invoice.status === "SENT" && (
                  <Button
                    variant="ghost"
                    className="flex justify-start"
                    onClick={async () => {
                      const confirmed = await confirm({
                        title: "Revert to Draft?",
                        body: "This will change the invoice status back to draft.",
                        actionButton: "Revert",
                      });
                      if (confirmed) {
                        invoiceUpdateMutation.mutate({
                          id: invoice.id,
                          invoice: {
                            status: "DRAFT",
                          },
                        });
                      }
                    }}
                    icon={ArrowLeftToLine}
                  >
                    Revert to Draft
                  </Button>
                )}
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={() =>
                    navigate(`/purchases/bills/add?invoiceId=${invoice.id}`)
                  }
                  iconClassName="text-blue-500"
                  icon={Receipt}
                >
                  Convert to Bill
                </Button>
                {!["PAID", "CANCELLED", "PARTIALLY_PAID"].includes(
                  invoice.status
                ) && (
                  <Button
                    variant="ghost"
                    className="flex justify-start text-red-500"
                    onClick={() => setShowCancelInvoiceModal(true)}
                  >
                    <Ban className="mr-2 w-5" />
                    Cancel Invoice
                  </Button>
                )}
                <Button
                  variant="ghost"
                  className="flex justify-start text-red-500"
                  onClick={async () => {
                    const confirmed = await confirm({
                      title: "Are you sure to delete this invoice?",
                      body: `This will delete the invoice "#${invoice.number}" and its history from the system.`,
                      actionButton: "Delete",
                    });
                    if (confirmed) {
                      invoiceDeleteMutation.mutate(invoice.id);
                    }
                  }}
                >
                  <Trash className="mr-2 w-5" />
                  Delete
                </Button>
              </>
            ),
          },
        ]}
      />
    </div>
  );

  const timeLineItems = () => {
    const items: {
      onClick: () => void;
      title: string;
      titleSuffix?: ReactNode;
      description: ReactNode;
    }[] = [];
    invoice.paymentReceivedAllocations.forEach((allocation) => {
      items.push({
        onClick: () => setEditPaymentModal(allocation.paymentsRecieved.id),
        title: `${formatCurrency(allocation.amount, "AED")} received`,
        titleSuffix: (
          <div className="flex gap-2 items-center">
            <Button
              onClick={() =>
                setShowPaymentDetailsModal({
                  number: allocation.paymentsRecieved.number,
                  uuid: allocation.paymentsRecieved.uuid,
                })
              }
              icon={Eye}
              size="xs"
              variant="primaryOutline"
            />
            <Button
              onClick={async () => {
                setPaymentDownloadLoading(true);
                await downloadPdf({
                  name: `Payment ${allocation.paymentsRecieved.number}`,
                  url: `print?payment=${allocation.paymentsRecieved.uuid}`,
                });
                setPaymentDownloadLoading(false);
              }}
              icon={ArrowDownToLine}
              size="xs"
              variant="primaryOutline"
              loading={paymentDownloadLoading}
            />
          </div>
        ),
        description: (
          <div>
            <p>
              {dayjs(allocation.paymentsRecieved.date).format("DD MMM YYYY")}
            </p>
            <div className="flex flex-wrap gap-1 items-center">
              {!!allocation.paymentsRecieved.refNo && (
                <p className="text-xs text-gray-500">{`#${allocation.paymentsRecieved.refNo} - `}</p>
              )}
              <Badge small variant="unique">
                {allocation.paymentsRecieved.paymentMethods.name}
              </Badge>
              {allocation.paymentsRecieved.collectedBy && (
                <Badge small variant="unique" icon={UserCircle}>
                  {formatName(allocation.paymentsRecieved.collectedBy)}
                </Badge>
              )}
            </div>
          </div>
        ),
      });
    });
    return items.reverse();
  };

  const totalAmount = calculateInvoice(invoice).invoiceTotal;
  const paidAmount = sum(invoice.paymentReceivedAllocations, (a) => a.amount);
  const balanceAmount = totalAmount - paidAmount;
  const showUnusedCreditsInfo =
    client.unUsedCredits > 0 &&
    heffl.array.includes(["PARTIALLY_PAID", "SENT"], invoice.status);

  return (
    <Page
      fullWidth
      title={`Invoice #${invoice.number}`}
      tags={
        <div className="flex gap-2">
          <InvoiceStatusBadge invoice={invoice} />
          {invoice.invoiceTags.map((tag) => (
            <Badge key={tag.id} background={tag.tags.color} small={isMobile()}>
              {tag.tags.name}
            </Badge>
          ))}
        </div>
      }
      description={`Created on ${format(
        invoice.createdAt,
        "dd MMM yyyy hh:mm a"
      )}`}
      showBack
      suffix={suffix}
    >
      <AddPaymentLinkModal
        open={showAddPaymentLinkModal}
        onClose={() => setShowAddPaymentLinkModal(false)}
        defaultValues={{
          amount: calculateInvoice(invoice).pendingTotal,
          invoiceId: invoice.id,
          clientId: invoice.clients.id,
          description: `Payment for invoice #${invoice.number}`,
          paymentGateway: "telr",
          currencyId: invoice.currencyId,
        }}
      />
      <ModalDrawer
        open={showPaymentDetailsModal !== undefined}
        onClose={() => setShowPaymentDetailsModal(undefined)}
        title="Payment reciept"
        defaultActionsButtonSize="xs"
        actions={[
          {
            label: "Download",
            icon: Download,
            buttonVariant: "primaryOutline",
            onClick: async () => {
              setPaymentDownloadLoading(true);
              await downloadPdf({
                name: `Payment ${showPaymentDetailsModal?.number}`,
                url: `print?payment=${showPaymentDetailsModal?.uuid}`,
              });
              setPaymentDownloadLoading(false);
            },
            loading: paymentDownloadLoading,
          },
        ]}
        modalClassName="max-w-screen-sm"
      >
        <div className="overflow-auto w-full h-full">
          <div className="w-[21cm] h-[29.7cm] transform scale-[0.75] origin-top-left mx-auto">
            <PrintPreview paymentUuid={showPaymentDetailsModal?.uuid} />
          </div>
        </div>
      </ModalDrawer>
      <ApplyUnusedCreditsModal
        clientId={invoice.clients.id}
        open={showApplyCreditsModal}
        onClose={() => setShowApplyCreditsModal(false)}
      />
      <ModalDrawer
        open={showCancelInvoiceModal}
        onClose={() => setShowCancelInvoiceModal(false)}
        title="Cancel Invoice"
        footer={
          <Button
            variant="destructive"
            onClick={() => {
              if (!cancelReason.length) return;
              invoiceUpdateMutation.mutate({
                id: invoice.id,
                invoice: {
                  status: "CANCELLED",
                  cancelReason: cancelReason,
                },
              });
              setShowCancelInvoiceModal(false);
              setCancelReason("");
            }}
            disabled={!cancelReason.length}
            loading={invoiceUpdateMutation.isLoading}
          >
            Cancel Invoice
          </Button>
        }
      >
        <Textarea
          placeholder="Reason for cancellation"
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </ModalDrawer>
      <AddPaymentReceivedModal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        defaultValues={{
          clientId: invoice.clients.id,
          invoiceId: invoice.id,
        }}
      />
      {!!editPaymentModal && (
        <EditPaymentReceivedModal
          open={!!editPaymentModal}
          id={editPaymentModal}
          onClose={() => setEditPaymentModal(null)}
        />
      )}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-12">
        <div className="flex justify-center w-full sm:col-span-8">
          <div className="w-full rounded-xl border border-gray-200 sm:w-fit sm:h-fit">
            {isMobile() ? (
              <MobileInvoice invoice={invoice} />
            ) : (
              <div ref={invoicePrintRef}>
                <TemplateRender
                  contentHtml={invoice.contentInjected}
                  template={invoice.documentTemplates}
                  previewMode={previewMode}
                />
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4 w-full sm:col-span-4" ref={animationRef}>
          {showUnusedCreditsInfo && (
            <ShineBorder
              className="overflow-hidden relative p-4 bg-gradient-to-br from-indigo-50 to-purple-50"
              borderRadius={12}
              color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
            >
              <div className="flex gap-3 items-center mb-3">
                <div className="p-2 bg-purple-100 rounded-lg">
                  <Sparkles className="w-5 h-5 text-purple-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Credits Available
                </h3>
              </div>

              <div className="space-y-3">
                <p className="text-gray-700">
                  This client has{" "}
                  <span className="font-semibold text-purple-700">
                    {heffl.format.currency(client.unUsedCredits, "AED")}
                  </span>{" "}
                  in unused credits that can be applied to this invoice.
                </p>

                <Button
                  onClick={() => setShowApplyCreditsModal(true)}
                  variant="primaryOutline"
                  size="sm"
                  className="w-full bg-white"
                  icon={CreditCard}
                >
                  Apply Credits
                </Button>
              </div>

              <div className="absolute bottom-0 left-0 w-40 h-40 bg-indigo-200 rounded-full opacity-20 filter blur-3xl delay-700 animate-pulse" />
            </ShineBorder>
          )}

          <Card
            title="Details"
            icon={<Info className="h-5 text-blue-500" />}
            className="space-y-3"
          >
            <InfoItemsHZ
              items={[
                {
                  label: "Date",
                  value: dayjs(invoice.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Status",
                  value: <InvoiceStatusBadge invoice={invoice} />,
                },
                {
                  hidden: invoice.status !== "CANCELLED",
                  label: "Cancel reason",
                  value: invoice.cancelReason,
                },
                {
                  label: "Client",
                  value: invoice.clients.name,
                  onClick: () =>
                    navigate(`/sales/clients/details/${invoice.clients.id}`),
                },
                {
                  label: "Due date",
                  value: dayjs(invoice.dueDate).format("DD/MM/YYYY"),
                },
                {
                  hidden: invoice.status === "DRAFT",
                  label: "Sent on",
                  value: dayjs(invoice.markedSentOn).format("DD/MM/YYYY"),
                },
                {
                  label: "Total amount",
                  value: formatCurrency(totalAmount, "AED"),
                },
                {
                  label: "Paid amount",
                  value: formatCurrency(paidAmount, "AED"),
                },
                {
                  label: "Balance amount",
                  value: formatCurrency(balanceAmount, "AED"),
                },
                {
                  hidden: integrations.get("zoho_books").integrated === false,
                  label: "Zoho ID",
                  onClick: () => {
                    const zohoBooksIntegrationData =
                      integrations.get("zoho_books");

                    if (zohoBooksIntegrationData.integrated) {
                      const link = `https://books.zoho.com/app/${zohoBooksIntegrationData.data.organization_id}#/invoices/${invoice.zohoInvoiceId}`;
                      window.open(link, "_blank");
                    }
                  },
                  value: invoice.zohoInvoiceId ? (
                    <p>{invoice.zohoInvoiceId}</p>
                  ) : (
                    <p className="text-red-500">Not synced</p>
                  ),
                },
                {
                  hidden: !team.apps.installed("FIELD_SERVICE"),
                  label: "Job",
                  value: invoice.fsJobInvoices
                    ? getJobId(invoice.fsJobInvoices.fsJobs)
                    : "Not linked",
                  onClick: () => {
                    if (!invoice.fsJobInvoices) return;
                    navigate(
                      `/field-service/jobs/details/${invoice.fsJobInvoices.fsJobs.id}`
                    );
                  },
                },
                {
                  label: "Sales order ",
                  value: invoice.salesOrderInvoices
                    ? `#${invoice.salesOrderInvoices.salesOrders.number}`
                    : "Not linked",
                  onClick: () => {
                    if (!invoice.salesOrderInvoices) return;
                    navigate(
                      `/sales/sales-orders/details/${invoice.salesOrderInvoices.salesOrders.id}`
                    );
                  },
                },
                {
                  hidden: !team.apps.installed("PROJECTS"),
                  label: "Project",
                  value: invoice.projectInvoices ? (
                    <Badge
                      onClick={() =>
                        navigate(
                          `/projects/details/${invoice.projectInvoices?.projects.id}`
                        )
                      }
                      background="gray"
                      icon={appIcons.projects.project.icon}
                      variant="unique"
                      small
                      className="cursor-pointer"
                    >
                      {invoice.projectInvoices.projects.title}
                    </Badge>
                  ) : (
                    "No projects linked"
                  ),
                },
                {
                  label: "Sales person",
                  value: invoice.salesPerson
                    ? formatName(invoice.salesPerson)
                    : "Not assigned",
                },
                { label: "Created by", value: formatName(invoice.createdBy) },
              ]}
            />
          </Card>
          <Card
            title="Payments"
            icon={<CreditCard className="h-5 text-orange-500" />}
            suffix={
              <Button
                disabled={
                  !(
                    invoice.status === "SENT" ||
                    invoice.status === "PARTIALLY_PAID"
                  )
                }
                icon={PlusIcon}
                onClick={() => setShowPaymentModal(true)}
                size="sm"
                variant="ghost"
              />
            }
          >
            {invoice.paymentReceivedAllocations.length > 0 ? (
              <Timeline activeItem={-1} items={timeLineItems()} />
            ) : (
              <Empty
                icon={CreditCard}
                iconSize={30}
                title="No payments made"
                description="Collect payments to mark the invoice as paid."
              />
            )}
          </Card>
          {integrations.get("telr").integrated && (
            <Card
              title="Payment Links"
              icon={<Link className="h-5 text-blue-500" />}
              suffix={
                <Button
                  icon={PlusIcon}
                  onClick={() => setShowAddPaymentLinkModal(true)}
                  size="sm"
                  variant="ghost"
                />
              }
            >
              {invoice.paymentLinks && invoice.paymentLinks.length > 0 ? (
                <div className="space-y-4">
                  {invoice.paymentLinks.map((link) => (
                    <div
                      key={link.id}
                      className="flex justify-between items-center"
                    >
                      <div>
                        <p className="text-sm font-medium">
                          {link.description}
                        </p>
                        <div className="flex gap-1 items-center">
                          <Badge
                            small
                            variant={
                              link.paymentStatus === "PAID"
                                ? "success"
                                : link.paymentStatus === "PENDING"
                                ? "warning"
                                : "error"
                            }
                          >
                            {link.paymentStatus}
                          </Badge>
                        </div>
                        <p className="text-xs text-gray-500">
                          {dynamicDateFormatting(link.createdAt)}
                        </p>
                      </div>
                      <div className="flex flex-col items-end space-x-2">
                        <p className="text-sm font-medium text-gray-600">
                          {formatCurrency(link.amount, "AED")}
                        </p>
                        <Button
                          disabled={link.paymentStatus === "PAID"}
                          size="sm"
                          onClick={() => {
                            const paymentLink = `https://clienthub.heffl.com/payment-links/${link.uuid}`;
                            copyToClipboard(paymentLink);
                          }}
                        >
                          <Copy className="w-4 h-4" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Empty
                  icon={Link}
                  iconSize={30}
                  title="No payment links"
                  description="Create payment links to share with your clients."
                />
              )}
            </Card>
          )}
          <Card
            title="Timeline"
            icon={<CalendarDays className="h-5 text-green-500" />}
          >
            <ActivityTimeline activities={invoice.activities} />
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default InvoiceDetails;
