import Contacts from "@/pages/crm/contacts/list";
import {
  Banknote,
  BarChart,
  Box,
  Briefcase,
  Building,
  Building2,
  Cable,
  CalendarDays,
  CheckSquare,
  CircleDollarSign,
  ClipboardList,
  CreditCard,
  DollarSign,
  FileDigit,
  FileStack,
  FileX,
  Goal,
  Hammer,
  Handshake,
  Key,
  Landmark,
  MapPin,
  PieChart,
  ReceiptText,
  ScrollText,
  ShoppingCart,
  Tag,
  Target,
  TargetIcon,
  TextCursorInput,
  Timer,
  UserSearch,
  UserSquare2,
  UsersRound,
  Wrench,
} from "lucide-react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import SideBarWrapper from "./components/layouts/SidebarWrapper";
import AiAssistant from "./pages/ai-assistant";
import Clients from "./pages/crm/clients/list";
import DealDetails from "./pages/crm/deals/details";

import appIcons from "@heffl/ui/components/appIcons";
import AppsList from "./pages/apps/list";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import EditAutomation from "./pages/automations/edit";
import AutomationsList from "./pages/automations/list";
import Banking from "./pages/books/banking";
import ChartOfAccounts from "./pages/books/chart-of-accounts";
import BooksDashboard from "./pages/books/dashboard";
import BooksReports from "./pages/books/reports";
import ExpensesReport from "./pages/books/reports/expense-report";
import ComingSoon from "./pages/coming-soon";
import ClientDetails from "./pages/crm/clients/details/newDetails";
import Details from "./pages/crm/contacts/details";
import CRMDashboard from "./pages/crm/dashboard";
import DealsList from "./pages/crm/deals/list";
import PipelineAdd from "./pages/crm/deals/pipelines/add";
import PipelineEdit from "./pages/crm/deals/pipelines/edit";
import SalesGoalDetails from "./pages/crm/goals/details";
import GoalsList from "./pages/crm/goals/list";
import LeadDiscovery from "./pages/crm/lead-discovery";
import LeadDetails from "./pages/crm/leads/details";
import LeadsList from "./pages/crm/leads/list";
import BusinessCardScan from "./pages/crm/leads/scan";
import SendEmail from "./pages/crm/sendEmail";
import Tasks from "./pages/crm/tasks";
import EditDocument from "./pages/documents/edit";
import TemplateUpdate from "./pages/documentTemplates/edit";
import TemplatesList from "./pages/documentTemplates/list";
import EmployeeDetails from "./pages/employees/details";
import Employees from "./pages/employees/list";
import PermissionSetEdit from "./pages/employees/permission-sets/edit";
import PermissionSets from "./pages/employees/permission-sets/list";
import ExternalConnect from "./pages/external-connect";
import Dashboard from "./pages/field-service/dashboard";
import AddJob from "./pages/field-service/jobs/add";
import JobDetails from "./pages/field-service/jobs/details";
import EditJob from "./pages/field-service/jobs/edit";
import FsJobsList from "./pages/field-service/jobs/list";
import CancelledSchedules from "./pages/field-service/reports/cancelled-schedules";
import CompletedSchedules from "./pages/field-service/reports/completed-schedules";
import ConfirmedSchedules from "./pages/field-service/reports/confirmed-schedules";
import FieldServiceReports from "./pages/field-service/reports/field-service-reports";
import InprogressSchedules from "./pages/field-service/reports/inprogress-schedules";
import ScheduledSchedules from "./pages/field-service/reports/scheduled-schedules";
import ServiceProductUtilization from "./pages/field-service/reports/service-product-utilization";
import VendorServiceReport from "./pages/field-service/reports/vendor-service-reports";
import Schedules from "./pages/field-service/schedules";
import ActivitiesDetails from "./pages/field-service/staff-app/activities/details";
import FieldStaffDashboard from "./pages/field-service/staff-app/dashboard";
import StaffAppJobDetails from "./pages/field-service/staff-app/schedules/details";
import Files from "./pages/files";
import FormDetails from "./pages/forms/details";
import FormsList from "./pages/forms/list";
import Integrations from "./pages/integrations/list";
import NewHome from "./pages/newHome";
import ObjectDetails from "./pages/objects/details";
import ObjectsList from "./pages/objects/list";
import PersonalDashboard from "./pages/personal-dashboard";
import FileForgeTest from "./pages/print-test";
// import ProjectDashboard from "./pages/projects/dashboard";
import CashflowTransactions from "./pages/books/cashflow-transactions";
import PurchasesByItemReport from "./pages/books/reports/purchasesByItem";
import SalesByItemReport from "./pages/books/reports/salesByItem";
import TrialBalance from "./pages/books/reports/trial-balance";
import VendorTransactions from "./pages/books/reports/vendor-transactions";
import ActivitiesList from "./pages/crm/activities/list";
import ContractsExpiringJobs from "./pages/field-service/reports/contracts-expiring-jobs";
import ProjectsDashboard from "./pages/projects/dashboard";
import { ProjectDetails } from "./pages/projects/details/projectsDetails";
import ProjectsListPage from "./pages/projects/list/list";
import ProjectPipelineAdd from "./pages/projects/pipelines/add";
import ProjectPipelinEdit from "./pages/projects/pipelines/edit";
import ProjectReports from "./pages/projects/reports";
import ProjectSummaryReport from "./pages/projects/reports/project-reports/project-summary-report";
import TasksReport from "./pages/projects/reports/task-reports";
import TimesheetReports from "./pages/projects/reports/timesheet-reports";
import ProjectTasks from "./pages/projects/tasks";
import Templates from "./pages/projects/templates";
import EditTemplate from "./pages/projects/templates/edit";
import TimeSheetsList from "./pages/projects/timesheets/list";
import BillAdd from "./pages/purchases/bills/add";
import BillDetails from "./pages/purchases/bills/details";
import BillEdit from "./pages/purchases/bills/edit";
import BillsList from "./pages/purchases/bills/list";
import ExpensesList from "./pages/purchases/expenses/list";
import PaymentsMadeList from "./pages/purchases/payments-made/list";
import PurchaseOrderAdd from "./pages/purchases/purchaseOrders/add";
import PurchaseOrderDetails from "./pages/purchases/purchaseOrders/details";
import PurchaseOrderEdit from "./pages/purchases/purchaseOrders/edit";
import PurchaseOrderListPage from "./pages/purchases/purchaseOrders/list";
import VendorDetails from "./pages/purchases/vendors/details";
import Vendors from "./pages/purchases/vendors/list";
import CommissionsList from "./pages/sales/commissions/list";
import SalesDashboard from "./pages/sales/dashboard";
import AddInvoice from "./pages/sales/invoices/add";
import InvoiceDetails from "./pages/sales/invoices/details";
import InvoiceEdit from "./pages/sales/invoices/edit";
import Invoices from "./pages/sales/invoices/list";
import AddPayment from "./pages/sales/paymetsReceived/add";
import PaymentsReceived from "./pages/sales/paymetsReceived/list";
import Products from "./pages/sales/products";
import AddProformaInvoice from "./pages/sales/proforma-invoice/add";
import ProformaInvoiceDetails from "./pages/sales/proforma-invoice/details";
import ProformaInvoiceEdit from "./pages/sales/proforma-invoice/edit";
import ProformaInvoices from "./pages/sales/proforma-invoice/list";
import AddQuotation from "./pages/sales/quotations/add";
import ClientShare from "./pages/sales/quotations/clientShare";
import QuotationDetails from "./pages/sales/quotations/details";
import EditQuotation from "./pages/sales/quotations/edit";
import Quotations from "./pages/sales/quotations/list";
import InvoiceReports from "./pages/sales/reports";
import CustomerBalances from "./pages/sales/reports/customer-balances-report";
import InvoiceSummaryReport from "./pages/sales/reports/invoice-summary-report";
import PaymentsReceivedReport from "./pages/sales/reports/payments-received-report";
import ProfitLossReport from "./pages/sales/reports/profit-loss-report";
import QuotesSummaryReport from "./pages/sales/reports/quotes-summary-report";
import TypingCenterReport from "./pages/sales/reports/typing-center-report";
import AddSalesOrder from "./pages/sales/salesOrders/add";
import SalesOrderDetails from "./pages/sales/salesOrders/details";
import EditSalesOrder from "./pages/sales/salesOrders/edit";
import SalesOrders from "./pages/sales/salesOrders/list";
import ClientVendorSettings from "./pages/settings/client-vendor";
import CommissionSettings from "./pages/settings/commission";
import CustomFields from "./pages/settings/custom-fields";
import FieldServiceSettings from "./pages/settings/fieldService";
import InvoiceSettings from "./pages/settings/invoices";
import LeadsSettings from "./pages/settings/leads";
import LostReasons from "./pages/settings/lostReasons";
import ModuleNumbers from "./pages/settings/moduleNumbers";
import PaymentMethods from "./pages/settings/paymentMethods";
import Profile from "./pages/settings/profile";
import QuotationSettings from "./pages/settings/quotation";
import SalesOrderSettings from "./pages/settings/sales-orders";
import Sources from "./pages/settings/sources";
import Tags from "./pages/settings/tags";
import TeamDetails from "./pages/settings/team-details";
import Zones from "./pages/settings/zones";
import VendorBalancesReport from "./pages/sales/reports/vendor-balances-report";
import ProjectFinancialReport from "./pages/projects/reports/project-reports/project-financial-report";
// import AppSidebar from "./components/layouts/AppSidebar";

const purchasesRoutes = (
  <>
    <Route path="purchase-orders">
      <Route index element={<PurchaseOrderListPage />} />
      <Route path="add" element={<PurchaseOrderAdd />} />
      <Route path="details/:id" element={<PurchaseOrderDetails />} />
      <Route path="edit/:id" element={<PurchaseOrderEdit />} />
    </Route>
    <Route path="vendors">
      <Route index element={<Vendors />} />
      <Route path="details/:id" element={<VendorDetails />} />
    </Route>
    <Route path="payments-made" element={<PaymentsMadeList />} />
    <Route path="bills">
      <Route index element={<BillsList />} />
      <Route path="add" element={<BillAdd />} />
      <Route path="details/:id" element={<BillDetails />} />
      <Route path="edit/:id" element={<BillEdit />} />
    </Route>
    <Route path="expenses">
      <Route index element={<ExpensesList />} />
    </Route>
    <Route path="reports">
      <Route path="vendor-transactions" element={<VendorTransactions />} />
    </Route>
  </>
);

const salesRoutes = (
  <>
    <Route path="proforma-invoices">
      <Route index element={<ProformaInvoices />} />
      <Route path="add" element={<AddProformaInvoice />} />
      <Route path="details/:id" element={<ProformaInvoiceDetails />} />
      <Route path="edit/:id" element={<ProformaInvoiceEdit />} />
    </Route>
    <Route path="dashboard" element={<SalesDashboard />} />
    <Route path="clients">
      <Route index element={<Clients />} />
      <Route path="details/:id" element={<ClientDetails />} />
    </Route>
    <Route path="quotations">
      <Route index element={<Quotations />} />
      <Route path="add" element={<AddQuotation />} />
      <Route path="details/:id" element={<QuotationDetails />} />
      <Route path="edit/:id" element={<EditQuotation />} />
    </Route>

    <Route path="invoices">
      <Route index element={<Invoices />} />
      <Route path="add" element={<AddInvoice />} />
      <Route path="details/:id" element={<InvoiceDetails />} />
      <Route path="edit/:id" element={<InvoiceEdit />} />
    </Route>
    <Route path="payments">
      <Route index element={<PaymentsReceived />} />
      <Route path="add" element={<AddPayment />} />
    </Route>
    <Route path="commissions" element={<CommissionsList />} />
    <Route path="sales-orders">
      <Route index element={<SalesOrders />} />
      <Route path="add" element={<AddSalesOrder />} />
      <Route path="details/:id" element={<SalesOrderDetails />} />
      <Route path="edit/:id" element={<EditSalesOrder />} />
    </Route>
  </>
);

// changed routes architecture to use show unsaved prompt
const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="external-connect/:mobile" element={<ExternalConnect />} />
      <Route path="client-hub">
        <Route path="quotes/:id" element={<ClientShare />} />
        <Route path="invoice/:id" element={<ClientShare />} />
      </Route>
      <Route element={<Login />} path="/login" />
      <Route element={<Register />} path="/add-new-user" />
      <Route>
        <Route path="/print-test" element={<FileForgeTest />} />
        <Route path="/" element={<PersonalDashboard />} />
        <Route path="new-home" element={<NewHome />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="contacts">
          <Route index element={<Contacts />} />
          <Route path="details/:contactId" element={<Details />} />
        </Route>
        <Route path="/products" element={<Products />} />
        <Route path="apps">
          <Route path="list" element={<AppsList />} />
        </Route>
        <Route
          path="/purchases"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: ClipboardList,
                  label: "Purchase orders",
                  path: "purchase-orders",
                },
                { icon: Banknote, label: "Expenses", path: "expenses" },
                { icon: Briefcase, label: "Vendors", path: "vendors" },
                { icon: ReceiptText, label: "Bills", path: "bills" },
                {
                  icon: appIcons.purchases.paymentsMade.icon,
                  label: "Payments Made",
                  path: "payments-made",
                },
              ]}
            />
          }
        >
          {purchasesRoutes}
        </Route>
        <Route
          path="/sales"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: PieChart,
                  label: "Dashboard",
                  path: "dashboard",
                },
                {
                  icon: ScrollText,
                  label: "Quotations",
                  path: "quotations",
                },
                {
                  icon: CircleDollarSign,
                  label: "Invoices",
                  path: "invoices",
                },
                {
                  icon: ShoppingCart,
                  label: "Sales Orders",
                  path: "sales-orders",
                },
                {
                  icon: DollarSign,
                  label: "Proforma",
                  path: "proforma-invoices",
                },
                {
                  icon: Banknote,
                  label: "Payments",
                  path: "payments",
                },
                { icon: Building, label: "Clients", path: "clients" },
                { icon: Box, label: "Products", path: "products" },
                {
                  icon: appIcons.sales.commissions.icon,
                  label: "Commissions",
                  path: "commissions",
                },
                { icon: BarChart, label: "Reports", path: "reports" },
              ]}
            />
          }
        >
          <Route path="products" element={<Products />} />
          {salesRoutes}
          <Route path="reports">
            <Route path="expense-report" element={<ExpensesReport />} />
            <Route path="profit-loss-report" element={<ProfitLossReport />} />
            <Route index element={<InvoiceReports />} />
            <Route
              path="invoice-summary-report"
              element={<InvoiceSummaryReport />}
            />
            <Route
              path="payments-received-report"
              element={<PaymentsReceivedReport />}
            />
            <Route
              path="quotes-summary-report"
              element={<QuotesSummaryReport />}
            />
            <Route
              path="vendor-summary-report"
              element={<VendorServiceReport />}
            />
            <Route
              path="customer-balances-report"
              element={<CustomerBalances />}
            />
            <Route
              path="vendor-balances-report"
              element={<VendorBalancesReport />}
            />
            <Route
              path="typing-center-report"
              element={<TypingCenterReport />}
            />
          </Route>
        </Route>
        <Route path="/documents">
          <Route path="edit/:id" element={<EditDocument />} />
        </Route>
        <Route path="/templates">
          <Route index element={<TemplatesList />} />
          <Route path="edit/:id" element={<TemplateUpdate />} />
        </Route>
        <Route
          path="/books"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: PieChart,
                  label: "Dashboard",
                  path: "dashboard",
                },
                {
                  icon: Box,
                  label: "Products",
                  path: "products",
                },
                {
                  icon: Landmark,
                  label: "Banking",
                  path: "banking",
                },
                {
                  icon: appIcons.sales.icon,
                  label: "Sales",
                  path: "sales",
                  subMenuLinks: [
                    {
                      icon: appIcons.sales.clients.icon,
                      label: "Clients",
                      path: "sales/clients",
                    },
                    {
                      icon: appIcons.sales.quotes.icon,
                      label: "Quotes",
                      path: "sales/quotations",
                    },
                    {
                      icon: appIcons.sales.invoices.icon,
                      label: "Invoices",
                      path: "sales/invoices",
                    },
                    {
                      icon: appIcons.sales.salesOrders.icon,
                      label: "Sales Orders",
                      path: "sales/sales-orders",
                    },
                    {
                      icon: appIcons.sales.payments.icon,
                      label: "Payments",
                      path: "sales/payments",
                    },
                    {
                      icon: appIcons.sales.commissions.icon,
                      label: "Commissions",
                      path: "sales/commissions",
                    },
                  ],
                },
                {
                  icon: appIcons.purchases.icon,
                  label: "Purchases",
                  path: "purchases",
                  subMenuLinks: [
                    {
                      icon: appIcons.purchases.vendors.icon,
                      label: "Vendors",
                      path: "purchases/vendors",
                    },
                    {
                      icon: appIcons.purchases.expenses.icon,
                      label: "Expenses",
                      path: "purchases/expenses",
                    },
                    {
                      icon: appIcons.purchases.paymentsMade.icon,
                      label: "Payments Made",
                      path: "purchases/payments-made",
                    },
                    {
                      icon: appIcons.purchases.bills.icon,
                      label: "Bills",
                      path: "purchases/bills",
                    },
                    {
                      icon: appIcons.purchases.purchaseOrders.icon,
                      label: "Purchase Orders",
                      path: "purchases/purchase-orders",
                    },
                  ],
                },
                {
                  icon: appIcons.books.chartOfAccounts.icon,
                  label: "Chart of Accounts",
                  path: "chart-of-accounts",
                },

                { icon: BarChart, label: "Reports", path: "reports" },
              ]}
            />
          }
        >
          <Route path="dashboard" element={<BooksDashboard />} />
          <Route path="products" element={<Products />} />
          <Route path="chart-of-accounts" element={<ChartOfAccounts />} />
          <Route path="banking" element={<Banking />} />
          <Route path="cashflow-accounts">
            <Route path="transactions/:id" element={<CashflowTransactions />} />
          </Route>
          <Route path="sales">{salesRoutes}</Route>
          <Route path="purchases">{purchasesRoutes}</Route>
          <Route path="reports">
            <Route index element={<BooksReports />} />
            <Route path="trial-balance" element={<TrialBalance />} />
            <Route path="sales-by-item" element={<SalesByItemReport />} />
            <Route
              path="purchases-by-item"
              element={<PurchasesByItemReport />}
            />
          </Route>
        </Route>
        <Route path="automations">
          <Route index element={<AutomationsList />} />
          <Route path="edit/:id" element={<EditAutomation />} />
        </Route>
        <Route path="forms">
          <Route index element={<FormsList />} />
          <Route path="details/:id" element={<FormDetails />} />
        </Route>
        <Route
          path="/crm"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: PieChart,
                  label: "Dashboard",
                  path: "dashboard",
                },
                { icon: TargetIcon, label: "Leads", path: "leads/table" },
                { icon: Handshake, label: "Deals", path: "deals/board" },
                { icon: Goal, label: "Goals", path: "goals" },
                { icon: Building, label: "Clients", path: "clients" },
                { icon: CheckSquare, label: "Tasks", path: "tasks/board" },
                {
                  icon: appIcons.common.activities.icon,
                  label: "Activities",
                  path: "activities/table",
                },
                {
                  icon: UserSquare2,
                  label: "Contacts",
                  path: "contacts",
                },
              ]}
            />
          }
        >
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="activities/:view" element={<ActivitiesList />} />
          <Route path="dashboard" element={<CRMDashboard />} />
          <Route path="send-email" element={<SendEmail />} />
          <Route path="lead-discovery" element={<LeadDiscovery />} />
          <Route path="goals">
            <Route index element={<GoalsList />} />
            <Route path="details/:id" element={<SalesGoalDetails />} />
          </Route>
          <Route path="tasks/:view" element={<Tasks />} />
          <Route path="leads">
            <Route path="details/:leadId" element={<LeadDetails />} />
            <Route path=":view" element={<LeadsList />} />
            <Route path="scan" element={<BusinessCardScan />} />
          </Route>
          <Route path="deals">
            <Route path=":view" element={<DealsList />} />
            <Route path="details/:dealId" element={<DealDetails />} />
            <Route path="pipelines">
              <Route path="edit/:pipelineId" element={<PipelineEdit />} />
              <Route path="add/" element={<PipelineAdd />} />
            </Route>
          </Route>
          <Route path="contacts">
            <Route index element={<Contacts />} />
            <Route path="details/:contactId" element={<Details />} />
          </Route>
          <Route path="clients">
            <Route index element={<Clients />} />
            <Route path="details/:id" element={<ClientDetails />} />
          </Route>
        </Route>
        <Route
          path="/projects"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: PieChart,
                  label: "Dashboard",
                  path: "dashboard",
                },
                {
                  icon: CheckSquare,
                  label: "Tasks",
                  path: "tasks/board",
                },
                {
                  icon: ClipboardList,
                  label: "Projects",
                  path: "board",
                },
                { icon: FileStack, label: "Templates", path: "templates" },
                { icon: Timer, label: "Timesheets", path: "timesheets" },
                { icon: BarChart, label: "Reports", path: "reports" },
              ]}
            />
            // <AppSidebar
            //   linkGroups={[
            //     {
            //       links: [
            //         {
            //           icon: PieChart,
            //           label: "Dashboard",
            //           path: "dashboard",
            //           type: "link",
            //         },
            //         {
            //           icon: CheckSquare,
            //           label: "Tasks",
            //           path: "tasks/board",
            //           type: "link",
            //         },
            //         {
            //           icon: ClipboardList,
            //           label: "Projects",
            //           path: "board",
            //           type: "link",
            //         },
            //         {
            //           icon: FileStack,
            //           label: "Templates",
            //           path: "templates",
            //           type: "list",
            //           isCollapsible: true,
            //         },
            //         {
            //           icon: Timer,
            //           label: "Timesheets",
            //           path: "timesheets",
            //           type: "link",
            //         },
            //         {
            //           icon: BarChart,
            //           label: "Reports",
            //           path: "reports",
            //           type: "link",
            //         },
            //       ],
            //     },
            //   ]}
            // />
          }
        >
          <Route path="dashboard" element={<ProjectsDashboard />} />
          <Route path="admin-dashboard" element={<ProjectsDashboard />} />
          <Route path=":view" element={<ProjectsListPage />} />
          <Route path="details/:projectId" element={<ProjectDetails />} />
          <Route path="tasks/:view" element={<ProjectTasks />} />
          <Route path="pipelines">
            <Route path="edit/:pipelineId" element={<ProjectPipelinEdit />} />
            <Route path="add/" element={<ProjectPipelineAdd />} />
          </Route>
          <Route path="templates">
            <Route index element={<Templates />} />
            <Route path="edit/:id" element={<EditTemplate />} />
          </Route>
          <Route path="timesheets" element={<TimeSheetsList />} />
          <Route path="reports">
            <Route index element={<ProjectReports />} />
            <Route
              path="project-summary-report"
              element={<ProjectSummaryReport />}
            />
            <Route
              path="project-financial-report"
              element={<ProjectFinancialReport />}
            />
            <Route path="tasks-report" element={<TasksReport />} />
            <Route path="timesheet-reports" element={<TimesheetReports />} />
          </Route>
        </Route>
        <Route
          path="/field-service"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: PieChart,
                  label: "Dashboard",
                  path: "dashboard",
                },
                {
                  icon: CalendarDays,
                  label: "Schedules",
                  path: "schedules/calendar",
                },
                {
                  icon: Hammer,
                  label: "Jobs",
                  path: "jobs",
                },
                {
                  icon: BarChart,
                  label: "Reports",
                  path: "reports",
                },
              ]}
            />
          }
        >
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="schedules/:view" element={<Schedules />} />
          <Route path="reports">
            <Route index element={<FieldServiceReports />} />
            <Route
              path="vendor-summary-report"
              element={<VendorServiceReport />}
            />
            <Route
              path="service-product-utilization"
              element={<ServiceProductUtilization />}
            />
            <Route
              path="confirmed-schedules"
              element={<ConfirmedSchedules />}
            />
            <Route
              path="scheduled-schedules"
              element={<ScheduledSchedules />}
            />
            <Route
              path="inprogress-schedules"
              element={<InprogressSchedules />}
            />
            <Route
              path="completed-schedules"
              element={<CompletedSchedules />}
            />
            <Route
              path="cancelled-schedules"
              element={<CancelledSchedules />}
            />
            <Route
              path="contracts-expiring-jobs"
              element={<ContractsExpiringJobs />}
            />
          </Route>
          <Route path="staff-app">
            <Route path="dashboard" element={<FieldStaffDashboard />} />
            <Route
              path="schedules/details/:id"
              element={<StaffAppJobDetails />}
            />
            <Route
              path="activities/details/:id"
              element={<ActivitiesDetails />}
            />
          </Route>

          <Route path="jobs">
            <Route index element={<FsJobsList />} />
            <Route path="add" element={<AddJob />} />
            <Route path="edit/:id" element={<EditJob />} />
            <Route path="details/:id" element={<JobDetails />} />
            Ro
          </Route>
        </Route>
        <Route path="/settings/profile" element={<Profile />} />
        <Route
          path="/settings"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: Building2,
                  label: "Client & Vendor",
                  path: "client-vendor",
                },
                {
                  icon: ScrollText,
                  label: "Quotation ",
                  path: "quotation",
                },
                {
                  icon: CircleDollarSign,
                  label: "Invoices",
                  path: "invoices",
                },
                {
                  icon: appIcons.sales.commissions.icon,
                  label: "Commission",
                  path: "commission",
                },
                {
                  icon: Target,
                  label: "Leads ",
                  path: "leads",
                },
                {
                  icon: Wrench,
                  label: "Field service",
                  path: "field-service",
                },
                {
                  icon: appIcons.sales.salesOrders.icon,
                  label: "Sales orders",
                  path: "sales-orders",
                },
                {
                  icon: Tag,
                  label: "Tags",
                  path: "tags",
                },
                {
                  icon: Box,
                  label: "Objects",
                  path: "objects",
                },
                {
                  icon: TextCursorInput,
                  label: "Custom fields",
                  path: "custom-fields",
                },
                {
                  icon: MapPin,
                  label: "Zones",
                  path: "zones",
                },
                {
                  icon: UserSearch,
                  label: "Sources",
                  path: "sources",
                },
                {
                  icon: FileX,
                  label: "Lost reasons",
                  path: "lost-reasons",
                },
                {
                  icon: CreditCard,
                  label: "Payment methods",
                  path: "payment-methods",
                },
                {
                  icon: FileDigit,
                  label: "Module numbers",
                  path: "module-numbers",
                },
                {
                  icon: Cable,
                  label: "Integrations",
                  path: "integrations",
                },
                {
                  icon: UsersRound,
                  label: "Team",
                  path: "team",
                },
              ]}
            />
          }
        >
          <Route index element={<Navigate to="client-vendor" />} />
          <Route path="team" element={<TeamDetails />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="module-numbers" element={<ModuleNumbers />} />
          <Route path="quotation" element={<QuotationSettings />} />
          <Route path="commission" element={<CommissionSettings />} />
          <Route path="invoices" element={<InvoiceSettings />} />
          <Route path="leads" element={<LeadsSettings />} />
          <Route path="field-service" element={<FieldServiceSettings />} />
          <Route path="sales-orders" element={<SalesOrderSettings />} />
          <Route path="client-vendor" element={<ClientVendorSettings />} />
          <Route path="tags" element={<Tags />} />
          <Route path="zones" element={<Zones />} />
          <Route path="lost-reasons" element={<LostReasons />} />
          <Route path="objects">
            <Route index element={<ObjectsList />} />
            <Route path="details/:slug" element={<ObjectDetails />} />
          </Route>
          <Route path="custom-fields" element={<CustomFields />} />
          <Route path="sources" element={<Sources />} />
          <Route path="payment-methods" element={<PaymentMethods />} />
        </Route>
        <Route path="/ai-assistant">
          <Route index element={<AiAssistant />} />
        </Route>
        <Route
          path="/employees"
          element={
            <SideBarWrapper
              links={[
                {
                  icon: UsersRound,
                  label: "All",
                  path: "list",
                },
                {
                  icon: Key,
                  label: "Permission sets",
                  path: "permission-sets",
                },
              ]}
            />
          }
        >
          <Route index element={<Navigate to="list" />} />
          <Route path="list" element={<Employees />} />
          <Route path="permission-sets" element={<PermissionSets />} />
          <Route
            path="permission-sets/edit/:id"
            element={<PermissionSetEdit />}
          />
          <Route path="details/:id" element={<EmployeeDetails />} />
        </Route>
        <Route path="/files">
          <Route index element={<Files />} />
        </Route>
      </Route>
      <Route path="*" element={<ComingSoon />} />
    </Route>
  )
);

export default routes;
