import { zodResolver } from "@hookform/resolvers/zod";
import { Briefcase, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";

import Page from "@/components/page";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";

import { trpc } from "@/helpers/trpc";

import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import { COUNTRIES_UAE_ID } from "@/lib/dbIds";
import { AddressForm, ContactForm } from "@/pages/crm/clients/list";
import Schemas from "@heffl/server/src/schemas";
import { Label } from "@heffl/ui/components/primitives/label";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  findPrimaryContact,
  formatAddress,
  formatName,
  isMobile,
} from "@heffl/ui/lib/utils";

const VendorForm = () => {
  return (
    <>
      <FormField name="name" label="Name">
        <Input placeholder="Vendor name" />
      </FormField>
      <Label>Primary contact</Label>
      <ContactForm name="primaryContact" />
      <StripeTabs
        className="mt-4"
        items={[
          {
            key: "other-details",
            label: "Other details",
            children: (
              <div className="flex flex-col gap-3">
                <FormField name="openingBalance" label="Opening Balance">
                  <Input placeholder="Opening Balance" type="number" />
                </FormField>
                <FormField name="taxNumber" label="TRN number">
                  <Input placeholder="TRN number" />
                </FormField>
              </div>
            ),
          },
          {
            key: "billing-address",
            label: "Billing Address",
            children: <AddressForm />,
          },
        ]}
      />
    </>
  );
};

export const AddVendorDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (newVendorId?: number) => void;
}) => {
  const vendorAddMutation = trpc.purchases.vendors.add.useMutation({
    onSuccess(data) {
      toast.success("Successfully added vendor.");
      form.reset();
      onClose(data.id);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const form = useForm<z.infer<typeof Schemas.purchases.vendor>>({
    resolver: zodResolver(Schemas.purchases.vendor),
    defaultValues: {
      billingAddress: {
        type: "BILLING",
        countryId: COUNTRIES_UAE_ID,
      },
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.purchases.vendor>) => {
    vendorAddMutation.mutate(values);
  };

  return (
    <ModalDrawer
      closeOnOverlayClick={false}
      className="max-w-xl"
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Add Vendor"
      footer={
        <Button
          loading={vendorAddMutation.isLoading}
          className="!mt-6 w-full"
          type="submit"
          variant="primary"
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
        >
          Add vendor
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <VendorForm />
      </Form>
    </ModalDrawer>
  );
};

export const EditVendorDrawer = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const { data: vendor } = trpc.purchases.vendors.details.useQuery(id, {
    refetchOnWindowFocus: false,
  });

  const vendorDeleteMutation = trpc.purchases.vendors.delete.useMutation({
    onSuccess() {
      navigate("/sales/vendors");
      toast.success("Successfully deleted vendor.");
      onClose();
    },
    onError(er) {
      toast.error(
        er.message || "Delete all associated resources of this vendor first."
      );
    },
  });

  const vendorUpdateMutation = trpc.purchases.vendors.update.useMutation({
    onSuccess() {
      toast.success("Successfully updated vendor.");
      onClose();
    },
    onError(er) {
      toast.error(er.message);
    },
  });

  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.purchases.vendor>>({
    resolver: zodResolver(Schemas.purchases.vendor),
  });

  useEffect(() => {
    if (vendor) {
      form.reset({
        ...vendor,
        primaryContact: vendor.contacts.find((contact) => contact.isPrimary),
        billingAddress: vendor.clientAddresses.find(
          (address) => address.type === "BILLING"
        ) || { countryId: COUNTRIES_UAE_ID, type: "BILLING" },
      });
    }
  }, [vendor, form]);

  const onSubmit = (values: z.infer<typeof Schemas.purchases.vendor>) => {
    vendorUpdateMutation.mutate({
      id,
      vendor: values,
    });
  };

  return (
    <ModalDrawer
      closeOnOverlayClick={false}
      className="max-w-xl"
      open={true}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Edit vendor"
      footer={
        <div className="flex gap-2 mt-2 w-full">
          <Button
            loading={vendorDeleteMutation.isLoading}
            variant="destructive"
            onClick={async () => {
              const confirmed = await confirm(
                "Are you sure you want to delete this vendor?"
              );
              if (confirmed) {
                vendorDeleteMutation.mutate(id);
              }
            }}
            icon={Trash2}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              form.handleSubmit(onSubmit)();
            }}
            loading={vendorUpdateMutation.isLoading}
            variant="primary"
            className="w-full"
            size="md"
          >
            Update
          </Button>
        </div>
      }
    >
      {!vendor ? (
        <FullScreenSpinner />
      ) : (
        <Form {...form} onSubmit={onSubmit}>
          <VendorForm />
        </Form>
      )}
    </ModalDrawer>
  );
};

const Vendors = () => {
  const navigate = useNavigate();

  const [addVendor, setAddVendor] = useState(false);
  const [editVendor, setEditVendor] = useState<number | null>(null);
  const [filters, setFilters] = useImmer({
    search: "",
    pageNo: 1,
    pageSize: 20,
  });

  const { data, isLoading: isLoadingVendors } =
    trpc.purchases.vendors.list.useQuery({
      search: filters.search,
    });

  return (
    <Page title="Vendors" fullWidth className="!p-0">
      {editVendor && (
        <EditVendorDrawer id={editVendor} onClose={() => setEditVendor(null)} />
      )}
      <AddVendorDrawer open={addVendor} onClose={() => setAddVendor(false)} />
      <div className="flex flex-row justify-between p-2">
        <SearchInput
          value={filters.search}
          onChange={(v) =>
            setFilters((f) => {
              f.search = v;
            })
          }
          placeholder="Search vendors..."
        />

        <ResponsiveActionButton
          onClick={() => setAddVendor(true)}
          text="Vendor"
        />
      </div>
      {data ? (
        isMobile() ? (
          <div className="flex flex-col gap-2 mt-4 mb-[100px]">
            {data.vendors.map((vendor) => (
              <div key={vendor.id} className="p-4 rounded-lg border">
                <h3 className="font-bold">{vendor.name}</h3>
                <p>{vendor.taxNumber}</p>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <DataGrid
              loading={isLoadingVendors}
              rowKey="id"
              name="vendorsListMain"
              className="h-[calc(100vh-127px-var(--header-height))] mt-12"
              label="Vendors"
              rows={data?.vendors || []}
              onCellClick={(row) =>
                navigate(`/purchases/vendors/details/${row.row.id}`)
              }
              empty={{
                icon: Briefcase,
                title: "No vendors",
                description: "Please add some vendors to get started.",
                actionText: "Add vendor",
                onAction: () => setAddVendor(true),
                buttonSize: "sm",
              }}
              columns={[
                {
                  key: "number",
                  name: "#",
                  width: 60,
                },
                {
                  key: "name",
                  name: "Vendor",
                  width: 300,
                  renderCell: ({ row }) => (
                    <p className="truncate cursor-pointer hover:text-primary-800 hover:underline">
                      {row.name}
                    </p>
                  ),
                },
                {
                  key: "contact",
                  name: "Contact",
                  width: 180,
                  renderCell: ({ row }) => {
                    const primaryContact = findPrimaryContact(row.contacts);
                    return (
                      <p>
                        {primaryContact
                          ? formatName(primaryContact)
                          : "No contact"}
                      </p>
                    );
                  },
                },
                {
                  key: "address",
                  name: "Address",
                  width: 180,
                  renderCell: ({ row }) => {
                    const address = row.clientAddresses.find(
                      (address) => address.type === "BILLING"
                    );
                    return (
                      <div className="text-sm truncate">
                        {address ? formatAddress(address) : "No address"}
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>
        )
      ) : (
        <FullScreenSpinner />
      )}
    </Page>
  );
};

export default Vendors;
