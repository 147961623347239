import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import {
  PermissionItem,
  Permissions,
} from "@heffl/server/src/helpers/permissions/permissionItems";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { capitalize } from "@heffl/ui/lib/utils";
import Fuse from "fuse.js";
import { Trash } from "lucide-react";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const PermissionCheckbox = ({
  name,
  label,
  allowed,
  onClick,
  description,
}: {
  name: string;
  label: string;
  allowed: boolean;
  onClick?: () => void;
  description?: string;
}) => {
  return (
    <div className="flex flex-col gap-1 py-2 border-b border-gray-100 last:border-0">
      <div className="flex gap-2 items-center">
        <Checkbox id={name} value={allowed} onChange={onClick} />
        <label className="text-sm font-medium">{label}</label>
      </div>
      {!!description && (
        <p className="ml-6 text-xs text-gray-500">{description}</p>
      )}
    </div>
  );
};

const PermissionSetEdit = () => {
  const navigate = useNavigate();
  const params = useParams();

  const permissionSetId = Number(params.id);
  const [searchQuery, setSearchQuery] = useState("");

  const { data: permissionSet } =
    trpc.permissionSets.details.useQuery(permissionSetId);

  const deletePermissionSetMutation =
    trpc.permissionSets.deletePermissionSet.useMutation({
      onSuccess() {
        toast.success("Successfully deleted permission set");
        navigate("/employees/permission-sets");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const updatePermissionSetMutation =
    trpc.permissionSets.updatePermission.useMutation({
      onSuccess() {
        toast.success("Successfully updated permission set");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const permissionsArray = useMemo(() => {
    if (!permissionSet) return [];

    return Object.keys(permissionSet.permissionsSetItems).map((app) => ({
      appName: app as keyof Permissions,
      sections: Object.keys(
        permissionSet.permissionsSetItems[app as keyof Permissions]
      ).map((section) => ({
        sectionName: section as keyof Permissions[keyof Permissions],
        permissions: permissionSet.permissionsSetItems[
          app as keyof Permissions
        ][section as keyof Permissions[keyof Permissions]] as PermissionItem[],
      })),
    }));
  }, [permissionSet]);

  // Create flat array of all permissions for search
  const allPermissions = useMemo(
    () =>
      permissionsArray.flatMap((app) =>
        app.sections.flatMap((section) =>
          section.permissions.map((permission) => ({
            ...permission,
            appName: app.appName,
            sectionName: section.sectionName,
          }))
        )
      ),
    [permissionsArray]
  );

  // Setup fuzzy search
  const fuse = useMemo(
    () =>
      new Fuse(allPermissions, {
        keys: ["name", "label", "appName"],
        threshold: 0.3,
      }),
    [allPermissions]
  );

  const filteredPermissions = useMemo(() => {
    if (!searchQuery) return permissionsArray;

    const searchResults = fuse.search(searchQuery).map((result) => result.item);

    // Reconstruct the hierarchy with only matching permissions
    return permissionsArray
      .map((app) => ({
        ...app,
        sections: app.sections
          .map((section) => ({
            ...section,
            permissions: section.permissions.filter((permission) =>
              searchResults.some(
                (result) =>
                  result.name === permission.name &&
                  result.appName === app.appName &&
                  result.sectionName === section.sectionName
              )
            ),
          }))
          .filter((section) => section.permissions.length > 0),
      }))
      .filter((app) => app.sections.length > 0);
  }, [searchQuery, permissionsArray, fuse]);

  if (!permissionSet) {
    return <FullScreenSpinner />;
  }

  return (
    <Page
      title={permissionSet.name}
      showBack
      breadcrumbs={[
        { label: "Permission sets", path: "/employees/permission-sets" },
        {
          label: "Edit permission set",
        },
      ]}
      className="relative bg-white"
      suffix={
        <Button
          variant="destructive"
          onClick={() => {
            heffl.modal.confirm({
              title: "Delete permission set",
              description:
                "Are you sure you want to delete this permission set? This action cannot be undone.",
              onConfirm: () => {
                deletePermissionSetMutation.mutate(permissionSet.id);
              },
            });
          }}
          loading={deletePermissionSetMutation.isLoading}
          icon={Trash}
          size="sm"
        >
          Delete
        </Button>
      }
    >
      <div className="sticky top-[44px] z-10 py-4 mb-6 w-full">
        <div className="px-4 mx-auto !w-full ">
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e)}
            placeholder="Search permissions..."
            className="!max-w-full"
          />
        </div>
      </div>
      <div className="mx-auto max-w-6xl">
        <div className="flex flex-col gap-8">
          {filteredPermissions
            .filter((permission) => permission.sections.length > 0)
            .map((permissionParent) => (
              <div
                key={permissionParent.appName as string}
                className="overflow-hidden bg-white rounded-lg border border-gray-200 shadow-sm"
              >
                <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-gray-900">
                    {capitalize(permissionParent.appName.split("_").join(" "))}
                  </h2>
                </div>
                <div className="grid grid-cols-1 gap-6 p-6 md:grid-cols-2 lg:grid-cols-3">
                  {permissionParent.sections
                    .filter((section) => section.permissions.length > 0)
                    .map((section) => (
                      <Card
                        key={section.sectionName}
                        title={capitalize(
                          // @ts-ignore
                          section.sectionName.split("_").join(" ")
                        )}
                        className="h-full"
                      >
                        <div className="flex flex-col">
                          {section.permissions.map((permission) => (
                            <PermissionCheckbox
                              name={permission.name}
                              label={permission.label}
                              allowed={permission.allowed}
                              description={`This permission allows users to ${permission.label.toLowerCase()}`}
                              onClick={() =>
                                updatePermissionSetMutation.mutate({
                                  permissionSetId: permissionSet.id,
                                  permissionName: permission.name,
                                  allowed: !permission.allowed,
                                  section: section.sectionName,
                                  parent: permissionParent.appName as string,
                                })
                              }
                              key={permission.name}
                            />
                          ))}
                        </div>
                      </Card>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Page>
  );
};

export default PermissionSetEdit;
