import { FC } from "react";
import EmptyTasks from "@/assets/images/empty-task-2.png";
import EmptyProjects from "@/assets/images/empty-projects-2.png";
import EmptyTimesheets from "@/assets/images/empty-timesheet-2.png";

type EmptyStateImageType = "tasks" | "projects" | "timesheets";

interface EmptyStateProps {
  type: EmptyStateImageType;
  title: string;
  description?: string | null;
  imageClassName?: string;
}

const images = {
  tasks: EmptyTasks,
  projects: EmptyProjects,
  timesheets: EmptyTimesheets,
};

const EmptyState: FC<EmptyStateProps> = ({
  type,
  title,
  description = null,
  imageClassName = "w-40 h-auto",
}) => {
  return (
    <div className="flex flex-col items-center justify-center p-8">
      <img src={images[type]} alt={title} className={imageClassName} />
      <p className="mt-4 text-gray-700 font-medium">{title}</p>
      {description && (
        <p className="mt-2 text-gray-500 text-xs">{description}</p>
      )}
    </div>
  );
};

export default EmptyState;
