import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import enums from "@heffl/server/src/schemas/enums";
import { Card } from "@heffl/ui/components/primitives/card";
import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { FileText } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type InvoiceFilter = {
  statuses: z.infer<typeof enums.invoiceStatus>[];
  clients: number[];
  dates: [Date, Date] | undefined;
};

const TypingCenterReport = () => {
  const [filters, setFilters] = useParamsState<InvoiceFilter>({
    statuses: ["PAID", "PARTIALLY_PAID", "SENT"],
    clients: [],
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
  });
  const [clientSearch, setClientSearch] = useState("");

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  const invoiceFilters = useMemo(
    () => ({
      statuses: filters.statuses,
      clients: filters.clients,
      dates: filters.dates,
    }),
    [filters]
  );

  const { data: invoiceSummary } =
    trpc.sales.reports.typingCenterReport.useQuery(invoiceFilters);

  const navigate = useNavigate();

  return (
    <Page title="Typing Center Report" fullWidth className="sm:!p-0" showBack>
      <div className="flex flex-wrap items-center pt-3 w-full">
        <FilterBar
          className="px-3"
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value as [Date, Date] }),
            },
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<typeof enums.invoiceStatus>[],
                }),
              options: enums.invoiceStatus.options.map((status) => ({
                label: status,
                value: status,
              })),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              value: filters.clients,
              showSearch: true,
              onChange: (value) => setFilters({ clients: value as number[] }),
              onSearch: (value) => setClientSearch(value),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            },
          ]}
        />
      </div>

      {invoiceSummary && (
        <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Invoiced Amount
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(
                invoiceSummary.summary.totalInvoicedAmount,
                "AED"
              )}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Typing Charges
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(invoiceSummary.summary.totalBuyPrice, "AED")}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Total Paid Amount
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(
                invoiceSummary.summary.totalInvoicedAmount,
                "AED"
              )}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Pending Amount
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(invoiceSummary.summary.totalPendingAmount, "AED")}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">Profit</h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(invoiceSummary.summary.totalProfit, "AED")}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Profit Percentage
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {invoiceSummary.summary.profitPercentage.toFixed(2)}%
            </p>
          </Card>

          {/* <Card>
                <h3 className="text-xs font-medium text-gray-500">
                  Average Invoice Value
                </h3>
                <p className="mt-1 text-xl font-semibold text-gray-900">
                  {formatCurrency(
                    invoiceSummary.summary.averageInvoiceValue,
                    "AED"
                  )}
                </p>
              </Card> */}
          {/* <Card>
                <h3 className="text-xs font-medium text-gray-500">Payment Rate</h3>
                <p className="mt-1 text-xl font-semibold text-gray-900">
                  {invoiceSummary.summary.paymentRate.toFixed(2)}%
                </p>
              </Card> */}
        </div>
      )}

      <DataGrid
        rowKey="id"
        name="invoiceSummaryReport"
        className="h-[calc(100vh-190px-var(--header-height))] mt-12"
        label="Invoice Summary"
        loading={!invoiceSummary}
        empty={{
          icon: FileText,
          title: "No invoices",
          description:
            "There are no invoices to display in the summary report.",
        }}
        rows={invoiceSummary?.invoices || []}
        columns={[
          {
            key: "number",
            name: "Number",
            width: 150,
            renderCell: ({ row }) => (
              <div
                className="truncate rounded-md cursor-pointer hover:text-primary-600"
                onClick={() => navigate(`/sales/invoices/details/${row.id}`)}
              >
                {row.number}
              </div>
            ),
          },
          {
            key: "date",
            name: "Date",
            width: 150,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },

          {
            key: "clients",
            name: "Customer",
            width: 200,
            renderCell: ({ row }) => {
              const clientName = row.clients?.name;
              return clientName ? (
                <div
                  className="truncate cursor-pointer hover:text-primary-600 hover:underline"
                  onClick={() =>
                    navigate(`/crm/clients/details/${row.clientId}`)
                  }
                >
                  {clientName}
                </div>
              ) : (
                <span className="text-gray-400">-</span>
              );
            },
          },
          {
            key: "service",
            name: "Service",
            width: 200,
            renderCell: ({ row }) => {
              const services = row.invoiceProducts;
              if (!services || services.length === 0) {
                return <span className="text-gray-400">-</span>;
              }
              return (
                <div className="truncate">
                  {services.map((product) => product.name).join(", ")}
                </div>
              );
            },
          },

          {
            key: "invoiceSubTotal",
            name: "Invoice Sub Total",
            width: 150,
            renderCell: ({ row }) => formatCurrency(row.subTotal, "AED", true),
          },
          {
            key: "invoiceTotalAmount",
            name: "Invoice Total Amount",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.totalAmount, "AED", true),
          },
          {
            key: "invoiceTax",
            name: "Invoice Tax",
            width: 150,
            renderCell: ({ row }) => formatCurrency(row.taxAmount, "AED", true),
          },
          {
            key: "buyPrice",
            name: "Typing Charges",
            width: 150,
            renderCell: ({ row }) => formatCurrency(row.buyPrice, "AED", true),
          },
          {
            key: "paid",
            name: "Paid",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.totalAmount - row.pendingAmount, "AED", true),
          },
          {
            key: "pending",
            name: "Pending",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.pendingAmount, "AED", true),
          },
          {
            key: "status",
            name: "Status",
            width: 150,
            renderCell: ({ row }) => row.status,
          },
          {
            key: "salesPerson",
            name: "Sales Person",
            width: 150,
            renderCell: ({ row }) =>
              row.salesPerson ? formatName(row.salesPerson) : "-",
          },
          {
            key: "receiptNumber",
            name: "Receipt #",
            width: 150,
            renderCell: ({ row }) =>
              row.paymentReceivedAllocations
                .map((allocation) => allocation.paymentsRecieved.number)
                .join(", ") || "-",
          },
        ]}
      />
    </Page>
  );
};

export default TypingCenterReport;
