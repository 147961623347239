import { Descriptions } from "antd";
import { DescriptionsItemType } from "antd/es/descriptions";

const InfoItems = ({
  items,
  bordered = true,
  column = undefined,
  size = "middle",
}: {
  items: DescriptionsItemType[];
  bordered?: boolean;
  column?: number;
  size?: "small" | "middle";
}) => {
  return (
    <Descriptions
      column={column}
      items={items}
      bordered={bordered}
      size={size}
    />
  );
};

export default InfoItems;
