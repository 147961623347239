import { CircleDashed, Loader } from "lucide-react";
import { useState } from "react";
import { cn, isMobile } from "../lib/utils";
import { Badge } from "./primitives/badge";
import Select from "./primitives/select";

type StageSize = "s" | "m" | "l";

interface StagesInputProps<T extends string | number> {
  stages: {
    id: T;
    text: string;
  }[];
  value?: T;
  onChange?: (value: T) => void;
  loading?: boolean;
  size?: StageSize;
  className?: string;
}

const sizeClasses: Record<StageSize, string> = {
  s: "h-7 text-xs min-w-[80px]",
  m: "h-10 text-sm min-w-[100px]",
  l: "h-14 text-lg min-w-[120px]",
};

type StageProps = {
  selected: boolean;
  index: number;
  stageCount: number;
  text: string;
  loading: boolean;
  selectedIndex: number;
  className?: string;
  onClick: () => void;
  size?: StageSize;
};

const Stage = ({
  index,
  text,
  selected,
  stageCount,
  onClick,
  loading = false,
  selectedIndex,
  className,
  size = "m",
}: StageProps) => {
  const stage =
    index === 0 ? "FIRST" : index === stageCount - 1 ? "LAST" : "MIDDLE";
  return (
    <div
      className={cn(
        "rounded-sm cursor-pointer relative transition-all duration-600",
        selected || index < selectedIndex
          ? "bg-primary hover:bg-green-600 text-white"
          : "bg-gray-200 hover:bg-gray-300 text-gray-600",
        stage === "FIRST" && "stage-first",
        stage === "MIDDLE" && "stage-middle ml-[-6px]",
        stage === "LAST" && "stage-last ml-[-6px]",
        "flex items-center justify-center font-medium px-4",
        sizeClasses[size],
        className
      )}
      onClick={onClick}
    >
      <p
        className={cn(
          "sm:block hidden whitespace-nowrap",
          loading && "invisible"
        )}
      >
        {text}
      </p>
      {loading && (
        <Loader className="absolute  h-3.5 animate-twSpin animate-infinite" />
      )}
    </div>
  );
};

export const StagesInput = <T extends string | number>({
  stages,
  value,
  onChange,
  loading,
  size = "m",
  className,
}: StagesInputProps<T>) => {
  const [isLastClicked, setIsLastClicked] = useState<T | undefined>(undefined);

  const selectedIndex = stages.findIndex((stage) => stage.id === value);

  return isMobile() ? (
    <Select
      icon={CircleDashed}
      previewParentClassName="w-full"
      options={stages.map((stage) => ({
        label: stage.text,
        value: stage.id,
      }))}
      value={value}
      className=""
      render={({ label, value }) => (
        <Badge
          variant={
            value === "UNQUALIFIED"
              ? "error"
              : value === "CONVERTED"
              ? "success"
              : "default"
          }
          small={false}
        >
          {label}
        </Badge>
      )}
      onChange={onChange}
    />
  ) : (
    <div className={cn("flex flex-row", className)}>
      {stages.map((stage, index) => {
        const isSelected = stage.id === value;
        return (
          <Stage
            key={stage.id}
            onClick={() => {
              setIsLastClicked(stage.id);
              onChange?.(stage.id);
            }}
            index={index}
            stageCount={stages.length}
            text={stage.text}
            selected={isSelected}
            selectedIndex={selectedIndex}
            loading={(loading && isLastClicked === stage.id) || false}
            size={size}
          />
        );
      })}
    </div>
  );
};

StagesInput.displayName = "StagesInput";
