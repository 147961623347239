import Page from "@/components/page";
import { CustomFieldInput } from "@/helpers/customFields/custom-fields-input-form";
import { trpc } from "@/helpers/trpc";
import useIntegrations from "@/lib/hooks/useIntegrations";
import { integrationProviders } from "@heffl/server/src/helpers/integrations/index";
import Schemas from "@heffl/server/src/schemas";
import { CustomIcon } from "@heffl/ui/components/icons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const IntegrationCard: React.FC<{
  icon: string;
  provider: keyof typeof integrationProviders;
  onConnect: () => void;
}> = ({ icon, provider, onConnect }) => {
  const integration = useIntegrations();
  const isConnected = integration.get(provider).integrated;

  const integrationDetails = integrationProviders[provider];

  return (
    <div className="flex gap-3 items-center p-4 rounded-md border shadow-md">
      <CustomIcon icon={icon} className="w-20 h-20" />
      <div>
        <p className="text-lg font-medium">{integrationDetails.label}</p>
        <p className="w-4/5 text-sm text-gray-500">
          {integrationDetails.description}
        </p>
      </div>
      <Button
        onClick={onConnect}
        disabled={isConnected}
        variant={isConnected ? "secondary" : "primary"}
        icon={Link}
      >
        {isConnected ? "Connected" : "Connect"}
      </Button>
    </div>
  );
};

const Integrations = () => {
  const form = useForm<z.infer<typeof Schemas.integrations.integration>>({
    resolver: zodResolver(Schemas.integrations.integration),
  });

  const provider = form.watch("provider");

  const [showInputEntry, setShowInputEntry] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const { data: integrations } = trpc.integrations.list.useQuery();
  const initiateZohoBooksOAuth =
    trpc.integrations.initiateZohoBooksOAuth.useMutation();

  const getAuthUrl = trpc.integrations.getAuthUrl.useMutation();

  const addIntegrationMutation = trpc.integrations.add.useMutation({
    onError: (error) => {
      toast.error(error.message);
      setIntegrationLoading(false);
    },
    onSuccess: async (_, { provider }) => {
      if (provider === "zoho_books") {
        const authUrl = await initiateZohoBooksOAuth.mutateAsync();
        window.open(authUrl, "_self");
      } else if (provider === "zoho_mail") {
        const authUrl = await getAuthUrl.mutateAsync({
          provider,
        });
        window.open(authUrl, "_self");
      }
      setShowInputEntry(false);
      setIntegrationLoading(false);
    },
  });

  const onDataSubmit = (
    values: z.infer<typeof Schemas.integrations.integration>
  ) => {
    setIntegrationLoading(true);
    addIntegrationMutation.mutateAsync({
      provider: values.provider,
      data: values.data,
      connected: values.provider === "telr" ? true : false,
    });
  };

  useEffect(() => {
    const zohoIntegration = integrations?.find(
      (i) => i.provider === "zoho_books"
    );
    if (zohoIntegration) {
      form.reset({
        provider: "zoho_books",
        data: zohoIntegration.data,
      });
    }
  }, [integrations]);

  if (!integrations) {
    return <FullScreenSpinner />;
  }

  return (
    <Page
      title="Integrations"
      description="Connect your favorite apps to heffl."
    >
      <ModalDrawer
        title={`Connect ${
          provider ? integrationProviders[provider].label : ""
        }`}
        open={showInputEntry}
        onClose={() => setShowInputEntry(false)}
        footer={
          <Button
            onClick={() => {
              form.handleSubmit(onDataSubmit)();
            }}
            variant="primary"
            loading={addIntegrationMutation.isLoading || integrationLoading}
          >
            Continue
          </Button>
        }
      >
        <Form {...form} onSubmit={onDataSubmit}>
          {provider &&
            integrationProviders[provider].inputs.map((field) => (
              <FormField
                name={`data.${field.name}`}
                key={field.name}
                label={field.label}
              >
                <CustomFieldInput field={field} />
              </FormField>
            ))}
        </Form>
      </ModalDrawer>
      <div className="grid grid-cols-3 gap-3">
        <IntegrationCard
          icon="zoho"
          provider="zoho_books"
          onConnect={() => {
            form.reset({ provider: "zoho_books" });
            setShowInputEntry(true);
          }}
        />
        <IntegrationCard
          icon="telr"
          provider="telr"
          onConnect={() => {
            form.reset({ provider: "telr" });
            setShowInputEntry(true);
          }}
        />
        <IntegrationCard
          icon="zohomail"
          provider="zoho_mail"
          onConnect={() => {
            form.reset({ provider: "zoho_mail" });
            setShowInputEntry(true);
          }}
        />
      </div>
    </Page>
  );
};

export default Integrations;
