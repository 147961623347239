/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DynamicTagInjectFormat } from "./dynamicTags";

interface Props {
  contentHtml: string;
  dataToInject: DynamicTagInjectFormat;
  css?: string;
}

const injectDynamicTags = (props: Props) => {
  let injectedContent = props.contentHtml;
  const dataToInjectArray: { key: string; value: string | null }[] =
    Object.keys(props.dataToInject).reduce(
      (acc: Record<string, string>[], key) => {
        // @ts-ignore
        const subKeys = Object.keys(props.dataToInject[key]);
        const subKeyObjects = subKeys.map((subKey) => ({
          key: subKey,
          // @ts-ignore
          value: props.dataToInject[key][subKey] ?? "",
        }));
        acc.push(...subKeyObjects);
        return acc;
      },
      []
    ) as { key: string; value: string | null }[];

  dataToInjectArray.forEach((data: { key: string; value: string | null }) => {
    data.value = data.value || "";
    injectedContent = injectedContent.replaceAll(
      `{{${data.key}}}`,
      data.value.toString()
    );
  });
  return `
  <style>${props.css}</style>
  ${injectedContent}`;
};

export default injectDynamicTags;
