import { trpc } from "@/helpers/trpc";
import { ProjectTaskDetailsModal } from "@/pages/projects/tasks/components/project-task-modals";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { produce } from "immer";
import { useState } from "react";
import { TTask } from "./SortableTaskCard";
import TaskBoard from "./TaskBoard";
import TaskCard from "./TaskCard";
import { isMobile } from "@heffl/ui/lib/utils";
import { CircleDot, Pause, Play, CheckCircle } from "lucide-react";

export const taskStatuses = [
  {
    id: "OPEN",
    label: "To do",
    value: "OPEN",
    color: "bg-gray-400",
    hex: "#9CA3AF",
    icon: CircleDot,
  },
  {
    id: "IN_PROGRESS",
    label: "In progress",
    value: "IN_PROGRESS",
    color: "bg-blue-400",
    hex: "#60A5FA",
    icon: Play,
  },
  {
    id: "ON_HOLD",
    label: "On hold",
    value: "ON_HOLD",
    color: "bg-red-400",
    hex: "#F87171",
    icon: Pause,
  },
  {
    id: "COMPLETED",
    label: "Completed",
    value: "COMPLETED",
    color: "bg-green-400",
    hex: "#4ADE80",
    icon: CheckCircle,
  },
] as const;

export const getProjectTaskStatus = (status: ProjectTaskStatus) => {
  return taskStatuses.find(
    (s) => s.value === status
  ) as (typeof taskStatuses)[number];
};

type ProjectTaskStatus = (typeof taskStatuses)[number]["value"];

const TasksKanban: React.FC<{
  tasks: TTask[];
  onChange: (tasks: TTask[]) => void;
  projectId?: number;
}> = ({ tasks, onChange, projectId }) => {
  const [activeCard, setActiveCard] = useState<TTask | null>(null);
  const [taskDetailsModalOpen, setTaskDetailsModalOpen] = useState<
    number | null
  >(null);

  const bulkTaskUpdate = trpc.projects.tasks.bulkUpdate.useMutation();

  const sensors = useSensors(
    useSensor(isMobile() ? TouchSensor : PointerSensor, {
      activationConstraint: isMobile()
        ? {
            delay: 300,
            tolerance: 5,
          }
        : {
            distance: 10,
          },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (e: DragStartEvent) => {
    setActiveCard((e.active.data.current?.task as TTask) || null);
  };

  const handleDragCancel = () => {
    setActiveCard(null);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const activeListId = active.data.current?.sortable.containerId;
    const overListId = over?.data.current?.sortable.containerId || over?.id;
    if (activeListId === overListId || !overListId) return;
    const updatedTasks = produce(tasks, (draft) => {
      const activeTask = draft.find((task) => task.id === active.id);
      if (activeTask) {
        activeTask.status = overListId as ProjectTaskStatus;
      }
    });
    onChange(updatedTasks);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveCard(null);
    if (!over) return;
    const activeListId = active.data.current?.sortable.containerId;
    const overListId = over.data.current?.sortable.containerId || over.id;
    if (activeListId === overListId) {
      const updatedData = produce(tasks, (draft) => {
        const activeTask = draft.find((task) => task.id === active.id);
        if (activeTask) {
          activeTask.status = overListId as ProjectTaskStatus;
        }
      });
      onChange(updatedData);
      const overListTasks = updatedData.filter(
        (task) => task.status === overListId
      );
      const updateOverTasks = overListTasks.map((task, i) => ({
        id: task.id,
        status: overListId as unknown as ProjectTaskStatus,
        position: i + 1,
      }));
      bulkTaskUpdate.mutate(updateOverTasks);
    }
  };

  if (!tasks) return <FullScreenSpinner />;
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      id="2"
    >
      {!!taskDetailsModalOpen && (
        <ProjectTaskDetailsModal
          open={taskDetailsModalOpen !== null}
          onClose={() => setTaskDetailsModalOpen(null)}
          taskId={taskDetailsModalOpen}
        />
      )}

      <main className="bg-transition-body flex gap-3 h-[calc(100vh-120px)] sm:h-[calc(100vh-160px)] overflow-x-auto overflow-y-hidden">
        {taskStatuses.map((taskStatus) => (
          <TaskBoard
            sectionLabel={taskStatus.label}
            sectionId={taskStatus.value}
            tasks={tasks.filter((t) => t.status === taskStatus.value)}
            key={taskStatus.value}
            onTaskClick={(id) => {
              setTaskDetailsModalOpen(id);
            }}
            inProject={projectId !== undefined}
          />
        ))}
      </main>
      <DragOverlay>
        {activeCard && (
          <div className="transition-transform transform -rotate-2 !max-w-[260px] ">
            <TaskCard
              task={activeCard}
              onTaskClick={() => {}}
              className="border-1.5df  border-primary-500"
            />
          </div>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default TasksKanban;
