import DropMenu from "@/components/DropMenu";
import { UserAvatar } from "@/components/UserAvatar";
import { AddActivityModal } from "@/components/actionsModals/activities-modals";
import { AddLogModal } from "@/components/actionsModals/log-modals";
import { AddNotesModal } from "@/components/actionsModals/notes-modals";
import { AddTaskModal } from "@/components/actionsModals/tasks-modals";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { formatCurrency, formatName, isMobile } from "@heffl/ui/lib/utils";
import enums from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  CollapsibleContent,
  CollapsibleTrigger,
} from "@heffl/ui/components/primitives/collapsible";
import CopyToClipboard from "@heffl/ui/components/primitives/copy-to-clipboard";
import Select from "@heffl/ui/components/primitives/select";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import NextTabs from "@heffl/ui/components/tabs";

import { Collapsible } from "@radix-ui/react-collapsible";
import {
  Calendar,
  CalendarCheck2,
  CheckCircle,
  ChevronDown,
  LucideIcon,
  Mail,
  MessageCircle,
  MessageSquare,
  PenSquare,
  Pencil,
  Phone,
  Plus,
  PlusCircle,
  StickyNote,
} from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";
import AddDealDrawer from "../../deals/components/AddDealDrawer";
import { ClientCard, InfoItem, MobileRoundedButton } from "../../deals/details";
import { EditContactDrawer } from "../list";
import ContactFocus from "./focus";
import ContactHistory from "./history";
import toast from "react-hot-toast";
import { Icons } from "@heffl/ui/components/icons";
import {
  callMobile,
  messageOnWhatsApp,
  openEmail,
} from "@/pages/field-service/staff-app/schedules/details";

const Details = () => {
  const params = useParams();
  const contactId = Number(params.contactId);
  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useImmer<{
    activity: boolean;
    task: boolean;
    note: boolean;
    log: z.infer<typeof enums.activityTypes> | undefined;
  }>({
    activity: false,
    task: false,
    note: false,
    log: undefined,
  });
  const [editContact, setEditContact] = useState(false);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [addDeal, setAddDeal] = useState(false);

  const contactUpdateMutation = trpc.contacts.update.useMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const { data: contactDetails } = trpc.contacts.details.useQuery(contactId);
  const { data: clientsData } = trpc.clients.list.useQuery({
    pageNo: 1,
    pageSize: 20,
  });

  if (!contactDetails) return <FullScreenSpinner />;

  const ContactDetails = (
    <ScrollArea className={`block px-3 w-full h-full sm:w-1/5 sm:block`}>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1 items-center mt-4 w-full">
          <UserAvatar user={contactDetails} size="lg" />
          <h1 className="text-lg font-semibold text-gray-800">
            {formatName(contactDetails)}
          </h1>
          <div className="flex flex-wrap gap-6 justify-center mt-2 sm:gap-2 sm:hidden">
            <MobileRoundedButton
              icon={Phone}
              label="Call"
              disabled={!contactDetails.mobile?.length}
              onClick={() => {
                callMobile(contactDetails.mobile || "");
              }}
            />
            <MobileRoundedButton
              icon={Mail}
              label="Email"
              disabled={!contactDetails.email?.length}
              onClick={() => {
                openEmail(contactDetails.email || "");
              }}
            />
            <MobileRoundedButton
              icon={Icons.whatsapp as LucideIcon}
              label="Message"
              disabled={!contactDetails.mobile?.length}
              onClick={() => {
                messageOnWhatsApp(contactDetails.mobile || "", "");
              }}
            />
          </div>
          <Button
            variant="outline"
            size="sm"
            className="py-0 mt-4 text-xs"
            onClick={() => setEditContact(true)}
          >
            <Pencil className="h-3.5" />
            Edit contact
          </Button>
          <div className="flex flex-row gap-2">
            <p className="text-sm text-gray-400">
              {contactDetails.clients?.name}
            </p>
          </div>
        </div>

        <div className="flex flex-row gap-1 justify-center items-center w-full">
          <div className="w-1 h-1 bg-green-500 rounded-full" />
          <p className="text-xs text-gray-400">
            Last activity:{" "}
            {contactDetails.updatedAt?.toDateString() || "Not updated"}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-5">
        {!!contactDetails.email && (
          <InfoItem
            label="Email"
            value={
              <div className="flex flex-wrap gap-1">
                <div className="flex gap-1 items-center px-2 py-1 bg-gray-100 rounded-xl">
                  <p className="text-xs">{contactDetails.email}</p>
                  <CopyToClipboard copyText={contactDetails.email} />
                </div>
              </div>
            }
          />
        )}

        <InfoItem label="Phone" value={contactDetails.mobile} />
        <InfoItem
          label="Job Title"
          value={contactDetails?.jobTitle || "Not added"}
        />
      </div>
    </ScrollArea>
  );

  const ContactActivity = (
    <ScrollArea
      className={` sm:w-3/5 !h-full sm:px-4 sm:py-4 p-0 sm:block bg-white sm:bg-gray-100 border-none sm:border-l border-gray-200 block  w-full  `}
    >
      <div className="flex justify-center items-center mt-3 w-full sm:justify-end">
        <div className="grid grid-cols-4 gap-5 sm:gap-2 sm:flex">
          <DropMenu
            items={[
              {
                label: "Email",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "email";
                  }),
                icon: Mail,
              },
              {
                label: "Call",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "call";
                  }),
                icon: Phone,
              },
              {
                label: "Whatsapp message",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "whatsapp_message";
                  }),
                icon: MessageCircle,
              },
              {
                label: "Meeting",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "meeting";
                  }),
                icon: Calendar,
              },
              {
                label: "SMS",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "sms";
                  }),
                icon: MessageSquare,
              },
            ]}
          >
            <MobileRoundedButton icon={PenSquare} label="Log" />
          </DropMenu>

          <MobileRoundedButton
            icon={StickyNote}
            label="Note"
            onClick={() =>
              setShowAdd((draft) => {
                draft.note = true;
              })
            }
          />
          <MobileRoundedButton
            icon={CheckCircle}
            label="Task"
            onClick={() =>
              setShowAdd((draft) => {
                draft.task = true;
              })
            }
          />
          <MobileRoundedButton
            icon={CalendarCheck2}
            label="Activity"
            onClick={() =>
              setShowAdd((draft) => {
                draft.activity = true;
              })
            }
          />
        </div>
      </div>

      <ContactFocus />
      <ContactHistory />
    </ScrollArea>
  );

  const ContactResources = (
    <ScrollArea
      className={`box-border p-3 px-0 w-full h-full sm:w-1/5 sm:block sm:h-[85vh]`}
    >
      <div className="flex flex-col gap-3">
        {/* Deals section */}
        <Collapsible defaultOpen className="p-4">
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Company</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            {/* Company */}
            {contactDetails.clients ? (
              <ClientCard company={contactDetails.clients} />
            ) : (
              <div className="flex flex-col gap-3 p-4 border-b border-gray-200">
                {!showAddCompany ? (
                  <Button
                    onClick={() => setShowAddCompany(true)}
                    variant="outline"
                    className="w-full text-gray-800"
                  >
                    <PlusCircle className="h-4" />
                    Add Company
                  </Button>
                ) : (
                  <Select
                    options={
                      clientsData?.clients?.map((company) => ({
                        label: company.name,
                        value: company.id,
                      })) || []
                    }
                    onChange={async (value) => {
                      if (!value) {
                        setShowAddCompany(false);
                      }
                      contactUpdateMutation.mutate({
                        id: contactId,
                        contact: {
                          clientId: Number(value),
                        },
                      });
                    }}
                  />
                )}
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
        {/* Company ends here */}

        {/* Deals section */}
        <Collapsible defaultOpen className="p-4">
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Deal</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            <Button
              onClick={() => setAddDeal(true)}
              variant="outline"
              className="w-full text-gray-600"
            >
              <Plus className="w-4 h-4 text-gray-500" />
              Create new deal
            </Button>

            {contactDetails.dealContacts.map((dealContact) => (
              <div
                key={dealContact.dealId}
                className="flex flex-col gap-2 p-4 w-full rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-50"
                onClick={() =>
                  navigate(`/crm/deals/details/${dealContact.deals.id}`)
                }
              >
                <div className="flex flex-row gap-2">
                  <p className="text-xs text-gray-400">
                    Closing date:{" "}
                    {dealContact.deals.expectedCloseDate?.toDateString()}
                  </p>
                </div>
                <Badge className="text-xs text-blue-500 bg-blue-600 w-fit">
                  {dealContact.deals.status}
                </Badge>
                <p className="text-sm font-semibold">
                  {dealContact.deals.title}
                </p>
                {!!dealContact.deals.price && (
                  <p>
                    {formatCurrency(
                      dealContact.deals.price,
                      dealContact.deals.currencies.symbol
                    )}
                  </p>
                )}
              </div>
            ))}
          </CollapsibleContent>
        </Collapsible>
        {/* Deals section ends here */}
      </div>
    </ScrollArea>
  );

  return (
    <Page
      fullWidth
      showBack
      title="Contact details"
      breadcrumbs={[
        {
          label: "Contacts",
          path: "/crm/contacts",
        },
        {
          label: "Contact details",
          path: `/crm/contacts/details/${contactDetails.id}`,
        },
      ]}
      className="pt-0"
    >
      <AddActivityModal
        open={showAdd.activity}
        onClose={() =>
          setShowAdd((draft) => {
            draft.activity = false;
          })
        }
        contactId={contactId}
      />
      <AddNotesModal
        open={showAdd.note}
        onClose={() =>
          setShowAdd((draft) => {
            draft.note = false;
          })
        }
        contactId={contactId}
      />
      <AddTaskModal
        open={showAdd.task}
        onClose={() =>
          setShowAdd((draft) => {
            draft.task = false;
          })
        }
        contactId={contactId}
      />
      {showAdd.log && (
        <AddLogModal
          open={!!showAdd.log}
          onClose={() =>
            setShowAdd((draft) => {
              draft.log = undefined;
            })
          }
          contactId={contactId}
          type={showAdd.log}
        />
      )}
      <AddDealDrawer
        open={addDeal}
        onClose={() => {
          setAddDeal(false);
        }}
      />
      {editContact && (
        <EditContactDrawer
          id={contactId}
          onClose={() => setEditContact(false)}
        />
      )}

      {isMobile() ? (
        <NextTabs
          className="mt-3"
          tabs={[
            {
              id: "info",
              label: "Info",
              content: ContactDetails,
            },
            {
              id: "activity",
              label: "Activity",
              content: ContactActivity,
            },
            {
              id: "resources",
              label: "Resources",
              content: ContactResources,
            },
          ]}
        />
      ) : (
        <div className="flex flex-row mt-0  w-full sm:mb-0 mb-[60px] sm:h-[92vh] h-full">
          {ContactDetails}
          {ContactActivity}
          {ContactResources}
        </div>
      )}
    </Page>
  );
};

export default Details;
